import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import api from "Service/api/api";
import {
  IReducerWithCallbackWithError,
  IReducerWithCallbackWithErrorParams,
} from "Shared/Types/shared";
import {
  TExportSentData,
  TImportSentData,
} from "Shared/Utils/yup/vehicleImport";

const getImportData = createAsyncThunk(
  "import/getImportData",
  async (params: any | null) => {
    try {
      const response = await api.importData.getImportType(params);
      return response.data.results;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const dataMigrationImportBulk = createAsyncThunk(
  "import/dataMigrationImportBulk",
  async (params: { section: string; csv_file: string }) => {
    try {
      const response = await api.importData.dataMigrationImportBulk(params);
      return response.data.results;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const dataMigrationImportData = createAsyncThunk(
  "import/dataMigrationImportData",
  async ({
    data,
    onSuccess,
    onError,
  }: IReducerWithCallbackWithError<TImportSentData>) => {
    try {
      const response = await api.importData.dataMigrationImportData(data);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        onError?.(error);
      }
      throw error;
    }
  },
);
const dataMigrationExportData = createAsyncThunk(
  "import/dataMigrationExportData",
  async ({
    data,
    onSuccess,
    onError,
    params,
  }: IReducerWithCallbackWithErrorParams<TExportSentData>) => {
    try {
      const response = await api.importData.dataMigrationExportData(
        data,
        params,
      );
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        onError?.(error);
      }
      throw error;
    }
  },
);

export {
  getImportData,
  dataMigrationImportBulk,
  dataMigrationExportData,
  dataMigrationImportData,
};
