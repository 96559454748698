import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const PartLocationsList = lazyLoader(
  () => import("Containers/Settings/PartLocations/PartLocationsList")
);

export const PartLocations = () => {
  return (
    <Layout>
      <PartLocationsList />
    </Layout>
  );
};
