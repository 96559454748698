import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  LIMIT_CHARTS_PAGE,
  LIMIT_PAGE,
  LIMIT_USER,
} from "Shared/Constants/app";
import { PageListSearchParams } from "Shared/Types/shared";
import dayjs from "dayjs";
import "dayjs/locale/en";
import relativeTime from "dayjs/plugin/relativeTime";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import memoizee from "memoizee";
import { useLocation } from "react-router-dom";
import api from "Service/api/api";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

export type DateType = {
  startDate: string | Date;
  endDate?: string | Date;
};

export const getNumPage = (count: number, limit?: number) => {
  if (!count) return 1;
  return Math.ceil(count / (limit || LIMIT_PAGE));
};
export const getNumPageForCharts = (count: number) => {
  return Math.ceil(count / LIMIT_CHARTS_PAGE);
};
export const getOffset = (page: number, limit?: number) => {
  if (page <= 1) return 0;
  return (page - 1) * (limit || 10);
};

export const getNumPageUser = (count: number) => {
  return Math.ceil(count / LIMIT_USER);
};

export const getOffsetUser = (count: number, page: number) => {
  if (page <= 1) return 0;
  return (page - 1) * LIMIT_USER;
};

export const format = (date: string | Date, formatDate = "h:mmA M/D/YYYY") => {
  const localDate = dayjs(date);
  return localDate.format(formatDate);
};
export const convertTo12HourFormat = (time: string | undefined) => {
  if (!time) return "";
  const [hour, minute] = time.split(":").map(Number);
  const isPM = hour >= 12;
  const adjustedHour = hour % 12 || 12;
  const period = isPM ? "PM" : "AM";
  return `${adjustedHour}:${minute.toString().padStart(2, "0")} ${period}`;
};

export const daysToSeconds = (days: number) => {
  return days * 86400;
};
export const formatToRelativeTime = (date: string | Date) => {
  const localDate = dayjs(date);
  return localDate.fromNow();
};

export const getTimeDiff = ({ startDate, endDate }: DateType) => {
  const start = dayjs(startDate);
  const end = endDate ? dayjs(endDate) : dayjs();
  const diffInDays = end.diff(start, "day");
  const diffInMonths = end.diff(start, "month");
  const remainingDays = end.diff(start.add(diffInMonths, "month"), "day");

  if (diffInMonths > 0) {
    if (remainingDays > 0) {
      return {
        months: diffInMonths,
        days: remainingDays,
        text: `${diffInMonths} m, ${remainingDays} d`,
      };
    } else {
      return {
        months: diffInMonths,
        days: 0,
        text: `${diffInMonths} m`,
      };
    }
  } else {
    return {
      months: 0,
      days: diffInDays,
      text: `${diffInDays} d`,
    };
  }
};

export const convertDuration = (dur: number): string => {
  const months = Math.floor(dur / 30);
  const days = dur % 30;

  if (months === 0) {
    return `${days} d`;
  } else if (days === 0) {
    return `${months} m`;
  } else {
    return `${months} m ${days} d`;
  }
};
export const formatCurrency = ({
  value,
  prefix = "$",
  maxLength = 16,
}: {
  value: string | number;
  prefix?: string;
  maxLength?: number;
}): { currencyValue: number; currencyText: string } | null => {
  // base constants
  const integerPartDelimiter = ",";
  const maxValueLength = maxLength > 16 ? 16 : maxLength;

  // clearing input value
  let currencyText =
    typeof value === "number"
      ? String(value)
      : value
          .replace(prefix, "")
          .replace(new RegExp(integerPartDelimiter, "g"), "")
          .slice(0, maxValueLength);

  // main check
  const invalidCurrencyRegex =
    /^[^0-9]|^0[^.]|[0-9.]+[^0-9.]|\.[0-9]*\.|\.\d{3,}/;
  if (invalidCurrencyRegex.test(currencyText)) {
    return null;
  }
  if (currencyText === "") {
    return {
      currencyValue: 0,
      currencyText: "",
    };
  }

  // fractional part check
  const maxFractionLength = 2;
  const invalidFractionRegex = /\.\d{0,1}$/;
  const integerLengthDiff =
    maxValueLength - maxFractionLength - currencyText.length;

  if (
    integerLengthDiff < 0 &&
    !(integerLengthDiff === -1 && currencyText.at(-2) === ".")
  ) {
    currencyText = currencyText.replace(invalidFractionRegex, "");
  }

  // assigning values
  const currencyValue = parseFloat(currencyText);
  currencyText = currencyText.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    integerPartDelimiter,
  );

  return {
    currencyValue,
    currencyText: prefix + currencyText,
  };
};

export const customRound = ({
  num,
  roundingThreshold = 6,
  significantSigns = 16,
}: {
  num: number;
  roundingThreshold?: number;
  significantSigns?: number;
}) => {
  const [integerPart, fractionalPart] = num.toString().split(".");
  const integerNum = Number(integerPart);

  if (fractionalPart === undefined) {
    return integerNum;
  }

  const splittedFractionalPart = fractionalPart
    .slice(0, significantSigns)
    .split("")
    .map((item) => Number(item));

  // rounding
  for (let i = fractionalPart.length - 2; i >= 1; i -= 1) {
    if (splittedFractionalPart[i + 1] >= roundingThreshold) {
      splittedFractionalPart[i] =
        splittedFractionalPart[i] === 9
          ? splittedFractionalPart[i]
          : (splittedFractionalPart[i] += 1);
    }
  }

  const floatPointNum = parseFloat(
    "0." + splittedFractionalPart.slice(0, 2).join(""),
  );

  return integerNum + floatPointNum;
};

export const formatBackend = (
  date: string | Date,
  formatDate = "YYYY-MM-DDThh:mm:ss.SSSSSSZ",
) => {
  let utcDate = dayjs(date);

  // Check if it's a Date object
  if (date instanceof Date) {
    utcDate = dayjs(date); // Convert to dayjs object
  }

  // Convert to UTC (timezone offset 0) and format for backend
  return utcDate.utc().format(formatDate);
};

export const convertIntoDateTime = (
  date: string,
  time: string | undefined | null,
) => {
  return format(date + time, "YYYY-MM-DDThh:mm:ssZ");
};
export const formatDateTimeToISO = (
  date: string,
  time: string | undefined | null,
) => {
  if (!date) {
    return "Invalid Date";
  }

  const combinedDateTime = `${date}T${time || "00:00:00"}`;

  const dateTime = new Date(combinedDateTime);

  if (isNaN(dateTime.getTime())) {
    return "Invalid Date";
  }

  return dateTime.toISOString();
};

export const toCapitalize = (text?: string) => {
  if (!text) return "---";
  return text[0].toUpperCase() + text.slice(1).toLowerCase();
};

export const getShortValue = (value: string, length = 36) => {
  if (value?.length > length) {
    return value.slice(0, length) + " . . . ";
  }

  return value;
};

export const safeAppendToFormData = (
  formData: FormData,
  key: string,
  value?: string,
) => {
  if (!value) return;
  formData.append(key, value);
};

export const createMemoizedThunk = <TParams, TResult>(
  type: string,
  apiFunction: (params: TParams) => Promise<TResult>,
  maxAgeMilliseconds: number = 3 * 1000,
) => {
  const memoizedApiFunction = memoizee(
    async (params: TParams) => {
      try {
        const response = await apiFunction(params);
        return response;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    {
      normalizer: (args: [TParams]) => JSON.stringify(args),
      promise: true,
      max: 50,
      maxAge: maxAgeMilliseconds,
    },
  );

  return createAsyncThunk(type, async (params: TParams) => {
    return await memoizedApiFunction(params);
  });
};

export const getPageListSearchParams = <
  T extends Record<string, number | boolean | string>,
>({
  paramNames,
  location,
  predefinedParams = {},
}: {
  paramNames: (keyof T | "page")[];
  location: ReturnType<typeof useLocation>;
  predefinedParams?: PageListSearchParams<T>;
}): PageListSearchParams<T> => {
  const params = new URLSearchParams(location.search);
  const result: PageListSearchParams<T> = { ...predefinedParams };
  const numberRegex = /^-?\d*\.?\d+$/;
  const booleanRegex = /^(true|false)$/i;
  const limitValue = +(params.get("limit") || LIMIT_PAGE);
  result.limit = limitValue;

  paramNames.forEach((key) => {
    const value = params.get(key as string);
    if (value === null || result[key]) {
      return;
    }
    const limitValue = +(parseInt(value, 10) || LIMIT_PAGE);
    if (key === "limit") {
      result.limit = limitValue;
    }
    if (key === "page") {
      result.offset = getOffset(parseInt(value, 10), limitValue);
    } else if (numberRegex.test(value)) {
      (result[key] as number) = parseFloat(value);
    } else if (booleanRegex.test(value)) {
      (result[key] as boolean) = value === "true";
    } else {
      result[key] = value as T[keyof T];
    }
  });

  return result;
};

export const createDocuments = async (selectedFiles: File[]) => {
  const createdFiles: string[] = [];
  await Promise.all(
    selectedFiles?.map((file) => {
      const formData = new FormData();
      formData.append("file", file, file.name);
      return api.document.createDocument(formData);
    }),
  ).then((documents) => {
    documents.forEach((document) => createdFiles.push(document.data.id));
  });

  return createdFiles;
};

export const joinUserNames = ({
  first = null,
  last = null,
  middle = null,
  order = "first_last",
}: {
  first?: string | null;
  last?: string | null;
  middle?: string | null;
  order?: string;
}): string => {
  const orderParts = order.split("_") as Array<keyof typeof nameParts>;

  const nameParts = {
    first: first,
    last: last,
    middle: middle,
  };

  const filteredParts = orderParts
    .map((part) => nameParts[part])
    .filter(Boolean);

  return filteredParts.join(" ");
};

export const cropText = ({
  value = "",
  maxLength = 30,
  placeholder = "...",
  from = "end",
}: {
  value?: string | null;
  maxLength?: number;
  placeholder?: string;
  from?: "start" | "end";
}): string => {
  if (value === undefined || value === null) {
    return "";
  }

  if (value.length <= maxLength) {
    return value;
  }

  if (from === "end") {
    return value.substring(0, maxLength) + placeholder;
  } else {
    return placeholder + value.substring(value.length - maxLength);
  }
};

export const breakString = (input?: string | null, chunkSize = 15): string => {
  if (input === undefined || input === null) {
    return "";
  }

  const words = input.split(" ");
  const resultChunks: string[] = [];

  words.forEach((word) => {
    if (word.length > chunkSize) {
      const regex = new RegExp(`.{1,${chunkSize}}`, "g");
      const subChunks = word.match(regex);
      if (subChunks) {
        resultChunks.push(subChunks.join(" "));
      } else {
        resultChunks.push(word);
      }
    } else {
      resultChunks.push(word);
    }
  });

  return resultChunks.join(" ");
};

export const splitSnakeCaseString = (input: string) => {
  return input
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const splitLastPathSegment = (path: string): string => {
  const lastSegment = path.replace(/\/+$/, "").split("/").pop() || "";

  function splitCamelCase(str: string): string {
    return str
      .replace(/[-_]/g, " ") // Replace hyphens and underscores with spaces
      .replace(/([A-Z])/g, " $1") // Add space before capital letters
      .replace(/^./, function (str) {
        return str.toUpperCase();
      })
      .trim();
  }

  return splitCamelCase(lastSegment);
};
