import { client } from "Service/axios";
import {
  SAMSARA_DRIVERS,
  SAMSARA_ID_GEO_POSITION,
  SAMSARA_INTEGRATIONS,
  SAMSARA_SAVE_DRIVERS,
  SAMSARA_SAVE_VEHICLES,
  SAMSARA_TOKEN_REGISTRATION,
  SAMSARA_TOKEN_REMOVAL,
  SAMSARA_UPDATE_FAULTS,
  SAMSARA_VEHICLES,
  SAMSARA_VEHICLE_DIAGNOSTICS,
} from "Shared/Constants/apiRequest";
import {
  SamsaraSelectDriversRequest,
  SamsaraSelectVehiclesRequest,
} from "Shared/Types/fleetmanagementapi";
import {
  ISamsaraDrivers,
  ISamsaraDriversResult,
  ISamsaraIntegration,
  ISamsaraIntegrationSearchParams,
  ISamsaraSaveDrivers,
  ISamsaraSaveVehicles,
  ISamsaraTokenRegistration,
  ISamsaraTokenRemoval,
  ISamsaraVehicles,
  ISamsaraVehiclesDiagnostics,
  ISamsaratIntegrationsResult,
} from "Shared/Types/samsara";

class Samsara {
  samsaraIntegrations(params: ISamsaraIntegrationSearchParams | null) {
    return client.get<ISamsaraIntegration[]>(SAMSARA_INTEGRATIONS, {
      params,
    });
  }
  samsaraGetDrivers(data: ISamsaraDrivers) {
    return client.get<ISamsaraDriversResult>(SAMSARA_DRIVERS, { params: data });
  }
  samsaraGetVehicles(data: ISamsaraVehicles) {
    return client.get<any>(SAMSARA_VEHICLES, { params: data });
  }
  samsaraSaveDrivers(data: ISamsaraSaveDrivers) {
    return client.post<SamsaraSelectDriversRequest>(SAMSARA_SAVE_DRIVERS, data);
  }
  samsaraSaveVehicles(data: ISamsaraSaveVehicles) {
    return client.post<SamsaraSelectVehiclesRequest>(
      SAMSARA_SAVE_VEHICLES,
      data,
    );
  }
  samsaraTokenRegistration(data: ISamsaraTokenRegistration) {
    return client.post<ISamsaratIntegrationsResult>(
      SAMSARA_TOKEN_REGISTRATION,
      data,
    );
  }
  samsaraTokenRemoval(data: ISamsaraTokenRemoval) {
    return client.post<any>(SAMSARA_TOKEN_REMOVAL, data);
  }
  samsaraVehiclesDiagnostics(data: ISamsaraVehiclesDiagnostics) {
    return client.post<any>(SAMSARA_VEHICLE_DIAGNOSTICS, data);
  }

  samsaraVehiclesGeoPosition(id: string) {
    return client.post<any>(SAMSARA_ID_GEO_POSITION(id));
  }

  samsaraUpdateFaults() {
    return client.post<any>(SAMSARA_UPDATE_FAULTS);
  }
}

export default Samsara;
