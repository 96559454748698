import { client } from "Service/axios";
import {
  NEAREST_VENDORS,
  VENDORS,
  VENDORS_COMMENTS_CREATE,
  VENDORS_CREATE,
  VENDORS_ID,
  VENDORS_ID_BULK_ADD_FILES,
  VENDORS_ID_BULK_REMOVE_FILES,
  VENDORS_ID_COMMENTS_DELETE,
  VENDORS_ID_DELETE,
  VENDORS_ID_FILES,
  VENDORS_ID_UPDATE,
} from "Shared/Constants/apiRequest";
import { IComment, IVendorCommentAdd } from "Shared/Types/comment";
import { IAddFiles } from "Shared/Types/file";
import { ReducerTypeForUpdate } from "Shared/Types/shared";
import {
  INearestVendorsParams,
  IVendorDetail,
  IVendorFilterParams,
} from "Shared/Types/vendor";
import { IAddVendor } from "Shared/Utils/yup/addVendor";

class Vendors {
  getVendorsList(params: IVendorFilterParams | null) {
    return client.get<any>(VENDORS, { params });
  }
  getNearestVendors(params: INearestVendorsParams) {
    return client.get<any>(NEAREST_VENDORS(params.vehicle_id), { params });
  }
  getVehicleVendorsList() {
    return client.get<any>(VENDORS, { params: { classification: "vehicle" } });
  }
  getVendor(id: string) {
    return client.get<IVendorDetail>(VENDORS_ID(id));
  }
  updateVendor({ data, id }: ReducerTypeForUpdate<IAddVendor>) {
    return client.patch<IAddVendor>(VENDORS_ID_UPDATE(id), data);
  }
  updateVendorDocument({ id, files }: IAddFiles) {
    return client.patch<any>(VENDORS_ID_UPDATE(id), { documents: files });
  }
  addVendor(data: IAddVendor) {
    return client.post<IAddVendor>(VENDORS_CREATE, data);
  }

  removeVendor(id: string) {
    return client.delete(VENDORS_ID_DELETE(id));
  }
  // Comments
  saveComment(data: IVendorCommentAdd) {
    return client.post<IComment>(VENDORS_COMMENTS_CREATE, data);
  }
  deleteComment(id: string) {
    return client.delete(VENDORS_ID_COMMENTS_DELETE(id));
  }
  getVendorDocumentsList(id: string) {
    return client.get<any>(VENDORS_ID_FILES(id));
  }
  addDocumentsToVendor({ id, files }: IAddFiles) {
    return client.post<any>(VENDORS_ID_BULK_ADD_FILES(id), {
      documents: files,
    });
  }
  removeDocumentsFromVendor({ id, files }: IAddFiles) {
    return client.post<any>(VENDORS_ID_BULK_REMOVE_FILES(id), {
      documents: files,
    });
  }
}

export default Vendors;
