import React from "react";
import Suspence from "Components/Layout/Suspence";
import { lazyLoader } from "Service/loader";
const LogInMain = lazyLoader(() => import("Containers/LogIn/LoginMain"));

export const Login = () => {
  return (
    <Suspence>
      <LogInMain />
    </Suspence>
  );
};
