import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IAssignedVehiclesSearchParams,
  IAssignEquipPost,
  IEquipSearchParams,
  IEquipTypesSearchParams,
  IRentHistoryPatch,
  IRentHistoryPost,
  IRentHistoryStatusPost,
  IUnassignEquipPost,
  IUsersSearchParams,
  IVehiclesSearchParams,
} from "Shared/Types/equipment";
import { IAddFilesToEntityTypes } from "Shared/Types/file";
import { EquipmentTypeCreateRequest } from "Shared/Types/fleetmanagementapi";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";
import { createMemoizedThunk } from "Shared/Utils/shared";
import { TEquipment } from "Shared/Utils/yup/equipmentShema";
import { TSearchWithId } from "Shared/Types/shared";

const getEquipmentTypes = createAsyncThunk(
  "equipment/getEquipmentTypes",
  async (params?: IEquipTypesSearchParams | null) => {
    try {
      const response = await api.equipment.getEquipmentTypes(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const equipmentTypesCreate = createAsyncThunk(
  "equipment/equipmentTypesCreate",
  async ({
    onError,
    onSuccess,
    data,
  }: IReducerWithCallback<EquipmentTypeCreateRequest>) => {
    try {
      const response = await api.equipment.equipmentTypesCreate(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);
const deleteEquipmentType = createAsyncThunk(
  "equipment/deleteEquipmentType",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.equipment.deleteEquipmentType(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const getAssignedVehicles = createMemoizedThunk(
  "equipment/getAssignedVehicles",
  async (params?: IAssignedVehiclesSearchParams) => {
    const response = await api.equipment.getAssignedVehicles(params);
    return response.data;
  },
);

const getVehicles = createMemoizedThunk(
  "equipment/getVehicles",
  async (params?: IVehiclesSearchParams) => {
    const response = await api.equipment.getVehicles(params);
    return response.data;
  },
);

const getUsers = createMemoizedThunk(
  "equipment/getUsers",
  async (params?: IUsersSearchParams) => {
    const response = await api.equipment.getUsers(params);
    return response.data;
  },
);

const getEquipmentList = createMemoizedThunk(
  "equipment/getEquipmentList",
  async (params?: IEquipSearchParams) => {
    const response = await api.equipment.getEquipmentList(params);
    return response.data;
  },
);

const getEquipmentDetails = createAsyncThunk(
  "equipment/getEquipmentDetails",
  async (id: string) => {
    try {
      const response = await api.equipment.getEquipmentDetails(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const unassignEquipment = createAsyncThunk(
  "equipment/unassignEquipment",
  async ({ id, data, onSuccess, onError }: IUpdateReducerWithCallback<any>) => {
    try {
      await api.equipment.unassignEquipment(id, data);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);

const assignEquipment = createAsyncThunk(
  "equipment/assignEquipment",
  async ({ id, data, onSuccess, onError }: IUpdateReducerWithCallback<any>) => {
    try {
      await api.equipment.assignEquipment(id, data);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);
const addEquipment = createAsyncThunk(
  "equipment/addEquipment",
  async ({ data, onSuccess, onError }: IReducerWithCallback<TEquipment>) => {
    try {
      const response = await api.equipment.addEquipment(data);
      onSuccess?.(response.data.id);
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);

const updateEquipment = createAsyncThunk(
  "equipment/updateEquipment",
  async ({ id, data, onSuccess, onError }: IUpdateReducerWithCallback<any>) => {
    try {
      await api.equipment.updateEquipment(data, id);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);
const addFilesToEquipment = createAsyncThunk(
  "equipment/addFilesToEquipment",
  async ({
    id,
    files,
    onErrorUploadFiles,
    onSuccessUploadFiles,
  }: IAddFilesToEntityTypes) => {
    try {
      await api.equipment.addFilesToEquipment({ id, files });
      onSuccessUploadFiles?.();
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

const removeFileFromEquipment = createAsyncThunk(
  "equipment/removeFileFromEquipment",
  async ({
    id,
    files,
    onErrorUploadFiles,
    onSuccessUploadFiles,
  }: IAddFilesToEntityTypes) => {
    try {
      await api.equipment.removeFileFromEquipment({ id, files });
      onSuccessUploadFiles?.();
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

const deleteEquipment = createAsyncThunk(
  "equipment/deleteEquipment",
  async ({ id, onSuccess, onError }: IDeleteReducerWithCallback) => {
    try {
      await api.equipment.deleteEquipment(id);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);
const deleteUnassignedEquipment = createAsyncThunk(
  "equipment/deleteEquipment",
  async ({ id, onSuccess, onError }: any) => {
    try {
      await api.equipment.deleteUnassignedEquipment(id);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);

const getLinkedVehiclesHistory = createMemoizedThunk(
  "equipment/getLinkedVehiclesHistory",
  async ({ id, params }: TSearchWithId) => {
    const response = await api.equipment.getLinkedVehiclesHistory(id, params);
    return response.data;
  },
);

const getAssignmentsHistory = createMemoizedThunk(
  "equipment/getAssignmentsHistory",
  async ({ id, params }: TSearchWithId) => {
    const response = await api.equipment.getAssignmentsHistory(id, params);
    return response.data;
  },
);

const getRentHistory = createMemoizedThunk(
  "equipment/getRentHistory",
  async ({ id, params }: TSearchWithId) => {
    const response = await api.equipment.getRentHistory(id, params);
    return response.data;
  },
);

const addRent = createAsyncThunk(
  "equipment/addRent",
  async ({
    data,
    onSuccess,
    onError,
  }: IReducerWithCallback<IRentHistoryPost>) => {
    try {
      await api.equipment.addRent(data);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);

const deleteRent = createAsyncThunk(
  "equipment/deleteRent",
  async ({ id, onSuccess, onError }: IDeleteReducerWithCallback) => {
    try {
      await api.equipment.deleteRent(id);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);

const updateRent = createAsyncThunk(
  "equipment/updateRent",
  async ({
    id,
    data,
    onSuccess,
    onError,
  }: IUpdateReducerWithCallback<IRentHistoryPatch>) => {
    try {
      await api.equipment.updateRent(id, data);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);

const updateRentToPaid = createAsyncThunk(
  "equipment/updateRentToPaid",
  async ({
    data,
    onSuccess,
    onError,
  }: IReducerWithCallback<IRentHistoryStatusPost>) => {
    try {
      await api.equipment.updateRentToPaid(data);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);

const updateRentToUnpaid = createAsyncThunk(
  "equipment/updateRentToUnpaid",
  async ({
    data,
    onSuccess,
    onError,
  }: IReducerWithCallback<IRentHistoryStatusPost>) => {
    try {
      await api.equipment.updateRentToUnpaid(data);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);

export {
  addEquipment,
  addFilesToEquipment,
  addRent,
  assignEquipment,
  deleteEquipment,
  deleteRent,
  equipmentTypesCreate,
  deleteEquipmentType,
  getAssignedVehicles,
  getAssignmentsHistory,
  getEquipmentDetails,
  getEquipmentList,
  getEquipmentTypes,
  getLinkedVehiclesHistory,
  getRentHistory,
  getUsers,
  getVehicles,
  removeFileFromEquipment,
  unassignEquipment,
  updateEquipment,
  updateRent,
  updateRentToPaid,
  updateRentToUnpaid,
  deleteUnassignedEquipment,
};
