import {
  ACCOUNTS_CONTACTS_ID_RESTORE_ARCHIVED,
  ACCOUNTS_CONTACTS_MULTIPLY_ARCHIVE,
  ACCOUNTS_CONTACTS_MULTIPLY_DELETE,
  ACCOUNTS_CONTACTS_MULTIPLY_UNARCHIVE,
} from "Shared/Constants/apiRequest";
import { client, clientWithoutInterceptors } from "Service/axios";
import {
  ACCOUNTS_CONTACTS,
  ACCOUNTS_CONTACTS_COMMENTS_CREATE,
  ACCOUNTS_CONTACTS_CREATE,
  ACCOUNTS_CONTACTS_ID,
  ACCOUNTS_CONTACTS_ID_ARCHIVE,
  ACCOUNTS_CONTACTS_ID_COMMENTS_DELETE,
  ACCOUNTS_CONTACTS_ID_DELETE,
  ACCOUNTS_CONTACTS_ID_FILES,
  ACCOUNTS_CONTACTS_ID_FILES_CREATE,
  ACCOUNTS_CONTACTS_ID_FILES_DELETE,
  ACCOUNTS_CONTACTS_ID_RESEND_INVITATION,
  ACCOUNTS_CONTACTS_ID_UPDATE,
  ACCOUNTS_PASSWORD_CONTACTS_SETUP,
} from "Shared/Constants/apiRequest";
import { IComment, IUserCommentAdd } from "Shared/Types/comment";
import {
  IUser,
  IUserDetail,
  IUserList,
  IUserSearchList,
} from "Shared/Types/user";
import { IAddFiles } from "Shared/Types/file";
import { ContactRequest } from "Shared/Types/fleetmanagementapi";
import axios from "axios";

class User {
  getUserList(params: IUserSearchList | null) {
    return client.get<IUserList>(ACCOUNTS_CONTACTS, { params });
  }
  getContactList(params: IUserSearchList | null) {
    return client.get<IUserList>(ACCOUNTS_CONTACTS, {
      params: { enable_fleetio_access: "false", ...params },
    });
  }
  getUser(id: string) {
    return client.get<IUserDetail>(ACCOUNTS_CONTACTS_ID(id));
  }
  archiveUser(id: string) {
    return client.post<IUser>(ACCOUNTS_CONTACTS_ID_ARCHIVE(id));
  }
  unarchiveUser(id: string) {
    return client.post<IUser>(ACCOUNTS_CONTACTS_ID_RESTORE_ARCHIVED(id));
  }
  deletedUser(id: string) {
    return client.delete<any>(ACCOUNTS_CONTACTS_ID_DELETE(id));
  }
  multiplyArchiveUser({ ids }: { ids: string[] }) {
    return client.post<any>(ACCOUNTS_CONTACTS_MULTIPLY_ARCHIVE, { ids });
  }
  multiplyUnarchiveUser({ ids }: { ids: string[] }) {
    return client.post<any>(ACCOUNTS_CONTACTS_MULTIPLY_UNARCHIVE, { ids });
  }
  multiplyDeletedUser({ ids }: { ids: string[] }) {
    return client.delete<any>(ACCOUNTS_CONTACTS_MULTIPLY_DELETE, {
      data: { ids: ids },
    });
  }

  addUser(data: FormData | ContactRequest) {
    return client.post<any>(ACCOUNTS_CONTACTS_CREATE, data);
  }
  updateUser(id: string, data: FormData) {
    return client.patch<IUser>(ACCOUNTS_CONTACTS_ID_UPDATE(id), data);
  }
  getUserFilesList(id: string) {
    return client.get<any>(ACCOUNTS_CONTACTS_ID_FILES(id));
  }
  addFilesToUser({ id, files }: IAddFiles) {
    return client.post<any>(ACCOUNTS_CONTACTS_ID_FILES_CREATE(id), { files });
  }
  removeFileFromUser({ id, files }: IAddFiles) {
    return client.post<any>(ACCOUNTS_CONTACTS_ID_FILES_DELETE(id), { files });
  }

  setupUserPassword(data: { password: string; token: string }) {
    return clientWithoutInterceptors.post<any>(
      ACCOUNTS_PASSWORD_CONTACTS_SETUP,
      data,
    );
  }

  resendInvitation(id: string) {
    return client.post<any>(ACCOUNTS_CONTACTS_ID_RESEND_INVITATION(id));
  }
  // Comments
  saveComment(data: IUserCommentAdd) {
    return client.post<IComment>(ACCOUNTS_CONTACTS_COMMENTS_CREATE, data);
  }
  deleteComment(id: string) {
    return client.delete(ACCOUNTS_CONTACTS_ID_COMMENTS_DELETE(id));
  }
}

export default User;
