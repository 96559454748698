import React from "react";
const AddVehicleForm = lazyLoader(
  () => import("Components/Vehicles/AddVehicleForm")
);
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

export const EditVehicle: React.FC = () => {
  return (
    <Layout>
      <AddVehicleForm />
    </Layout>
  );
};
