import { client } from "Service/axios";
import {
  INVENTORY_PURCHASE_ORDERS,
  INVENTORY_PURCHASE_ORDERS_ID,
  INVENTORY_PURCHASE_ORDERS_ID_DELETE,
  INVENTORY_PURCHASE_ORDERS_ID_DOCUMENT,
  INVENTORY_PURCHASE_ORDERS_ID_RECEIVE,
  INVENTORY_PURCHASE_ORDERS_ID_REMOVE_DOCUMENTS,
  INVENTORY_PURCHASE_ORDERS_ID_STATUS,
  INVENTORY_PURCHASE_ORDERS_ID_UPDATE,
  INVENTORY_PURCHASE_ORDERS_ID_UPLOAD_DOCUMENTS,
  VEHICLES_MODELS_CREATE,
} from "Shared/Constants/apiRequest";
import { IAddFiles } from "Shared/Types/file";
import {
  IPurchaseOrderDetail,
  IPurchaseOrderReceiveItems,
  IPurchaseOrderSearchParams,
  IUpdatePurchaseOrder,
} from "Shared/Types/purchaseOrder";
import { ReducerTypeForUpdate } from "Shared/Types/shared";
import { IAddPurchaseOrder } from "Shared/Utils/yup/puchaseOrders";

type ISwitchPurchaseOrderStatus = {
  id: string;
  status: string;
};

class PurchaseOrder {
  getPurchaseOrdersList(params: IPurchaseOrderSearchParams | null) {
    return client.get<any>(INVENTORY_PURCHASE_ORDERS, { params });
  }
  getPurchaseOrder(id: string) {
    return client.get<IPurchaseOrderDetail>(INVENTORY_PURCHASE_ORDERS_ID(id));
  }
  addPurchaseOrder(data: IAddPurchaseOrder | IUpdatePurchaseOrder) {
    return client.post<any>(VEHICLES_MODELS_CREATE, data);
  }

  updatePurchaseOrder({
    data,
    id,
  }: ReducerTypeForUpdate<IUpdatePurchaseOrder>) {
    return client.patch<any>(INVENTORY_PURCHASE_ORDERS_ID_UPDATE(id), data);
  }
  deletePurchaseOrder(id: string) {
    return client.delete<any>(INVENTORY_PURCHASE_ORDERS_ID_DELETE(id));
  }

  getPurchaseOrderFiles(id: string) {
    return client.get<any>(INVENTORY_PURCHASE_ORDERS_ID_DOCUMENT(id));
  }

  addFilesToPurchaseOrder({ id, files }: IAddFiles) {
    return client.post<any>(INVENTORY_PURCHASE_ORDERS_ID_UPLOAD_DOCUMENTS(id), {
      documents: files,
    });
  }
  sendReceivedLineItems({
    id,
    data,
  }: ReducerTypeForUpdate<IPurchaseOrderReceiveItems>) {
    return client.post<any>(INVENTORY_PURCHASE_ORDERS_ID_RECEIVE(id), data);
  }
  removeFilesFromPurchaseOrder({ id, files }: IAddFiles) {
    return client.post<any>(INVENTORY_PURCHASE_ORDERS_ID_REMOVE_DOCUMENTS(id), {
      ids: files,
    });
  }

  switchPurchaseOrderStatus({ id, status }: ISwitchPurchaseOrderStatus) {
    return client.post<any>(
      `${INVENTORY_PURCHASE_ORDERS_ID_STATUS(id)}${status}/`
    );
  }
}

export default PurchaseOrder;
