import { createSlice } from "@reduxjs/toolkit";

import {
  IServiceTask,
  TAssemblyCode,
  TMaintenanceCategory,
  TReasonForRepairCode,
  TSystemCode,
} from "Shared/Types/serviceTask";

import {
  getAssemblyCodes,
  getMaintenanceCategories,
  getReasonForRepairCodes,
  getServiceTask,
  getServiceTasksList,
  getSystemCodes,
} from "./reducer";
import { RootState } from "../store";

interface IServiceTaskState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  serviceTasksList: IServiceTask[];
  currentServiceTask: IServiceTask | null;
  numPage: number;
  systemCodes: TSystemCode[];
  maintenanceCategories: TMaintenanceCategory[];
  assemblyCodes: TAssemblyCode[];
  reasonForRepairCodes: TReasonForRepairCode[];
}

const initialState: IServiceTaskState = {
  error: null,
  loading: false,
  success: null,
  serviceTasksList: [],
  currentServiceTask: null,
  numPage: 0,
  systemCodes: [],
  maintenanceCategories: [],
  assemblyCodes: [],
  reasonForRepairCodes: [],
};

const serviceTaskSlice = createSlice({
  name: "serviceTask",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    clearCurrentServiceTask(state) {
      state.currentServiceTask = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getServiceTasksList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.serviceTasksList = action.payload.data;
      state.numPage = action.payload.count;
    });
    builder.addCase(getServiceTask.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.currentServiceTask = action.payload;
    });
    builder.addCase(getSystemCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.systemCodes = action.payload;
    });
    builder.addCase(getMaintenanceCategories.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.maintenanceCategories = action.payload;
    });
    builder.addCase(getAssemblyCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.assemblyCodes = action.payload;
    });
    builder.addCase(getReasonForRepairCodes.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = false;
      state.reasonForRepairCodes = action.payload;
    });
  },
});

export const { clear, clearCurrentServiceTask } = serviceTaskSlice.actions;

export const selectServiceTasksList = (state: RootState) =>
  state.serviceTask.serviceTasksList;
export const selectCurrentServiceTask = (state: RootState) =>
  state.serviceTask.currentServiceTask;
export const selectSystemCodes = (state: RootState) =>
  state.serviceTask.systemCodes;
export const selectMaintenanceCategories = (state: RootState) =>
  state.serviceTask.maintenanceCategories;
export const selectAssemblyCodes = (state: RootState) =>
  state.serviceTask.assemblyCodes;
export const selectReasonForRepairCodes = (state: RootState) =>
  state.serviceTask.reasonForRepairCodes;
export const selectServiceTasksNumPage = (state: RootState) =>
  state.serviceTask.numPage;
export default serviceTaskSlice;
