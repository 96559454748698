import React, {FC} from 'react'

import {TIconProps} from "./types";

export const Parts: FC<TIconProps> = ({size = 20}) =>
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.501 13.8515V6.14841C17.5004 6.03714 17.4705 5.928 17.4142 5.83199C17.358 5.73597 17.2774 5.65648 17.1807 5.60154L10.3057 1.73435C10.213 1.68087 10.1079 1.65271 10.001 1.65271C9.89401 1.65271 9.78893 1.68087 9.69629 1.73435L2.82129 5.60154C2.72453 5.65648 2.64397 5.73597 2.58774 5.83199C2.5315 5.928 2.50157 6.03714 2.50098 6.14841V13.8515C2.50157 13.9628 2.5315 14.072 2.58774 14.168C2.64397 14.264 2.72453 14.3435 2.82129 14.3984L9.69629 18.2656C9.78893 18.3191 9.89401 18.3472 10.001 18.3472C10.1079 18.3472 10.213 18.3191 10.3057 18.2656L17.1807 14.3984C17.2774 14.3435 17.358 14.264 17.4142 14.168C17.4705 14.072 17.5004 13.9628 17.501 13.8515V13.8515Z"
      stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.8291 11.9141V7.85156L6.25098 3.67188" stroke="white" strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M17.415 5.82812L10.0713 10L2.58691 5.82812" stroke="white" strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
    <path d="M10.0713 10L10.001 18.3438" stroke="white" strokeWidth="1.5" strokeLinecap="round"
          strokeLinejoin="round"/>
  </svg>

export default Parts
