import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const AddReminderVehicle = lazyLoader(
  () =>
    import(
      "Containers/ReminderVehicles/AddEditReminderVehicle/AddReminderVehicle"
    )
);

export const AddReminderVehiclesPage = () => {
  return (
    <Layout>
      <AddReminderVehicle />
    </Layout>
  );
};
