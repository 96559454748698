import Link from "Components/UI/Link/Link";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectGlobalSearchResult } from "Redux/InputGlobal/InputGlobalSlice";
import { EQUIPMENTS } from "Shared/Constants/apiRequest";
import {
  FUEL_HISTORY,
  INSPECTIONS_HISTORY,
  ISSUE,
  PARTS,
  PURCHASE_ORDERS,
  REMINDERS_VEHICLES,
  SERVICE_HISTORY,
  UNIT,
  USERS,
  VENDORS,
  WORK_ORDERS,
} from "Shared/Constants/routes";
import { format } from "Shared/Utils/shared";
import styles from "../InputSearch.module.scss";

type SearchResultProps = {
  onClose: () => void;
  searchValue: string | undefined;
};

const SearchResult: React.FC<SearchResultProps> = ({ searchValue }) => {
  const results = useSelector(selectGlobalSearchResult);
  const navigate = useNavigate();

  const highlightText = (text: string, searchTerm: string) => {
    if (!searchTerm) return text;
    const regex = new RegExp(`(${searchTerm})`, "gi");
    return text.replace(
      regex,
      (match) => `<span style="color:#5b90c2;">${match}</span>`,
    );
  };

  const isAllEmpty =
    !results?.Vehicle?.records &&
    !results?.Contact?.records &&
    !results?.Equipment?.records &&
    !results?.Inspection_Form?.records &&
    !results?.Inspection_Submission?.records &&
    !results?.Issue?.records &&
    !results?.Part?.records &&
    !results?.Vendor?.records &&
    !results?.Documents?.records &&
    !results?.Fuel?.records &&
    !results?.Work_Order?.records &&
    !results?.Purchase_Order?.records;

  const isHave = (records?: any[]) => {
    return records && records.length > 0;
  };

  if (!searchValue) {
    return <h4>Nothing was found for your request</h4>;
  }
  type TExample = {
    title: string;
    records?: {
      detail_route: string;
      desc: (string | number)[];
    }[];
    list_route: string;
  };

  const categories: TExample[] = [
    {
      title: "Units",
      records: results?.Vehicle?.records.map(
        ({ id, name, status, year, make, model, group }) => ({
          detail_route: `${UNIT}/${id}/`,
          desc: [
            `${name}, ${status || "---"} - ${year || "---"}, ${make?.name || "---"} / ${model?.name || "---"} - ${group?.name || "---"}`,
          ],
        }),
      ),
      list_route: UNIT,
    },
    {
      title: "Part",
      records: results?.Part?.records.map(({ id, part_number, category }) => ({
        detail_route: `${PARTS}/${id}`,
        desc: [`${part_number || "---"} / ${category?.name || "---"}`],
      })),
      list_route: PARTS,
    },
    {
      title: "Issue",
      records: results?.Issue?.records.map(
        ({
          id,
          summary,
          vehicle,
          status,
          reported_by,
          reported_date,
          assigned_to,
        }) => ({
          detail_route: `${ISSUE}/${id}`,
          desc: [
            `${summary || ""} / ${vehicle?.name || "---"} / ${status || "---"} - ${reported_by?.first_name || "---"} / ${format(reported_date || "---")} / ${assigned_to?.first_name || "---"}`,
          ],
        }),
      ),
      list_route: ISSUE,
    },
    {
      title: "Equipment",
      records: results?.Equipment?.records.map(
        ({ id, name, serial_number, type }) => ({
          detail_route: `${EQUIPMENTS}${id}`,
          desc: [
            `${name} - ${serial_number || "---"} / ${type?.name || "---"}`,
          ],
        }),
      ),
      list_route: EQUIPMENTS,
    },
    {
      title: "Vendor",
      records: results?.Vendor?.records.map(({ id, name, address }) => ({
        detail_route: `${VENDORS}/${id}`,
        desc: [`${name} / ${address}`],
      })),
      list_route: VENDORS,
    },
    {
      title: "Vehicle Renewal",
      records: results?.Vehicle_Renewals?.records.map(
        ({ id, vehicle, renewal_type, due_date }) => ({
          detail_route: `${REMINDERS_VEHICLES}/${id}`,
          desc: [
            `${vehicle?.name || "---"} - ${renewal_type?.name || "---"} / ${format(due_date) || "---"}`,
          ],
        }),
      ),
      list_route: REMINDERS_VEHICLES,
    },
    {
      title: "Contact",
      records: results?.Contact?.records.map(({ id, first_name, email }) => ({
        detail_route: `${USERS}/${id}`,
        desc: [`${first_name}, ${email || "---"}`],
      })),
      list_route: USERS,
    },
    {
      title: "Inspection",
      records: results?.Inspection_Submission?.records.map(
        ({ id, vehicle, submitted_at, submitted_by, form }) => ({
          detail_route: `${INSPECTIONS_HISTORY}/${id}`,
          desc: [
            `${vehicle?.name || "---"} - ${format(submitted_at) || "---"}, ${submitted_by?.first_name || "---"} / ${form?.title || "---"}`,
          ],
        }),
      ),
      list_route: INSPECTIONS_HISTORY,
    },
    {
      title: "Service",
      records: results?.Service?.records.map(
        ({ id, vehicle, completion_date, total_cost, service_task_items }) => ({
          detail_route: `${SERVICE_HISTORY}/${id}`,
          desc: [
            `${vehicle?.name || "---"} / ${format(completion_date) || "---"}, ${total_cost} / ${service_task_items?.[0]?.service_task?.name || "---"}`,
          ],
        }),
      ),
      list_route: SERVICE_HISTORY,
    },
    {
      title: "Documents",
      records: results?.Documents?.records.map(
        ({ id, file_name, location }) => ({
          detail_route: ``,
          desc: [`${file_name}, ${location}`],
        }),
      ),
      list_route: "",
    },
    {
      title: "Purchase Order",
      records: results?.Purchase_Order?.records.map(
        ({ id, po_number, status, total }) => ({
          detail_route: `${PURCHASE_ORDERS}/${id}`,
          desc: [
            `${po_number || "---"}, ${status || "---"} - ${total || "---"}`,
          ],
        }),
      ),
      list_route: PURCHASE_ORDERS,
    },
    {
      title: "Fuel",
      records: results?.Fuel?.records.map(
        ({ id, vehicle, date, fuel_price }) => ({
          detail_route: `${FUEL_HISTORY}/${id}`,
          desc: [
            `${vehicle?.name || "---"} / ${format(date)} - ${fuel_price || "---"}`,
          ],
        }),
      ),
      list_route: FUEL_HISTORY,
    },
    {
      title: "Work Order",
      records: results?.Work_Order?.records.map(
        ({ id, vehicle, created, status }) => ({
          detail_route: `${WORK_ORDERS}/${id}`,
          desc: [
            `${vehicle?.name || "---"} - ${format(created) || "---"} / ${status || "---"}`,
          ],
        }),
      ),
      list_route: WORK_ORDERS,
    },
  ];

  return (
    <div className={styles.modal}>
      {isAllEmpty && <h4>Nothing was found for your request</h4>}

      {categories.map((el, index) => {
        if (!isHave(el.records)) return null;
        return (
          <div key={index}>
            <h4>{el.title}</h4>
            {el.records?.map((item, index) => (
              <div
                onClick={() => navigate(item.detail_route)}
                key={index}
                className={styles.box}
              >
                <span>-</span>
                {item.desc.map((val, index) => (
                  <span
                    key={index}
                    dangerouslySetInnerHTML={{
                      __html: highlightText(val.toString(), searchValue || ""),
                    }}
                  />
                ))}
              </div>
            ))}
            <div className={styles.moreLink}>
              <Link to={el.list_route}>More</Link>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SearchResult;
