import { client } from "Service/axios";
import {
  REMINDERS_VEHICLE_RENEWAL_TYPE,
  REMINDERS_VEHICLE_RENEWAL_TYPE_CREATE,
  REMINDERS_VEHICLES,
  REMINDERS_VEHICLES_CREATE,
  REMINDERS_VEHICLES_DELETE_ID,
  REMINDERS_VEHICLES_DELETE_MULTIPLE,
  REMINDERS_VEHICLES_ID,
  REMINDERS_VEHICLES_TYPE,
  REMINDERS_VEHICLES_UPDATE_ID,
} from "Shared/Constants/apiRequest";
import {
  VehicleRenewalList,
  VehicleRenewalRetrieve,
  VehicleRenewalTypeCreate,
  VehicleRenewalTypeList,
} from "Shared/Types/fleetmanagementapi";
import { PaginatedResponse } from "Shared/Types/shared";
import { TReminderVehicleSchema } from "Shared/Utils/yup/reminderVehicles";
import {
  IReminderVehiclesSearch,
  IRenewalTypesListSearch,
} from "Shared/Types/reminder";

class ReminderVehicle {
  getReminderVehicleList(params: IReminderVehiclesSearch | null) {
    return client.get<PaginatedResponse<VehicleRenewalList[]>>(
      REMINDERS_VEHICLES,
      { params }
    );
  }
  getReminderVehicleTypeList(params: IRenewalTypesListSearch | null) {
    return client.get<PaginatedResponse<VehicleRenewalTypeList[]>>(
      REMINDERS_VEHICLES_TYPE,
      { params }
    );
  }
  createReminderVehicleType(data: VehicleRenewalTypeCreate | null) {
    return client.post(REMINDERS_VEHICLE_RENEWAL_TYPE_CREATE, data);
  }
  getReminderVehicleById(id: string) {
    return client.get<VehicleRenewalRetrieve>(REMINDERS_VEHICLES_ID(id));
  }
  createReminderVehicle(data: TReminderVehicleSchema) {
    return client.post(REMINDERS_VEHICLES_CREATE, data);
  }
  updateReminderVehicle(id: string, data: any) {
    return client.put(REMINDERS_VEHICLES_UPDATE_ID(id), data);
  }
  deleteReminderVehicle(id: string) {
    return client.delete(REMINDERS_VEHICLES_DELETE_ID(id));
  }
  deleteMultipleReminderVehicles({ ids }: { ids: string[] }) {
    return client.post(REMINDERS_VEHICLES_DELETE_MULTIPLE, { ids: ids });
  }
}

export default ReminderVehicle;
