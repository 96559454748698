import { client } from "Service/axios";
import {
  BLOCK_ENGINE_BLOCK_TYPES,
  BLOCK_ENGINE_BLOCK_TYPES_CREATE,
} from "Shared/Constants/apiRequest";
import { IAddBlock, IBlockSearchList } from "Shared/Types/blockType";

class BlockType {
  getBlockTypeList(params: IBlockSearchList | null) {
    return client.get<any>(BLOCK_ENGINE_BLOCK_TYPES, { params });
  }
  addBlockType(data: IAddBlock) {
    return client.post<any>(BLOCK_ENGINE_BLOCK_TYPES_CREATE, data);
  }
}

export default BlockType;
