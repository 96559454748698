import React, { ReactNode } from "react";
import { LogoLogin } from "Assets";
import Icon from "@ant-design/icons/lib/components/Icon";
import { Link } from "react-router-dom";
import styles from "./AuthFormLayout.module.scss";
import Line from "../../Line/Line";
import cn from "classnames";

interface linkObject {
  name: string;
  url: string;
}

type AuthFormLayoutProps = {
  children: ReactNode;
  links?: linkObject[];
  title?: string;
  withFooter?: boolean;
  iconTopMargin?: string;
  isLogin?: boolean;
};

const AuthFormLayout: React.FC<AuthFormLayoutProps> = ({
  children,
  links,
  withFooter,
  iconTopMargin,
  isLogin,
}) => {
  return (
    <div
      className={cn(styles.form_layout, isLogin && styles.form_layout_login)}
    >
      <div className={styles.form_layout__container}>
        <Icon
          component={LogoLogin}
          alt="logo icon"
          className={styles.form_layout__container__logo}
          style={{ fontSize: "105px", marginTop: iconTopMargin }}
        />
        {children}
        {withFooter && (
          <div className={styles.form_layout__footer}>
            <div className={styles.form_layout__footer__items}>
              {links?.map((el) => (
                <Link
                  className={styles.form_layout__footer__items__link_text}
                  key={el.url}
                  to={el.url}
                >
                  <p>{el.name}</p>
                  <Line className={styles.line} />
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AuthFormLayout;
