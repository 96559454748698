import React from "react";
import Suspence from "Components/Layout/Suspence";
import { lazyLoader } from "Service/loader";
const Subdomen = lazyLoader(() => import("Containers/Subdomen/Subdomen"));

export const CreateSubdomen = () => {
  return (
    <Suspence>
      <Subdomen />
    </Suspence>
  );
};
