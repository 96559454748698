import { ContactRequest } from "Shared/Types/fleetmanagementapi";
import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IUserCommentAdd } from "Shared/Types/comment";
import { IAddFilesToEntityTypes } from "Shared/Types/file";
import {
  IDeleteReducerWithCallback,
  IdOnSuccessOnErrorDispatchParams,
  IReducerWithCallback,
  IReducerWithCallbackWithError,
  MultiplyActionDispatchParams,
} from "Shared/Types/shared";
import { IUserSearchList } from "Shared/Types/user";
import { AxiosError } from "axios";

const getUserList = createAsyncThunk(
  "users/getUserList",
  async (params: IUserSearchList | null) => {
    try {
      const response = await api.user.getUserList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getContactList = createAsyncThunk(
  "users/getContactList",
  async (params: IUserSearchList | null) => {
    try {
      const response = await api.user.getContactList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const archiveUser = createAsyncThunk(
  "users/archiveUser",
  async ({ id, onSuccess, onError }: IdOnSuccessOnErrorDispatchParams) => {
    try {
      await api.user.archiveUser(id);
      onSuccess?.();
    } catch (error) {
      onError?.();
    }
  },
);
const unarchiveUser = createAsyncThunk(
  "users/unarchiveUser",
  async ({ id, onSuccess, onError }: IdOnSuccessOnErrorDispatchParams) => {
    try {
      await api.user.unarchiveUser(id);
      onSuccess?.();
    } catch (error) {
      onError?.();
    }
  },
);

const deleteUser = createAsyncThunk(
  "operatorType/deleteUser",
  async ({ id, onSuccess, onError }: IdOnSuccessOnErrorDispatchParams) => {
    try {
      await api.user.deletedUser(id);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  },
);
const multiplyArchiveUser = createAsyncThunk(
  "operatorType/multiplyArchiveUser",
  async ({ ids, onError, onSuccess }: MultiplyActionDispatchParams) => {
    try {
      const response = await api.user.multiplyArchiveUser({ ids });
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);
const multiplyUnarchiveUser = createAsyncThunk(
  "operatorType/multiplyArchiveUser",
  async ({ ids, onError, onSuccess }: MultiplyActionDispatchParams) => {
    try {
      const response = await api.user.multiplyUnarchiveUser({ ids });
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);
const multiplyDeleteUser = createAsyncThunk(
  "operatorType/multiplyDeleteUser",
  async ({ ids, onError, onSuccess }: MultiplyActionDispatchParams) => {
    try {
      const response = await api.user.multiplyDeletedUser({ ids });
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const getUser = createAsyncThunk("users/getUser", async (id: string) => {
  try {
    const response = await api.user.getUser(id);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const addUser = createAsyncThunk(
  "users/addUser",
  async ({
    data,
    onSuccess,
    onError,
  }: IReducerWithCallbackWithError<FormData>) => {
    try {
      const response = await api.user.addUser(data);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        onError?.(error);
      }
      throw error;
    }
  },
);
type Update = { id: string; data: FormData };

const updateUser = createAsyncThunk(
  "users/updateUser",
  async ({ id, data }: Update, { dispatch }) => {
    try {
      const response = await api.user.updateUser(id, data);
      dispatch(getUser(id));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const setupUserPassword = createAsyncThunk(
  "users/setupUserPassword",
  async (data: { password: string; token: string }) => {
    try {
      const response = await api.user.setupUserPassword(data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const resendInvitation = createAsyncThunk(
  "users/resendInvitation",
  async ({ id, onSuccess, onError }: IdOnSuccessOnErrorDispatchParams) => {
    try {
      await api.user.resendInvitation(id);
      onSuccess?.();
      await api.user.resendInvitation(id);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      onError?.();
      throw error;
    }
  },
);

const getUserFilesList = createAsyncThunk(
  "users/getUsersFilesList",
  async (id: string) => {
    try {
      const response = await api.user.getUserFilesList(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addFilesToUser = createAsyncThunk(
  "users/addFilesToUser",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.user.addFilesToUser({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getUserFilesList(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

const removeFileFromUser = createAsyncThunk(
  "users/removeFilesFromUser",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.user.removeFileFromUser({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getUserFilesList(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

const createComment = createAsyncThunk(
  "user/createComment",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<IUserCommentAdd>) => {
    try {
      const response = await api.user.saveComment(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const deleteComment = createAsyncThunk(
  "user/createComment",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.user.deleteComment(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export {
  addUser,
  getUserList,
  getUser,
  setupUserPassword,
  resendInvitation,
  archiveUser,
  updateUser,
  removeFileFromUser,
  addFilesToUser,
  getUserFilesList,
  deleteUser,
  createComment,
  deleteComment,
  unarchiveUser,
  multiplyUnarchiveUser,
  multiplyArchiveUser,
  multiplyDeleteUser,
  getContactList,
};
