import { client } from "Service/axios";
import {
  VEHICLE_TRANSMISSION_TYPES,
  VEHICLE_TRANSMISSION_TYPES_CREATE,
} from "Shared/Constants/apiRequest";
import {
  IAddTransmissionType,
  ITransmissionTypeSearchList,
} from "Shared/Types/TransmissionType";

class TransmissionType {
  getTransmissionTypeList(params: ITransmissionTypeSearchList | null) {
    return client.get<any>(VEHICLE_TRANSMISSION_TYPES, { params });
  }
  addTransmissionTypeList(data: IAddTransmissionType) {
    return client.post<any>(VEHICLE_TRANSMISSION_TYPES_CREATE, data);
  }
}

export default TransmissionType;
