import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const ReminderContactEdit = lazyLoader(
  () =>
    import("Containers/ReminderContact/ReminderContactEdit/ReminderContactEdit")
);
export const ReminderContactEditPage = () => {
  return (
    <Layout>
      <ReminderContactEdit />
    </Layout>
  );
};
