import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import api from "Service/api/api";
import { IVehicleCommentAdd } from "Shared/Types/comment";
import { IAddFilesToEntityTypes } from "Shared/Types/file";
import { ServiceReminderBulkCreateRequest } from "Shared/Types/fleetmanagementapi";
import { IVehicleMeterHistory } from "Shared/Types/meter-history";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IReducerWithCallbackWithError,
  IReducerWithoutData,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";
import {
  IAddLinkedVehicleType,
  IVehicleListSearch,
} from "Shared/Types/vehicle";

const getVehicleList = createAsyncThunk(
  "vehicles/getVehicleList",
  async (params: IVehicleListSearch | null) => {
    try {
      const response = await api.vehicle.getVehicleList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
const deleteVehicle = createAsyncThunk(
  "vehicles/deleteVehicle",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.vehicle.deleteVehicle(id);
      dispatch(getVehicleList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
const archiveVehicle = createAsyncThunk(
  "vehicles/updateVehicle",
  async (payload: { id: string; params: IVehicleListSearch }, { dispatch }) => {
    const { id, params } = payload;
    try {
      const response = await api.vehicle.archiveVehicle(id);
      dispatch(getVehicleList(params));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
const unarchiveVehicle = createAsyncThunk(
  "vehicles/unArchiveVehicle",
  async (payload: { id: string; params: IVehicleListSearch }, { dispatch }) => {
    const { id, params } = payload;
    try {
      const response = await api.vehicle.unarchiveVehicle(id);
      dispatch(getVehicleList(params));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
const getVehicle = createAsyncThunk(
  "vehicles/getVehicle",
  async (id: string) => {
    try {
      const response = await api.vehicle.getVehicle(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
const addVehicle = createAsyncThunk(
  "vehicles/addVehicle",
  async ({ data, onError, onSuccess }: IReducerWithCallbackWithError<any>) => {
    try {
      const response = await api.vehicle.addVehicle(data);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        onError?.(error);
      }
      throw error;
    }
  }
);

const editVehicle = createAsyncThunk(
  "vehicles/editVehicle",
  async ({ id, data, onError, onSuccess }: IUpdateReducerWithCallback<any>) => {
    try {
      const response = await api.vehicle.editVehicle(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        onError?.(error);
      }
      throw error;
    }
  }
);

const getLinkedVehicleList = createAsyncThunk(
  "vehicles/getLinkedVehicleList",
  async (id: string) => {
    try {
      const response = await api.vehicle.getLinkedVehicleList(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const linkVehicle = createAsyncThunk(
  "vehicles/linkVehicle",
  async (
    data: IAddLinkedVehicleType & {
      onSuccessfullyLinked: () => void;
      onError: (err?: any) => void;
    },
    { dispatch }
  ) => {
    try {
      const response = await api.vehicle.linkVehicle(data);
      dispatch(getLinkedVehicleList(data.vehicle_id));
      data.onSuccessfullyLinked();
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        data.onError?.(error);
      }
      throw error;
    }
  }
);

const unlinkVehicle = createAsyncThunk(
  "vehicles/unlinkVehicle",
  async (
    data: IAddLinkedVehicleType & { onSuccessfullyUnlinked: () => void },
    { dispatch }
  ) => {
    try {
      const response = await api.vehicle.unlinkVehicle(data);
      dispatch(getLinkedVehicleList(data.vehicle_id));
      data.onSuccessfullyUnlinked();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const getVehicleDocumentsList = createAsyncThunk(
  "vehicles/getVehicleDocumentsList",
  async (id: string) => {
    try {
      const response = await api.vehicle.getVehicleDocumentsList(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const addDocumentsToVehicle = createAsyncThunk(
  "vehicles/addDocumentsToVehicle",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch }
  ) => {
    try {
      await api.vehicle.addDocumentsToVehicle({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getVehicleDocumentsList(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  }
);

const removeDocumentsFromVehicle = createAsyncThunk(
  "vehicles/addDocumentsToVehicle",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch }
  ) => {
    try {
      await api.vehicle.removeDocumentsFromVehicle({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getVehicleDocumentsList(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  }
);

const createComment = createAsyncThunk(
  "vehicle/createComment",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<IVehicleCommentAdd>) => {
    try {
      const response = await api.vehicle.saveComment(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

const deleteComment = createAsyncThunk(
  "vehicle/createComment",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.vehicle.deleteComment(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

const getVehicleIssues = createAsyncThunk(
  "vehicle/getVehicleIssues",
  async (id: string) => {
    try {
      const response = await api.vehicle.getVehicleIssues(id);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);
const getVehicleEntries = createAsyncThunk(
  "vehicle/getVehicleEntries",
  async ({ id, params }: IVehicleMeterHistory) => {
    try {
      const response = await api.vehicle.getVehicleEntries(id, params);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);
const getVehicleServices = createAsyncThunk(
  "vehicle/getVehicleServices",
  async (id: string) => {
    try {
      const response = await api.vehicle.getVehicleServices(id);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const getVehicleFaults = createAsyncThunk(
  "vehicle/getVehicleFaults",
  async (id: string) => {
    try {
      const response = await api.vehicle.getVehicleFaults(id);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);

const getVehicleAssignedEquipments = createAsyncThunk(
  "vehicle/getVehicleAssignedEquipments",
  async (id: string) => {
    try {
      const response = await api.vehicle.getAssignedEquipments(id);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);
const getVehicleAnalyticsComplete = createAsyncThunk(
  "vehicle/getVehicleAnalyticsComplete",
  async () => {
    try {
      const response = await api.vehicle.getVehicleAnalyticsComplete();
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
);
const getVehicleLatestOdometer = createAsyncThunk(
  "vehicles/getVehicleLatestOdometer",
  async ({
    id,
    onSuccess,
    onError,
  }: IReducerWithoutData<ServiceReminderBulkCreateRequest>) => {
    try {
      const response = await api.vehicle.getVehicleLatestOdometer(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response?.data) {
        onError?.(error);
      }
      throw error;
    }
  }
);

export {
  addDocumentsToVehicle,
  addVehicle,
  getVehicleAnalyticsComplete,
  archiveVehicle,
  createComment,
  deleteComment,
  deleteVehicle,
  editVehicle,
  getLinkedVehicleList,
  getVehicle,
  getVehicleAssignedEquipments,
  getVehicleDocumentsList,
  getVehicleEntries,
  getVehicleFaults,
  getVehicleIssues,
  getVehicleList,
  getVehicleServices,
  linkVehicle,
  removeDocumentsFromVehicle,
  unarchiveVehicle,
  unlinkVehicle,
  getVehicleLatestOdometer,
};
