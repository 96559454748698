import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const InspectionsHistoryDashboard = lazyLoader(
  () =>
    import(
      "Containers/Inspections/InspectionsHistoryDashboard/InspectionsHistoryDashboard"
    )
);

export const InspectionHistoryList = () => {
  return (
    <Layout>
      <InspectionsHistoryDashboard />
    </Layout>
  );
};
