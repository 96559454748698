import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
import React from "react";
const ReminderServiceDetail = lazyLoader(
  () => import("Containers/ReminderService/ReminderServiceDetail")
);

export const ReminderServiceDetailPage = () => {
  return (
    <Layout>
      <ReminderServiceDetail />
    </Layout>
  );
};
