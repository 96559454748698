import React, { useEffect, useRef } from "react";
import { ReactComponent as Logout } from "Assets/vehicleIcon/logout.svg";
import { ReactComponent as Close } from "Assets/close.svg";
import { ReactComponent as User } from "Assets/user-removebg-preview-gray.svg";
import { ReactComponent as Settings } from "Assets/sidebaricons/SettingsGray.svg";
import styles from "./ModalUserProfile.module.scss";
import { useAppDispatch } from "Redux/store";
import { logout } from "Redux/Auth/slice";
import { useNavigate } from "react-router";
import {
  SETTINGS_GENERAL_SETTING,
  SETTINGS_USER_PROFILE,
} from "Shared/Constants/routes";
import { readObj } from "Service/storage";

type ModalProps = {
  setOpen: (open: boolean) => void;
};

const ModalUserProfile: React.FC<ModalProps> = ({ setOpen }) => {
  const dispatch = useAppDispatch();
  const user = readObj("account");
  const navigate = useNavigate();
  const modalRef = useRef<HTMLDivElement>(null);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleNavigateToUserProfile = () => {
    navigate(SETTINGS_USER_PROFILE);
  };
  const handleNavigateToGeneralSettings = () => {
    navigate(SETTINGS_GENERAL_SETTING);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setOpen]);

  return (
    <div className={styles.content} ref={modalRef}>
      <Close className={styles.content__close} onClick={() => setOpen(false)} />

      <div className={styles.content__absolute}>
        <div className={styles.content__absolute__userProfile}>
          <User
            width="10"
            className={styles.content__absolute__userProfile__user}
          />
          <p onClick={handleNavigateToUserProfile}>{user?.first_name}</p>
        </div>
        {(user?.contact?.is_administrator || user.is_owner) && (
          <div className={styles.content__absolute__userProfile}>
            <Settings
              width="10"
              className={styles.content__absolute__userProfile__user}
            />
            <p onClick={handleNavigateToGeneralSettings}>General Settings</p>
          </div>
        )}

        <div
          onClick={handleLogout}
          className={styles.content__absolute__logout}
        >
          <Logout
            height="10"
            width="10"
            className={styles.content__absolute__logout_iconLog}
          />
          <p>Log Out</p>
        </div>
      </div>
    </div>
  );
};

export default ModalUserProfile;
