import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const Parts = lazyLoader(
  () => import("Containers/Parts/PartDetailsDashboard/PartDetail")
);

export const PartDetail = () => {
  return (
    <Layout>
      <Parts />
    </Layout>
  );
};
