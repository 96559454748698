export const LIMIT_PAGE = 10;
export const LIMIT_CHARTS_PAGE = 10;
export const LIMIT_SERVICE_TASK = 10;
export const LIMIT_VEHICLE_GROUP = 10;
export const LIMIT_USER = 10;
export const LIMIT_MORE_USER = 10000;
export const LIMIT_ROLE_LIST = 10000;
export const LIMIT_VEHICLES = 10000;
export const LIMIT_NEAREST_VENDORS = 40;
export const FORMAT_WITHOUT_TIME = "YYYY-MM-DD";
export const DATE_FORMAT_WITH_SLASHES = "MM/DD/YYYY";
export const DATE_FORMAT_WITH_DOTS = "MM.DD.YYYY";
export const TIME_ONLY_FORMAT = "hh:mm";
export const TIME_WITH_AM_FORMAT = "hh:mm A";
export const FORMAT_MONTH_DATE_YEAR_TIME_AM = "MMMM DD, YYYY h:mm A";
export const FORMAT_WEEK_MONTH_DATE_YEAR_TIME_AM = "dddd, MMMM D, YYYY h:mm A";
export const FORMAT_WEEK_MONTH_DATE_YEAR = "dddd, MMMM D, YYYY";
export const FORMAT_MONTH_DATE_YEAR = "MMMM DD, YYYY";
export const FORMAT_HOURS_MINUTES_SECONDS = "HH:mm A";
export const FORMAT_MONTH_DATE = "MMMM DD";
export const FORMAT_FULL_DATE = "YYYY-MM-DD hh:mm A";
export const TEMPLATE_LIMIT = 30;
