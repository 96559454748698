import { createAsyncThunk } from "@reduxjs/toolkit";
import { notifySuccess } from "Components/Notify/notify";
import api from "Service/api/api";
import {
  IAddFaultsRule,
  IFaultsListSearch,
  IFaultsRule,
  IFaultsRulesSearch,
} from "Shared/Types/faults";
import { IReducerWithCallback } from "Shared/Types/shared";

export const getFaultsList = createAsyncThunk(
  "faults/getFaultsList",
  async (params: IFaultsListSearch | null) => {
    try {
      const response = await api.faults.getFaultsList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getCurrentFault = createAsyncThunk(
  "faults/getCurrentFault",
  async (id: string) => {
    try {
      const response = await api.faults.getFault(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getFaultCodesList = createAsyncThunk(
  "faults/getFaultCodesList",
  async () => {
    try {
      const response = await api.faults.getFaultsCodesList();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getFaultRulesList = createAsyncThunk(
  "faults/getFaultRulesList",
  async (params: IFaultsRulesSearch | null) => {
    try {
      const response = await api.faults.getFaultsRulesList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getCurrentFaultRule = createAsyncThunk(
  "faults/getCurrentFaultRule",
  async (id: string) => {
    try {
      const response = await api.faults.getFaultsRule(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const addFaultRule = createAsyncThunk(
  "faults/addFaultRule",
  async (
    { onError, onSuccess, data }: IReducerWithCallback<IAddFaultsRule>,
    { dispatch }
  ) => {
    try {
      const response = await api.faults.createFaultsRule(data);
      onSuccess?.();
      dispatch(getFaultRulesList(null));
      return response.data;
    } catch (err) {
      onError?.();
      console.error(err);
      throw err;
    }
  }
);

export const updateFaultRule = createAsyncThunk(
  "faults/updateFaultRule",
  async (data: IFaultsRule, { dispatch }) => {
    try {
      const response = await api.faults.updateFaultsRule(data);
      dispatch(getFaultRulesList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const deleteFaultRule = createAsyncThunk(
  "faults/deleteFaultRule",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.faults.deleteFaultsRule(id);
      notifySuccess("Fault rule successfully deleted!");
      dispatch(getFaultRulesList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const IgnoreFaultRule = createAsyncThunk(
  "faults/IgnoreFaultRule",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.faults.ignoreFault(id);
      dispatch(getFaultsList(null));
      notifySuccess("Fault successfully ignored!");
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
