import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getGooglePosition } from "./reducer";

interface googleMapState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  googleMapAddress: string | null;
}

const initialState: googleMapState = {
  loading: false,
  error: null,
  googleMapAddress: null,
};

const googleMapSlice = createSlice({
  name: "googleMap",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGooglePosition.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.googleMapAddress = action.payload;
    });
    builder.addCase(getGooglePosition.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
  },
});

export const { clear } = googleMapSlice.actions;

export const selectGoogleMapAddress = (state: RootState) =>
  state.googleMap.googleMapAddress;

export default googleMapSlice;
