import React from "react";

import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const EditReminderVehicle = lazyLoader(
  () =>
    import(
      "Containers/ReminderVehicles/EditReminderVehicle/EditReminderVehicle"
    )
);

export const EditReminderVehiclesPage = () => {
  return (
    <Layout>
      <EditReminderVehicle />
    </Layout>
  );
};
