import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const ServiceHistoryDashboard = lazyLoader(
  () =>
    import(
      "Containers/ServiceHistory/ServiceHistoryDashboard/ServiceHistoryDashboard"
    )
);

export const ServiceHistoryListPage = () => {
  return (
    <Layout>
      <ServiceHistoryDashboard />
    </Layout>
  );
};
