import React from "react";

import { ArrowDownIcon } from "Assets";

import Icon from "../Icon/Icon";
import styles from "./IconCircleFrame.module.scss";

interface IconFrameProps {
  icon?: React.ElementType;
  onClick?: () => void;
  padding?: string;
  bgColor?: string;
  label?: string;
  withArrow?: boolean;
}

const IconCircleFrame: React.FC<IconFrameProps> = ({
  icon,
  padding,
  bgColor,
  onClick,
  label,
  withArrow,
}) => {
  return (
    <div
      onClick={onClick}
      style={{ padding: padding, background: bgColor }}
      className={styles.frame}
    >
      <Icon component={icon} />
      {label && (
        <p className={styles.frame_label}>
          {label} {withArrow && <Icon component={ArrowDownIcon} />}
        </p>
      )}
    </div>
  );
};

export default IconCircleFrame;
