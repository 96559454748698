import React from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const VendorForm = lazyLoader(
  () => import("Components/Vendor/VendorForm/VendorForm")
);

export const AddVendors = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Vendors", link: "/vendor" },
    { title: "New Vendor", link: "" },
  ];

  return (
    <Layout>
      <VendorForm breads={breads} type="CREATE" />
    </Layout>
  );
};
