import React from "react";
import { Button as MUIButton, ButtonProps } from "@mui/material";
import cn from "classnames";
import styles from "./Button.module.scss";

interface IButton extends Omit<ButtonProps, "variant" | "color"> {
  color?: "primary" | "secondary" | "error";
  variant?: "outlined" | "contained" | "text";

  to?: string;
}

const Button: React.FC<IButton> = ({
  color = "primary",
  className,
  ...props
}) => {
  const buttonClasses = {
    root: cn(styles.button, styles[`button__${color}`], className),
  };

  return (
    <MUIButton
      variant="contained"
      color={color}
      classes={buttonClasses}
      {...props}
    >
      {props.children}
    </MUIButton>
  );
};

export default Button;
