import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
import React from "react";

const ReportsIssueFaultsSummaryList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsIssueFaultsSummary/ReportsIssueFaultsSummaryList/ReportsIssueFaultsSummaryList"
    )
);
export const ReportsIssueFaultsSummary = () => {
  return (
    <Layout>
      <ReportsIssueFaultsSummaryList />
    </Layout>
  );
};
