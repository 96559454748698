import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

const ReportServiceReminderComplianceDashboard = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportServiceReminderComplianceDashboard/ReportServiceReminderComplianceList/ReportServiceReminderComplianceList"
    )
);
export const ReportServiceReminderCompliance = () => {
  return (
    <Layout>
      <ReportServiceReminderComplianceDashboard />
    </Layout>
  );
};
