import React from "react";
import { Layout } from "Components/Layout/Layout";
import Breadcrumb, { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import { selectCurrentPurchaseOrder } from "Redux/PurchaseOrder/slice";
import Line from "Components/UI/Line/Line";
import { lazyLoader } from "Service/loader";
const PurchaseOrderDetail = lazyLoader(
  () =>
    import("Containers/PurchaseOrder/PurchaseOrderDetail/PurchaseOrderDetail")
);

export const PurchaseOrderDetailPage = () => {
  const purchaseOrder = useSelector(selectCurrentPurchaseOrder);

  const breads: BreadcrumbItem[] = [
    { title: "Purchase Orders", link: "/purchase-orders" },
    {
      title: `Purchase order ${purchaseOrder?.po_number || ""}` || "",
      link: "",
    },
  ];

  return (
    <Layout>
      <Breadcrumb breads={breads} />
      <Line />
      <PurchaseOrderDetail />
    </Layout>
  );
};
