import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { ContactRenewalTypeCreate } from "Shared/Types/fleetmanagementapi";
import { IReminderContactSearch } from "Shared/Types/reminder";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IUpdateReducerWithCallback,
  MultiplyActionDispatchParams,
} from "Shared/Types/shared";

export const getReminderContactList = createAsyncThunk(
  "reminder/getReminderContactList",
  async (params: IReminderContactSearch) => {
    try {
      const response =
        await api.remidnerConctact.getReminderContactList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getReminderContactById = createAsyncThunk(
  "reminder/getReminderContactById",
  async (id: string) => {
    try {
      const response = await api.remidnerConctact.getReminderContactById(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const createReminderContact = createAsyncThunk(
  "reminder/createReminderContact",
  async ({ data, onError, onSuccess }: IReducerWithCallback<any>) => {
    try {
      const response = await api.remidnerConctact.createReminderContact(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const updateReminderContact = createAsyncThunk(
  "reminder/updateReminderContact",
  async ({ id, data, onError, onSuccess }: IUpdateReducerWithCallback<any>) => {
    try {
      const response = await api.remidnerConctact.updateReminderContact(
        id,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const deleteReminderContact = createAsyncThunk(
  "reminder/deleteReminderContact",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.remidnerConctact.deleteReminderContact(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
export const multiplyDeleteReminderContact = createAsyncThunk(
  "reminder/multiplyDeleteReminderContact",
  async ({ ids, onError, onSuccess }: MultiplyActionDispatchParams) => {
    try {
      const response =
        await api.remidnerConctact.multiplyDeletedReminderContact({ ids });
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const getReminderConcactTypes = createAsyncThunk(
  "reminder/getReminderConcactTypes",
  async () => {
    try {
      const response = await api.remidnerConctact.getReminderConcactTypes();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const createReminderContactType = createAsyncThunk(
  "reminder/createReminderContactType",
  async ({
    onError,
    onSuccess,
    data,
  }: IReducerWithCallback<ContactRenewalTypeCreate>) => {
    try {
      const response =
        await api.remidnerConctact.createReminderContactType(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
