import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getTelematicDeviceList,
  archiveTelematicDevice,
  syncTelematicDeviceList,
} from "./reducer";
import { ITelematicDevice } from "Shared/Types/telematic-device";

interface TelematiceDeviceState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  telematicDeviceList: ITelematicDevice[];
  numPage: number;
}

const initialState: TelematiceDeviceState = {
  loading: false,
  error: null,
  telematicDeviceList: [],
  numPage: 1,
};

const telematicDeviceSlice = createSlice({
  name: "telematicDevice",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTelematicDeviceList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.numPage = action.payload.count;
      state.telematicDeviceList = action.payload.results;
    });
    builder.addCase(getTelematicDeviceList.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(archiveTelematicDevice.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(archiveTelematicDevice.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(archiveTelematicDevice.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(syncTelematicDeviceList.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(syncTelematicDeviceList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(syncTelematicDeviceList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
    });
  },
});

export const { clear } = telematicDeviceSlice.actions;

export const selectTelematiceDeviceList = (state: RootState) =>
  state.telematicDeivice.telematicDeviceList;
export const selectTelematiceDeviceLoading = (state: RootState) =>
  state.telematicDeivice.loading;
export const selectTelematiceDeviceError = (state: RootState) =>
  state.telematicDeivice.error;
export const selectTelematiceDeviceSuccess = (state: RootState) =>
  state.telematicDeivice.success;
export const selectTelematiceDeviceNumPage = (state: RootState) =>
  state.telematicDeivice.numPage;

export default telematicDeviceSlice;
