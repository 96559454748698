import { client } from "Service/axios";
import { GEOCODING_API_URL } from "Service/config";
import { TGoogleMapType } from "Shared/Types/googleMap";

class GoogleMap {
  getGooglePosition({ lat, lng }: TGoogleMapType) {
    return client.get(GEOCODING_API_URL(lat, lng));
  }
}

export default GoogleMap;
