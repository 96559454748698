import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { BrowserRouter } from "react-router-dom";
import "./Styles/index.scss";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import ErrorBoundary from "./Components/ErrorBoundary/ErrorBoundary";
import NiceModal from "@ebay/nice-modal-react";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Suspense>
      <ReactErrorBoundary FallbackComponent={ErrorBoundary}>
        <Provider store={store}>
          <BrowserRouter>
            <NiceModal.Provider>
              <App />
            </NiceModal.Provider>
          </BrowserRouter>
        </Provider>
      </ReactErrorBoundary>
    </Suspense>
  </React.StrictMode>,
);
