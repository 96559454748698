import api from "Service/api/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ITelematicDeviceAssignVehicle,
  ITelematicDeviceSearchParams,
  ITelematicDeviceUnassignVehicle,
} from "Shared/Types/telematic-device";
import {
  IReducerActionWithCallback,
  IReducerWithCallback,
} from "Shared/Types/shared";

const getTelematicDeviceList = createAsyncThunk(
  "telematicDevice/getTelematicDeviceList",
  async (params: ITelematicDeviceSearchParams | null) => {
    try {
      const response = await api.telematicDevice.getTelematicDeviceList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const archiveTelematicDevice = createAsyncThunk(
  "telematicDevice/archive",
  async (id: string) => {
    try {
      const response = await api.telematicDevice.archiveTelematicDevice(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const assignVehicleToDevice = createAsyncThunk(
  "telematicDevice/assignVehicle",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<ITelematicDeviceAssignVehicle>) => {
    try {
      const response = await api.telematicDevice.assignVehicle(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
const unassignVehicleToDevice = createAsyncThunk(
  "telematicDevice/unAssignVehicleToDevice",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<ITelematicDeviceUnassignVehicle>) => {
    try {
      const response = await api.telematicDevice.unassignVehicle(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

const syncTelematicDeviceList = createAsyncThunk(
  "telematicDevice/syncTelematicDeviceList",
  async ({ onSuccess }: IReducerActionWithCallback) => {
    try {
      const response = await api.telematicDevice.syncTelematicDevices();
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export {
  archiveTelematicDevice,
  getTelematicDeviceList,
  assignVehicleToDevice,
  unassignVehicleToDevice,
  syncTelematicDeviceList,
};
