import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const AddFuelHistory = lazyLoader(
  () => import("Containers/FuelHistory/AddFuelHistory/AddFuelHistory")
);

export const AddFuelHistoryPage = () => {
  return (
    <Layout>
      <AddFuelHistory />
    </Layout>
  );
};
