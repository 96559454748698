import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const WorkOrderDetailDashboard = lazyLoader(
  () => import("Containers/WorkOrders/WorkOrderDetail/WorkOrderDetailDashboard")
);

export const WorkOrderDetail = () => {
  return (
    <Layout>
      <WorkOrderDetailDashboard />
    </Layout>
  );
};
