import { client } from "Service/axios";
import {
  COMMON_GROUPS,
  COMMON_GROUPS_CREATE,
  COMMON_GROUPS_DELETE,
} from "Shared/Constants/apiRequest";
import { IAddGroupType, IGroupTypeSearchList } from "Shared/Types/group";

class GroupType {
  getGroupTypeList(params: IGroupTypeSearchList | null) {
    return client.get<any>(COMMON_GROUPS, { params });
  }
  addGroupType(data: IAddGroupType) {
    return client.post<any>(COMMON_GROUPS_CREATE, data);
  }
  deleteGroupType(id: string) {
    return client.delete<any>(COMMON_GROUPS_DELETE(id));
  }
}

export default GroupType;
