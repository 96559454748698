import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getSamsaraDrivers,
  getSamsaraIntegrations,
  getSamsaraVehicleGeoPosition,
  getSamsaraVehicles,
  getSamsaraVehiclesWithoutLimit,
  samsaraSaveDrivers,
  samsaraSaveVehicles,
  samsaraTokenRegistration,
  samsaraTokenRemoval,
} from "./reducer";
import {
  ISamsaraDriversResult,
  ISamsaraVehiclesResult,
  ISamsaraIntegration,
  ISamsaraVehicleGeoPosition,
  ISamsaraDriverEntityResult,
} from "Shared/Types/samsara";

interface SamsaraState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  vehicles: ISamsaraVehiclesResult[];
  vehiclesWithoutLimit: ISamsaraVehiclesResult[];
  vehiclesCount: number;
  drivers: ISamsaraDriversResult;
  driversCount: number;
  loadingDrivers: boolean;
  loadingVehicles: boolean;
  ingetragtions: ISamsaraIntegration[];

  samsaraToken?: number | null;
  checkedDrivers?: ISamsaraDriverEntityResult[];
  checkedVehicles?: ISamsaraVehiclesResult[];
  vehicleGeoPosition: ISamsaraVehicleGeoPosition | null;
}

const initialState: SamsaraState = {
  loading: false,
  loadingDrivers: false,
  loadingVehicles: false,
  error: null,
  vehicles: [],
  vehiclesWithoutLimit: [],
  drivers: {
    results: [],
    count: 0,
  },
  driversCount: 0,
  vehiclesCount: 0,
  ingetragtions: [],
  checkedDrivers: [],

  checkedVehicles: [],
  vehicleGeoPosition: null,
};

const samsaraSlice = createSlice({
  name: "samsara",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    clearGeoPosition(state) {
      state.vehicleGeoPosition = null;
    },
    setCheckedDrivers(state, action) {
      state.checkedDrivers = action.payload || [];
    },

    setCheckedVehicles(state, action) {
      state.checkedVehicles = action.payload || [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSamsaraIntegrations.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getSamsaraIntegrations.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.ingetragtions = action.payload;
    });
    builder.addCase(getSamsaraIntegrations.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getSamsaraDrivers.pending, (state) => {
      state.loading = true;
      state.loadingDrivers = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getSamsaraDrivers.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.loadingDrivers = false;
      state.success = true;
      state.driversCount = action.payload.count || 0;
      state.drivers = action.payload;
    });
    builder.addCase(getSamsaraDrivers.rejected, (state, action) => {
      state.loading = false;
      state.loadingDrivers = false;
      state.error = action.error.message;
    });

    builder.addCase(getSamsaraVehicles.pending, (state) => {
      state.loading = true;
      state.loadingVehicles = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getSamsaraVehicles.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.loadingVehicles = false;
      state.success = true;
      state.vehiclesCount = action.payload.count || 0;
      state.vehicles = action.payload.results;
    });
    builder.addCase(getSamsaraVehicles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.loadingVehicles = false;
    });
    builder.addCase(getSamsaraVehiclesWithoutLimit.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(
      getSamsaraVehiclesWithoutLimit.fulfilled,
      (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = true;
        state.vehiclesWithoutLimit = action.payload.results;
      },
    );
    builder.addCase(
      getSamsaraVehiclesWithoutLimit.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      },
    );

    builder.addCase(samsaraSaveVehicles.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(samsaraSaveVehicles.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(samsaraSaveVehicles.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(samsaraSaveDrivers.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(samsaraSaveDrivers.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(samsaraSaveDrivers.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(samsaraTokenRegistration.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(samsaraTokenRegistration.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(samsaraTokenRegistration.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(samsaraTokenRemoval.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(samsaraTokenRemoval.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(samsaraTokenRemoval.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getSamsaraVehicleGeoPosition.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getSamsaraVehicleGeoPosition.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.vehicleGeoPosition = action.payload;
    });
    builder.addCase(getSamsaraVehicleGeoPosition.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const {
  clear,
  setCheckedDrivers,
  setCheckedVehicles,
  clearGeoPosition,
} = samsaraSlice.actions;

export const selectSamsaraLoading = (state: RootState) => state.samsara.loading;
export const selectSamsaraError = (state: RootState) => state.samsara.error;
export const selectSamsaraSuccess = (state: RootState) => state.samsara.success;
export const selectSamsaraDrivers = (state: RootState) => state.samsara.drivers;
export const selectSamsaraCheckedDrivers = (state: RootState) =>
  state.samsara.checkedDrivers;
export const selectSamsaraCheckedVehicles = (state: RootState) =>
  state.samsara.checkedVehicles;
export const selectSamsaraVehicles = (state: RootState) =>
  state.samsara.vehicles;
export const selectSamsaraVehiclesCount = (state: RootState) =>
  state.samsara.vehiclesCount;
export const selectSamsaraIntegrations = (state: RootState) =>
  state.samsara.ingetragtions;
export const selectSamsaraVehicleGeoPosition = (state: RootState) =>
  state.samsara.vehicleGeoPosition;
export default samsaraSlice;
export const selectSamsaraDriversCount = (state: RootState) =>
  state.samsara.drivers?.count;
export const selectSamsaraVehiclesWithoutLimit = (state: RootState) =>
  state.samsara.vehiclesWithoutLimit;
export const selectSamsaraLoadingVehicles = (state: RootState) =>
  state.samsara.loadingVehicles;
export const selectSamsaraLoadingDrivers = (state: RootState) =>
  state.samsara.loadingDrivers;
