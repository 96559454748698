export const aiGeneretedText = `   The image shows the front part of a car that has sustained significant damage. Here’s an analysis of the problems and the work required for repair:
          
Problems:
* Damaged Hood:
  The hood is heavily deformed with large dents.
  Internal mounting points or hinges may also be damaged.

* Damaged Front Bumper:
  The front bumper appears cracked or misaligned.
  Mounting brackets may be broken or missing.

* Broken Left Headlight:
  The left headlight is shattered or dislodged.
  Internal connections or brackets might also be affected.

* Potential Radiator Damage:
  Given the deformation of the hood, the radiator or cooling system may be damaged.

* Possible Damage to Internal Components:
  The dents suggest potential damage to the internal structure and mounts inside the engine compartment.

Work Required for Restoration:
* Hood Repair or Replacement:
  If possible, straighten the hood and restore its shape.
  If the damage is too severe, replace the hood entirely.

* Repair or Replacement of Front Bumper:
  Depending on the extent of the damage: straighten and repaint the bumper, or replace it.

* Replacement of the Left Headlight:
  Install a new headlight and ensure proper electrical connections and alignment.

* Inspection and Repair of the Radiator:
  Check the cooling system for leaks or damage and fix or replace the radiator if necessary.

* Inspection of Internal Components:
  Assess and fix any damage to mounts, the chassis, and other internal parts.

* Painting:
  After repairs, repaint the repaired or replaced components to restore the car’s appearance.

* Testing and Adjustments:
  Test the car to ensure all systems and components are functioning correctly after repairs`;
