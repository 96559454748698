import { client } from "Service/axios";

import {
  COMPANY,
  COMPANY_CURRENCIES,
  COMPANY_DOMAIN_CHECK,
  COMPANY_DOMAIN_CREATE,
  COMPANY_DOMAIN_SUGGEST,
  COMPANY_FLEETSIZES,
  COMPANY_INDUSTRIES,
  COMPANY_ONBOARDING,
  COMPANY_UPDATE,
} from "Shared/Constants/apiRequest";
import { ICompanyOnboarding } from "Shared/Types/auth";
import { IGeneral } from "Shared/Types/companyProps";
import { ISubdomain } from "Shared/Utils/yup/companyShema";
import { IPersonalCompanyData } from "Shared/Utils/yup/personalCompany";

class CompanyProps {
  getCurrencyList() {
    return client.get<any>(COMPANY_CURRENCIES);
  }
  getIndustryList() {
    return client.get<any>(COMPANY_INDUSTRIES);
  }
  getFleetSizeList() {
    return client.get<any>(COMPANY_FLEETSIZES);
  }
  getPersonalGeneral() {
    return client.get<any>(COMPANY);
  }
  checkDomain(subdomain: ISubdomain) {
    return client.post<any>(COMPANY_DOMAIN_CHECK, subdomain);
  }
  createNewDomain(subdomain: ISubdomain) {
    return client.post<any>(COMPANY_DOMAIN_CREATE, subdomain);
  }
  suggestDomain(base: any) {
    return client.post<any>(COMPANY_DOMAIN_SUGGEST, base);
  }

  updatePersonalGeneral(data: IPersonalCompanyData) {
    return client.patch<any>(COMPANY_UPDATE, data);
  }
  getGeneralSetting(params: IGeneral | null) {
    return client.patch<any>(COMPANY_UPDATE, params);
  }
  createCompanyOnboarding(data: ICompanyOnboarding) {
    return client.post<any>(COMPANY_ONBOARDING, {
      ...data,
      short_date_format: "10/02/2023",
    });
  }
}

export default CompanyProps;
