import { client } from "Service/axios";
import {
  BILLING_CURRENT_INVOICE,
  BILLING_INFO,
  BILLING_INVOICES_LIST,
} from "Shared/Constants/apiRequest";
import {
  CompanyPackage,
  Invoice,
  PaginatedInvoiceList,
} from "Shared/Types/fleetmanagementapi";
import { IBillingInvoiceSearchParams } from "Shared/Types/invoice";

class Invoices {
  getInvoicesList(params: IBillingInvoiceSearchParams | null) {
    return client.get<PaginatedInvoiceList>(BILLING_INVOICES_LIST, { params });
  }
  getInvoiceInfo() {
    return client.get<CompanyPackage>(BILLING_INFO);
  }
  getCurrentInvoice() {
    return client.get<Invoice>(BILLING_CURRENT_INVOICE);
  }
}

export default Invoices;
