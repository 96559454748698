import React from "react";
import "./App.css";
import { Navigate, Route, Routes, useLocation } from "react-router";
import { routeArray } from "Routes/route-config";
import { useAppDispatch } from "Redux/store";
import { useSelector } from "react-redux";
import { clear, readObj } from "Service/storage";
import { restoreSession } from "Redux/Auth/reducers";
import { useAuthRedirect } from "Shared/Hook/use-auth";
import { selectAccount } from "Redux/Auth/slice";
import { ToastBlock } from "Components/Notify/notify";
import { ISSUES_ADD } from "Shared/Constants/routes";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import "react-calendar/dist/Calendar.css";

function App() {
  const location = useLocation();
  const profile = useSelector(selectAccount);
  const dispatch = useAppDispatch();

  if (location.pathname !== ISSUES_ADD) clear("issueFromFault");

  const routes = routeArray.map((r) => (
    <Route key={r.path} path={r.path} element={<r.page />} />
  ));

  const redirect = useAuthRedirect(location);

  if (!profile) {
    const acc = readObj("account");
    if (acc) {
      dispatch(restoreSession());
    }
  }

  if (redirect !== undefined) return <Navigate to={redirect} />;

  return (
    <>
      <ToastBlock />
      <Routes>{routes}</Routes>
    </>
  );
}

export default App;
