import { client } from "Service/axios";
import {
  DASHBOARD_ISSUES_CALENDAR,
  DASHBOARD_VEHICLE_ASSIGNMENT_ID,
  DASHBOARD_VEHICLE_ASSIGNMENTS,
  DASHBOARD_REMINDERS,
  DASHBOARD_WORK_ORDER,
  DASHBOARD_RECENT_COMMENTS,
  DASHBOARD_INSPECTION_SUBMISSION_WEEKLY,
  DASHBOARD_INSPECTION_SUBMISSION_WEEKLY_OUTCOMES,
  DASHBOARD_LOCATION,
} from "Shared/Constants/apiRequest";
import {
  DashboardVehicleStatus,
  TDashboardIssuesCalendarParams,
  TDashboardVehicleAssignmentsPaginated,
  TDashboardVehicleAssignmentsParams,
  DashboardReminder,
  DashboardWorkOrder,
  DashboardInspectionSubmission,
  DashboardInspectionSubmissionOutcomes,
  DashboardLocation,
  DashboardIssuesStatus,
} from "Shared/Types/dashboard";
import {
  AssignmentDetail,
  IssuesCalendarResponse,
  PaginatedRecentCommentDashboardList,
} from "Shared/Types/fleetmanagementapi";
import {
  DASHBOARD_ISSUES_STATS,
  DASHBOARD_VEHICLE_STATUS,
} from "Shared/Constants/apiRequest";

class Dashboard {
  getDashboardVehicleAssignment(
    params: TDashboardVehicleAssignmentsParams | null,
  ) {
    return client.get<TDashboardVehicleAssignmentsPaginated>(
      DASHBOARD_VEHICLE_ASSIGNMENTS,
      { params },
    );
  }
  getDashboardVehicleAssignmentDetail(id: string) {
    return client.get<AssignmentDetail>(DASHBOARD_VEHICLE_ASSIGNMENT_ID(id));
  }
  getVehicleStatus() {
    return client.get<DashboardVehicleStatus>(DASHBOARD_VEHICLE_STATUS);
  }
  getIssuesStats() {
    return client.get<any>(DASHBOARD_ISSUES_STATS);
  }
  getDashboardReminders(params: any) {
    return client.get<DashboardReminder>(DASHBOARD_REMINDERS, {
      params,
    });
  }
  getDashboardWorkOrder(params: any) {
    return client.get<DashboardWorkOrder>(DASHBOARD_WORK_ORDER, {
      params,
    });
  }
  getDashboardIssuesCalendar(params: TDashboardIssuesCalendarParams | null) {
    return client.get<IssuesCalendarResponse>(DASHBOARD_ISSUES_CALENDAR, {
      params,
    });
  }
  getDashboardRecentComments() {
    return client.get<PaginatedRecentCommentDashboardList>(
      DASHBOARD_RECENT_COMMENTS,
    );
  }

  getDashboardInspectionSubmission() {
    return client.get<DashboardInspectionSubmission>(
      DASHBOARD_INSPECTION_SUBMISSION_WEEKLY,
    );
  }
  getDashboardInspectionSubmissionOutcomes() {
    return client.get<DashboardInspectionSubmissionOutcomes>(
      DASHBOARD_INSPECTION_SUBMISSION_WEEKLY_OUTCOMES,
    );
  }
  getDashboardLocation() {
    return client.get<DashboardLocation>(DASHBOARD_LOCATION);
  }
  getDashboardIssuesStatus() {
    return client.get<DashboardIssuesStatus>(DASHBOARD_ISSUES_STATS);
  }
}

export default Dashboard;
