import React from "react";
import { lazyLoader } from "Service/loader";
import { Layout } from "Components/Layout/Layout";
import Breadcrumb, { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
const PurchaseOrderForm = lazyLoader(
  () =>
    import(
      "Containers/PurchaseOrder/AddPurchaseOrder/PurchaseOrderForm/PurchaseOrderForm"
    )
);

export const AddPurchaseOrder = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Parts", link: "/purchase-orders" },
    { title: "New Purchase Order", link: "" },
  ];

  return (
    <Layout hasSidebar={false}>
      <Breadcrumb breads={breads} />
      <PurchaseOrderForm type="CREATE" />
    </Layout>
  );
};
