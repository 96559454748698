import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getInputGlobal } from "./InputGlobalReducer";
import { TSearchGlobal } from "Shared/Types/globalSearch";

interface IGlobalSearchTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  result: TSearchGlobal | null;
}

const initialState: IGlobalSearchTypeState = {
  loading: false,
  error: null,
  result: null,
};

const inputGlobalSlice = createSlice({
  name: "inputGlobalSlice",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInputGlobal.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.result = action.payload;
    });
    builder.addCase(getInputGlobal.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
  },
});

export const { clear } = inputGlobalSlice.actions;

export const selectGlobalSearchResult = (state: RootState) =>
  state.inputGlobal.result;

export default inputGlobalSlice;
