import React, { MouseEvent } from "react";
import {
  IconButtonProps as MUIIconButtonProps,
  IconButton as MUIIconButton,
} from "@mui/material";
import styles from "./IconButton.module.scss";
import Icon from "Components/UI/Icon/Icon";
import cn from "classnames";
import { ArrowDownIcon } from "Assets";

interface IconButtonProps
  extends Omit<MUIIconButtonProps, "color" | "onClick"> {
  icon: React.ElementType;
  color?: string;
  withBorder?: boolean;
  label?: string;
  containerClassName?: string;
  variant?: "primary";
  width?: number;
  height?: number;
  isAlwaysOpen?: boolean;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  withArrow?: boolean;
  isHideBorder?: boolean;
  isHideBackground?: boolean;
}

const IconButton: React.FC<IconButtonProps> = ({
  icon,
  className,
  color,
  label,
  containerClassName,
  variant,
  width = 21,
  height = 21,
  isAlwaysOpen,
  onClick,
  withArrow,
  isHideBorder = false,
  isHideBackground = false,
  ...props
}) => {
  return (
    <div
      style={{ width: !isAlwaysOpen && label ? "160px" : "" }}
      className={isAlwaysOpen ? styles.open_block_cont : styles.block_cont}
    >
      <div
        className={cn(
          label ? styles.label_block : styles.block,
          containerClassName,
        )}
        style={{
          border: isHideBorder ? "none" : undefined,
          background: isHideBackground ? "none" : undefined,
        }}
        onClick={onClick}
      >
        <MUIIconButton
          size="medium"
          classes={{
            root: cn(styles.btn, variant && styles.btn__primary, className),
          }}
          {...props}
        >
          <Icon component={icon} color={color} width={width} height={height} />
        </MUIIconButton>
        {label && (
          <span className={styles.label_block__label}>
            {label} {withArrow && <Icon component={ArrowDownIcon} />}
          </span>
        )}
      </div>
    </div>
  );
};
export default IconButton;
