import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  addTransmissionTypeList,
  getTransmissionTypeList,
} from "./TransmissionTypeReducer";
import { ITransmissionType } from "Shared/Types/TransmissionType";

interface TransmissionTypeSliceState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  transmissionTypeSliceList: ITransmissionType[];
}

const initialState: TransmissionTypeSliceState = {
  loading: false,
  error: null,
  transmissionTypeSliceList: [],
};

const TransmissionTypeSlice = createSlice({
  name: "TransmissionTypeSlice",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTransmissionTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.transmissionTypeSliceList = action.payload.results;
    });
    builder.addCase(addTransmissionTypeList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addTransmissionTypeList.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addTransmissionTypeList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = TransmissionTypeSlice.actions;

export const selectTransmissionTypeList = (state: RootState) =>
  state.transmissionType.transmissionTypeSliceList;
export const selectTransmissionTypeAuthLoading = (state: RootState) =>
  state.aspiration.loading;
export const selectTransmissionTypeAuthError = (state: RootState) =>
  state.aspiration.error;
export const selectTransmissionTypeAuthSuccess = (state: RootState) =>
  state.aspiration.success;

export default TransmissionTypeSlice;
