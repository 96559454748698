import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const AddServiceReminder = lazyLoader(
  () =>
    import("Containers/ReminderService/AddServiceReminder/AddServiceReminder")
);

export const AddServiceReminderPage = () => {
  return (
    <Layout>
      <AddServiceReminder />
    </Layout>
  );
};
