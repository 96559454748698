import { client } from "Service/axios";
import {
  VEHICLES_FUEL_TYPES,
  VEHICLES_FUEL_TYPES_CREATE,
} from "Shared/Constants/apiRequest";
import { IAddFuelType, IFuelTypeSearchList } from "Shared/Types/fuelType";

class FuelType {
  getFuelTypeList(params: IFuelTypeSearchList | null) {
    return client.get<any>(VEHICLES_FUEL_TYPES, { params });
  }
  addFuelType(data: IAddFuelType) {
    return client.post<any>(VEHICLES_FUEL_TYPES_CREATE, data);
  }
}

export default FuelType;
