import { createSlice } from "@reduxjs/toolkit";

import { IWorkOrder, TRelatedServiceTask } from "Shared/Types/workOrder";
import { WorkOrderRetrieve } from "Shared/Types/fleetmanagementapi";

import { RootState } from "../store";
import {
  editWorkOrder,
  getWorkOrder,
  getWorkOrderList,
  getWorkOrderRelatedServiceTasks,
} from "./reducer";

interface ICompanyRequestsState {
  error?: string | null;
  loading: boolean;
  success?: boolean;
  loadingEdit: boolean;
  numPage: number;
  currentWorkOrder: IWorkOrder | null;
  relatedServiceTasks: TRelatedServiceTask | null;
  workOrderList: {
    error?: string | null;
    loading: boolean;
    success?: boolean | null;
    workOrderList: WorkOrderRetrieve[] | [];
  };
}

const initialState: ICompanyRequestsState = {
  loading: false,
  error: null,
  loadingEdit: false,
  numPage: 0,
  currentWorkOrder: null,
  relatedServiceTasks: null,
  workOrderList: {
    loading: false,
    error: null,
    success: false,
    workOrderList: [],
  },
};

const workOrderSlice = createSlice({
  name: "workOrder",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = false;
    },
    clearCurrentWorkOrder(state) {
      state.currentWorkOrder = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWorkOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentWorkOrder = action.payload;
    });
    builder.addCase(
      getWorkOrderRelatedServiceTasks.fulfilled,
      (state, action) => {
        state.loading = false;
        state.error = null;
        state.relatedServiceTasks = action.payload;
      },
    );
    builder.addCase(getWorkOrderList.pending, (state, action) => {
      state.workOrderList.loading = true;
      state.workOrderList.error = null;
    });
    builder.addCase(getWorkOrderList.fulfilled, (state, action) => {
      state.workOrderList.loading = false;
      state.workOrderList.error = null;
      state.workOrderList.success = true;
      state.workOrderList.workOrderList = action.payload.results;
      state.numPage = action.payload.count;
    });
    builder.addCase(getWorkOrderList.rejected, (state, action) => {
      state.workOrderList.loading = false;
      state.workOrderList.error = action.error.message;
      state.workOrderList.success = false;
    });
    builder.addCase(editWorkOrder.fulfilled, (state) => {
      state.loadingEdit = false;
    });
    builder.addCase(editWorkOrder.pending, (state) => {
      state.loadingEdit = true;
    });
    builder.addCase(editWorkOrder.rejected, (state) => {
      state.loadingEdit = false;
    });
  },
});
export const { clear, clearCurrentWorkOrder } = workOrderSlice.actions;
export const selectCurrentWorkOrder = (state: RootState) =>
  state.work_order.currentWorkOrder;
export const selectWorkOrderRelatedServiceTasks = (state: RootState) =>
  state.work_order.relatedServiceTasks;
export const selectWorkOrderNumPage = (state: RootState) =>
  state.work_order.numPage;
export const selectWorkOrderList = (state: RootState) =>
  state.work_order.workOrderList.workOrderList;
export const selectWorkOrderListLoading = (state: RootState) =>
  state.work_order.workOrderList.loading;
export const selectWorkOrderListError = (state: RootState) =>
  state.work_order.workOrderList.error;
export const selectEditWorkOrderLoading = (state: RootState) =>
  state.work_order.loadingEdit;
export const selectWorkOrderListSuccess = (state: RootState) =>
  state.work_order.workOrderList.success;

export default workOrderSlice;
