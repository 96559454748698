import { ReactComponent as SearchIcon } from "Assets/searchIcon.svg";
import { useAppDispatch } from "Redux/store";
import React, { useEffect, useState, useRef } from "react";
import styles from "./InputSearch.module.scss";
import { clear } from "Redux/InputGlobal/InputGlobalSlice";
import { getInputGlobal } from "Redux/InputGlobal/InputGlobalReducer";
import SearchResult from "./SearchResult/SearchResult";
import { useSearchParams } from "react-router-dom";

const GlobalSearch: React.FC = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string | undefined>();
  const [searchParams, setSearchParams] = useSearchParams();
  const modalRef = useRef<HTMLDivElement>(null);

  const searchTerm = searchParams.get("query") || "";

  const onClose = () => {
    if (!searchValue || searchValue === "") {
      dispatch(clear());
      setOpen(false);
    }
    setOpen(false);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    setSearchParams({ query: e.target.value });
    if (e.target.value) {
      setOpen(true);
    }
  };

  const fetchSearchResults = async (term: string) => {
    if (term) {
      dispatch(getInputGlobal({ query: term }));
    }
  };

  useEffect(() => {
    fetchSearchResults(searchTerm);
  }, [searchTerm]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => {
      document.body.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.searchBox} ref={modalRef}>
      <input
        className={styles.searchBox__input}
        placeholder="Search"
        onChange={handleInputChange}
        value={searchValue}
        onFocus={() => {
          if (searchValue) {
            setOpen(true);
          }
        }}
      />
      {open && <SearchResult onClose={onClose} searchValue={searchValue} />}
      <SearchIcon className={styles.searchBox__icon} />
    </div>
  );
};

export default GlobalSearch;
