import { client } from "Service/axios";
import {
  SAMSARA_DEVICES_SYNC,
  VEHICLES_ID_ARCHIVE_TELEMATICS_DEVICES,
  VEHICLES_ID_ASSIGN_VEHICLE,
  VEHICLES_ID_UNASSIGN_VEHICLE,
  VEHICLES_TELEMATICS_DEVICES,
} from "Shared/Constants/apiRequest";
import { PaginatedResponse } from "Shared/Types/shared";
import {
  ITelematicDevice,
  ITelematicDeviceAssignVehicle,
  ITelematicDeviceSearchParams,
  ITelematicDeviceUnassignVehicle,
} from "Shared/Types/telematic-device";

class TelematicDevice {
  getTelematicDeviceList(params: ITelematicDeviceSearchParams | null) {
    return client.get<PaginatedResponse<ITelematicDevice[]>>(
      VEHICLES_TELEMATICS_DEVICES,
      { params }
    );
  }
  archiveTelematicDevice(id: string) {
    return client.post(VEHICLES_ID_ARCHIVE_TELEMATICS_DEVICES(id));
  }
  assignVehicle({ vehicle, deviceId }: ITelematicDeviceAssignVehicle) {
    return client.post(VEHICLES_ID_ASSIGN_VEHICLE(deviceId), { vehicle });
  }
  unassignVehicle({ deviceId }: ITelematicDeviceUnassignVehicle) {
    return client.post(VEHICLES_ID_UNASSIGN_VEHICLE(deviceId));
  }
  syncTelematicDevices() {
    return client.post(SAMSARA_DEVICES_SYNC);
  }
}

export default TelematicDevice;
