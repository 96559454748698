import { createSlice } from "@reduxjs/toolkit";
import { IIssue } from "Shared/Types/issue";
import { IFile } from "Shared/Types/file";
import { RootState } from "../store";
import {
  addIssue,
  getIssue,
  getIssueFilesList,
  getIssueList,
  getIssuesChartAnalytics,
} from "./reducer";
import { IssueDashboardResponse } from "Shared/Types/fleetmanagementapi";

interface InitialState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  issueList: IIssue[];
  currentIssue: IIssue | null;
  currentIssueLoading: boolean;
  currentIssueFiles: IFile[];
  numPage: number;
  chartAnalytics: {
    loading: boolean;
    error?: string | null;
    data: IssueDashboardResponse | null;
  };
}

const initialState: InitialState = {
  loading: false,
  error: null,
  issueList: [],
  currentIssue: null,
  currentIssueLoading: false,
  numPage: 0,
  currentIssueFiles: [],
  chartAnalytics: {
    loading: false,
    error: null,
    data: null,
  },
};

const issueSlice = createSlice({
  name: "issue",
  initialState,
  reducers: {
    clearAllInIssues(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
      state.issueList = [];
      state.currentIssue = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getIssueList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.issueList = action.payload.results;
      state.numPage = action.payload.count;
    });
    builder.addCase(getIssueList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getIssueList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getIssue.fulfilled, (state, action) => {
      state.currentIssueLoading = false;
      state.error = null;
      state.currentIssue = action.payload;
    });
    builder.addCase(getIssue.pending, (state) => {
      state.currentIssueLoading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getIssue.rejected, (state, action) => {
      state.currentIssueLoading = false;
      state.error = action.error.message;
    });
    builder.addCase(addIssue.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addIssue.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addIssue.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getIssueFilesList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getIssueFilesList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.currentIssueFiles = action.payload.results;
    });
    builder.addCase(getIssueFilesList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getIssuesChartAnalytics.pending, (state) => {
      state.chartAnalytics.loading = true;
      state.chartAnalytics.error = null;
    });
    builder.addCase(getIssuesChartAnalytics.fulfilled, (state, action) => {
      state.chartAnalytics.loading = false;
      state.chartAnalytics.error = null;
      state.chartAnalytics.data = action.payload;
    });
    builder.addCase(getIssuesChartAnalytics.rejected, (state, action) => {
      state.chartAnalytics.loading = false;
      state.chartAnalytics.error = action.error.message;
    });
  },
});

export const { clearAllInIssues } = issueSlice.actions;

export const selectIssueList = (state: RootState) => state.issue.issueList;

export const selectCurrentIssueFilesList = (state: RootState) =>
  state.issue.currentIssueFiles;
export const selectCurrentIssue = (state: RootState) =>
  state.issue.currentIssue;
export const selectIssueLoading = (state: RootState) => state.issue.loading;
export const selectCurrentIssueLoading = (state: RootState) =>
  state.issue.currentIssueLoading;
export const selectIssueError = (state: RootState) => state.issue.error;
export const selectIssueSuccess = (state: RootState) => state.issue.success;
export const selectIssueNumPage = (state: RootState) => state.issue.numPage;
export const selectIssueChartAnalyticsData = (state: RootState) =>
  state.issue.chartAnalytics.data;
export const selectIssueChartAnalyticsLoading = (state: RootState) =>
  state.issue.chartAnalytics.loading;

export default issueSlice;
