import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

const ReportsPartsByLocationList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsPartsByLocation/ReportsPartsByLocationList/ReportsPartsByLocationList"
    )
);
export const ReportsPartsByLocation = () => {
  return (
    <Layout>
      <ReportsPartsByLocationList />
    </Layout>
  );
};
