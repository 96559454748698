import * as Yup from "yup";
import { PasswordSchema } from "./shared";
import { z } from "zod";

export const passwordSchema = Yup.object().shape({
  password: PasswordSchema,
  confirm_password: Yup.string()
    .required()
    .oneOf([Yup.ref("password")], "Passwords must match"),
});

export const changePasswordSchema = Yup.object().shape({
  old_password: Yup.string().required(),
  new_password: PasswordSchema,
});
