import { createSlice } from "@reduxjs/toolkit";
import { IFuelInductionType } from "Shared/Types/fuelInduction";
import { RootState } from "../store";
import {
  addFuelInductionType,
  getFuelInductionTypeList,
} from "./FuelInductionReducer";

interface bodyTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  fuelInductionTypeList: IFuelInductionType[];
}

const initialState: bodyTypeState = {
  loading: false,
  error: null,
  fuelInductionTypeList: [],
};

const FuelInductionTypeSlice = createSlice({
  name: "fuelInductionType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFuelInductionTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.fuelInductionTypeList = action.payload.results;
    });
    builder.addCase(addFuelInductionType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addFuelInductionType.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addFuelInductionType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = FuelInductionTypeSlice.actions;

export const selectFuelInductionTypeList = (state: RootState) =>
  state.fuelInduction.fuelInductionTypeList;
export const selectFuelInductionTypeAuthLoading = (state: RootState) =>
  state.fuelInduction.loading;
export const selectFuelInductionTypeAuthError = (state: RootState) =>
  state.fuelInduction.error;
export const selectFuelInductionTypeAuthSuccess = (state: RootState) =>
  state.fuelInduction.success;

export default FuelInductionTypeSlice;
