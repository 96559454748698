import React, { memo, useEffect } from "react";
import styles from "./Sidebar.module.scss";
import { generalData } from "Shared/Constants/sidebar";
import cn from "classnames";
import { useSelector } from "react-redux";
import { selectPersonalGeneral } from "Redux/CompanyProps/slice";
import { useAppDispatch } from "Redux/store";
import { getPersonalGeneral } from "Redux/CompanyProps/reducer";
import Icon from "Components/UI/Icon/Icon";
import { NewLogoIcon } from "Assets";
import SidebarHeaderItem from "./SidebarHeaderItem";

const SidebarNew: React.FC = () => {
  const companyInfo = useSelector(selectPersonalGeneral);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getPersonalGeneral());
  }, []);

  return (
    <div className={cn(styles.sidebar)}>
      {companyInfo?.logo?.file ? (
        <img
          className={styles.sidebar__logo}
          src={companyInfo?.logo.file}
          alt="/"
        />
      ) : (
        <div className={styles.sidebar__logo}>
          <Icon
            height={80}
            component={NewLogoIcon}
            alt="Easy Fleet"
            fontSize="small"
          />
        </div>
      )}
      <div className={styles.sidebar__general}>
        {generalData.map((item) => (
          <SidebarHeaderItem
            key={item.title}
            title={item.title}
            subMenu={item.subMenu}
            icon={item.icon}
          />
        ))}
      </div>
    </div>
  );
};
export default memo(SidebarNew);
