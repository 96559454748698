import { createSlice } from "@reduxjs/toolkit";
import { PaymentType } from "Shared/Types/fleetmanagementapi";
import { RootState } from "../store";
import { getPaymentType } from "./reducer";

interface InitialState {
  loading: boolean;
  count: number;
  paymentTypeList: PaymentType[];
}

const initialState: InitialState = {
  loading: false,
  count: 0,
  paymentTypeList: [],
};

const paymentSlice = createSlice({
  name: "paymentSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPaymentType.fulfilled, (state, action) => {
      state.loading = false;
      state.paymentTypeList = action.payload.results || [];
      state.count = action.payload.count || 0;
    });
  },
});

export const selectPaymentTypeList = (state: RootState) =>
  state.payment.paymentTypeList;

export default paymentSlice;
