import { client } from "Service/axios";
import {
  AUTH_EMAIL_CODE,
  AUTH_LOGIN,
  AUTH_ME,
  AUTH_ME_PATCH,
  AUTH_PASSWORD,
  AUTH_PASSWORD_CONFIRM,
  AUTH_PASSWORD_RESET,
  AUTH_REGISTRATION,
  AUTH_RESET_EMAIL_CONFIRMATION,
  AUTH_TOKEN_VERIFY,
} from "Shared/Constants/apiRequest";
import {
  IAuthLogin,
  IAuthRefresh,
  IChangePassword,
  IResetPassword,
  IVerificationCode,
} from "Shared/Types/auth";
import { IUserProfileData } from "Shared/Utils/yup/userProfileShema";

class Auth {
  login(data: IAuthLogin) {
    return client.post<any>(AUTH_LOGIN, data);
  }
  refresh(data: IAuthRefresh) {
    return client.post<any>(AUTH_TOKEN_VERIFY, data);
  }
  getMe() {
    return client.get<any>(AUTH_ME);
  }
  updateMe(data: Omit<IUserProfileData, "email">) {
    return client.patch<any>(AUTH_ME_PATCH, data);
  }
  deleteMeProfileImage() {
    return client.patch<any>(AUTH_ME_PATCH, { profile_picture: "" });
  }

  changePassword(data: IChangePassword) {
    return client.post<any>(AUTH_PASSWORD, data);
  }
  resetPasswordByEmail(email: string) {
    return client.post<any>(AUTH_PASSWORD_RESET, { email });
  }

  resetPassword(data: IResetPassword) {
    return client.post<any>(AUTH_PASSWORD_CONFIRM, data);
  }

  register(data: IAuthLogin) {
    return client.post<any>(AUTH_REGISTRATION, data);
  }

  resendEmailForVerification(email: string) {
    return client.post<any>(AUTH_RESET_EMAIL_CONFIRMATION, { email });
  }
  sendVerificationCode(data: IVerificationCode) {
    return client.post<any>(
      AUTH_EMAIL_CODE,
      { code: data.code },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
  }
}

export default Auth;
