import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const ServiceHistoryDetail = lazyLoader(
  () =>
    import(
      "Containers/ServiceHistory/ServiceHistoryDetail/ServiceHistoryDetail"
    )
);

export const ServiceHistoryDetails = () => {
  return (
    <Layout>
      <ServiceHistoryDetail />
    </Layout>
  );
};
