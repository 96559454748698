import { client } from "Service/axios";
import {
  VEHICLE_BODY_TYPES,
  VEHICLE_BODY_TYPES_CREATE,
} from "Shared/Constants/apiRequest";
import { IAddBodyType, IBodyTypeSearchList } from "Shared/Types/bodyType";

class BodyType {
  getBodyTypeList(params: IBodyTypeSearchList | null) {
    return client.get<any>(VEHICLE_BODY_TYPES, { params });
  }
  addBodyType(data: IAddBodyType) {
    return client.post<any>(VEHICLE_BODY_TYPES_CREATE, data);
  }
}

export default BodyType;
