import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'Service/api/api';
import { IPapersSearch } from 'Shared/Types/paper';
import { IUpdateReducerWithCallback } from 'Shared/Types/shared';
import { IPaperData } from 'Shared/Utils/yup/document';

const getDocumentsList = createAsyncThunk(
  'documents/getDocumentsList',
  async (params: IPapersSearch | null) => {
    try {
      const response = await api.document.getDocumentList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getDocument = createAsyncThunk(
  'documents/getDocument',
  async (id: string) => {
    try {
      const response = await api.document.getDocument(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const deleteDocument = createAsyncThunk(
  'documents/deleteDocument',
  async (id: string, { dispatch }) => {
    try {
      await api.document.deleteDocument(id);
      dispatch(getDocumentsList(null));
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const updateDocument = createAsyncThunk(
  'documents/updateDocument',
  async (
    { id, data, onError, onSuccess }: IUpdateReducerWithCallback<IPaperData>,
    { dispatch },
  ) => {
    try {
      const response = await api.document.updateDocument({ data, id });
      dispatch(getDocumentsList(null));
      onSuccess && onSuccess();
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const createDocument = createAsyncThunk(
  'documents/createDocument',
  async (formData: FormData, { dispatch }) => {
    try {
      const response = await api.document.createDocument(formData);
      dispatch(getDocumentsList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const document = createAsyncThunk(
  'documents/document',
  async (id: string, { dispatch }) => {
    try {
      const response = await api.document.document(id);
      dispatch(getDocumentsList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export {
  createDocument,
  deleteDocument,
  document,
  getDocument,
  getDocumentsList,
  updateDocument,
};
