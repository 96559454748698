import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IVendorCommentAdd } from "Shared/Types/comment";
import { IAddFilesToEntityTypes } from "Shared/Types/file";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";
import {
  INearestVendorsParams,
  IVendorFilterParams,
} from "Shared/Types/vendor";
import { IAddVendor } from "Shared/Utils/yup/addVendor";

export const getVendorsList = createAsyncThunk(
  "vendor/getVendorsList",
  async (params: IVendorFilterParams | null) => {
    try {
      const response = await api.vendors.getVendorsList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const getNearestVendors = createAsyncThunk(
  "vendor/getNearestVendors",
  async (params: INearestVendorsParams) => {
    try {
      const response = await api.vendors.getNearestVendors(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const getNearestVendorsMore = createAsyncThunk(
  "vendor/getNearestVendorsMore",
  async (params: INearestVendorsParams) => {
    try {
      const response = await api.vendors.getNearestVendors(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const getVehicleVendorsList = createAsyncThunk(
  "vendor/getVehicleVendorsList",
  async () => {
    try {
      const response = await api.vendors.getVehicleVendorsList();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const getVendor = createAsyncThunk(
  "vendor/getVendor",
  async (id: string) => {
    try {
      const response = await api.vendors.getVendor(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const updateVendor = createAsyncThunk(
  "vendor/updateVendor",
  async (
    { data, id, onSuccess, onError }: IUpdateReducerWithCallback<IAddVendor>,
    { dispatch },
  ) => {
    try {
      const response = await api.vendors.updateVendor({ data, id });
      dispatch(getVendor(id));
      onSuccess && onSuccess();
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

export const updateVendorDocuments = createAsyncThunk(
  "vendor/updateVendorDocument",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.vendors.updateVendorDocument({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getVendor(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);
export const addVendor = createAsyncThunk(
  "vendor/createVendor",
  async (
    { data, onSuccess, onError }: IReducerWithCallback<IAddVendor>,
    { dispatch },
  ) => {
    try {
      const response = await api.vendors.addVendor(data);
      dispatch(getVendorsList(null));
      onSuccess && onSuccess();
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

export const removeVendor = createAsyncThunk(
  "vendor/removeVendor",
  async ({
    data: { id },
    onError,
    onSuccess,
  }: IReducerWithCallback<{ id: string }>) => {
    try {
      const response = await api.vendors.removeVendor(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const createComment = createAsyncThunk(
  "vendor/createComment",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<IVendorCommentAdd>) => {
    try {
      const response = await api.vendors.saveComment(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const deleteComment = createAsyncThunk(
  "vendor/createComment",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.vendors.deleteComment(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);
export const getVendorDocumentsList = createAsyncThunk(
  "vendor/getVendorDocumentsList",
  async (id: string) => {
    try {
      const response = await api.vendors.getVendorDocumentsList(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const addDocumentsToVendor = createAsyncThunk(
  "vendor/addDocumentsToVendor",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.vendors.addDocumentsToVendor({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getVendor(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

export const removeDocumentsFromVendor = createAsyncThunk(
  "vendor/removeDocumentsFromVendor",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.vendors.removeDocumentsFromVendor({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getVendor(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);
