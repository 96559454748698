import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddMakeType, IMakeTypeSearchList } from "Shared/Types/make";

const getMakeTypeList = createAsyncThunk(
  "makeType/getMakeTypeList",
  async (params: IMakeTypeSearchList | null) => {
    try {
      const response = await api.makeType.getMakeTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addMakeType = createAsyncThunk(
  "makeType/addMakeType",
  async (data: IAddMakeType) => {
    try {
      const response = await api.makeType.addMakeType(data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addMakeType, getMakeTypeList };
