export const REGISTER = "/register";
export const LOGIN = "/login";
export const RESET_PASSWORD_EMAIL = "/reset-password-email";
export const RESET_PASSWORD = "/reset-password";
export const VERIFICATION = "/verification";
export const ONBOARDING_FORM = "/onboarding-form";
export const LAYOUT = "/";
export const HOME = "/home";
export const UNITS = "/units";
export const UNITS_UPDATE = "/units/update";
export const UNIT = "/units";
export const CREATE_UNIT = "/create-unit";
export const CREATE_UNIT_ANY = `${CREATE_UNIT}/*`;
export const ASSIGNMENTS = "/assignments";
export const NOT_FOUND = "/not-found";
export const FOR_BIDDEN = "/forbidden";
export const SETTINGS_ROLE = "/settings/roles";
export const SETTINGS = "/settings/*";
export const SETTINGS_ROLES_CREATE = "/settings/roles/create";
export const SETTINGS_ROLES_UPDATE = "/settings/roles/update";
export const USERS = "/users";
export const CONTACTS = "/contacts";
export const USERS_CREATE = "/users/create";
export const USER = "/users";
export const CONTACT_SETUP_PASSWORD = "/contact/setup-password";
export const USERS_UPDATE = "/users/update";
export const SAMSARA_DEVICES = "/samsara-devices";
export const SAMSARA_DEVICES_SAMSARA_INTEGRATION = `${SAMSARA_DEVICES}/samsara-integration`;
export const SAMSARA_DEVICES_SAMSARA_INTEG_REGISTR = `${SAMSARA_DEVICES_SAMSARA_INTEGRATION}/registration`;
export const SAMSARA_DEVICES_SAMSARA_INTEG_VEHICLE = `${SAMSARA_DEVICES_SAMSARA_INTEGRATION}/vehicle`;
export const SAMSARA_DEVICES_SAMSARA_INTEG_USERS = `${SAMSARA_DEVICES_SAMSARA_INTEGRATION}/users`;
export const SAMSARA_DEVICES_SAMSARA_INTEG_INFO = `${SAMSARA_DEVICES_SAMSARA_INTEGRATION}/info`;
export const SAMSARA_DEVICES_SAMSARA_INTEG_FINISH = `${SAMSARA_DEVICES_SAMSARA_INTEGRATION}/finish`;
export const VENDORS = "/vendor";
export const VENDOR_ADD = "/vendor/add/";
export const VENDOR_EDIT = "/vendor/edit";
export const VENDOR = "/vendor";
export const FAULTS = "/faults";
export const ISSUES = "/issues";
export const ISSUES_ADD = "/issues/add";
export const ISSUE = "/issues";
export const ISSUES_UPDATE = "/issues/update";
export const METER_HISTORY = "/meter-history";
export const SETTINGS_FAULTS_RULES = "/settings/fault-rules";
export const SETTINGS_GENERAL_SETTING_EDIT = "/settings/general-setting/edit";
export const SETTINGS_GENERAL_SETTING_BILLING =
  "/settings/general-setting/billing";
export const SETTINGS_GENERAL_SETTING = "/settings/general-setting";
export const SETTINGS_USER_PROFILE = "/settings/user-profile";
export const SETTINGS_GROUPS_TYPE = "/settings/groups-type";
export const SETTINGS_USER_PROFILE_EDIT = "/settings/user-profile/edit";
export const PURCHASE_ORDERS = "/purchase-orders";
export const PURCHASE_ORDERS_CREATE = "/purchase-orders/create";
export const PURCHASE_ORDERS_EDIT = "/purchase-orders/edit";
export const PURCHASE_ORDER = "/purchase-orders";
export const PARTS = "/parts";
export const PART = "/parts";
export const PARTS_EDIT = "/parts/edit";
export const PARTS_CREATE = "/parts/create";
export const SETTINGS_PART_LOCATIONS = "/settings/part-locations";
export const SETTINGS_EQUIPMENT_LOCATIONS = "/settings/equipment-type";
export const PAPERS = "/papers";
export const SERVICE_HISTORY = "/service-history";
export const SERVICE_HISTORY_CREATE = `${SERVICE_HISTORY}/create`;
export const SERVICE_HISTORY_UPDATE = `${SERVICE_HISTORY}/update`;
export const SERVICE_TASKS = "/services/service-task";
export const SERVICE_TASKS_CREATE = `${SERVICE_TASKS}/create/`;
export const SERVICE_TASKS_UPDATE = `${SERVICE_TASKS}/edit/`;
export const WORK_ORDERS = "/work-orders";
export const WORK_ORDERS_ADD = `${WORK_ORDERS}/add`;
export const WORK_ORDERS_UPDATE = `${WORK_ORDERS}/edit`;
export const INSPECTIONS = `/inspections`;
export const INSPECTIONS_FORMS = `${INSPECTIONS}/forms`;
export const INSPECTIONS_FORMS_ADD = `${INSPECTIONS_FORMS}/add`;
export const INSPECTIONS_FORMS_EDIT = `${INSPECTIONS_FORMS}/edit`;
export const INSPECTIONS_FORMS_ITEMS = (id: string) =>
  `${INSPECTIONS_FORMS}/${id}/items`;
export const INSPECTIONS_FORMS_ITEMS_EDIT = (id: string) =>
  `${INSPECTIONS_FORMS_EDIT}/${id}/items`;
export const INSPECTIONS_HISTORY = `${INSPECTIONS}/history`;
export const SUBDOMAIN_CREATE = `/subdomain/create`;
export const INSPECTIONS_ITEM_FAILURES = `${INSPECTIONS}/item-failures`;

export const EQUIPMENTS = `/equipments`;
export const EQUIPMENTS_CREATE = `${EQUIPMENTS}/create`;
export const EQUIPMENTS_ID_DETAIL = (id: string) => `${EQUIPMENTS}/${id}/`;
export const EQUIPMENTS_UPDATE = `${EQUIPMENTS}/update`;
export const FUEL_HISTORY = `/fuel-history`;
export const FUEL_HISTORY_CREATE = `/fuel-history/create`;
export const FUEL_HISTORY_EDIT = `/fuel-history/edit`;

export const TRAINING_VIDEOS = "/training-videos";

export const REMINDERS = "/reminders";
export const REMINDERS_CONTACTS = `${REMINDERS}/contacts`;
export const REMINDERS_CONTACTS_ADD = `${REMINDERS_CONTACTS}/add`;
export const REMINDERS_CONTACTS_UPDATE_ID = (id: string) =>
  `${REMINDERS_CONTACTS}/${id}/update`;
export const REMINDERS_SERVICES = `${REMINDERS}/services`;
export const REMINDERS_SERVICES_ID = (id: string) =>
  `${REMINDERS_SERVICES}/${id}`;
export const REMINDERS_SERVICES_CREATE = `${REMINDERS}/services/add`;
export const REMINDERS_SERVICES_UPDATE = `${REMINDERS}/services/edit`;

export const REMINDERS_VEHICLES = `${REMINDERS}/vehicles`;
export const REMINDERS_VEHICLES_CREATE = `${REMINDERS_VEHICLES}/create`;
export const REMINDERS_VEHICLES_EDIT = `${REMINDERS_VEHICLES}/edit`;

export const REPORTS = "/reports";
export const REPORTS_CATALOGUES = REPORTS + "/catalogues/";
export const REPORTS_VEHICLE = REPORTS + "/vehicle";
export const REPORTS_VEHICLES = REPORTS + "/vehicles";
export const REPORTS_STATUS_CHANGES = REPORTS + "/status-changes";
export const REPORTS_STATUS_SUMMARY = REPORTS + "/status-summary";

export const REPORTS_INSPECTIONS = REPORTS + "/inspections";
export const REPORTS_COST_COMPARISON_BY_YEAR_IN_SERVICE =
  REPORTS + "/cost-comparison-in-service";
export const REPORTS_COST_COMPARISON_BY_YEAR =
  REPORTS + "/cost-comparison-by-year";
export const REPORTS_LIST_OF_UNITS = REPORTS + "/list-of-units/";
export const REPORTS_CATALOGUES_LIST = REPORTS_CATALOGUES + "reports/";
export const REPORTS_VEHICLE_ASSIGNMENTS = REPORTS_VEHICLE + "-assignments";
export const REPORTS_ISSUE = REPORTS + "/issue";
export const REPORTS_WORK_ORDERS = REPORTS + "/work-orders";
export const REPORTS_USERS = REPORTS + "/users";
export const REPORTS_PARTS = REPORTS + "/parts";
export const REPORTS_INSPECTIONS_SUBMISSION_SUMMARY =
  REPORTS_INSPECTIONS + "/submission-summary/";
export const REPORTS_USERS_RENEWAL_REMINDERS =
  REPORTS_USERS + "/user-renewal-reminders/";
export const REPORTS_INSPECTIONS_SUBMISSION_LIST =
  REPORTS_INSPECTIONS + "/submission-list/";
export const REPORTS_FUEL = REPORTS + "/fuel";
export const REPORTS_VEHICLES_RENEWAL_REMINDERS =
  REPORTS_VEHICLES + "/renewal-reminders/";
export const REPORTS_VEHICLE_ASSIGNMENTS_LOG =
  REPORTS_VEHICLE_ASSIGNMENTS + "/assignments-log/";
export const REPORTS_VEHICLE_DETAILS = REPORTS_VEHICLES + "/vehicles-detail/";

export const REPORTS_VEHICLE_DETAILS_ID = (id: string) =>
  `${REPORTS_VEHICLE_DETAILS}${id}/`;
export const REPORTS_VEHICLE_ASSIGNMENTS_SUMMARY =
  REPORTS_VEHICLE_ASSIGNMENTS + "/assignments-summary/";
export const REPORTS_INSPECTIONS_FAILED_INSPECTIONS =
  REPORTS_INSPECTIONS + "/failed-inspections/";
export const REPORTS_OPERATING_COST_SUMMARY =
  REPORTS + "/operating-costs-summary/";
export const REPORTS_PARTS_BY_LOCATION = REPORTS_PARTS + "/parts-by-location/";
export const REPORTS_USERS_LIST = REPORTS_USERS + "/user-list/";
export const REPORTS_WORK_ORDERS_BY_VEHICLE = `${REPORTS_WORK_ORDERS}/work-orders-by-vehicle/`;
export const REPORTS_ISSUES = REPORTS + "/issues";
export const REPORTS_ISSUES_LIST = REPORTS_ISSUES + "/issues-list/";
export const REPORTS_SERVICES = REPORTS + "/services";
export const REPORTS_ISSUES_FAULTS_SUMMARY =
  REPORTS_ISSUES + "/faults-summary/";
export const REPORTS_PARTS_ACTIVITY = REPORTS_PARTS + "/parts-activity/";
export const REPORTS_FUEL_ENTRIES_BY_VEHICLE = `${REPORTS_FUEL}/fuel-entries-by-vehicle/`;
export const REPORTS_FUEL_SUMMARY = `${REPORTS_FUEL}/fuel-summary/`;
export const REPORTS_FUEL_SUMMARY_BY_LOCATION = `${REPORTS_FUEL}/fuel-summary-by-location/`;
export const REPORTS_WORK_ORDERS_LABOR_LIST =
  REPORTS_WORK_ORDERS + "/labor-time-entries-list/";
export const REPORTS_WORK_ORDERS_LIST =
  REPORTS_WORK_ORDERS + "/work-orders-list/";
export const REPORTS_PART_BY_VEHICLE = REPORTS + "/part-by-vehicle/";
export const REPORTS_PURCHASE_ORDERS_LIST =
  REPORTS_PARTS + "/purchase-orders-list/";

export const REPORTS_SERVICES_WITHOUT_SERVICE =
  REPORTS_SERVICES + "/vehicles-without-service/";
export const REPORTS_SERVICES_REMINDERS =
  REPORTS_SERVICES + "/service-reminder/";
export const REPORTS_SERVICE_REMINDER_COMPLIANCE =
  REPORTS_SERVICES + "/reminder/compliance";
export const REPORTS_SERVICE_HISTORY_BY_VEHICLE = `${REPORTS_SERVICES}/service-history-by-vehicle/`;
export const REPORTS_SERVICE_HISTORY_SUMMARY =
  REPORTS_SERVICES + "/service-history-summary/";
export const DASHBOARD = "/dashboard";
export const GET_UNIT_DETAIL_PATH = (id: string) => `${UNIT}/${id}`;
export const IMPORT_FILES = "/import-files";
export const EXPORT_FILES = "/export-files";

export const EXPENSE = "/expense";
export const EXPENSE_CREATE = `${EXPENSE}/create`;
export const EXPENSE_EDIT = `${EXPENSE}/edit`;
