import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";

export const getExpenseList = createAsyncThunk(
  "expense/getExpenseList",
  async () => {
    try {
      const response = await api.expense.getExpenseList();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
