import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "Service/api/api";
import { getErrorMessage } from "Shared/Constants/serviceTasks";
import {
  TCodesSearchParams,
  TServiceTasksParams,
} from "Shared/Types/serviceTask";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IReducerWithCallbackWithError,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";
import { TServiceTask } from "Shared/Utils/yup/serviceTask";

export const getServiceTasksList = createAsyncThunk(
  "serviceTask/getServiceTasksList",
  async (params: TServiceTasksParams | null) => {
    try {
      const response = await api.serviceTask.getServiceTasks(params);
      return { data: response.data.results, count: response.data.count };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const archiveServiceTask = createAsyncThunk(
  "serviceTask/archiveServiceTask",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.serviceTask.archiveServiceTask(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const unarchiveServiceTask = createAsyncThunk(
  "serviceTask/unarchiveServiceTask",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.serviceTask.unarchiveServiceTask(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const getServiceTask = createAsyncThunk(
  "serviceTask/getServiceTask",
  async (id: string) => {
    try {
      const response = await api.serviceTask.getServiceTaskById(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const addServiceTask = createAsyncThunk(
  "serviceTask/addServiceTask",
  async (
    { data, onError, onSuccess }: IReducerWithCallback<TServiceTask>,
    { dispatch },
  ) => {
    try {
      const response = await api.serviceTask.addServiceTask(data);
      onSuccess?.();
      dispatch(getServiceTasksList(null));
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const editServiceTask = createAsyncThunk(
  "serviceTask/addServiceTask",
  async (
    { id, data, onError, onSuccess }: IUpdateReducerWithCallback<TServiceTask>,
    { dispatch },
  ) => {
    try {
      const response = await api.serviceTask.editServiceTask({ id, data });
      onSuccess?.();
      dispatch(getServiceTasksList(null));
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const getSystemCodes = createAsyncThunk(
  "serviceTask/getSystemCodes",
  async (params: TCodesSearchParams | null) => {
    try {
      const response = await api.serviceTask.getSystemCodes(params);
      return response.data.results;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getMaintenanceCategories = createAsyncThunk(
  "serviceTask/getMaintenanceCategories",
  async (params: TCodesSearchParams | null) => {
    try {
      const response = await api.serviceTask.getMaintenanceCategories(params);
      return response.data.results;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getAssemblyCodes = createAsyncThunk(
  "serviceTask/getAssemblyCodes",
  async (params: TCodesSearchParams | null) => {
    try {
      const response = await api.serviceTask.getAssemblyCodes(params);
      return response.data.results;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getReasonForRepairCodes = createAsyncThunk(
  "serviceTask/getReasonForRepairCodes",
  async (params: TCodesSearchParams | null) => {
    try {
      const response = await api.serviceTask.getReasonForRepairCodes(params);
      return response.data.results;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
// Utility function to handle error messages

export const createSystemCodes = createAsyncThunk(
  "serviceTask/createSystemCodes",
  async ({ data, onSuccess, onError }: IReducerWithCallbackWithError<any>) => {
    try {
      const response = await api.serviceTask.createSystemCodes({ data });
      onSuccess?.();
      return response.data;
    } catch (error: any) {
      const errorMessage = getErrorMessage(
        error?.response?.data?.system_code?.[0],

        "Failed to add system code. Please check the provided data or try again later.",
      );
      onError?.(errorMessage);
      throw error;
    }
  },
);

export const createMaintenanceCategories = createAsyncThunk(
  "serviceTask/createMaintenanceCategories",
  async ({ data, onSuccess, onError }: IReducerWithCallbackWithError<any>) => {
    try {
      const response = await api.serviceTask.createMaintenanceCategories({
        data,
      });
      onSuccess?.();
      return response.data;
    } catch (error: any) {
      const errorMessage = getErrorMessage(
        error?.response?.data?.category_code?.[0],
        "Failed to add category code. Please check the data or try again later.",
      );
      onError?.(errorMessage);
      throw error;
    }
  },
);

export const createAssemblyCodes = createAsyncThunk(
  "serviceTask/createAssemblyCodes",
  async ({ data, onSuccess, onError }: IReducerWithCallbackWithError<any>) => {
    try {
      const response = await api.serviceTask.createAssemblyCodes({ data });
      onSuccess?.();
      return response.data;
    } catch (error: any) {
      const errorMessage = getErrorMessage(
        error?.response?.data?.assembly_code?.[0],
        "Failed to add assembly code. Please check the data or try again later.",
      );
      onError?.(errorMessage);
      throw error;
    }
  },
);

export const createReasonForRepairCodes = createAsyncThunk(
  "serviceTask/createReasonForRepairCodes",
  async ({ data, onSuccess, onError }: IReducerWithCallbackWithError<any>) => {
    try {
      const response = await api.serviceTask.createReasonForRepairCodes({
        data,
      });
      onSuccess?.();
      return response.data;
    } catch (error: any) {
      const errorMessage = getErrorMessage(
        error?.response?.data?.code_key?.[0],
        "Failed to add repair code. Please check the provided data or try again later.",
      );
      onError?.(errorMessage);
      throw error;
    }
  },
);

export const deleteServiceTask = createAsyncThunk(
  "serviceTask/deleteServiceTask",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.serviceTask.deleteServiceTask(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);
