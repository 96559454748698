import { client } from "Service/axios";
import {
  ACCOUNTS_ROLES,
  ACCOUNTS_ROLES_CREATE,
  ACCOUNTS_ROLES_ID_PERMISSIONS_MODULES,
  ACCOUNTS_ROLES_ID_PERMISSIONS_UPDATE,
  ACCOUNTS_SYSTEM_MODULES,
  ACCOUNTS_SYSTEM_PERMISSION,
} from "Shared/Constants/apiRequest";
import {
  IAddRole,
  IPermissionModuleList,
  IRoleList,
  IRoleSearchList,
  IRoleWithModules,
  IUpdateRole,
} from "Shared/Types/role";
import { Pagination } from "Shared/Types/shared";

class Role {
  getRoleList(params: IRoleSearchList | null) {
    return client.get<IRoleList>(ACCOUNTS_ROLES, { params });
  }
  addRole(data: IAddRole) {
    return client.post(ACCOUNTS_ROLES_CREATE, data);
  }

  updateRole(data: IUpdateRole) {
    return client.patch(ACCOUNTS_ROLES_ID_PERMISSIONS_UPDATE(data.id), data);
  }

  getRoleModules(roleId: string) {
    return client.get<IRoleWithModules>(
      ACCOUNTS_ROLES_ID_PERMISSIONS_MODULES(roleId)
    );
  }

  getSystemModuleList(params: Pagination) {
    return client.get<IPermissionModuleList>(ACCOUNTS_SYSTEM_MODULES, {
      params,
    });
  }

  getPermissionList(params: Pagination) {
    return client.get<IRoleList>(ACCOUNTS_SYSTEM_PERMISSION, { params });
  }
}

export default Role;
