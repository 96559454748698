import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";

import {
  ICostComparisonInServiceParams,
  IReportInspectionsSubmissionSummaryParams,
  IReportLimit,
  IReportListOfUnitsParams,
  IReportParams,
  IReportRenewalRemindersParams,
  IReportsAssignmentsLogParams,
  IReportsAssignmentsSummaryParams,
  IReportsInspectionSubmissionListParams,
  IReportsInspectionsFailedParams,
  IReportStatusChangeParams,
  IReportStatusSummaryParams,
  IReportsVehicleDetailParams,
  IReportsOperatingCostSummaryParams,
  IReportsIssueListParams,
  IReportsServiceRemindersParams,
  IReportsWorkOrdersParams,
  IReportServiceReminderComplianceParams,
  IReportPartsByVehicleParams,
  IReportPurchaseOrdersListParams,
} from "Shared/Types/reports";

const getReportsCatalogues = createAsyncThunk(
  "reports/getCatalogues",
  async (params: IReportLimit | null) => {
    try {
      const response = await api.reports.getCatalogues(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getReportsListCatalogues = createAsyncThunk(
  "reports/getReportsList",
  async (params: IReportParams | null) => {
    try {
      const response = await api.reports.getReportsListCatalogues(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getReportsPartsActivity = createAsyncThunk(
  "reports/getReportsPartsActivity",
  async (params: any) => {
    try {
      const response = await api.reports.getReportsPartsActivity(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportsListOfUnits = createAsyncThunk(
  "reports/getReportsListOfUnits",
  async (params: IReportListOfUnitsParams | null) => {
    try {
      const response = await api.reports.getReportsListOfUnits(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportsServiceHistorySummary = createAsyncThunk(
  "reports/getReportsServiceHistorySummary",
  async (params: any) => {
    try {
      const response =
        await api.reports.getReportsServiceHistorySummary(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportsServicesWithoutService = createAsyncThunk(
  "reports/getReportsServicesWithoutService",
  async (params: any) => {
    try {
      const response =
        await api.reports.getReportsServicesWithoutService(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportsUserList = createAsyncThunk(
  "reports/getReportsUserList",
  async (params: any) => {
    try {
      const response = await api.reports.getReportsUserList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportsStatusChanges = createAsyncThunk(
  "reports/getReportsStatusChanges",
  async (params: IReportStatusChangeParams | null) => {
    try {
      const response = await api.reports.getReportsStatusChanges(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getReportsStatusSummary = createAsyncThunk(
  "reports/getReportsStatusSummary",
  async (params: IReportStatusSummaryParams | null) => {
    try {
      const response = await api.reports.getReportsStatusSummary(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportsRenewalReminders = createAsyncThunk(
  "reports/getReportsRenewalReminders",
  async (params: IReportRenewalRemindersParams | null) => {
    try {
      const response = await api.reports.getReportsRenewalReminders(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportsPartsByLocation = createAsyncThunk(
  "reports/getReportsPartsByLocation",
  async (params: any) => {
    try {
      const response = await api.reports.getReportsPartsByLocation(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getCostComparisonInService = createAsyncThunk(
  "reports/getCostComparisonInService",
  async (params: ICostComparisonInServiceParams | null) => {
    try {
      const response = await api.reports.getCostComparisonInService(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getInspectionsSubmissionSummary = createAsyncThunk(
  "reports/getInspectionsSubmissionSummary",
  async (params: IReportInspectionsSubmissionSummaryParams | null) => {
    try {
      const response =
        await api.reports.getReportsInspectionsSubmissionSummary(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getReportsAssignmentsLog = createAsyncThunk(
  "reports/getReportsAssignmentsLog",
  async (params: IReportsAssignmentsLogParams | null) => {
    try {
      const response = await api.reports.getReportsAssignmentsLog(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getReportsUsersRenewalReminders = createAsyncThunk(
  "reports/getReportsUsersRenewalReminders",
  async (params: any) => {
    try {
      const response =
        await api.reports.getReportsUsersRenewalReminders(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getReportsAssignmentsSummary = createAsyncThunk(
  "reports/getReportsAssignmentsSummary",
  async (params: IReportsAssignmentsSummaryParams | null) => {
    try {
      const response = await api.reports.getReportsAssignmentsSummary(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getReportsInspectionsSubmissionList = createAsyncThunk(
  "reports/getInspectionsSubmissionList",
  async (params: IReportsInspectionSubmissionListParams | null) => {
    try {
      const response =
        await api.reports.getReportsInspectionSubmissionList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getReportsInspectionsFailed = createAsyncThunk(
  "reports/getReportsInspectionsFailed",
  async (params: IReportsInspectionsFailedParams | null) => {
    try {
      const response = await api.reports.getReportsInspectionsFailed(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportsVehiclesDetail = createAsyncThunk(
  "reports/getReportsVehiclesDetail",
  async (params: IReportsVehicleDetailParams | null) => {
    try {
      const response = await api.reports.getReportsVehiclesDetail(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportOperatingCostSummary = createAsyncThunk(
  "reports/getReportOperatingCostSummary",
  async (params: IReportsOperatingCostSummaryParams | null) => {
    try {
      const response = await api.reports.getReportOperatingCostSummary(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportIssueList = createAsyncThunk(
  "reports/getReportIssueList",
  async (params: IReportsIssueListParams | null) => {
    try {
      const response = await api.reports.getReportIssueList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportServiceReminders = createAsyncThunk(
  "reports/getReportServiceReminders",
  async (params: IReportsServiceRemindersParams | null) => {
    try {
      const response = await api.reports.getReportServiceReminders(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportIssueFaultsSummary = createAsyncThunk(
  "reports/getIssueFaultsSummary",
  async (params: any) => {
    try {
      const response = await api.reports.getReportIssueFaultsSummary(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportWorkOrdersList = createAsyncThunk(
  "reports/getReportWorkOrdersList",
  async (params: IReportsWorkOrdersParams | null) => {
    try {
      const response = await api.reports.getReportWorkOrdersList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportWorkOrdersLaborList = createAsyncThunk(
  "reports/getReportWorkOrdersLaborList",
  async (params: any) => {
    try {
      const response = await api.reports.getReportWorkOrdersLaborList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportWorkOrdersByVehicle = createAsyncThunk(
  "reports/getReportWorkOrdersByVehicle",
  async (params: any) => {
    try {
      const response = await api.reports.getReportWorkOrderByVehicle(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportServicesHistoryByVehicle = createAsyncThunk(
  "reports/getReportServicesHistoryByVehicle",
  async (params: any) => {
    try {
      const response =
        await api.reports.getReportServiceHistoryByVehicle(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportServiceReminderCompliance = createAsyncThunk(
  "reports/getReportServiceReminderCompliance",
  async (params: IReportServiceReminderComplianceParams | null) => {
    try {
      const response =
        await api.reports.getReportServiceReminderCompliance(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getReportPartsByVehicle = createAsyncThunk(
  "reports/getReportPartsByVehicle",
  async (params: IReportPartsByVehicleParams | null) => {
    try {
      const response = await api.reports.getReportPartsByVehicle(params);
      return response.data.table;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getReportVehicleDetailId = createAsyncThunk(
  "reports/getReportVehicleDetailId",
  async ({ id, params }: { id: string; params?: any }) => {
    const response = await api.reports.getReportVehicleDetailId(id, params);
    return response.data;
  },
);

const getReportPurchaseOrdersList = createAsyncThunk(
  "reports/getReportPurchaseOrdersList",
  async (params: IReportPurchaseOrdersListParams | null) => {
    const response = await api.reports.getReportPurchaseOrdersList(params);
    return response.data;
  },
);
const getReportFuelEntriesByVehicle = createAsyncThunk(
  "reports/getReportFuelEntriesByVehicle",
  async (params: any) => {
    const response = await api.reports.getReportFuelEntriesByVehicle(params);
    return response.data;
  },
);
const getReportFuelSummary = createAsyncThunk(
  "reports/getReportFuelSummary",
  async (params: any) => {
    const response = await api.reports.getReportFuelSummary(params);
    return response.data;
  },
);
const getReportFuelSummaryByLocation = createAsyncThunk(
  "reports/getReportFuelSummaryByLocation",
  async (params: any) => {
    const response = await api.reports.getReportFuelSummaryByLocation(params);
    return response.data;
  },
);
export {
  getReportsListCatalogues,
  getReportsCatalogues,
  getReportsListOfUnits,
  getReportFuelEntriesByVehicle,
  getReportFuelSummary,
  getReportFuelSummaryByLocation,
  getReportsStatusChanges,
  getReportServiceReminders,
  getReportVehicleDetailId,
  getReportsStatusSummary,
  getReportsRenewalReminders,
  getCostComparisonInService,
  getReportsAssignmentsLog,
  getInspectionsSubmissionSummary,
  getReportsAssignmentsSummary,
  getReportsInspectionsSubmissionList,
  getReportIssueFaultsSummary,
  getReportsServicesWithoutService,
  getReportsUsersRenewalReminders,
  getReportsInspectionsFailed,
  getReportsVehiclesDetail,
  getReportsPartsByLocation,
  getReportOperatingCostSummary,
  getReportsPartsActivity,
  getReportIssueList,
  getReportsServiceHistorySummary,
  getReportWorkOrdersList,
  getReportsUserList,
  getReportServiceReminderCompliance,
  getReportWorkOrdersLaborList,
  getReportWorkOrdersByVehicle,
  getReportServicesHistoryByVehicle,
  getReportPartsByVehicle,
  getReportPurchaseOrdersList,
};
