import React from "react";
import {
  Checkbox as MUICheckbox,
  CheckboxProps as MUICheckboxProps,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import cn from "classnames";
import styles from "./Styles.module.scss";

type CheckboxProps = Omit<MUICheckboxProps, "name"> & {
  withController?: boolean;
  control?: Control<any>;
  label?: string;
  name: string;
  labelClassName?: string;
  labelPosition?: "left" | "right";
  downLabel?: string;
};

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  control,
  labelClassName,
  withController,
  name,
  labelPosition = "left",
  onChange,
  defaultChecked = false,
  downLabel,
  ...props
}) => {
  return (
    <>
      <div
        className={cn(styles.checkbox, props.className)}
        onClick={(e) => e.stopPropagation()}
      >
        {label && labelPosition === "left" && (
          <label
            className={cn(styles.checkbox__label, labelClassName)}
            htmlFor={name}
          >
            {label}
          </label>
        )}
        {withController ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultChecked}
            render={({ field }) => (
              <MUICheckbox
                style={{
                  color: field.value ? "#007DF2" : "#C9C9C9",
                  padding: "5px",
                }}
                id={name}
                {...field}
                defaultChecked={defaultChecked}
                checked={Boolean(field.value)}
                onChange={(event, checked) => {
                  field.onChange(event);
                  onChange?.(event, checked);
                }}
              />
            )}
          />
        ) : (
          <MUICheckbox
            id={name}
            sx={{
              color: "#C9C9C9",
              padding: "5px",
              "&.Mui-checked": {
                color: "#005DAC80, #005DAC33",
              },
            }}
            checked={props.checked}
            onChange={(event, checked) => {
              onChange?.(event, checked);
            }}
            {...props}
          />
        )}
        {label && labelPosition === "right" && (
          <label
            className={cn(styles.checkbox__label, labelClassName)}
            htmlFor={name}
          >
            {label}
          </label>
        )}
      </div>
      {downLabel && (
        <span className={styles.checkbox__downLabel}>{downLabel}</span>
      )}
    </>
  );
};
export default Checkbox;
