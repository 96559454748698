import { useDispatch } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";

import authSlice from "Redux/Auth/slice";

import breadcrumbSlice from "./App/slice";
import aspirationSlice from "./AspirationType/AspirationTypeSlice";
import assignmentSlice from "./Assignments/slice";
import BlockTypeSlice from "./BlockType/BlockTypeSlice";
import bodySubTypeSlice from "./BodySubType/BodySubTypeSlice";
import bodyTypeSlice from "./BodyType/BodyTypeSlice";
import BrakeTypeSlice from "./BrakeType/BrakeTypeSlice";
import CamTypeSlice from "./CamType/CamTypeSlice";
import CompanyPropsSlice from "./CompanyProps/slice";
import DocumentSlice from "./Documents/slice";
import DriveTypeSlice from "./DriveType/DriveTypeSlice";
import FaultsSlice from "./Faults/slice";
import FuelInductionTypeSlice from "./FuelInduction/FuelInductionSlice";
import fuelTypeSlice from "./FuelType/FuelTypeSlice";
import groupTypeSlice from "./GroupType/GroupTypeSlice";
import issueSlice from "./Issue/slice";
import inspectionsSlice from "./Inspections/slice";
import labelsTypeSlice from "./LabelsType/LabelsTypeSlice";
import makeTypeSlice from "./MakeType/MakeTypeSlice";
import meterHistorySlice from "./MeterHistory/slice";
import modelSlice from "./ModelType/ModelTypeSlice";
import partSlice from "./Parts/slice";
import purchaseOrdersSlice from "./PurchaseOrder/slice";
import VenderSlice from "./PurchaseVendor/PurchaseVendorSlice";
import RoleSlice from "./Role/slice";
import samsaraSlice from "./Samsara/slice";
import servicesSlice from "./ServicesHistory/slice";
import telematicDeviceSlice from "./TelematicDevice/slice";
import TransmissionTypeSlice from "./TransmissionType/TransmissionTypeSlice";
import typeSlice from "./Type/typeSlice";
import userSlice from "./User/slice";
import vehiclesSlice from "./Vehicle/slice";
import vendorSlice from "./Vendors/slice";
import serviceTaskSlice from "./ServiceTask/slice";
import workOrderSlice from "./WorkOrder/slice";
import equipmentSlice from "./Equipment/slice";
import fuelHistorySlice from "./FuelHistory/slice";
import traningSlice from "./Traning/slice";
import reminderVehicleSlice from "./ReminderVehicle/slice";
import reminderContactSlice from "./ReminderContact/slice";
import googleMapSlice from "./GoogleMap/slice";
import reminderServiceSlice from "./ReminderService/slice";
import reportsSlice from "./Reports/slice";
import inputGlobalSlice from "./InputGlobal/InputGlobalSlice";
import dashboardSlice from "./Dashboard/slice";
import importDataSlice from "./ImportData/slice";
import aiSlice from "./AI/slice";
import Invoices from "./Invoice/slice";
import paymentSlice from "./PaymentType/slice";
import Expense from "./Expense/slice";

const store = configureStore({
  reducer: {
    auth: authSlice.reducer,
    type: typeSlice.reducer,
    bodyType: bodyTypeSlice.reducer,
    bodySubType: bodySubTypeSlice.reducer,
    fuelType: fuelTypeSlice.reducer,
    makeType: makeTypeSlice.reducer,
    modelType: modelSlice.reducer,
    breadcrumb: breadcrumbSlice.reducer,
    labels: labelsTypeSlice.reducer,
    groupType: groupTypeSlice.reducer,
    aspiration: aspirationSlice.reducer,
    blockType: BlockTypeSlice.reducer,
    camType: CamTypeSlice.reducer,
    transmissionType: TransmissionTypeSlice.reducer,
    driveType: DriveTypeSlice.reducer,
    brakeType: BrakeTypeSlice.reducer,
    fuelInduction: FuelInductionTypeSlice.reducer,
    vehicle: vehiclesSlice.reducer,
    venders: VenderSlice.reducer,
    role: RoleSlice.reducer,
    documents: DocumentSlice.reducer,
    user: userSlice.reducer,
    companyProps: CompanyPropsSlice.reducer,
    issue: issueSlice.reducer,
    assignment: assignmentSlice.reducer,
    telematicDeivice: telematicDeviceSlice.reducer,
    samsara: samsaraSlice.reducer,
    faults: FaultsSlice.reducer,
    meterHistory: meterHistorySlice.reducer,
    part: partSlice.reducer,
    purchaseOrder: purchaseOrdersSlice.reducer,
    vendors: vendorSlice.reducer,
    serviceTask: serviceTaskSlice.reducer,
    services_history: servicesSlice.reducer,
    work_order: workOrderSlice.reducer,
    inspections: inspectionsSlice.reducer,
    equipment: equipmentSlice.reducer,
    fuelHistory: fuelHistorySlice.reducer,
    training: traningSlice.reducer,
    reminderContact: reminderContactSlice.reducer,
    reminderVehilce: reminderVehicleSlice.reducer,
    reminderService: reminderServiceSlice.reducer,
    googleMap: googleMapSlice.reducer,
    reports: reportsSlice.reducer,
    inputGlobal: inputGlobalSlice.reducer,
    dashboard: dashboardSlice.reducer,
    importData: importDataSlice.reducer,
    ai: aiSlice.reducer,
    invoice: Invoices.reducer,
    payment: paymentSlice.reducer,
    expense: Expense.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
