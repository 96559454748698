import React from "react";
import styles from "./AuthBtn.module.scss";
import { Button, ButtonProps } from "@mui/material";

export const AuthBtn: React.FC<ButtonProps & { bgColor?: string }> = ({
  bgColor,
  children,
  fullWidth,
  ...rest
}) => {
  return (
    <Button
      className={styles.authBtn}
      variant="contained"
      {...rest}
      style={{
        textTransform: "none",
        fontSize: "20px",
        background: bgColor,
        width: !fullWidth ? "fit-content" : "100%",
        padding: !fullWidth ? "0 40px" : "inherit",
      }}
    >
      {children}
    </Button>
  );
};
