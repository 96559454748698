import { client } from "Service/axios";
import {
  ADD_WORK_ORDER_COMMENT,
  DELETE_WORK_ORDER_COMMENT,
  ADD_WORK_ORDER_FILES,
  REMOVE_WORK_ORDER_FILE,
  START_WORK_ORDER_LABOR_ID,
  STOP_WORK_ORDER_LABOR_ID,
  WORK_ORDER_DELETE_ID,
  WORK_ORDER_DELETE_LABOR_ID,
  WORK_ORDER_ID,
  WORK_ORDER_RELATED_SERVICE_TASKS_ID,
  WORK_ORDER_SWITCH_TO_COMPLETE_STATUS_ID,
  WORK_ORDER_SWITCH_TO_OPEN_STATUS_ID,
  WORK_ORDER_SWITCH_TO_PENDING_STATUS_ID,
  WORK_ORDER,
  ADD_WORK_ORDER,
  EDIT_WORK_ORDER,
} from "Shared/Constants/apiRequest";
import {
  IWorkOrder,
  IWorkOrderListSearch,
  TRelatedServiceTask,
} from "Shared/Types/workOrder";
import { TLaborDetailFields } from "Shared/Utils/yup/workOrders";
import { PaginatedResponse, ReducerTypeForUpdate } from "Shared/Types/shared";
import { IWorkOrderComment, IWorkOrderCommentAdd } from "Shared/Types/comment";
import {
  UploadFilesWorkOrder,
  WorkOrderCreateRequest,
  WorkOrderRetrieve,
} from "Shared/Types/fleetmanagementapi";
import { DashboardWorkOrderData } from "Shared/Types/dashboard";

class WorkOrder {
  getWorkOrderById(id: string) {
    return client.get<IWorkOrder>(WORK_ORDER_ID(id));
  }
  getWorkOrderList(params: IWorkOrderListSearch | null) {
    return client.get<PaginatedResponse<WorkOrderRetrieve[]>>(WORK_ORDER, {
      params,
    });
  }
  switchWorkOrderStatusToOpen(id: string) {
    return client.post<IWorkOrder>(WORK_ORDER_SWITCH_TO_OPEN_STATUS_ID(id));
  }
  switchWorkOrderStatusToPending(id: string) {
    return client.post<IWorkOrder>(WORK_ORDER_SWITCH_TO_PENDING_STATUS_ID(id));
  }
  switchWorkOrderStatusToComplete(id: string) {
    return client.post<IWorkOrder>(WORK_ORDER_SWITCH_TO_COMPLETE_STATUS_ID(id));
  }
  deleteWorkOrder(id: string) {
    return client.delete<any>(WORK_ORDER_DELETE_ID(id));
  }
  startWorkOrderLabor(id: string, data: { service_task: string }) {
    return client.post<any>(START_WORK_ORDER_LABOR_ID(id), data);
  }
  stopWorkOrderLabor(wo_id: string, id: string, data: TLaborDetailFields) {
    return client.patch<any>(STOP_WORK_ORDER_LABOR_ID(wo_id, id), data);
  }
  deleteWorkOrderServiceTaskLabor(wo_id: string, id: string) {
    return client.delete<any>(WORK_ORDER_DELETE_LABOR_ID(wo_id, id));
  }
  getWorkOrderRelatedServiceTasks(id: string) {
    return client.get<TRelatedServiceTask>(
      WORK_ORDER_RELATED_SERVICE_TASKS_ID(id),
    );
  }
  addWorkOrderComment(data: IWorkOrderCommentAdd) {
    return client.post<IWorkOrderComment>(ADD_WORK_ORDER_COMMENT, data);
  }
  deleteWorkOrderComment(id: string) {
    return client.delete<any>(DELETE_WORK_ORDER_COMMENT(id));
  }

  addWorkOrderFiles(id: string, files: string[]) {
    return client.post<UploadFilesWorkOrder>(ADD_WORK_ORDER_FILES(id), {
      documents: files,
    });
  }
  removeWorkOrderFile(id: string, files: string[]) {
    return client.post<UploadFilesWorkOrder>(REMOVE_WORK_ORDER_FILE(id), {
      documents: files,
    });
  }
  addWorkOrder(data: WorkOrderCreateRequest) {
    return client.post<WorkOrderRetrieve>(ADD_WORK_ORDER, data);
  }
  editWorkOrder({
    id,
    data,
  }: ReducerTypeForUpdate<WorkOrderCreateRequest | DashboardWorkOrderData>) {
    return client.patch<WorkOrderRetrieve>(EDIT_WORK_ORDER(id), data);
  }
}

export default WorkOrder;
