import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const AddPartContainer = lazyLoader(
  () => import("Containers/Parts/AddEditPart/AddPartContainer")
);

export const AddPart = () => {
  return (
    <Layout hasSidebar={false}>
      <AddPartContainer />
    </Layout>
  );
};
