import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import styles from "./UniqueInput.module.scss";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { Control, Controller } from "react-hook-form";
import { TextFieldProps } from "@mui/material";
import Icon from "Components/UI/Icon/Icon";
import { EyeIcon } from "Assets";

type UniqueInputProps = Omit<TextFieldProps, "name"> & {
  label?: string;
  type?: string;
  style?: React.CSSProperties;
  placeholder?: string;
  className?: string;
  name: string;
  control?: Control<any>;
  withController?: boolean;
  showEyeIcon?: boolean;
};

export const UniqueInput: React.FC<UniqueInputProps> = ({
  label,
  value,
  control,
  withController,
  name,
  placeholder,
  type = "text",
  variant = "standard",
  classes,
  showEyeIcon = false,
  ...props
}) => {
  const [viewer, setViewer] = useState(!showEyeIcon);
  const handleVisibility = () => {
    setViewer(!viewer);
  };

  return (
    <div className={styles.input}>
      {withController ? (
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <>
              <TextField
                {...props}
                onChange={(value) => {
                  field.onChange(value);
                }}
                InputLabelProps={{
                  style: { color: "#fff" },
                }}
                variant={variant}
                value={field.value}
                label={label}
                type={viewer ? "text" : "password"}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: showEyeIcon ? (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleVisibility}
                      >
                        {viewer ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ) : null,
                }}
                classes={{
                  root: styles.input__input,
                }}
              />
              {fieldState.error && (
                <div className={styles.input__error}>
                  {fieldState.error.message}
                </div>
              )}
            </>
          )}
        />
      ) : (
        <TextField
          {...props}
          InputLabelProps={{
            style: { color: "#fff" },
          }}
          variant={variant}
          label={label}
          value={value || ""}
          type={viewer ? "text" : "password"}
          InputProps={{
            disableUnderline: true,
            endAdornment: showEyeIcon ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleVisibility}
                >
                  {viewer ? <Icon component={EyeIcon} /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
          classes={{
            root: styles.input__input,
          }}
        />
      )}
    </div>
  );
};
