import { client } from "Service/axios";
import {
  COMMON_LABELS,
  COMMON_LABELS_CREATE,
} from "Shared/Constants/apiRequest";
import { IAddLabelsType, ILabelsTypeSearchList } from "Shared/Types/labels";

class LabelsType {
  getLabelsTypeList(params: ILabelsTypeSearchList | null) {
    return client.get<any>(COMMON_LABELS, { params });
  }
  addLabelsType(data: IAddLabelsType) {
    return client.post<any>(COMMON_LABELS_CREATE, data);
  }
}

export default LabelsType;
