import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
import React from "react";

const ReportsInspectionsFailedList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsInspectionsFailed/ReportsInspectionsFailedList/ReportsInspectionsFailedList"
    )
);
export const ReportsInspectionsFailed = () => {
  return (
    <Layout>
      <ReportsInspectionsFailedList />
    </Layout>
  );
};
