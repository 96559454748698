import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";
import { TrainingVideo, VideoSection } from "Shared/Types/fleetmanagementapi";
import { SectionCategory } from "Shared/Types/fleetmanagementapi";
import {
  getTrainingVideoList,
  getVideoListSections,
  getVideoListSectionsCategories,
} from "./reducer";

type State = {
  loading: boolean;
  videoList: TrainingVideo[];
  videoListSections: VideoSection[] | null;
  videoListSectionsCategories: SectionCategory[] | null;
};

const initialState: State = {
  loading: false,
  videoList: [],
  videoListSections: null,
  videoListSectionsCategories: null,
};
const traningSlice = createSlice({
  name: "traning",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getTrainingVideoList.fulfilled, (state, action) => {
      state.loading = false;
      state.videoList = action.payload.results || [];
    });
    builder.addCase(getTrainingVideoList.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getVideoListSections.fulfilled, (state, action) => {
      state.loading = false;
      state.videoListSections = action.payload || null;
    });
    builder.addCase(getVideoListSections.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(
      getVideoListSectionsCategories.fulfilled,
      (state, action) => {
        state.loading = false;
        state.videoListSectionsCategories = action.payload || null;
      },
    );
    builder.addCase(getVideoListSectionsCategories.pending, (state) => {
      state.loading = true;
    });
  },
});

export const selectTrainingVidoeList = (state: RootState) =>
  state.training.videoList;
export const selectTrainingVideoListSections = (state: RootState) =>
  state.training.videoListSections;
export const selectTrainingVideoListSectionsCategories = (state: RootState) =>
  state.training.videoListSectionsCategories;
export const selectTrainingLoading = (state: RootState) =>
  state.training.loading;
export default traningSlice;
