import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddFuelType, IFuelTypeSearchList } from "Shared/Types/fuelType";

const getCamTypeList = createAsyncThunk(
  "camType/getCamTypeList",
  async (params: IFuelTypeSearchList | null) => {
    try {
      const response = await api.camType.getCamTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addCamType = createAsyncThunk(
  "camType/addCamType",
  async (data: IAddFuelType, { dispatch }) => {
    try {
      const response = await api.camType.addCamType(data);
      dispatch(getCamTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addCamType, getCamTypeList };
