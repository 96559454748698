import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddType, IType, ITypeListSearch } from "Shared/Types/type";

const getTypeList = createAsyncThunk(
  "type/getTypeList",
  async (params: ITypeListSearch | null) => {
    try {
      const response = await api.type.getTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const addType = createAsyncThunk("type/addType", async (data: IAddType) => {
  try {
    const response = await api.type.addType(data);

    return response.data as IType;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

export { addType, getTypeList };
