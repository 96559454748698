import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "Service/api/api";
import {
  PatchedUpdateServiceReminderRequest,
  ResolveServiceReminderRequest,
  ServiceReminderBulkCreateRequest,
  ServiceReminderRequest,
  ServiceReminderSnoozeRequest,
} from "Shared/Types/fleetmanagementapi";
import { ServiceReminderComment } from "Shared/Types/fleetmanagementapi";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IUpdateReducerWithCallback,
  MultiplyActionDispatchParams,
} from "Shared/Types/shared";
import { IReminderServiceSearch } from "Shared/Types/reminder";
import { IAddFilesToEntityTypes } from "Shared/Types/file";

export const getReminderServicesList = createAsyncThunk(
  "reminderService/getReminderServicesList",
  async (params: IReminderServiceSearch | null) => {
    try {
      const response =
        await api.reminderServices.getReminderServicesLIst(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const createReminderService = createAsyncThunk(
  "reminderService/createReminderService",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<ServiceReminderRequest>) => {
    try {
      await api.reminderServices.createReminderService(data);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  }
);
export const createReminderServiceWithBulkVehicles = createAsyncThunk(
  "reminderService/createReminderServiceWithBulkVehicles",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<ServiceReminderBulkCreateRequest>) => {
    try {
      await api.reminderServices.createReminderServiceWithBulkVehicles(data);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  }
);

export const editReminderService = createAsyncThunk(
  "reminderService/editReminderService",
  async ({
    id,
    data,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<ServiceReminderBulkCreateRequest>) => {
    try {
      await api.reminderServices.editReminderService(id, data);
      onSuccess?.();
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  }
);

export const getReminderServiceDetail = createAsyncThunk(
  "reminderService/getReminderServiceModalDetail",
  async (id: string) => {
    try {
      const response = await api.reminderServices.getReminderServiceDetail(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const deleteReminderServices = createAsyncThunk(
  "reminder/deleteReminderVehicle",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.reminderServices.deleteReminderServices(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
export const multiplyDeleteReminderService = createAsyncThunk(
  "reminder/multiplyDeleteReminderService",
  async ({ ids, onError, onSuccess }: MultiplyActionDispatchParams) => {
    try {
      const response =
        await api.reminderServices.multiplyDeletedReminderService({ ids });
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
export const deleteComment = createAsyncThunk(
  "reminder/deleteComment",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.reminderServices.deleteComment(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const createComment = createAsyncThunk(
  "reminder/createComment",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<ServiceReminderComment>) => {
    try {
      const response = await api.reminderServices.saveComment(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const resolveServiceReminderByServiceTask = createAsyncThunk(
  "reminder/resolveServiceReminderByServiceTask",
  async ({
    id,
    data,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<ResolveServiceReminderRequest>) => {
    try {
      const response =
        await api.reminderServices.resolveServiceReminderByServiceTask(
          id,
          data
        );
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const addWorkOrderToServiceReminder = createAsyncThunk(
  "reminder/addWorkOrderToServiceReminder",
  async ({
    id,
    data,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<PatchedUpdateServiceReminderRequest>) => {
    try {
      const response = await api.reminderServices.addWorkOrderToServiceReminder(
        id,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const snoozeServiceReminder = createAsyncThunk(
  "reminder/snoozeServiceReminder",
  async ({
    id,
    data,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<ServiceReminderSnoozeRequest>) => {
    try {
      const response = await api.reminderServices.snoozeServiceReminder(
        id,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const resumeServiceReminder = createAsyncThunk(
  "reminder/resumeServiceReminder",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.reminderServices.resumeServiceReminder(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const restartServiceReminder = createAsyncThunk(
  "reminder/restartServiceReminder",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.reminderServices.restartServiceReminder(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const addFilesToServiceReminder = createAsyncThunk(
  "reminder/addFilesToServiceReminder",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch }
  ) => {
    try {
      await api.reminderServices.addDocumentsToServiceReminder({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getReminderServiceDetail(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  }
);

export const removeFileFromServiceReminder = createAsyncThunk(
  "reminder/removeFileFromServiceReminder",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch }
  ) => {
    try {
      await api.reminderServices.removeDocumentsFromServiceReminder({
        id,
        files,
      });
      onSuccessUploadFiles?.();
      dispatch(getReminderServiceDetail(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  }
);
