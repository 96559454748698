import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const EditFuelHistory = lazyLoader(
  () => import("Containers/FuelHistory/EditFuelHistory/EditFuelHistory")
);

export const EditFuelHistoryPage = () => {
  return (
    <Layout>
      <EditFuelHistory />
    </Layout>
  );
};
