import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IAddTransmissionType,
  ITransmissionTypeSearchList,
} from "Shared/Types/TransmissionType";

const getTransmissionTypeList = createAsyncThunk(
  "transmissionType/getTransmissionTypeList",
  async (params: ITransmissionTypeSearchList | null) => {
    try {
      const response =
        await api.transmissionTypeList.getTransmissionTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addTransmissionTypeList = createAsyncThunk(
  "transmissionType/addTransmissionTypeList",
  async (data: IAddTransmissionType, { dispatch }) => {
    try {
      const response =
        await api.transmissionTypeList.addTransmissionTypeList(data);
      dispatch(getTransmissionTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addTransmissionTypeList, getTransmissionTypeList };
