import React from "react";
import Suspence from "Components/Layout/Suspence";
import { lazyLoader } from "Service/loader";
const RegisterStep1 = lazyLoader(
  () => import("Containers/Register/RegisterStep1/RegisterStep1")
);

export const Register = () => {
  return (
    <Suspence>
      <RegisterStep1 />
    </Suspence>
  );
};
