import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";
import {
  getCurrentInspectionForm,
  getInspectionFormItems,
  getInspection,
  getInspectionFormList,
  getInspectionForms,
  getInspectionItems,
  getInspections,
  setAcknowledgeStageToInspection,
  getInspectionFormListInfinite,
} from "./reducer";
import {
  InspectionFormDetailsRead,
  InspectionFormRead,
  InspectionFormWriteRequest,
  InspectionReadItem,
  InspectionSubmissionDetailItems,
} from "Shared/Types/fleetmanagementapi";
import { IInspectionItem, InspectionForm } from "Shared/Types/inspections";

interface IInspectionsRequestsState {
  inspectionFormList: {
    loading: boolean;
    inspectionList: InspectionFormRead[];
    numPage: number;
  };
  inspectionFormListInfinite: {
    hasMore: boolean;
    inspectionList: InspectionFormRead[];
    count: number;
  };
  inspections: {
    loading: boolean;
    items: InspectionFormRead[];
    basicItems: InspectionForm[];
    count: number;
  };
  failures: {
    loading: boolean;
    items: InspectionFormRead[];
    count: number;
  };
  inspection: InspectionSubmissionDetailItems | null;
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  currentInspectionFormItems: InspectionReadItem[];
  inspectionForms: InspectionFormWriteRequest[];
  inspectionFormItems: IInspectionItem[];
  inspectionItemsList: IInspectionItem[];
  count: number;
  currentInscpectionForm: InspectionFormDetailsRead | null;
}

const initialState: IInspectionsRequestsState = {
  inspectionFormList: {
    loading: false,
    inspectionList: [],
    numPage: 0,
  },
  inspectionFormListInfinite: {
    inspectionList: [],
    count: 0,
    hasMore: false,
  },
  currentInspectionFormItems: [],
  inspections: {
    loading: false,
    basicItems: [],
    items: [],
    count: 0,
  },
  failures: {
    loading: false,
    items: [],
    count: 0,
  },
  inspection: null,
  error: null,
  loading: false,
  success: null,
  inspectionForms: [],
  inspectionFormItems: [],
  inspectionItemsList: [],
  count: 0,
  currentInscpectionForm: null,
};

const inspectionsSlice = createSlice({
  name: "inspections",
  initialState,
  reducers: {
    clearInspection: (state) => {
      state.inspection = null;
    },
    clearLoadingStates(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    clearCurrentInspectionForm(state) {
      state.currentInscpectionForm = null;
    },
    clearInspectionsForms(state) {
      state.inspectionForms = [];
    },
    clearInspectionFormItems(state) {
      state.inspectionFormItems = [];
    },
    clearInspectionItemsList(state) {
      state.inspectionItemsList = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getInspections.pending, (state) => {
      state.inspections.loading = true;
      state.inspections.items = [];
    });
    builder.addCase(getInspections.fulfilled, (state, action) => {
      state.inspections.loading = false;
      state.inspections.items = action.payload.results as InspectionFormRead[];
      state.inspections.basicItems = action.payload.results as InspectionForm[];
      state.inspections.count = action.payload.count;
    });
    builder.addCase(getInspections.rejected, (state) => {
      state.inspections.loading = false;
    });

    builder.addCase(getInspection.fulfilled, (state, action) => {
      state.inspection = action.payload;
    });
    builder.addCase(getInspection.rejected, (state) => {
      state.inspection = null;
    });
    builder.addCase(getInspectionFormList.pending, (state) => {
      state.inspectionFormList.loading = true;
      state.inspectionFormList.inspectionList = [];
    });
    builder.addCase(getInspectionFormList.fulfilled, (state, action) => {
      state.inspectionFormList.loading = false;
      state.inspectionFormList.inspectionList = action.payload.results;
      if (!state.inspectionFormListInfinite.inspectionList.length) {
        state.inspectionFormListInfinite.inspectionList =
          action.payload.results;
        state.inspectionFormListInfinite.hasMore = !!action.payload.next;
      }
      state.inspectionFormListInfinite.count = action.payload.count;
      state.inspectionFormList.numPage = action.payload.count;
    });
    builder.addCase(getInspectionFormList.rejected, (state) => {
      state.inspectionFormList.loading = false;
    });
    builder.addCase(getInspectionFormListInfinite.pending, (state) => {
      state.inspectionFormListInfinite.hasMore = false;
    });
    builder.addCase(
      getInspectionFormListInfinite.fulfilled,
      (state, action) => {
        state.inspectionFormListInfinite.hasMore = !!action.payload.next;
        state.inspectionFormListInfinite.inspectionList =
          state.inspectionFormListInfinite.inspectionList.concat(
            action.payload.results,
          );
        state.inspectionFormListInfinite.count = action.payload.count;
      },
    );
    builder.addCase(getInspectionFormListInfinite.rejected, (state) => {
      state.inspectionFormListInfinite.hasMore = false;
    });

    builder.addCase(getInspectionForms.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.inspectionForms = action.payload;
    });
    builder.addCase(getInspectionForms.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getInspectionForms.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getCurrentInspectionForm.fulfilled, (state, action) => {
      state.currentInscpectionForm = action.payload;
    });

    builder.addCase(getInspectionItems.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      const { count, results } = action.payload.data;
      if (action.payload.type === "form") {
        state.inspectionFormItems = results;
      } else {
        state.count = count;
        state.inspectionItemsList = results;
      }
    });
    builder.addCase(getInspectionItems.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getInspectionItems.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(setAcknowledgeStageToInspection.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(
      setAcknowledgeStageToInspection.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      },
    );
    builder.addCase(setAcknowledgeStageToInspection.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getInspectionFormItems.fulfilled, (state, action) => {
      state.currentInspectionFormItems = action.payload.results;
    });
  },
});

export const {
  clearLoadingStates,
  clearInspectionsForms,
  clearInspectionFormItems,
  clearInspectionItemsList,
  clearCurrentInspectionForm,
  clearInspection,
} = inspectionsSlice.actions;

export const selectInspectionFormListLoading = (state: RootState) =>
  state.inspections.inspectionFormList.loading;
export const selectInspectionFormList = (state: RootState) =>
  state.inspections.inspectionFormList.inspectionList;
export const selectInspectionFormNumPage = (state: RootState) =>
  state.inspections.inspectionFormList.numPage;
export const selectInspectionFormListHasMore = (state: RootState) =>
  state.inspections.inspectionFormListInfinite.hasMore;
export const selectInspectionFormListInfinite = (state: RootState) =>
  state.inspections.inspectionFormListInfinite.inspectionList;
export const selectInspectionFormCount = (state: RootState) =>
  state.inspections.inspectionFormListInfinite.count;
export const selectInspectionForms = (state: RootState) =>
  state.inspections.inspectionForms;
export const selectInspectionItemsList = (state: RootState) =>
  state.inspections.inspectionItemsList;
export const selectInspectionFormItems = (state: RootState) =>
  state.inspections.inspectionFormItems;
export const selectInspectionsLoading = (state: RootState) =>
  state.inspections.loading;
export const selectInspectionsError = (state: RootState) =>
  state.inspections.error;
export const selectInspectionsSuccess = (state: RootState) =>
  state.inspections.success;
export const selectInspectionsBasicList = (state: RootState) =>
  state.inspections.inspections.basicItems;
export const selectInspectionsFailureaCount = (state: RootState) =>
  state.inspections.count;
export const selectCurrentInspectionForm = (state: RootState) =>
  state.inspections.currentInscpectionForm;
export const selectCurrentInspectionFormItems = (state: RootState) =>
  state.inspections.currentInspectionFormItems;
export const selectInspectionsList = (state: RootState) =>
  state.inspections.inspections.items;
export const selectInsepctionsCount = (state: RootState) =>
  state.inspections.inspections.count;
export const selectInspection = (state: RootState) =>
  state.inspections.inspection;

export default inspectionsSlice;
