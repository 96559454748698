import React from "react";

import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

const ExpenseDashboard = lazyLoader(
  () => import("Containers/Expense/ExpenseDashboard/ExpenseDashboard"),
);

export const ExpenseListPage = () => {
  return (
    <Layout>
      <ExpenseDashboard />
    </Layout>
  );
};
