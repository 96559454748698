import { client } from "Service/axios";
import {
  VEHICLE_WHEELS_TIRES_BRAKE_SYSTEMS,
  VEHICLE_WHEELS_TIRES_BRAKE_SYSTEMS_CREATE,
} from "Shared/Constants/apiRequest";
import { IAddBrakeType, IBrakeypeSearchList } from "Shared/Types/brakeType";

class BrakeType {
  getBrakeTypeList(params: IBrakeypeSearchList | null) {
    return client.get<any>(VEHICLE_WHEELS_TIRES_BRAKE_SYSTEMS, { params });
  }
  addBrakeType(data: IAddBrakeType) {
    return client.post<any>(VEHICLE_WHEELS_TIRES_BRAKE_SYSTEMS_CREATE, data);
  }
}

export default BrakeType;
