import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { addModelType, getModelList } from "./ModelTypeReducer";
import { IModelType } from "Shared/Types/models";

interface modelState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  modelTypeList: IModelType[];
}

const initialState: modelState = {
  loading: false,
  error: null,
  modelTypeList: [],
};

const modelSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getModelList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.modelTypeList = action.payload.results;
    });
    builder.addCase(addModelType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addModelType.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.modelTypeList = [...state.modelTypeList, action.payload];
    });
    builder.addCase(addModelType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = modelSlice.actions;

export const selectModelTypeList = (state: RootState) =>
  state.modelType.modelTypeList;
export const selectModelAuthLoading = (state: RootState) =>
  state.modelType.loading;
export const selectModelAuthError = (state: RootState) => state.modelType.error;
export const selectModelAuthSuccess = (state: RootState) =>
  state.modelType.success;

export default modelSlice;
