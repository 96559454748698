import { createSlice } from "@reduxjs/toolkit";
import { ILabelsType } from "Shared/Types/labels";
import { RootState } from "../store";
import { addLabelsType, getLabelsTypeList } from "./LabelsTypeReducer";

interface labelsTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  labelsTypeList: ILabelsType[];
}

const initialState: labelsTypeState = {
  loading: false,
  error: null,
  labelsTypeList: [],
};

const labelsTypeSlice = createSlice({
  name: "labelsType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLabelsTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.labelsTypeList = action.payload.results;
    });
    builder.addCase(addLabelsType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addLabelsType.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.labelsTypeList = [action.payload, ...state.labelsTypeList];
    });
    builder.addCase(addLabelsType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = labelsTypeSlice.actions;

export const selectLabelsTypeList = (state: RootState) =>
  state.labels.labelsTypeList;
export const selectLabelsAuthLoading = (state: RootState) =>
  state.labels.loading;
export const selectLabelsAuthError = (state: RootState) => state.labels.error;
export const selectLabelsAuthSuccess = (state: RootState) =>
  state.labels.success;

export default labelsTypeSlice;
