import React from "react";
import styles from "./Line.module.scss";

type TLineProps = {
  width?: string;
  bg?: string;
  margin?: string;
  height?: number;
  className?: string;
};

const Line = (props: TLineProps) => {
  return (
    <hr
      style={{
        width: props.width,
        background: props.bg,
        margin: props.margin,
        height: props.height,
      }}
      className={(styles.line, props.className)}
    />
  );
};

export default Line;
