import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const WorkOrderList = lazyLoader(
  () => import("Containers/WorkOrders/WorkOrderList/WorkOrderList")
);

export const WorkOrderListPage = () => {
  return (
    <Layout>
      <WorkOrderList />
    </Layout>
  );
};
