import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  addUser,
  getContactList,
  getUser,
  getUserFilesList,
  getUserList,
} from "./reducer";
import { IUser, IUserDetail } from "Shared/Types/user";
import { IFile } from "Shared/Types/file";

interface InitialState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  userList: IUser[];
  contactList: IUser[];
  currentUser: IUserDetail | null;
  currentUserFilesList: IFile[];
  numPage: number;
  count: number;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  userList: [],
  contactList: [],
  currentUser: null,
  numPage: 1,
  count: 0,
  currentUserFilesList: [],
};

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
      state.userList = [];
      state.currentUser = null;
    },
    clearUser(state) {
      state.currentUser = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.userList = action.payload.results;
      state.numPage = action.payload.count;
      state.count = action.payload.count;
    });
    builder.addCase(getUserList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getUserList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getContactList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.contactList = action.payload.results;
      state.numPage = action.payload.count;
      state.count = action.payload.count;
    });
    builder.addCase(getContactList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getContactList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentUser = action.payload;
    });
    builder.addCase(getUser.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addUser.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addUser.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getUserFilesList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentUserFilesList = action.payload;
    });
    builder.addCase(getUserFilesList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getUserFilesList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear, clearUser } = userSlice.actions;

export const selectUserList = (state: RootState) => state.user.userList;
export const selectContactList = (state: RootState) => state.user.contactList;
export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectUserLoading = (state: RootState) => state.user.loading;
export const selectUserError = (state: RootState) => state.user.error;
export const selectUserSuccess = (state: RootState) => state.user.success;
export const selectCurrentUserFilesList = (state: RootState) =>
  state.user.currentUserFilesList;
export const selectUserNumPage = (state: RootState) => state.user.numPage;
export const selectUserCount = (state: RootState) => state.user.count;

export default userSlice;
