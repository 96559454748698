import { client } from "Service/axios";
import {
  VEHICLE_MODELS,
  VEHICLE_MODELS_CREATE,
} from "Shared/Constants/apiRequest";
import { IAddModelType, IModelTypeSearchList } from "Shared/Types/models";

class ModelType {
  getModelTypeList(params: IModelTypeSearchList | null) {
    return client.get<any>(VEHICLE_MODELS, { params });
  }
  addModelType(data: IAddModelType) {
    return client.post<any>(VEHICLE_MODELS_CREATE, data);
  }
}

export default ModelType;
