import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
import React from "react";
const ReminderServiceList = lazyLoader(
  () => import("Containers/ReminderService/ReminderServiceList")
);

export const ReminderServiceListPage = () => {
  return (
    <Layout>
      <ReminderServiceList />
    </Layout>
  );
};
