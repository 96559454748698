import { client } from "Service/axios";
import {
  DASHBOARD_ANALYTICS_COMPLETE,
  VEHICLE_LATEST_ODOMETER,
  VEHICLES,
  VEHICLES_COMMENTS_CREATE,
  VEHICLES_CREATE,
  VEHICLES_ID,
  VEHICLES_ID_ARCHIVE,
  VEHICLES_ID_ASSIGNED_EQUIPMENTS,
  VEHICLES_ID_BULK_ADD_DOCUMENTS,
  VEHICLES_ID_BULK_REMOVE_DOCUMENTS,
  VEHICLES_ID_COMMENTS_DELETE,
  VEHICLES_ID_DELETE,
  VEHICLES_ID_DOCUMENTS,
  VEHICLES_ID_FAULTS,
  VEHICLES_ID_ISSUES,
  VEHICLES_ID_LINK,
  VEHICLES_ID_LINKED,
  VEHICLES_ID_METER_ENTRIES,
  VEHICLES_ID_RESTORE_ARCHIVE,
  VEHICLES_ID_SERVICES,
  VEHICLES_ID_UNLINK,
  VEHICLES_ID_UPDATE,
} from "Shared/Constants/apiRequest";
import { IComment, IVehicleCommentAdd } from "Shared/Types/comment";
import { IAddFiles } from "Shared/Types/file";
import {
  MeterEntry,
  PaginatedVehicleMeterEntryList,
} from "Shared/Types/fleetmanagementapi";
import { IMeterHistorySearchParams } from "Shared/Types/meter-history";
import {
  IAddLinkedVehicleType,
  IAddVehicle,
  ICurrentVehicle,
  IVehicleListSearch,
  VehicleAnalyticsComplete,
} from "Shared/Types/vehicle";

class Vehicle {
  getVehicleList(params: IVehicleListSearch | null) {
    return client.get<any>(VEHICLES, { params });
  }
  getVehicle(id: string) {
    return client.get<ICurrentVehicle>(VEHICLES_ID(id));
  }
  getVehicleAnalyticsComplete() {
    return client.get<VehicleAnalyticsComplete>(DASHBOARD_ANALYTICS_COMPLETE);
  }
  addVehicle(data: IAddVehicle) {
    return client.post<any>(VEHICLES_CREATE, data);
  }
  deleteVehicle(id: string) {
    return client.delete<any>(VEHICLES_ID_DELETE(id));
  }
  archiveVehicle(id: string) {
    return client.post<any>(VEHICLES_ID_ARCHIVE(id));
  }
  unarchiveVehicle(id: string) {
    return client.post<any>(VEHICLES_ID_RESTORE_ARCHIVE(id));
  }

  editVehicle(id: string, data: IAddVehicle) {
    return client.patch<any>(VEHICLES_ID_UPDATE(id), data);
  }

  getLinkedVehicleList(id: string) {
    return client.get<any>(VEHICLES_ID_LINKED(id));
  }
  linkVehicle(data: IAddLinkedVehicleType) {
    return client.patch<any>(VEHICLES_ID_LINK(data.vehicle_id), {
      linked_vehicle_id: data.linked_vehicle_id,
    });
  }
  unlinkVehicle(data: IAddLinkedVehicleType) {
    return client.patch<any>(VEHICLES_ID_UNLINK(data.vehicle_id), {
      linked_vehicle_id: data.linked_vehicle_id,
    });
  }

  getVehicleIssues(id: string) {
    return client.get<any>(VEHICLES_ID_ISSUES(id));
  }
  getVehicleServices(id: string) {
    return client.get<any>(VEHICLES_ID_SERVICES(id));
  }
  getVehicleEntries(id: string, params?: IMeterHistorySearchParams) {
    return client.get<PaginatedVehicleMeterEntryList>(
      VEHICLES_ID_METER_ENTRIES(id),
      { params }
    );
  }

  getVehicleFaults(id: string) {
    return client.get<any>(VEHICLES_ID_FAULTS(id));
  }

  getAssignedEquipments(id: string) {
    return client.get<any>(VEHICLES_ID_ASSIGNED_EQUIPMENTS(id));
  }
  //   for documents

  getVehicleDocumentsList(id: string) {
    return client.get<any>(VEHICLES_ID_DOCUMENTS(id));
  }
  addDocumentsToVehicle({ id, files }: IAddFiles) {
    return client.post<any>(VEHICLES_ID_BULK_ADD_DOCUMENTS(id), {
      files,
    });
  }
  removeDocumentsFromVehicle({ id, files }: IAddFiles) {
    return client.post<any>(VEHICLES_ID_BULK_REMOVE_DOCUMENTS(id), {
      files,
    });
  }
  // Comments
  saveComment(data: IVehicleCommentAdd) {
    return client.post<IComment>(VEHICLES_COMMENTS_CREATE, data);
  }
  deleteComment(id: string) {
    return client.delete(VEHICLES_ID_COMMENTS_DELETE(id));
  }

  getVehicleLatestOdometer(id: string) {
    return client.get<MeterEntry>(VEHICLE_LATEST_ODOMETER(id));
  }
}

export default Vehicle;
