import { createSlice } from "@reduxjs/toolkit";
import { IDriveType } from "Shared/Types/drive";
import { RootState } from "../store";
import { addBrakeType, getBrakeTypeList } from "./BrakeTypeReducer";

interface bodyTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  brakeTypeList: IDriveType[];
}

const initialState: bodyTypeState = {
  loading: false,
  error: null,
  brakeTypeList: [],
};

const BrakeTypeSlice = createSlice({
  name: "brakeType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBrakeTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.brakeTypeList = action.payload.results;
    });
    builder.addCase(addBrakeType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addBrakeType.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addBrakeType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = BrakeTypeSlice.actions;

export const selectBrakeTypeList = (state: RootState) =>
  state.brakeType.brakeTypeList;
export const selectBrakeTypeAuthLoading = (state: RootState) =>
  state.brakeType.loading;
export const selectBrakeTypeAuthError = (state: RootState) =>
  state.brakeType.error;
export const selectBrakeTypeAuthSuccess = (state: RootState) =>
  state.brakeType.success;

export default BrakeTypeSlice;
