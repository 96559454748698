import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const EditPartContainer = lazyLoader(
  () => import("Containers/Parts/AddEditPart/EditPartContainer")
);

export const EditPart = () => {
  return (
    <Layout>
      <EditPartContainer />
    </Layout>
  );
};
