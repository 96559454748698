import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const ServiceTasksDashboard = lazyLoader(
  () =>
    import(
      "Containers/ServiceTasks/ServiceTasksDashboard/ServiceTasksDashboard"
    )
);

export const ServiceTaskListPage = () => {
  return (
    <Layout>
      <ServiceTasksDashboard />
    </Layout>
  );
};
