import { createSlice } from "@reduxjs/toolkit";

import { IGroupType } from "Shared/Types/group";

import { RootState } from "../store";
import { addGroupType, getGroupTypeList } from "./GroupTypeReducer";

interface IGroupTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  groupTypeList: IGroupType[];
  numPage: number;
}

const initialState: IGroupTypeState = {
  loading: false,
  error: null,
  groupTypeList: [],
  numPage: 0,
};

const groupTypeSlice = createSlice({
  name: "groupType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getGroupTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.groupTypeList = action.payload.results;
      state.numPage = action.payload.count;
    });
    builder.addCase(addGroupType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addGroupType.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addGroupType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = groupTypeSlice.actions;

export const selectGroupTypeList = (state: RootState) =>
  state.groupType.groupTypeList;
export const selectGroupTypeNumPage = (state: RootState) =>
  state.groupType.numPage;
export const selectGroupAuthLoading = (state: RootState) =>
  state.groupType.loading;
export const selectGroupAuthError = (state: RootState) => state.groupType.error;
export const selectGroupAuthSuccess = (state: RootState) =>
  state.groupType.success;

export default groupTypeSlice;
