import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getCostComparisonInService,
  getInspectionsSubmissionSummary,
  getReportsAssignmentsLog,
  getReportsAssignmentsSummary,
  getReportsCatalogues,
  getReportsInspectionsSubmissionList,
  getReportsInspectionsFailed,
  getReportsListCatalogues,
  getReportsListOfUnits,
  getReportsRenewalReminders,
  getReportsStatusChanges,
  getReportsStatusSummary,
  getReportsVehiclesDetail,
  getReportOperatingCostSummary,
  getReportIssueList,
  getReportServiceReminders,
  getReportWorkOrdersList,
  getReportServiceReminderCompliance,
  getReportIssueFaultsSummary,
  getReportWorkOrdersByVehicle,
  getReportsUsersRenewalReminders,
  getReportWorkOrdersLaborList,
  getReportsPartsByLocation,
  getReportsUserList,
  getReportPartsByVehicle,
  getReportVehicleDetailId,
  getReportsServicesWithoutService,
  getReportsServiceHistorySummary,
  getReportPurchaseOrdersList,
  getReportsPartsActivity,
  getReportServicesHistoryByVehicle,
  getReportFuelEntriesByVehicle,
  getReportFuelSummary,
  getReportFuelSummaryByLocation,
} from "./reducer";
import {
  IReportCatalogues,
  IReportFuelEntriesByVehicle,
  IReportFuelSummary,
  IReportFuelSummaryByLocation,
  IReportInspectionsSubmissionList,
  IReportInspectionsSubmissionSummary,
  IReportList,
  IReportListOfUnits,
  IReportPartsActivity,
  IReportPartsByLocation,
  IReportPurchaseOrdersList,
  IReportsAssignmentsLog,
  IReportServiceHistorySummary,
  IReportServicesWithoutService,
  IReportsInspectionsFailed,
  IReportsIssueFaultsSummary,
  IReportsIssuesList,
  IReportsOperatingCostSummary,
  IReportsReminderServiceComplianceList,
  IReportsRenewalReminders,
  IReportsServiceHistoryByVehicle,
  IReportsServiceReminders,
  IReportsStatusSummary,
  IReportStatusChanges,
  IReportsUserList,
  IReportsVehiclesDetail,
  IReportsWorkOrdersByVehicle,
  IReportUsersRenewalReminders,
  IReportVehiclesDetailId,
  IReportWorkOrderLaborList,
  IReportWorkOrdersList,
  TReportCostComparisonInService,
  TReportsAssignmentsSummary,
} from "Shared/Types/reports";
import { PaginatedPartsByVehicleReportList } from "Shared/Types/fleetmanagementapi";

interface InitialState {
  success?: boolean | null;
  error?: string | null;
  loadingCatalogues: boolean;
  loadingCataloguesList: boolean;
  loadingReportStatusChanges: boolean;
  loadingReportListOfUnits: boolean;
  loadingReportInspectionsSubmissionSummary: boolean;
  loadingReportInspectionsSubmissionList: boolean;
  loadingReportStatusSummary: boolean;
  loadingReportRenewalReminders: boolean;
  loadingCostComparisonInService: boolean;
  loadingServicesWithoutService: boolean;
  loadingReportsAssignmentsSummary: boolean;
  loadingServiceHistoryByVehicle: boolean;
  loadingWorkOrdersList: boolean;
  loadingUsersRenewalReminders: boolean;
  loadingReportsServiceReminders: boolean;
  loadingWorkOrdersByVehicle: boolean;
  loadingReportVehiclesDetail: boolean;
  loadingFuelSummary: boolean;
  loadingFuelSummaryByLocation: boolean;
  loadingReportsInspectionsFailed: boolean;
  loadingFuelEntriesByVehicle: boolean;
  loadingIssueFaultsSummary: boolean;
  loadingUserList: boolean;
  loadingIssueList: boolean;
  loadingWorkOrderLaborList: boolean;
  loadingAssignmentsLog: boolean;
  loadingServiceHistorySummary: boolean;
  loadingReportsVehicleDetailId: boolean;
  loadingReportOperatingCostSummary: boolean;
  loadingPartsActivity: boolean;
  loadingPartByLocation: boolean;
  loadingReportServiceReminderCompliance: boolean;
  loadingReportsPurchaseOrdersList: boolean;
  reportCatalogues: IReportCatalogues;
  reportListCatalogues: IReportList;
  reportListOfUnits: IReportListOfUnits;
  reportStatusChanges: IReportStatusChanges;
  reportStatusSummary: IReportsStatusSummary;
  reportRenewalReminders: IReportsRenewalReminders;
  reportInspectionsSubmissionSummary: IReportInspectionsSubmissionSummary;
  reportInspectionsSubmissionList: IReportInspectionsSubmissionList;
  reportCostComparisonInService: TReportCostComparisonInService;
  reportAssignmentsLog: IReportsAssignmentsLog;
  reportAssignmentsSummary: TReportsAssignmentsSummary;
  reportIssueFaultsSummary: IReportsIssueFaultsSummary;
  reportInspectionsFailed: IReportsInspectionsFailed;
  reportServiceReminders: IReportsServiceReminders;
  reportWorkOrdersByVehicle: IReportsWorkOrdersByVehicle;
  reportServiceHistoryByVehicle: IReportsServiceHistoryByVehicle;
  reportVehiclesDetail: IReportsVehiclesDetail;
  reportUsersRenewalReminders: IReportUsersRenewalReminders;
  reportOperatingCostSummary: IReportsOperatingCostSummary;
  reportFuelEntriesByVehicle: IReportFuelEntriesByVehicle;
  reportFuelSummary: IReportFuelSummary;
  reportFuelSummaryByLocation: IReportFuelSummaryByLocation;
  reportIssueList: IReportsIssuesList;
  reportPartsByLocation: IReportPartsByLocation;
  reportWorkOrdersList: IReportWorkOrdersList;
  reportServiceReminderCompliance: IReportsReminderServiceComplianceList;
  reportWorkOrderLaborList: IReportWorkOrderLaborList;
  reportServicesWithoutService: IReportServicesWithoutService;
  reportUserList: IReportsUserList;
  reportPartByVehicle: PaginatedPartsByVehicleReportList;
  reportVehicleDetailId: IReportVehiclesDetailId;
  reportServiceHistorySummary: IReportServiceHistorySummary;
  reportPurchaseOrdersList: IReportPurchaseOrdersList;
  reportPartsActivity: IReportPartsActivity;
  countCataloguesList: number;
  countCatalogues: number;
  countUserList: number;
  countStatusChanges: number;
  countListOfUnits: number;
  countServiceHistorySummary: number;
  countStatusSummary: number;
  countServicesWithoutService: number;
  countFuelSummary: number;
  countFuelSummaryByLocation: number;
  countRenewalReminders: number;
  countWorkOrderLaborList: number;
  countInspectionsSubmissionSummary: number;
  countUsersRenewalReminders: number;
  countInspectionsSubmissionList: number;
  countPartsActivity: number;
  countCostComparisonInService: number;
  countFuelEntriesByVehicle: number;
  countServiceReminders: number;
  countServiceHistoryByVehicle: number;
  countAssignmentsLog: number;
  countWorkOrdersList: number;
  countIssueFaultsSummary: number;
  countPartsByLocation: number;
  countVehicleDetailId: number;
  countInspectionsFailed: number;
  countReportAssignmentsSummary: number;
  countWorkOrdersByVehicle: number;
  countIssueList: number;
  countReportVehiclesDetail: number;
  countReportOperatingCostSummary: number;
  countReportServiceReminderCompliance: number;
  countReportsPurchaseOrdersList: number;
}

const initialState: InitialState = {
  error: null,
  loadingReportListOfUnits: false,
  loadingCatalogues: false,
  loadingIssueFaultsSummary: false,
  loadingCataloguesList: false,
  loadingReportStatusChanges: false,
  loadingReportStatusSummary: false,
  loadingReportRenewalReminders: false,
  loadingPartsActivity: false,
  loadingFuelSummary: false,
  loadingFuelSummaryByLocation: false,
  loadingServicesWithoutService: false,
  loadingWorkOrderLaborList: false,
  loadingReportInspectionsSubmissionSummary: false,
  loadingServiceHistorySummary: false,
  loadingReportInspectionsSubmissionList: false,
  loadingCostComparisonInService: false,
  loadingAssignmentsLog: false,
  loadingReportsAssignmentsSummary: false,
  loadingReportsInspectionsFailed: false,
  loadingUserList: false,
  loadingReportsServiceReminders: false,
  loadingReportsVehicleDetailId: false,
  loadingIssueList: false,
  loadingWorkOrdersByVehicle: false,
  loadingFuelEntriesByVehicle: false,
  loadingUsersRenewalReminders: false,
  loadingReportVehiclesDetail: false,
  loadingWorkOrdersList: false,
  loadingReportOperatingCostSummary: false,
  loadingPartByLocation: false,
  loadingReportServiceReminderCompliance: false,
  loadingReportsPurchaseOrdersList: false,
  reportStatusChanges: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportServiceHistorySummary: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },

  reportUserList: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportPartsActivity: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportServicesWithoutService: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportFuelEntriesByVehicle: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportFuelSummary: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportFuelSummaryByLocation: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },

  reportPartsByLocation: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportStatusSummary: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportListCatalogues: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  reportListOfUnits: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportUsersRenewalReminders: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportCatalogues: {
    data: [],
    overall_reports_count: 0,
  },
  reportRenewalReminders: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportCostComparisonInService: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportAssignmentsLog: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportAssignmentsSummary: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportInspectionsFailed: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportInspectionsSubmissionSummary: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportWorkOrderLaborList: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportInspectionsSubmissionList: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportVehiclesDetail: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportIssueList: {
    charts: [
      {
        dataset: [],
        is_summarized: false,
        metadata: {
          axis_labels: {
            x_axis_label: "",
            y_axis_label: "",
          },
          title: "",
        },
        type: "bar_chart",
      },
    ],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportServiceReminders: {
    charts: [
      {
        dataset: [],
        is_summarized: false,
        metadata: {
          axis_labels: {
            x_axis_label: "",
            y_axis_label: "",
          },
          title: "",
        },
        type: "bar_chart",
      },
    ],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportWorkOrdersByVehicle: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportServiceHistoryByVehicle: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportOperatingCostSummary: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportWorkOrdersList: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportIssueFaultsSummary: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportServiceReminderCompliance: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  reportPartByVehicle: {
    count: 0,
    next: "",
    previous: "",
    results: [],
  },
  reportPurchaseOrdersList: {
    charts: [],
    table: {
      count: 0,
      next: "",
      previous: "",
      results: [],
    },
  },
  countIssueFaultsSummary: 0,
  reportVehicleDetailId: {
    charts: [],
    table: {
      id: "",
      name: "",
      type: null,
      status: "",
      year: null,
      fuel_type: null,
      current_meter: 0,
      current_meter_date: null,
      current_secondary_meter: 0,
      current_secondary_meter_date: null,
      ownership: null,
      body_type: null,
      body_subtype: null,
      make: null,
      model: null,
      vin_sn: null,
      color: null,
      license_plate: null,
      group: null,
      registration_state_province: null,
      operator: null,
      trim: null,

      purchase: {
        vendor: null,
        purchase_date: null,
        purchase_price: null,
        odometer: null,
        notes: null,
        warranty_expiration_date: null,
        max_meter_value: null,
      },
      lifecycle: {
        in_service_date: null,
        in_service_odometer: null,
        estimated_service_life_months: null,
        estimated_service_life_meter: null,
        estimated_resale_value: null,
        out_of_service_date: null,
        out_of_service_odometer: null,
      },
      comments: [],
      service_reminders: [],
      specifications: {
        dimensions: {
          id: "",
          width: 0,
          height: 0,
          length: 0,
          interior_volume: 0,
          passenger_volume: 0,
          cargo_volume: 0,
          ground_clearance: 0,
          bed_length: 0,
          weight: 0,
          vehicle: "",
        },
        performance: {
          id: "",
          towing_capacity: 0,
          max_payload: 0,
          fuel_economy_city: 0,
          fuel_economy_highway: 0,
          fuel_economy_combined: 0,
          vehicle: "",
        },
        engine: {
          id: "",
          engine_summary: "",
          engine_brand: "",
          aspiration: {
            id: "",
            name: "",
          },
          block_type: {
            id: "",
            name: "",
          },
          bore: 0,
          cam_type: {
            id: "",
            name: "",
          },
          compression: 0,
          cylinders: 0,
          displacement: 0,
          fuel_induction: {
            id: "",
            name: "",
          },
          max_hp: 0,
          max_torque: 0,
          redline_rpm: 0,
          stroke: 0,
          valves: 0,
          vehicle: "",
        },
        fuel: {
          id: "",
          fuel_quality: "",
          fuel_tank_capacity_1: 0,
          fuel_tank_capacity_2: 0,
          vehicle: "",
        },
        transmission: {
          id: "",
          transmission_description: "",
          transmission_brand: "",
          transmission_type: {
            id: "",
            name: "",
          },
          transmission_gears: 0,
          vehicle: "",
        },
        wheels_tires: {
          id: "",
          drive_type: {
            id: "",
            name: "",
          },
          brake_system: {
            id: "",
            name: "",
          },
          front_track_width: 0,
          rear_track_width: 0,
          wheelbase: 0,
          front_wheel_diameter: 0,
          rear_wheel_diameter: 0,
          rear_axle: "",
          front_tire_type: "",
          front_tire_psi: 0,
          rear_tire_type: "",
          rear_tire_psi: 0,
          vehicle: "",
        },
        oil: {
          id: "",
          oil_type: "",
          oil_capacity: 0,
          vehicle: "",
        },
      },
      vehicle_renewal_reminders: [],
    },
  },
  countReportVehiclesDetail: 0,
  countStatusChanges: 0,
  countCatalogues: 0,
  countCataloguesList: 0,
  countListOfUnits: 0,
  countStatusSummary: 0,
  countInspectionsSubmissionSummary: 0,
  countInspectionsSubmissionList: 0,
  countUsersRenewalReminders: 0,
  countRenewalReminders: 0,
  countCostComparisonInService: 0,
  countPartsByLocation: 0,
  countAssignmentsLog: 0,
  countWorkOrderLaborList: 0,
  countPartsActivity: 0,
  countFuelEntriesByVehicle: 0,
  countFuelSummary: 0,
  countFuelSummaryByLocation: 0,
  countWorkOrdersList: 0,
  countServiceReminders: 0,
  countWorkOrdersByVehicle: 0,
  countServicesWithoutService: 0,
  countUserList: 0,
  countReportAssignmentsSummary: 0,
  countIssueList: 0,
  countServiceHistorySummary: 0,
  countInspectionsFailed: 0,
  countVehicleDetailId: 0,
  countReportOperatingCostSummary: 0,
  countReportServiceReminderCompliance: 0,
  countReportsPurchaseOrdersList: 0,
  loadingServiceHistoryByVehicle: false,
  countServiceHistoryByVehicle: 0,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loadingCatalogues = false;
      state.success = null;
      state.loadingCataloguesList = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getReportsCatalogues.fulfilled, (state, action) => {
        state.loadingCatalogues = false;
        state.error = null;
        state.reportCatalogues = action.payload.results;
        state.countCatalogues = action.payload.count;
      })
      .addCase(getReportsCatalogues.pending, (state) => {
        state.loadingCatalogues = true;
        state.error = null;
      })
      .addCase(getReportsCatalogues.rejected, (state, action) => {
        state.loadingCatalogues = false;
        state.error = action?.payload as string;
      })
      .addCase(getReportsListCatalogues.fulfilled, (state, action) => {
        state.loadingCataloguesList = false;
        state.error = null;
        state.reportListCatalogues = action.payload;
        state.countCataloguesList = action.payload.count;
      })
      .addCase(getReportsListCatalogues.pending, (state) => {
        state.loadingCataloguesList = true;
        state.error = null;
      })
      .addCase(getReportsListCatalogues.rejected, (state, action) => {
        state.loadingCataloguesList = false;
        state.error = action?.payload as string;
      })
      .addCase(getReportsPartsActivity.fulfilled, (state, action) => {
        state.loadingPartsActivity = false;
        state.error = null;
        state.reportPartsActivity = action.payload;
        state.countPartsActivity = action.payload.table.count;
      })
      .addCase(getReportsPartsActivity.pending, (state) => {
        state.loadingPartsActivity = true;
        state.error = null;
      })
      .addCase(getReportsPartsActivity.rejected, (state, action) => {
        state.loadingPartsActivity = false;
        state.error = action?.payload as string;
      })
      .addCase(getReportsStatusChanges.fulfilled, (state, action) => {
        state.loadingReportStatusChanges = false;
        state.error = null;
        state.reportStatusChanges = action.payload;
        state.countStatusChanges = action.payload.table.count || 0;
      })
      .addCase(getReportsStatusChanges.pending, (state) => {
        state.loadingReportStatusChanges = true;
        state.error = null;
        state.countStatusChanges = 0;
      })
      .addCase(getReportsStatusChanges.rejected, (state, action) => {
        state.loadingReportStatusChanges = false;
        state.error = action?.payload as string;
        state.countStatusChanges = 0;
      })
      .addCase(getReportsListOfUnits.fulfilled, (state, action) => {
        state.loadingReportListOfUnits = false;
        state.countListOfUnits = action.payload.table.count || 0;
        state.error = null;
        state.reportListOfUnits = action.payload;
      })
      .addCase(getReportsListOfUnits.pending, (state) => {
        state.loadingReportListOfUnits = true;
        state.error = null;
      })
      .addCase(getReportsListOfUnits.rejected, (state, action) => {
        state.loadingReportListOfUnits = false;
        state.error = action?.payload as string;
      })
      .addCase(getReportsServiceHistorySummary.fulfilled, (state, action) => {
        state.loadingServiceHistorySummary = false;
        state.countServiceHistorySummary = action.payload.table.count || 0;
        state.error = null;
        state.reportServiceHistorySummary = action.payload;
      })
      .addCase(getReportsServiceHistorySummary.pending, (state) => {
        state.loadingServiceHistorySummary = true;
        state.error = null;
      })
      .addCase(getReportsServiceHistorySummary.rejected, (state, action) => {
        state.loadingServiceHistorySummary = false;
        state.error = action?.payload as string;
      })

      .addCase(getReportsServicesWithoutService.fulfilled, (state, action) => {
        state.loadingServicesWithoutService = false;
        state.countServicesWithoutService = action.payload.table.count || 0;
        state.error = null;
        state.reportServicesWithoutService = action.payload;
      })
      .addCase(getReportsServicesWithoutService.pending, (state) => {
        state.loadingServicesWithoutService = true;
        state.error = null;
      })
      .addCase(getReportsServicesWithoutService.rejected, (state, action) => {
        state.loadingServicesWithoutService = false;
        state.error = action?.payload as string;
      })
      .addCase(getReportsUserList.fulfilled, (state, action) => {
        state.loadingUserList = false;
        state.countUserList = action.payload.table.count || 0;
        state.error = null;
        state.reportUserList = action.payload;
      })
      .addCase(getReportsUserList.pending, (state) => {
        state.loadingUserList = true;
        state.error = null;
      })
      .addCase(getReportsUserList.rejected, (state, action) => {
        state.loadingUserList = false;
        state.error = action?.payload as string;
      })
      .addCase(getReportsStatusSummary.fulfilled, (state, action) => {
        state.loadingReportStatusSummary = false;
        state.countStatusSummary = action.payload.table.count || 0;
        state.error = null;
        state.reportStatusSummary = action.payload;
      })
      .addCase(getReportsStatusSummary.pending, (state) => {
        state.loadingReportStatusSummary = true;
        state.error = null;
      })
      .addCase(getReportsStatusSummary.rejected, (state, action) => {
        state.loadingReportStatusSummary = false;
        state.error = action.payload as string;
      })
      .addCase(getReportsRenewalReminders.fulfilled, (state, action) => {
        state.loadingReportRenewalReminders = false;
        state.countRenewalReminders = action.payload.table.count || 0;
        state.error = null;
        state.reportRenewalReminders = action.payload;
      })
      .addCase(getReportsRenewalReminders.pending, (state) => {
        state.loadingReportRenewalReminders = true;
        state.error = null;
      })
      .addCase(getReportsRenewalReminders.rejected, (state, action) => {
        state.loadingReportStatusSummary = false;
        state.error = action.payload as string;
      })
      .addCase(getReportsPartsByLocation.fulfilled, (state, action) => {
        state.loadingPartByLocation = false;
        state.countPartsByLocation = action.payload.table.count || 0;
        state.error = null;
        state.reportPartsByLocation = action.payload;
      })
      .addCase(getReportsPartsByLocation.pending, (state) => {
        state.loadingPartByLocation = true;
        state.error = null;
      })
      .addCase(getReportsPartsByLocation.rejected, (state, action) => {
        state.loadingPartByLocation = false;
        state.error = action.payload as string;
      })
      .addCase(getCostComparisonInService.fulfilled, (state, action) => {
        state.loadingCostComparisonInService = false;
        state.countCostComparisonInService = action.payload.table.count || 0;
        state.error = null;
        state.reportCostComparisonInService = action.payload;
      })
      .addCase(getCostComparisonInService.pending, (state) => {
        state.loadingCostComparisonInService = true;
        state.error = null;
      })
      .addCase(getInspectionsSubmissionSummary.fulfilled, (state, action) => {
        state.loadingReportInspectionsSubmissionSummary = false;
        state.countInspectionsSubmissionSummary =
          action.payload.table.count || 0;
        state.error = null;
        state.reportInspectionsSubmissionSummary = action.payload;
      })
      .addCase(getInspectionsSubmissionSummary.pending, (state) => {
        state.loadingReportInspectionsSubmissionSummary = true;
        state.error = null;
      })
      .addCase(getInspectionsSubmissionSummary.rejected, (state, action) => {
        state.loadingReportInspectionsSubmissionSummary = false;
        state.error = action.payload as string;
      })
      .addCase(getReportsAssignmentsLog.fulfilled, (state, action) => {
        state.loadingAssignmentsLog = false;
        state.countAssignmentsLog = action.payload.table.count || 0;
        state.error = null;
        state.reportAssignmentsLog = action.payload;
      })
      .addCase(getReportsAssignmentsLog.pending, (state) => {
        state.loadingAssignmentsLog = true;
        state.error = null;
      })
      .addCase(getReportsAssignmentsLog.rejected, (state, action) => {
        state.loadingAssignmentsLog = false;
        state.error = action.payload as string;
      })
      .addCase(getReportsUsersRenewalReminders.fulfilled, (state, action) => {
        state.loadingUsersRenewalReminders = false;
        state.countUsersRenewalReminders = action.payload.table.count || 0;
        state.error = null;
        state.reportUsersRenewalReminders = action.payload;
      })
      .addCase(getReportsUsersRenewalReminders.pending, (state) => {
        state.loadingUsersRenewalReminders = true;
        state.error = null;
      })
      .addCase(getReportsUsersRenewalReminders.rejected, (state, action) => {
        state.loadingUsersRenewalReminders = false;
        state.error = action.payload as string;
      })
      .addCase(getReportsAssignmentsSummary.fulfilled, (state, action) => {
        state.loadingReportsAssignmentsSummary = false;
        state.countReportAssignmentsSummary = action.payload.table.count || 0;
        state.error = null;
        state.reportAssignmentsSummary = action.payload || [];
      })
      .addCase(getReportsAssignmentsSummary.pending, (state) => {
        state.loadingReportsAssignmentsSummary = true;
        state.error = null;
      })
      .addCase(getReportsAssignmentsSummary.rejected, (state, action) => {
        state.loadingReportsAssignmentsSummary = false;
        state.error = action.payload as string;
      })
      .addCase(
        getReportsInspectionsSubmissionList.fulfilled,
        (state, action) => {
          state.loadingReportInspectionsSubmissionList = false;
          state.countInspectionsSubmissionList =
            action.payload.table.count || 0;
          state.error = null;
          state.reportInspectionsSubmissionList = action.payload || [];
        },
      )
      .addCase(getReportsInspectionsSubmissionList.pending, (state) => {
        state.loadingReportInspectionsSubmissionList = true;
        state.error = null;
      })
      .addCase(
        getReportsInspectionsSubmissionList.rejected,
        (state, action) => {
          state.loadingReportInspectionsSubmissionList = false;
          state.error = action.payload as string;
        },
      )
      .addCase(getReportsInspectionsFailed.fulfilled, (state, action) => {
        state.loadingReportsInspectionsFailed = false;
        state.countInspectionsFailed = action.payload.table.count || 0;
        state.error = null;
        state.reportInspectionsFailed = action.payload || [];
      })
      .addCase(getReportsInspectionsFailed.pending, (state) => {
        state.loadingReportsInspectionsFailed = true;
        state.error = null;
      })
      .addCase(getReportsInspectionsFailed.rejected, (state, action) => {
        state.loadingReportsInspectionsFailed = false;
        state.error = action.payload as string;
      })
      .addCase(getReportWorkOrdersByVehicle.fulfilled, (state, action) => {
        state.loadingWorkOrdersByVehicle = false;
        state.countWorkOrdersByVehicle = action.payload.table.count || 0;
        state.error = null;
        state.reportWorkOrdersByVehicle = action.payload || [];
      })
      .addCase(getReportWorkOrdersByVehicle.pending, (state) => {
        state.loadingWorkOrdersByVehicle = true;
        state.error = null;
      })
      .addCase(getReportWorkOrdersByVehicle.rejected, (state, action) => {
        state.loadingWorkOrdersByVehicle = false;
        state.error = action.payload as string;
      })
      .addCase(getReportServicesHistoryByVehicle.fulfilled, (state, action) => {
        state.loadingServiceHistoryByVehicle = false;
        state.countServiceHistoryByVehicle = action.payload.table.count || 0;
        state.error = null;
        state.reportServiceHistoryByVehicle = action.payload || [];
      })
      .addCase(getReportServicesHistoryByVehicle.pending, (state) => {
        state.loadingServiceHistoryByVehicle = true;
        state.error = null;
      })
      .addCase(getReportServicesHistoryByVehicle.rejected, (state, action) => {
        state.loadingServiceHistoryByVehicle = false;
        state.error = action.payload as string;
      })
      .addCase(getReportsVehiclesDetail.fulfilled, (state, action) => {
        state.loadingReportVehiclesDetail = false;
        state.countReportVehiclesDetail = action.payload.table.count || 0;
        state.error = null;
        state.reportVehiclesDetail = action.payload || [];
      })
      .addCase(getReportsVehiclesDetail.pending, (state) => {
        state.loadingReportVehiclesDetail = true;
        state.error = null;
      })
      .addCase(getReportsVehiclesDetail.rejected, (state, action) => {
        state.loadingReportVehiclesDetail = false;
        state.error = action.payload as string;
      })
      .addCase(getReportOperatingCostSummary.fulfilled, (state, action) => {
        state.loadingReportOperatingCostSummary = false;
        state.countReportOperatingCostSummary = action.payload.table.count || 0;
        state.error = null;
        state.reportOperatingCostSummary = action.payload || [];
      })
      .addCase(getReportOperatingCostSummary.pending, (state) => {
        state.loadingReportOperatingCostSummary = true;
        state.error = null;
      })
      .addCase(getReportOperatingCostSummary.rejected, (state, action) => {
        state.loadingReportOperatingCostSummary = false;
        state.error = action.payload as string;
      })
      .addCase(getReportIssueList.fulfilled, (state, action) => {
        state.loadingIssueList = false;
        state.countIssueList = action.payload.table.count || 0;
        state.error = null;
        state.reportIssueList = action.payload || [];
      })
      .addCase(getReportIssueList.pending, (state) => {
        state.loadingIssueList = true;
        state.error = null;
      })
      .addCase(getReportIssueList.rejected, (state, action) => {
        state.loadingIssueList = false;
        state.error = action.payload as string;
      })
      .addCase(getReportServiceReminders.fulfilled, (state, action) => {
        state.loadingReportsServiceReminders = false;
        state.countServiceReminders = action.payload.table.count || 0;
        state.error = null;
        state.reportServiceReminders = action.payload || [];
      })
      .addCase(getReportServiceReminders.pending, (state) => {
        state.loadingReportsServiceReminders = true;
        state.error = null;
      })
      .addCase(getReportServiceReminders.rejected, (state, action) => {
        state.loadingReportsServiceReminders = false;
        state.error = action.payload as string;
      })
      .addCase(getReportIssueFaultsSummary.fulfilled, (state, action) => {
        state.loadingIssueFaultsSummary = false;
        state.countIssueFaultsSummary = action.payload.table.count || 0;
        state.error = null;
        state.reportIssueFaultsSummary = action.payload || [];
      })
      .addCase(getReportIssueFaultsSummary.pending, (state) => {
        state.loadingIssueFaultsSummary = true;
        state.error = null;
      })
      .addCase(getReportIssueFaultsSummary.rejected, (state, action) => {
        state.loadingIssueFaultsSummary = false;
        state.error = action.payload as string;
      })
      .addCase(getReportWorkOrdersList.fulfilled, (state, action) => {
        state.loadingWorkOrdersList = false;
        state.countWorkOrdersList = action.payload.table.count || 0;
        state.error = null;
        state.reportWorkOrdersList = action.payload || [];
      })
      .addCase(getReportWorkOrdersList.pending, (state) => {
        state.loadingWorkOrdersList = true;
        state.error = null;
      })
      .addCase(getReportWorkOrdersList.rejected, (state, action) => {
        state.loadingWorkOrdersList = false;
        state.error = action.payload as string;
      })
      .addCase(getReportWorkOrdersLaborList.fulfilled, (state, action) => {
        state.loadingWorkOrderLaborList = false;
        state.countWorkOrderLaborList = action.payload.table.count || 0;
        state.error = null;
        state.reportWorkOrderLaborList = action.payload || [];
      })
      .addCase(getReportWorkOrdersLaborList.pending, (state) => {
        state.loadingWorkOrderLaborList = true;
        state.error = null;
      })
      .addCase(getReportWorkOrdersLaborList.rejected, (state, action) => {
        state.loadingWorkOrderLaborList = false;
        state.error = action.payload as string;
      })
      .addCase(
        getReportServiceReminderCompliance.fulfilled,
        (state, action) => {
          state.loadingReportServiceReminderCompliance = false;
          state.countReportServiceReminderCompliance =
            action.payload.table.count || 0;
          state.error = null;
          state.reportServiceReminderCompliance = action.payload || [];
        },
      )
      .addCase(getReportServiceReminderCompliance.pending, (state) => {
        state.loadingReportServiceReminderCompliance = true;
        state.error = null;
      })
      .addCase(getReportServiceReminderCompliance.rejected, (state, action) => {
        state.loadingReportServiceReminderCompliance = false;
        state.error = action.payload as string;
      })
      .addCase(getReportPartsByVehicle.fulfilled, (state, action) => {
        state.reportPartByVehicle.results = action.payload.results || [];
      })
      .addCase(getReportVehicleDetailId.fulfilled, (state, action) => {
        state.loadingReportsVehicleDetailId = false;
        state.error = null;
        state.reportVehicleDetailId = action.payload || [];
      })
      .addCase(getReportVehicleDetailId.pending, (state) => {
        state.loadingReportsVehicleDetailId = true;
        state.error = null;
      })
      .addCase(getReportVehicleDetailId.rejected, (state, action) => {
        state.loadingReportsVehicleDetailId = false;
        state.error = action.payload as string;
      })
      .addCase(getReportPurchaseOrdersList.fulfilled, (state, action) => {
        state.loadingReportsPurchaseOrdersList = false;
        state.countReportsPurchaseOrdersList = action.payload.table.count || 0;
        state.error = null;
        state.reportPurchaseOrdersList = action.payload || [];
      })
      .addCase(getReportPurchaseOrdersList.pending, (state) => {
        state.loadingReportsPurchaseOrdersList = true;
        state.error = null;
      })
      .addCase(getReportPurchaseOrdersList.rejected, (state, action) => {
        state.loadingReportsPurchaseOrdersList = false;
        state.error = action.payload as string;
      })
      .addCase(getReportFuelEntriesByVehicle.fulfilled, (state, action) => {
        state.loadingFuelEntriesByVehicle = false;
        state.countFuelEntriesByVehicle = action.payload.table.count || 0;
        state.error = null;
        state.reportFuelEntriesByVehicle = action.payload || [];
      })
      .addCase(getReportFuelEntriesByVehicle.pending, (state) => {
        state.loadingFuelEntriesByVehicle = true;
        state.error = null;
      })
      .addCase(getReportFuelEntriesByVehicle.rejected, (state, action) => {
        state.loadingFuelEntriesByVehicle = false;
        state.error = action.payload as string;
      })
      .addCase(getReportFuelSummary.fulfilled, (state, action) => {
        state.loadingFuelSummary = false;
        state.countFuelSummary = action.payload.table.count || 0;
        state.error = null;
        state.reportFuelSummary = action.payload || [];
      })
      .addCase(getReportFuelSummary.pending, (state) => {
        state.loadingFuelSummary = true;
        state.error = null;
      })
      .addCase(getReportFuelSummary.rejected, (state, action) => {
        state.loadingFuelSummary = false;
        state.error = action.payload as string;
      })
      .addCase(getReportFuelSummaryByLocation.fulfilled, (state, action) => {
        state.loadingFuelSummaryByLocation = false;
        state.countFuelSummaryByLocation = action.payload.table.count || 0;
        state.error = null;
        state.reportFuelSummaryByLocation = action.payload || [];
      })
      .addCase(getReportFuelSummaryByLocation.pending, (state) => {
        state.loadingFuelSummaryByLocation = true;
        state.error = null;
      })
      .addCase(getReportFuelSummaryByLocation.rejected, (state, action) => {
        state.loadingFuelSummaryByLocation = false;
        state.error = action.payload as string;
      });
  },
});

export const { clear } = reportsSlice.actions;

export const selectReportsListCatalogues = (state: RootState) =>
  state.reports.reportListCatalogues;
export const selectReportsPartByVehicle = (state: RootState) =>
  state.reports.reportPartByVehicle;
export const selectCountCatalogues = (state: RootState) =>
  state.reports.countCatalogues;
export const selectReportsCatalogues = (state: RootState) =>
  state.reports.reportCatalogues;
export const selectReportsCataloguesListCount = (state: RootState) =>
  state.reports.countCataloguesList;
export const selectReportsCataloguesLoading = (state: RootState) =>
  state.reports.loadingCatalogues;
export const selectReportsError = (state: RootState) => state.reports.error;
export const selectReportsSuccess = (state: RootState) => state.reports.success;
export const selectReportsCataloguesListLoading = (state: RootState) =>
  state.reports.loadingCataloguesList;
export const selectReportsUsersRenewalReminders = (state: RootState) =>
  state.reports.reportUsersRenewalReminders;
export const selectReportsUsersRenewalRemindersCount = (state: RootState) =>
  state.reports.countUsersRenewalReminders;
export const selectReportsUsersRenewalRemindersLoading = (state: RootState) =>
  state.reports.loadingUsersRenewalReminders;
export const selectReportsPartsByLocation = (state: RootState) =>
  state.reports.reportPartsByLocation;
export const selectReportsServicesWithoutService = (state: RootState) =>
  state.reports.reportServicesWithoutService;
export const selectReportsServicesWithoutServiceLoading = (state: RootState) =>
  state.reports.loadingServicesWithoutService;
export const selectReportsServicesWithoutServiceCount = (state: RootState) =>
  state.reports.countServicesWithoutService;
export const selectReportsPartsByLocationCount = (state: RootState) =>
  state.reports.countPartsByLocation;
export const selectReportsPartsByLocationLoading = (state: RootState) =>
  state.reports.loadingPartByLocation;
export const selectReportsServiceHistorySummary = (state: RootState) =>
  state.reports.reportServiceHistorySummary;
export const selectReportsServiceHistorySummaryLoading = (state: RootState) =>
  state.reports.loadingServiceHistorySummary;
export const selectReportsServiceHistorySummaryCount = (state: RootState) =>
  state.reports.countServiceHistorySummary;

export const selectReportsUserList = (state: RootState) =>
  state.reports.reportUserList;
export const selectReportsUserListLoading = (state: RootState) =>
  state.reports.loadingUserList;
export const selectReportsUserListCount = (state: RootState) =>
  state.reports.countUserList;
export const selectReportsListOfUnits = (state: RootState) =>
  state.reports.reportListOfUnits;
export const selectReportsListOfUnitsLoading = (state: RootState) =>
  state.reports.loadingReportListOfUnits;
export const selectReportsListOfUnitsCount = (state: RootState) =>
  state.reports.countListOfUnits;
export const selectReportsStatusChanges = (state: RootState) =>
  state.reports.reportStatusChanges;
export const selectReportsStatusChangesLoading = (state: RootState) =>
  state.reports.loadingReportStatusChanges;
export const selectReportsStatusChangesCount = (state: RootState) =>
  state.reports.countStatusChanges;
export default reportsSlice;
export const selectReportsStatusSummary = (state: RootState) =>
  state.reports.reportStatusSummary;
export const selectReportsStatusSummaryLoading = (state: RootState) =>
  state.reports.loadingReportStatusSummary;
export const selectReportsStatusSummaryCount = (state: RootState) =>
  state.reports.countStatusSummary;
export const selectReportsPartsActivity = (state: RootState) =>
  state.reports.reportPartsActivity;
export const selectReportsPartsActivityLoading = (state: RootState) =>
  state.reports.loadingPartsActivity;
export const selectReportsPartsActivityCount = (state: RootState) =>
  state.reports.countPartsActivity;
export const selectReportsRenewalReminders = (state: RootState) =>
  state.reports.reportRenewalReminders;
export const selectReportsRenewalRemindersLoading = (state: RootState) =>
  state.reports.loadingReportRenewalReminders;
export const selectReportsRenewalRemindersCount = (state: RootState) =>
  state.reports.countStatusSummary;
export const selectReportsRenewalRemindersError = (state: RootState) =>
  state.reports.error;
export const selectReportsWorkOrdersByVehicle = (state: RootState) =>
  state.reports.reportWorkOrdersByVehicle;
export const selectReportsWorkOrdersByVehicleLoading = (state: RootState) =>
  state.reports.loadingWorkOrdersByVehicle;
export const selectReportsWorkOrdersByVehicleCount = (state: RootState) =>
  state.reports.countWorkOrdersByVehicle;
export const selectReportsServiceHistoryByVehicle = (state: RootState) =>
  state.reports.reportServiceHistoryByVehicle;
export const selectReportsServiceHistoryByVehicleLoading = (state: RootState) =>
  state.reports.loadingServiceHistoryByVehicle;
export const selectReportsServiceHistoryByVehicleCount = (state: RootState) =>
  state.reports.countServiceHistoryByVehicle;
export const selectCostComparisonInServiceList = (state: RootState) =>
  state.reports.reportCostComparisonInService;
export const selectCostComparisonInServiceLoading = (state: RootState) =>
  state.reports.loadingCostComparisonInService;
export const selectCostComparisonInServiceCount = (state: RootState) =>
  state.reports.countCostComparisonInService;
export const selectReportsInspectionsSubmissionSummaryList = (
  state: RootState,
) => state.reports.reportInspectionsSubmissionSummary;
export const selectReportsInspectionsSubmissionSummaryLoading = (
  state: RootState,
) => state.reports.loadingReportInspectionsSubmissionSummary;
export const selectReportsInspectionsSubmissionSummaryCount = (
  state: RootState,
) => state.reports.countInspectionsSubmissionSummary;
export const selectReportsIssueFaultsSummaryList = (state: RootState) =>
  state.reports.reportIssueFaultsSummary;
export const selectReportsIssueFaultsSummaryLoading = (state: RootState) =>
  state.reports.loadingIssueFaultsSummary;
export const selectReportsIssueFaultsSummaryCount = (state: RootState) =>
  state.reports.countIssueFaultsSummary;
export const selectReportsAssignmentsLog = (state: RootState) =>
  state.reports.reportAssignmentsLog;
export const selectReportsAssignmentsLogLoading = (state: RootState) =>
  state.reports.loadingAssignmentsLog;
export const selectReportsAssignmentsLogCount = (state: RootState) =>
  state.reports.countAssignmentsLog;
export const selectReportsAssignmentsSummaryList = (state: RootState) =>
  state.reports.reportAssignmentsSummary;
export const selectReportsAssignmentsSummaryLoading = (state: RootState) =>
  state.reports.loadingReportsAssignmentsSummary;
export const selectReportsAssignmentsSummaryCount = (state: RootState) =>
  state.reports.countReportAssignmentsSummary;
export const selectReportsWorkOrdersLaborList = (state: RootState) =>
  state.reports.reportWorkOrderLaborList;
export const selectReportsWorkOrdersLaborListCount = (state: RootState) =>
  state.reports.countWorkOrderLaborList;
export const selectReportsWorkOrdersLaborListLoading = (state: RootState) =>
  state.reports.loadingWorkOrderLaborList;

export const selectReportsInspectionSubmissionListList = (state: RootState) =>
  state.reports.reportInspectionsSubmissionList;
export const selectReportsInspectionSubmissionListLoading = (
  state: RootState,
) => state.reports.loadingReportInspectionsSubmissionList;
export const selectReportsInspectionSubmissionListCount = (state: RootState) =>
  state.reports.countInspectionsSubmissionList;
export const selectReportsInspectionsFailedList = (state: RootState) =>
  state.reports.reportInspectionsFailed;
export const selectReportsInspectionsFailedLoading = (state: RootState) =>
  state.reports.loadingReportsInspectionsFailed;
export const selectReportsInspectionsFailedCount = (state: RootState) =>
  state.reports.countInspectionsFailed;
export const selectReportsVehiclesDetail = (state: RootState) =>
  state.reports.reportVehiclesDetail;
export const selectReportsVehiclesDetailLoading = (state: RootState) =>
  state.reports.loadingReportVehiclesDetail;
export const selectReportsVehiclesDetailCount = (state: RootState) =>
  state.reports.countReportVehiclesDetail;
export const selectReportsOperatingCostSummary = (state: RootState) =>
  state.reports.reportOperatingCostSummary;
export const selectReportsOperatingCostSummaryLoading = (state: RootState) =>
  state.reports.loadingReportOperatingCostSummary;
export const selectReportsOperatingCostSummaryCount = (state: RootState) =>
  state.reports.countReportOperatingCostSummary;
export const selectReportsIssueList = (state: RootState) =>
  state.reports.reportIssueList;
export const selectReportsIssueListLoading = (state: RootState) =>
  state.reports.loadingIssueList;
export const selectReportsIssueListCount = (state: RootState) =>
  state.reports.countIssueList;
export const selectReportsServiceReminders = (state: RootState) =>
  state.reports.reportServiceReminders;
export const selectReportsServiceRemindersCompliance = (state: RootState) =>
  state.reports.reportServiceReminderCompliance;
export const selectReportsServiceRemindersLoading = (state: RootState) =>
  state.reports.loadingReportsServiceReminders;
export const selectReportsServiceRemindersCount = (state: RootState) =>
  state.reports.countServiceReminders;
export const selectReportWorkOrdersList = (state: RootState) =>
  state.reports.reportWorkOrdersList;
export const selectReportWorkOrdersListLoading = (state: RootState) =>
  state.reports.loadingWorkOrdersList;
export const selectReportWorkOrdersListCount = (state: RootState) =>
  state.reports.countWorkOrdersList;
export const selectReportsVehicleDetailId = (state: RootState) =>
  state.reports.reportVehicleDetailId;
export const selectReportsPurchaseOrdersListLoading = (state: RootState) =>
  state.reports.loadingReportsPurchaseOrdersList;
export const selectReportsPurchaseOrdersListCount = (state: RootState) =>
  state.reports.countReportsPurchaseOrdersList;
export const selectReportsPurchaseOrdersListData = (state: RootState) =>
  state.reports.reportPurchaseOrdersList.table;
export const selectReportsPurchaseOrdersListCharts = (state: RootState) =>
  state.reports.reportPurchaseOrdersList.charts;

export const selectReportsVehicleDetailIdLoading = (state: RootState) =>
  state.reports.loadingReportsVehicleDetailId;

export const selectReportFuelEntriesByVehicle = (state: RootState) =>
  state.reports.reportFuelEntriesByVehicle;

export const selectReportFuelEntriesByVehicleLoading = (state: RootState) =>
  state.reports.loadingFuelEntriesByVehicle;

export const selectReportFuelEntriesByVehicleCount = (state: RootState) =>
  state.reports.countFuelEntriesByVehicle;

export const selectReportFuelSummary = (state: RootState) =>
  state.reports.reportFuelSummary;

export const selectReportFuelSummaryLoading = (state: RootState) =>
  state.reports.loadingFuelSummary;

export const selectReportFuelSummaryCount = (state: RootState) =>
  state.reports.countFuelSummary;

export const selectReportFuelSummaryByLocation = (state: RootState) =>
  state.reports.reportFuelSummaryByLocation;

export const selectReportFuelSummaryByLocationLoading = (state: RootState) =>
  state.reports.loadingFuelSummaryByLocation;

export const selectReportFuelSummaryByLocationCount = (state: RootState) =>
  state.reports.countFuelSummaryByLocation;
