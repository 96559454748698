import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const ReportFuelSummaryList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportFuelSummary/ReportFuelSummaryList/ReportFuelSummaryList"
    )
);

export const ReportFuelSummary = () => {
  return (
    <Layout>
      <ReportFuelSummaryList />
    </Layout>
  );
};
