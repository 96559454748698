import React from "react";
import { create, antdModalV5, useModal } from "@ebay/nice-modal-react";
import { DialogProps as MUIDialogProps } from "@mui/material";
import cn from "classnames";
import styles from "./ModalSuccess.module.scss";

import Modal from "Components/UI/Modal/Modal";
import { CheckMarkIcon } from "Assets";

type Props = MUIDialogProps & {
  dynamicText: string;
};

export const ModalSuccess = create<Props>(({ dynamicText, ...props }) => {
  const modal = useModal();

  const onCancel = () => modal.hide();

  return (
    <Modal
      {...antdModalV5(modal)}
      {...props}
      classes={{
        paper: cn(styles.container),
        scrollPaper: styles.scroll,
      }}
      className={styles.modal}
      onClose={onCancel}
      isClosed={true}
      aria-labelledby="success-modal-title"
    >
      <section className={styles.wrapper}>
        <CheckMarkIcon height={180} width={180} />
        <h3 className={styles.description} id="success-modal-title">
          <strong>Completed: </strong>
          The <strong>{dynamicText}</strong> has been exported
        </h3>
      </section>
    </Modal>
  );
});
