import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";

const getInputGlobal = createAsyncThunk(
  "input/getInputGlobal",
  async (params: any | null) => {
    try {
      const response = await api.inputGlobal.getInputGlobal(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export { getInputGlobal };
