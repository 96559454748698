import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IAddBodySubType,
  IBodySubTypeListSearch,
} from "Shared/Types/bodySubType";

const getBodySubTypeList = createAsyncThunk(
  "bodySubType/getBodySubTypeList",
  async (params: IBodySubTypeListSearch | null) => {
    try {
      const response = await api.bodySubType.getBodySubTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addBodySubType = createAsyncThunk(
  "bodySubType/addBodySubType",
  async (data: IAddBodySubType, { dispatch }) => {
    try {
      const response = await api.bodySubType.addBodySubType(data);
      dispatch(getBodySubTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addBodySubType, getBodySubTypeList };
