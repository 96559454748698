import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const MeterHistoryList = lazyLoader(
  () => import("Containers/MeterHistory/MeterHistoryList/MeterHistoryList")
);

export const MeterHistoryListPage: React.FC = () => {
  return (
    <Layout>
      <MeterHistoryList />
    </Layout>
  );
};
