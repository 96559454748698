import React from "react";
import { UniqueInput } from "../../../Components/Login/UniqueInput/UniqueInput";
import { AuthBtn } from "../../../Components/Login/AuthBtn/AuthBtn";
import AuthFormLayout from "../../../Components/UI/Layouts/AuthFormLayout/AuthFormLayout";
import { useNavigate, useParams } from "react-router";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { passwordSchema } from "../../../Shared/Utils/yup/passwordSchema";
import { notifySuccess } from "../../../Components/Notify/notify";
import { useAppDispatch } from "../../../Redux/store";
import { setupUserPassword } from "../../../Redux/User/reducer";
import styles from "../../ForgotPassword/ForgotPasswordStep1.module.scss";
import { LOGIN } from "Shared/Constants/routes";

const SetupUserPassword = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  type IPasswordTypes = {
    password: string;
    confirm_password: string;
  };
  const handleSetupUserPassword = async (values: IPasswordTypes) => {
    if (token) {
      const data = {
        password: values.password,
        token: token,
      };
      await dispatch(setupUserPassword(data)).unwrap();
      notifySuccess("Successfully setup password!");
      navigate(LOGIN);
    }
  };

  return (
    <div className={styles.wrapper}>
      <AuthFormLayout iconTopMargin="130px">
        <form
          onSubmit={handleSubmit(handleSetupUserPassword)}
          className={styles.wrapper__form}
        >
          <UniqueInput
            name="password"
            type="password"
            className={styles.wrapper__form__control}
            variant="standard"
            label="New password"
            showEyeIcon
            control={control}
            withController
          />
          <UniqueInput
            name="confirm_password"
            type="password"
            className={styles.wrapper__form__control}
            variant="standard"
            label="Repeat password"
            showEyeIcon
            control={control}
            withController
          />
          <div className={styles.wrapper__form__btn}>
            <div className={styles.btn__submit}>
              <AuthBtn fullWidth type="submit">
                Save
              </AuthBtn>
            </div>
          </div>
        </form>
      </AuthFormLayout>
    </div>
  );
};

export default SetupUserPassword;
