import React, { useEffect, useState } from "react";
import SidebarItem, { SidebarItemProps } from "./SidebarItem";
import { useLocation } from "react-router";
import Icon from "Components/UI/Icon/Icon";
import styles from "./Sidebar.module.scss";
import cn from "classnames";
import { CloseWhiteIcon } from "Assets";
import { readObj, write } from "Service/storage";

type SidebarHeaderItemProps = {
  title: string;
  icon: React.ElementType;
  subMenu: SidebarItemProps[];
};

const checkIsPageActive = (list: SidebarItemProps[], path: string) =>
  list.some(
    (item) =>
      path === item.url ||
      item.subMenu?.some((elem) => elem.url && path === elem.url)
  );

const SidebarHeaderItem: React.FC<SidebarHeaderItemProps> = ({
  title,
  icon,
  subMenu,
}) => {
  const openedSections = (readObj("openedSections") || []) as string[];
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(
    checkIsPageActive(subMenu, location.pathname) ||
      openedSections.includes(title)
  );

  const toggleSection = () => {
    const currentOpenedSections = readObj("openedSections") || [];
    setIsOpen((prev) => {
      if (prev) {
        write(
          "openedSections",
          currentOpenedSections.filter((item: string) => item !== title)
        );
      } else {
        write("openedSections", [...currentOpenedSections, title]);
      }
      return !prev;
    });
  };

  useEffect(() => {
    if (checkIsPageActive(subMenu, location.pathname)) {
      setIsOpen(true);
    }
  }, [subMenu, location]);

  useEffect(() => {
    const currentOpenedSections = readObj("openedSections") || [];
    if (isOpen && !currentOpenedSections.includes(title)) {
      write("openedSections", [...currentOpenedSections, title]);
    }
  }, [title]);

  return (
    <div
      className={cn(
        styles.sidebar__section,
        !isOpen && styles.sidebar__section_closed
      )}
    >
      <div
        onMouseDown={toggleSection}
        className={cn(
          styles.sidebar__header,
          !isOpen && styles.sidebar__header_closed
        )}
      >
        <div className={styles.sidebar__header_icon}>
          <Icon
            component={isOpen ? CloseWhiteIcon : icon}
            fontSize="medium"
            color="transparent"
          />
        </div>
        <h4 className={styles.sidebar__header_title}>{title}</h4>
      </div>

      <div
        className={cn(
          styles.sidebar__mainList,
          !isOpen && styles.sidebar__mainList_closed
        )}
      >
        {subMenu.map((item) => (
          <SidebarItem key={item.title} {...item} />
        ))}
      </div>
    </div>
  );
};

export default SidebarHeaderItem;
