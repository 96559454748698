import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "Service/api/api";
import {
  TDashboardIssuesCalendarParams,
  TDashboardVehicleAssignmentsParams,
} from "Shared/Types/dashboard";

export const getDashboardVehicleAssignment = createAsyncThunk(
  "dashboard/getDashboardVehicleAssignment",
  async (params: TDashboardVehicleAssignmentsParams | null) => {
    try {
      const response =
        await api.dashboard.getDashboardVehicleAssignment(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getDashboardVehicleAssignmentDetail = createAsyncThunk(
  "dashboard/getDashboardVehicleAssignmentDetail",
  async (id: string) => {
    try {
      const response =
        await api.dashboard.getDashboardVehicleAssignmentDetail(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getVehicleStatus = createAsyncThunk(
  "dashboard/getVehicleStatus",
  async () => {
    try {
      const response = await api.dashboard.getVehicleStatus();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const getIssuesStats = createAsyncThunk(
  "dashboard/getIssuesStats",
  async () => {
    try {
      const response = await api.dashboard.getIssuesStats();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getDashboardIssuesCalendar = createAsyncThunk(
  "dashboard/getDashboardIssuesCalendar",
  async (params: TDashboardIssuesCalendarParams | null) => {
    try {
      const response = await api.dashboard.getDashboardIssuesCalendar(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const getDashboardInspectionSubmission = createAsyncThunk(
  "dashboard/getDashboardInspectionSubmission",
  async () => {
    try {
      const response = await api.dashboard.getDashboardInspectionSubmission();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const getDashboardInspectionSubmissionOutcomes = createAsyncThunk(
  "dashboard/getDashboardInspectionSubmissionOutcomes",
  async () => {
    try {
      const response =
        await api.dashboard.getDashboardInspectionSubmissionOutcomes();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const getDashboardLocation = createAsyncThunk(
  "dashboard/getDashboardLocation",
  async () => {
    try {
      const response = await api.dashboard.getDashboardLocation();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const getDashboardIssuesStatus = createAsyncThunk(
  "dashboard/getDashboardIssuesStatus",
  async () => {
    try {
      const response = await api.dashboard.getDashboardIssuesStatus();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const getDashboardReminders = createAsyncThunk(
  "dashboard/getDashboardReminders",
  async (params: any) => {
    try {
      const response = await api.dashboard.getDashboardReminders(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const getDashboardWorkOrder = createAsyncThunk(
  "dashboard/getDashboardWorkOrder",
  async (params: any) => {
    try {
      const response = await api.dashboard.getDashboardWorkOrder(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const getDashboardRecentComments = createAsyncThunk(
  "dashboard/getDashboardRecentComments",
  async () => {
    try {
      const response = await api.dashboard.getDashboardRecentComments();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
