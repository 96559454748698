import { createSlice } from "@reduxjs/toolkit";
import { IDetailService, IServices } from "Shared/Types/services-history";
import { RootState } from "../store";
import { getServicesFiles, getServicesId, getServicesList } from "./reducer";
import { IFile } from "Shared/Types/file";

interface ICompanyRequestsState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  servicesList: IServices[];
  currentService: IDetailService | null;
  currentServicesFiles: IFile[];
  numPage: number;
}

const initialState: ICompanyRequestsState = {
  loading: false,
  error: null,
  servicesList: [],
  numPage: 0,
  currentService: null,
  currentServicesFiles: [],
};

const servicesSlice = createSlice({
  name: "sliceService",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
      state.currentService = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServicesList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.servicesList = action.payload.results;
        state.numPage = action.payload.count;
      })
      .addCase(getServicesId.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.currentService = action.payload;
      });

    builder.addCase(getServicesFiles.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.currentServicesFiles = action.payload;
    });
  },
});

export const { clear } = servicesSlice.actions;

export const selectServicesList = (state: RootState) =>
  state.services_history.servicesList;
export const selectDetailServices = (state: RootState) =>
  state.services_history.currentService;
export const selectServicesFiles = (state: RootState) =>
  state.services_history.currentServicesFiles;
export const selectServicesLoading = (state: RootState) =>
  state.services_history.loading;
export const selectServicesError = (state: RootState) =>
  state.services_history.error;
export const selectServicesSuccess = (state: RootState) =>
  state.services_history.success;
export const selectNumPage = (state: RootState): number =>
  state.services_history.numPage;
export default servicesSlice;
