import { createSlice } from "@reduxjs/toolkit";
import { IBodyType } from "Shared/Types/bodyType";
import { RootState } from "../store";
import { addBodySubType, getBodySubTypeList } from "./BodySubTypeReducer";

interface bodySubTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  bodyTypeList: IBodyType[];
}

const initialState: bodySubTypeState = {
  loading: false,
  error: null,
  bodyTypeList: [],
};

const bodySubTypeSlice = createSlice({
  name: "bodySubType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBodySubTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.bodyTypeList = action.payload.results;
    });
    builder.addCase(addBodySubType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addBodySubType.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addBodySubType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = bodySubTypeSlice.actions;

export const selectSubTypeList = (state: RootState) =>
  state.bodySubType.bodyTypeList;
export const selectSubTypeAuthLoading = (state: RootState) =>
  state.bodySubType.loading;
export const selectSubTypeAuthError = (state: RootState) =>
  state.bodySubType.error;
export const selectSubTypeAuthSuccess = (state: RootState) =>
  state.bodySubType.success;

export default bodySubTypeSlice;
