import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddModelType, IModelTypeSearchList } from "Shared/Types/models";

const getModelList = createAsyncThunk(
  "modelType/getModelList",
  async (params: IModelTypeSearchList | null) => {
    try {
      const response = await api.modelType.getModelTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addModelType = createAsyncThunk(
  "modelType/addModelType",
  async (data: IAddModelType) => {
    try {
      const response = await api.modelType.addModelType(data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addModelType, getModelList };
