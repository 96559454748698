import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddDriveType, IDriveTypeListSearch } from "Shared/Types/drive";

const getDriveTypeList = createAsyncThunk(
  "driveType/getDriveTypeList",
  async (params: IDriveTypeListSearch | null) => {
    try {
      const response = await api.driveType.getDriveTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addDriveType = createAsyncThunk(
  "driveType/addDriveType",
  async (data: IAddDriveType, { dispatch }) => {
    try {
      const response = await api.driveType.addDriveType(data);
      dispatch(getDriveTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addDriveType, getDriveTypeList };
