import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "Service/api/api";
import { IFuelHistorySearchList } from "Shared/Types/fuelHistory";
import {
  IDeleteReducerWithCallback,
  MultiplyActionDispatchParams,
  MultiplyActionDispatchParamsWithAnotherField,
} from "Shared/Types/shared";
import { IAddFilesToEntityTypes } from "Shared/Types/file";
import {
  IReducerWithCallback,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";
import { TFaultHistory } from "Shared/Utils/yup/fuelHistory";
import { IFuelHistoryCommentAdd } from "Shared/Types/comment";

export const getFuelHistoryList = createAsyncThunk(
  "fuelHistory/getFuelHistoryList",
  async (params: IFuelHistorySearchList | null) => {
    try {
      const response = await api.fuelHistory.getFuelHistoryList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getCurrentFuelHistory = createAsyncThunk(
  "fuelHistory/getCurrentFuelHistory",
  async (id: string) => {
    try {
      const response = await api.fuelHistory.getCurrentFuelHistory(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getFuelHistoryStatistics = createAsyncThunk(
  "fuelHistory/getFuelHistoryStatistics",
  async () => {
    try {
      const response = await api.fuelHistory.getFuelHistoryStatistics();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const deleteFuelHistory = createAsyncThunk(
  "fuelHistory/deleteFuelHistory",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.fuelHistory.deleteFuelHistory(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const deleteMultipleFuelHistory = createAsyncThunk(
  "fuelHistory/deleteMultipleFuelHistory",
  async ({
    ids,
    onError,
    onSuccess,
  }: MultiplyActionDispatchParamsWithAnotherField) => {
    try {
      const response = await api.fuelHistory.deleteMultipleFuelHistory(ids);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const addDocumentsToFuelHistory = createAsyncThunk(
  "fuelHistory/addDocumentsToFuelHistory",
  async ({
    id,
    files,
    onErrorUploadFiles,
    onSuccessUploadFiles,
  }: IAddFilesToEntityTypes) => {
    try {
      await api.fuelHistory.addDocumentsToFuelHistory({ id, files });
      onSuccessUploadFiles?.();
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

export const removeDocumentsFromFuelHistory = createAsyncThunk(
  "fuelHistory/removeDocumentsFromFuelHistory",
  async ({
    id,
    files,
    onErrorUploadFiles,
    onSuccessUploadFiles,
  }: IAddFilesToEntityTypes) => {
    try {
      await api.fuelHistory.removeDocumentsFromFuelHistory({ id, files });
      onSuccessUploadFiles?.();
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

export const addFuelHistory = createAsyncThunk(
  "fuelHistory/createFuelHistory",
  async ({ data, onError, onSuccess }: IReducerWithCallback<TFaultHistory>) => {
    try {
      await api.fuelHistory.createFuelHistory(data);
      onSuccess?.();
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const editFuelHistory = createAsyncThunk(
  "fuelHistory/editFuelHistory",
  async ({
    id,
    data,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<TFaultHistory>) => {
    try {
      await api.fuelHistory.editFuelHistory(id, data);
      onSuccess?.();
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const createFuelHistoryComment = createAsyncThunk(
  "fuelHistory/createComment",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<IFuelHistoryCommentAdd>) => {
    try {
      const response = await api.fuelHistory.saveComment(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const deleteFuelHistoryComment = createAsyncThunk(
  "fuelHistory/deleteComment",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.fuelHistory.deleteComment(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);
