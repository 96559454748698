import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const InspectionChooseVehicle = lazyLoader(
  () => import("Containers/Inspections/InspectionChooseVehicle")
);

export const InspectionVehicleChoose = () => {
  return (
    <Layout>
      <InspectionChooseVehicle />
    </Layout>
  );
};
