import { client } from "Service/axios";
import {
  VEHICLE_MAKES,
  VEHICLE_MAKES_CREATE,
} from "Shared/Constants/apiRequest";
import {
  IAddMakeType,
  IMakeType,
  IMakeTypeSearchList,
} from "Shared/Types/make";

class MakeType {
  getMakeTypeList(params: IMakeTypeSearchList | null) {
    return client.get<any>(VEHICLE_MAKES, { params });
  }
  addMakeType(data: IAddMakeType) {
    return client.post<IMakeType>(VEHICLE_MAKES_CREATE, data);
  }
}

export default MakeType;
