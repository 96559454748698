import React, {
  FC,
  ReactElement,
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import styles from "./Sidebar.module.scss";
import Icon from "Components/UI/Icon/Icon";
import cn from "classnames";
import { useLocation, useNavigate } from "react-router";

type SubMenuItem = {
  title: string;
  url: string;
  icon:
    | React.ComponentType<React.SVGProps<SVGSVGElement>>
    | ReactElement
    | FC
    | null;
};

export type SidebarItemProps = {
  icon: React.ElementType;
  title: string;
  url: string;
  subMenu?: SubMenuItem[];
  isDropdown?: boolean;
  withNotificaion?: boolean;
  notifcationsCount?: number;
};

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  title,
  url,
  subMenu,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isSubeMenuActive, setIsSubMenuActive] = useState(false);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const isActive = useMemo(
    () =>
      location.pathname === url ||
      subMenu?.some((item) => item.url === location.pathname),
    [location, url, isSubeMenuActive]
  );

  const handleChangeMenu = () => {
    if (subMenu?.length) {
      setIsSubMenuActive(true);
    } else {
      navigate(url);
    }
  };

  const handleChangeSubMenuItem = (e: React.MouseEvent, url: string) => {
    e.stopPropagation();
    navigate(url);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      contentRef.current &&
      !contentRef.current?.contains(event.target as Node)
    ) {
      setIsSubMenuActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={contentRef}
      onClick={handleChangeMenu}
      className={cn(styles.sidebarItem, isActive && styles.sidebarItem_active)}
    >
      <div className={styles.sidebarItem__icon}>
        <Icon component={icon} fontSize="medium" color="transparent"></Icon>
      </div>
      <p className={cn(isSubeMenuActive && styles.sidebarItem__title_active)}>
        {title}
      </p>

      {isSubeMenuActive && (
        <ul className={cn(styles.sidebarItem__popup)}>
          {subMenu?.map((item) => (
            <li
              className={cn(
                styles.sidebarItem__popup_item,
                item.url === location.pathname &&
                  styles.sidebarItem__popup_item_active
              )}
              onClick={(e) => handleChangeSubMenuItem(e, item.url)}
              key={item.url}
            >
              {item.title}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
export default SidebarItem;
