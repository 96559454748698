import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const AssignmentsList = lazyLoader(
  () => import("Containers/Assignments/AssignmetsList/AssignmentsList")
);

export const AssignmentsPage = () => {
  return (
    <Layout>
      <AssignmentsList />
    </Layout>
  );
};
