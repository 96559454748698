import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import api from "Service/api/api";
import { IServicesCommentAdd } from "Shared/Types/comment";
import {
  IAddDocumentsToEntityTypes,
  IAddFilesToEntityTypes,
} from "Shared/Types/file";
import { IServicesSearch } from "Shared/Types/services-history";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IReducerWithCallbackWithError,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";
import { IAddServices } from "Shared/Utils/yup/service-history";

const getServicesList = createAsyncThunk(
  "services/getServicesList",
  async (params: IServicesSearch | null) => {
    try {
      const response = await api.services_history.getServicesList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
const getServicesId = createAsyncThunk(
  "service/getServicesId",
  async (id: string) => {
    try {
      const response = await api.services_history.getServicesId(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const deleteServiceHistory = createAsyncThunk(
  "service/deleteServiceHistory",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.services_history.deleteServiceHistory(id);
      dispatch(getServicesList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const getServicesFiles = createAsyncThunk(
  "service/getServicesFiles",
  async (id: string) => {
    try {
      const response = await api.services_history.getServicesFiles(id);
      const documents = response.data.documents || [];
      const photos = response.data.photos ? response.data.photos : [];
      return [...documents, ...photos];
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const addFilesToServices = createAsyncThunk(
  "service/addFilesToServices",
  async ({
    id,
    files,
    onErrorUploadFiles,
    onSuccessUploadFiles,
  }: IAddFilesToEntityTypes) => {
    try {
      await api.services_history.addFilesToServices({ id, files });
      onSuccessUploadFiles?.();
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  }
);

const removeFileFromServices = createAsyncThunk(
  "service/removeFileFromServices",
  async ({
    id,
    documents,
    onErrorUploadFiles,
    onSuccessUploadFiles,
  }: IAddDocumentsToEntityTypes) => {
    try {
      await api.services_history.removeFileFromServices({ id, documents });
      onSuccessUploadFiles?.();
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  }
);
const addServiceHistory = createAsyncThunk(
  "service/addServiceHistory",
  async ({
    data,
    onSuccess,
    onError,
  }: IReducerWithCallbackWithError<IAddServices>) => {
    try {
      const response = await api.services_history.addServiceHistory(data);
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        onError?.(error);
      }
      throw error;
    }
  }
);

export const updateServiceHistoryPatch = createAsyncThunk(
  "service/updateServiceHistory",
  async ({
    id,
    data,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<IAddServices>) => {
    try {
      const response = await api.services_history.updateServiceHistoryPatch(
        id,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
const saveComment = createAsyncThunk(
  "service/saveComment",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<IServicesCommentAdd>) => {
    try {
      const response = await api.services_history.saveComment(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

const deleteServicesComments = createAsyncThunk(
  "service/deleteServicesComments",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.services_history.deleteServicesComments(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export {
  addFilesToServices,
  addServiceHistory,
  deleteServiceHistory,
  deleteServicesComments,
  getServicesFiles,
  getServicesId,
  getServicesList,
  removeFileFromServices,
  saveComment,
};
