import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const Main = lazyLoader(() => import("Containers/Dashboard/Main/Main"));

export const Dashboard = () => {
  return (
    <Layout>
      <Main />
    </Layout>
  );
};
