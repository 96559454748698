import { client } from "Service/axios";
import {
  REMINDERS_CONTACTS,
  REMINDERS_CONTACTS_CREATE,
  REMINDERS_CONTACTS_DELETE_ID,
  REMINDERS_CONTACTS_ID,
  REMINDERS_CONTACTS_MULTIPLE_DELETE,
  REMINDERS_CONTACTS_RENEWAL_TYPE_CREATE,
  REMINDERS_CONTACTS_TYPE,
  REMINDERS_CONTACTS_UPDATE_ID,
} from "Shared/Constants/apiRequest";
import {
  ContactRenewalList,
  ContactRenewalRetrieve,
  ContactRenewalTypeCreate,
  ContactRenewalTypeList,
} from "Shared/Types/fleetmanagementapi";
import { IReminderContactSearch } from "Shared/Types/reminder";
import { PaginatedResponse } from "Shared/Types/shared";

class ReminderContact {
  getReminderContactList(params: IReminderContactSearch | null) {
    return client.get<PaginatedResponse<ContactRenewalList[]>>(
      REMINDERS_CONTACTS,
      { params }
    );
  }
  getReminderContactById(id: string) {
    return client.get<ContactRenewalRetrieve>(REMINDERS_CONTACTS_ID(id));
  }
  createReminderContact(data: any) {
    return client.post(REMINDERS_CONTACTS_CREATE, data);
  }
  updateReminderContact(id: string, data: any) {
    return client.patch(REMINDERS_CONTACTS_UPDATE_ID(id), data);
  }
  deleteReminderContact(id: string) {
    return client.delete(REMINDERS_CONTACTS_DELETE_ID(id));
  }
  multiplyDeletedReminderContact({ ids }: { ids: string[] }) {
    return client.post<any>(REMINDERS_CONTACTS_MULTIPLE_DELETE, {
      ids: ids,
    });
  }

  getReminderConcactTypes() {
    return client.get<PaginatedResponse<ContactRenewalTypeList[]>>(
      REMINDERS_CONTACTS_TYPE
    );
  }
  createReminderContactType(data: ContactRenewalTypeCreate | null) {
    return client.post(REMINDERS_CONTACTS_RENEWAL_TYPE_CREATE, data);
  }
}

export default ReminderContact;
