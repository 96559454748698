import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const EditServiceHistory = lazyLoader(
  () =>
    import(
      "Containers/ServiceHistory/AddEditServiceHistory/EditServiceHesitory"
    )
);

export const EditsServiceHistory = () => {
  return (
    <Layout>
      <EditServiceHistory />
    </Layout>
  );
};
