import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IAddRole,
  IRoleSearchList,
  IUpdateRole,
} from "../../Shared/Types/role";
import { IReducerWithCallback } from "../../Shared/Types/shared";

const getRolesList = createAsyncThunk(
  "role/getRoles",
  async (params: IRoleSearchList | null) => {
    try {
      const response = await api.role.getRoleList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addRole = createAsyncThunk(
  "role/addRole",
  async ({ data, onError, onSuccess }: IReducerWithCallback<IAddRole>) => {
    try {
      const response = await api.role.addRole(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const updateRole = createAsyncThunk(
  "role/updateRole",
  async ({ data, onError, onSuccess }: IReducerWithCallback<IUpdateRole>) => {
    try {
      const response = await api.role.updateRole(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export { addRole, updateRole, getRolesList };
