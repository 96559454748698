import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const UserDashboard = lazyLoader(
  () => import("Containers/User/UserDashboard/UserDashboard")
);

export const UserDashboardPage = () => {
  return (
    <Layout>
      <UserDashboard type="user" />
    </Layout>
  );
};
