import { createSlice } from "@reduxjs/toolkit";

import { CompanyPackage, Invoice } from "Shared/Types/fleetmanagementapi";

import { RootState } from "../store";
import { getCurrentInvoice, getInvoiceInfo, getInvoicesList } from "./reducer";

interface InvoiceSliceState {
  invoicesList: {
    loading: boolean;
    error: string | null;
    data: Invoice[];
  };
  invoiceInfo: {
    loading: boolean;
    error: string | null;
    data: CompanyPackage | null;
  };
  currentInvoice: {
    loading: boolean;
    error: string | null;
    data: Invoice | null;
  };
}

const initialState: InvoiceSliceState = {
  invoicesList: {
    loading: false,
    error: null,
    data: [],
  },
  invoiceInfo: {
    loading: false,
    error: null,
    data: null,
  },
  currentInvoice: {
    loading: false,
    error: null,
    data: null,
  },
};

const InvoiceSlice = createSlice({
  name: "InvoiceSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getInvoicesList.pending, (state) => {
      state.invoicesList.loading = true;
      state.invoicesList.error = null;
    });
    builder.addCase(getInvoicesList.rejected, (state) => {
      state.invoicesList.loading = false;
    });
    builder.addCase(getInvoicesList.fulfilled, (state, action) => {
      state.invoicesList.loading = false;
      state.invoicesList.error = null;
      state.invoicesList.data = action.payload.results;
    });
    builder.addCase(getInvoiceInfo.pending, (state) => {
      state.invoiceInfo.loading = true;
      state.invoiceInfo.error = null;
    });
    builder.addCase(getInvoiceInfo.rejected, (state) => {
      state.invoiceInfo.loading = false;
    });
    builder.addCase(getInvoiceInfo.fulfilled, (state, action) => {
      state.invoiceInfo.loading = false;
      state.invoiceInfo.error = null;
      state.invoiceInfo.data = action.payload;
    });
    builder.addCase(getCurrentInvoice.pending, (state) => {
      state.currentInvoice.loading = true;
      state.currentInvoice.error = null;
    });
    builder.addCase(getCurrentInvoice.rejected, (state) => {
      state.currentInvoice.loading = false;
    });
    builder.addCase(getCurrentInvoice.fulfilled, (state, action) => {
      state.currentInvoice.loading = false;
      state.currentInvoice.error = null;
      state.currentInvoice.data = action.payload;
    });
  },
});

export const selectInvoicesList = (state: RootState) =>
  state.invoice.invoicesList.data;
export const selectInvoicesListLoading = (state: RootState) =>
  state.invoice.invoicesList.loading;

export const selectInvoiceInfo = (state: RootState) =>
  state.invoice.invoiceInfo.data;
export const selectInvoiceInfoLoading = (state: RootState) =>
  state.invoice.invoiceInfo.loading;

export const selectCurrentInvoice = (state: RootState) =>
  state.invoice.currentInvoice.data;
export const selectCurrentInvoiceLoading = (state: RootState) =>
  state.invoice.currentInvoice.loading;

export default InvoiceSlice;
