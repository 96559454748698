import React, { FC } from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const SettingsDashboardPage = lazyLoader(
  () => import("Containers/Settings/SettingsDashboard/SettingsDashboard")
);

export const SettingsDashboard: FC = () => {
  return (
    <Layout>
      <SettingsDashboardPage />
    </Layout>
  );
};
