import { client } from "Service/axios";
import {
  VEHICLES_ENGINE_CAM_TYPES,
  VEHICLES_ENGINE_CAM_TYPES_CREATE,
} from "Shared/Constants/apiRequest";
import { IAddCamType, ICamTypeSearchList } from "Shared/Types/camType";

class CamType {
  getCamTypeList(params: ICamTypeSearchList | null) {
    return client.get<any>(VEHICLES_ENGINE_CAM_TYPES, { params });
  }
  addCamType(data: IAddCamType) {
    return client.post<any>(VEHICLES_ENGINE_CAM_TYPES_CREATE, data);
  }
}

export default CamType;
