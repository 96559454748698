import { client } from "Service/axios";
import {
  VIDEOS,
  VIDEOS_SECTIONS,
  VIDEOS_SECTIONS_CATEGORIES,
} from "Shared/Constants/apiRequest";
import {
  PaginatedVideoSectionList,
  SectionCategory,
  TrainingVideo,
} from "Shared/Types/fleetmanagementapi";
import { PaginatedResponse } from "Shared/Types/shared";
import {
  ITrainingTypeSearchList,
  ITrainingVideosCategoryParams,
  ITrainingVideosParams,
} from "Shared/Types/trainint";

class Video {
  getVideoList(params: ITrainingVideosParams | null) {
    return client.get<PaginatedResponse<TrainingVideo[]>>(VIDEOS, { params });
  }
  getVideoListSections(params: ITrainingTypeSearchList | null) {
    return client.get<PaginatedVideoSectionList>(VIDEOS_SECTIONS, {
      params,
    });
  }
  getVideoListSectionsCategories(params: ITrainingVideosCategoryParams | null) {
    return client.get<PaginatedVideoSectionList>(VIDEOS_SECTIONS_CATEGORIES, {
      params,
    });
  }
}

export default Video;
