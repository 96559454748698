import { createSlice } from "@reduxjs/toolkit";
import { IFuelType } from "Shared/Types/fuelType";
import { RootState } from "../store";
import { addFuelType, getFuelTypeList } from "./FuelTypeReducer";

interface fuelTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  fuelTypeList: IFuelType[];
}

const initialState: fuelTypeState = {
  loading: false,
  error: null,
  fuelTypeList: [],
};

const fuelTypeSlice = createSlice({
  name: "fuelType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFuelTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.fuelTypeList = action.payload.results;
    });
    builder.addCase(addFuelType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addFuelType.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.fuelTypeList = [...state.fuelTypeList, action.payload];
    });
    builder.addCase(addFuelType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = fuelTypeSlice.actions;

export const selectFuelTypeList = (state: RootState) =>
  state.fuelType.fuelTypeList;
export const selectFuelTypeAuthLoading = (state: RootState) =>
  state.fuelType.loading;
export const selectFuelTypeAuthError = (state: RootState) =>
  state.fuelType.error;
export const selectFuelTypeAuthSuccess = (state: RootState) =>
  state.fuelType.success;

export default fuelTypeSlice;
