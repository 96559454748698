import React, { ReactElement, ReactNode } from "react";
import IconButton from "@mui/material/IconButton";
import Menu, { MenuProps as MuiMenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { ThreeDotsIcon } from "Assets";

import css from "./Meatballs.module.scss";

export type MeatballsCallback<T> = (
  value: T,
  optionKey: string | number,
) => void;

type MeatballItem = {
  label: string;
  key: string | number;
  icon?: ReactElement;
};
type Props<T> = Omit<MuiMenuProps, "onChange" | "open"> & {
  options: MeatballItem[];
  value: T;
  onChange: MeatballsCallback<T>;
  children?: ReactNode;
};
const Meatballs = <T,>({
  options,
  value,
  onChange,
  children,
  ...props
}: Props<T>) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (option: MeatballItem) => {
    setAnchorEl(null);
    return onChange(value, option.key);
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <IconButton
        classes={{ root: css.button }}
        aria-label="more"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {children ? children : <ThreeDotsIcon />}
      </IconButton>
      <Menu
        classes={{ list: css.menu }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        {...props}
      >
        {options.map((option) => (
          <MenuItem
            className={css.menu__item}
            key={option.key}
            onClick={() => handleClose(option)}
            disableRipple
          >
            {option.label}
            {option.icon}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default Meatballs;
