import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { readObj, write } from "Service/storage";
import { IAuthLogin, IAuthRefresh, IChangePassword } from "Shared/Types/auth";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import { IUserProfileData } from "Shared/Utils/yup/userProfileShema";
import {
  IReducerActionWithCallback,
  IReducerWithCallback,
} from "Shared/Types/shared";

const login = createAsyncThunk("account/login", async (data: IAuthLogin) => {
  try {
    const token = await api.auth.login(data);
    if (token.data.is_accepted_from_admin) {
      write("access", token.data.token);
      write("refresh", token.data.refresh);
      const me = await api.auth.getMe();
      notifySuccess("You successfully logged in!");
      return { ...token.data, ...me.data };
    } else {
      write("is_accepted_from_admin", token.data.is_accepted_from_admin);
      return {};
    }
  } catch (error) {
    notifyError("Incorrect email or password!");
    throw error;
  }
});

const getMe = createAsyncThunk("account/getMe", async () => {
  try {
    const response = await api.auth.getMe();
    const prevLocalData = readObj("account");
    write("account", { ...prevLocalData, ...response.data });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const updateMe = createAsyncThunk(
  "account/updateMe",
  async (
    {
      data,
      onError,
      onSuccess,
    }: IReducerWithCallback<Omit<IUserProfileData, "email">>,
    { dispatch },
  ) => {
    try {
      const response = await api.auth.updateMe(data);
      dispatch(getMe());
      onSuccess && onSuccess();
      const prevLocalData = readObj("account");
      write("account", { ...prevLocalData, ...response.data });
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const deleteMeProfileImage = createAsyncThunk(
  "account/deleteMeImage",
  async ({ onError, onSuccess }: IReducerActionWithCallback, { dispatch }) => {
    try {
      const response = await api.auth.deleteMeProfileImage();
      dispatch(getMe());
      onSuccess && onSuccess();
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const refresh = createAsyncThunk(
  "account/refresh",
  async (data: IAuthRefresh) => {
    try {
      const response = await api.auth.refresh(data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const register = createAsyncThunk(
  "account/register",
  async (data: { values: IAuthLogin; onSuccess: () => void }) => {
    try {
      const response = await api.auth.register(data.values);
      data.onSuccess();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const changePassword = createAsyncThunk(
  "account/changePassword",
  async ({
    data,
    onSuccess,
    onError,
  }: IReducerWithCallback<IChangePassword>) => {
    try {
      const response = await api.auth.changePassword(data);
      onSuccess && onSuccess();
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);
const restoreSession = createAsyncThunk(`account/restoreSession`, async () => {
  const profile = readObj("account");

  return { profile };
});

export {
  login,
  refresh,
  restoreSession,
  register,
  changePassword,
  getMe,
  updateMe,
  deleteMeProfileImage,
};
