import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IVendorsTypeSearchList } from "Shared/Types/purchaseVendor";

const getPurchaseVendorList = createAsyncThunk(
  "venderType/getVenderList",
  async (params: IVendorsTypeSearchList | null) => {
    try {
      const response = await api.vendorstype.getPurchaseVendorList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export { getPurchaseVendorList };
