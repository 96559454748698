import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const InspectionsFormsDashboard = lazyLoader(
  () =>
    import(
      "Containers/Inspections/InspectionsFormsDashboard/InspectionsFormsDashboard"
    )
);

export const InspectionFormsList = () => {
  return (
    <Layout>
      <InspectionsFormsDashboard />
    </Layout>
  );
};
