import React, { useEffect } from "react";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { Layout } from "Components/Layout/Layout";
import { useSelector } from "react-redux";
import { selectVendor } from "Redux/Vendors/slice";
import { useParams } from "react-router";
import { useAppDispatch } from "Redux/store";
import { getVendor } from "Redux/Vendors/reducer";
import { lazyLoader } from "Service/loader";
const VendorForm = lazyLoader(
  () => import("Components/Vendor/VendorForm/VendorForm")
);

export const EditVendor = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Vendors", link: "/vendor" },
    { title: "Edit  Vendor", link: "" },
  ];
  const vendor = useSelector(selectVendor);
  const dispatch = useAppDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(getVendor(id));
    }
  }, [id]);
  return (
    <Layout>
      {!!vendor && <VendorForm vendor={vendor} breads={breads} type="EDIT" />}
    </Layout>
  );
};
