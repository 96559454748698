import { createSlice } from "@reduxjs/toolkit";
import {
  ContactRenewalList,
  ContactRenewalRetrieve,
  ContactRenewalTypeList,
} from "Shared/Types/fleetmanagementapi";
import {
  getReminderConcactTypes,
  getReminderContactById,
  getReminderContactList,
} from "./reducer";
import { RootState } from "Redux/store";

type State = {
  loading: boolean;
  reminderContactList: ContactRenewalList[];
  currentContactReminder: ContactRenewalRetrieve | null;
  count: number;
  types: ContactRenewalTypeList[];
};

const initialState: State = {
  loading: false,
  reminderContactList: [],
  currentContactReminder: null,
  count: 0,
  types: [],
};

const reminderContactSlice = createSlice({
  name: "reminder",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getReminderContactList.fulfilled, (state, action) => {
      state.loading = false;
      state.reminderContactList = action.payload.results;
      state.count = action.payload.count;
    });
    builder.addCase(getReminderContactById.fulfilled, (state, action) => {
      state.loading = false;
      state.currentContactReminder = action.payload;
    });
    builder.addCase(getReminderConcactTypes.fulfilled, (state, action) => {
      state.types = action.payload.results;
    });
  },
});

export const selectReminderContactList = (state: RootState) =>
  state.reminderContact.reminderContactList;
export const selectCurrentReminderContact = (state: RootState) =>
  state.reminderContact.currentContactReminder;
export const selectReminderContactLoading = (state: RootState) =>
  state.reminderContact.loading;
export const selectReminderContactCount = (state: RootState) =>
  state.reminderContact.count;
export const selectReminderContactTypes = (state: RootState) =>
  state.reminderContact.types;

export default reminderContactSlice;
