import { FC, lazy } from "react";

const REFRESH_TIMEOUT = 10 * 60 * 1000;

export const lazyRetry = <T>(
  componentImport: () => Promise<{
    default: FC<T>;
  }>
): Promise<{
  default: FC<T>;
}> =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem(storageKey) || "false"
    );
    componentImport()
      .then((component) => {
        if (!component) {
          window.sessionStorage.setItem(storageKey, "true");
          setTimeout(() => {
            window.sessionStorage.removeItem(storageKey);
          }, REFRESH_TIMEOUT);
          if (!hasRefreshed) window.location.reload();
          return;
        }
        window.sessionStorage.removeItem(storageKey);
        resolve(component);
      })
      .catch((error: Error) => {
        if (!hasRefreshed) {
          window.sessionStorage.setItem(storageKey, "true");
          setTimeout(() => {
            window.sessionStorage.removeItem(storageKey);
          }, REFRESH_TIMEOUT);
          window.location.reload();
        }
        reject(error);
      });
  });

export const lazyLoader = <T>(
  componentImport: () => Promise<{
    default: FC<T>;
  }>
) => lazy(() => lazyRetry(componentImport));
