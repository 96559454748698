import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddGroupType, IGroupTypeSearchList } from "Shared/Types/group";
import { IDeleteReducerWithCallback } from "../../Shared/Types/shared";

const getGroupTypeList = createAsyncThunk(
  "groupType/getGroupTypeList",
  async (params: IGroupTypeSearchList | null) => {
    try {
      const response = await api.groupType.getGroupTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addGroupType = createAsyncThunk(
  "groupType/addGroupType",
  async (data: IAddGroupType, { dispatch }) => {
    try {
      const response = await api.groupType.addGroupType(data);
      dispatch(getGroupTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const deleteGroupType = createAsyncThunk(
  "groupType/deleteGroupType",
  async (
    { id, onError, onSuccess }: IDeleteReducerWithCallback,
    { dispatch },
  ) => {
    try {
      const response = await api.groupType.deleteGroupType(id);
      dispatch(getGroupTypeList(null));
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export { addGroupType, getGroupTypeList, deleteGroupType };
