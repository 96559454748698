import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const ReportFuelSummaryByLocationList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportFuelSummaryByLocation/ReportFuelSummaryByLocationList/ReportFuelSummaryByLocationList"
    )
);

export const ReportFuelSummaryByLocation = () => {
  return (
    <Layout>
      <ReportFuelSummaryByLocationList />
    </Layout>
  );
};
