import React, { ReactNode, Suspense } from "react";
import { Loader } from "Components/UI/Loader";
import styles from "./Layout.module.scss";
import cn from "classnames";

type SuspenceProps = {
  children: ReactNode;
};

const Suspence: React.FC<SuspenceProps> = ({ children }) => {
  return (
    <Suspense
      fallback={
        <div className={cn(styles.loader, styles.loader__outerLayout)}>
          <Loader />
        </div>
      }
    >
      <div>{children}</div>
    </Suspense>
  );
};
export default Suspence;
