import { client } from "Service/axios";
import { VENDORS } from "Shared/Constants/apiRequest";
import { IVendorsTypeSearchList } from "Shared/Types/purchaseVendor";

class PurchaseVendorType {
  getPurchaseVendorList(params: IVendorsTypeSearchList | null) {
    return client.get<any>(VENDORS, { params });
  }
}

export default PurchaseVendorType;
