import React, { FC } from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const IssueAdd = lazyLoader(() => import("Containers/Issue/IssueAdd/IssueAdd"));

export const IssueAddPage: FC = () => {
  return (
    <Layout>
      <IssueAdd />
    </Layout>
  );
};
