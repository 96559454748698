import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

const ReportsCostComparisonInServiceList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsCostComparisonInService/ReportsCostComparisonInServiceList/ReportsCostComparisonInServiceList"
    )
);

export const ReportsCostComparisonInService = () => {
  return (
    <Layout>
      <ReportsCostComparisonInServiceList />
    </Layout>
  );
};
