import React from "react";
import Suspence from "Components/Layout/Suspence";
import { lazyLoader } from "Service/loader";
const VerificationCode = lazyLoader(
  () => import("Containers/Register/RegisterStep2/VerificationCode")
);

export const VerificationPage = () => {
  return (
    <Suspence>
      <VerificationCode />
    </Suspence>
  );
};
