import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const ServiceTaskEdit = lazyLoader(
  () => import("Containers/ServiceTasks/ServiceTaskAddEdit/ServiceTaskEdit")
);

export const ServiceTaskEditPage = () => {
  return (
    <Layout>
      <ServiceTaskEdit />
    </Layout>
  );
};
