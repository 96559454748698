import { client } from "Service/axios";
import { EXPENSE } from "Shared/Constants/apiRequest";
import { PaginatedExpenseListList } from "Shared/Types/fleetmanagementapi";

class Expense {
  getExpenseList() {
    return client.get<PaginatedExpenseListList>(EXPENSE);
  }
}

export default Expense;
