import { Breadcrumbs } from "@mui/material";
import React, { ReactNode } from "react";
import styles from "./Breadcrumb.module.scss";
import { Link } from "react-router-dom";
import cn from "classnames";
import ImportExportButton from "Components/ImportExportFiles/ImportExportButton/ImportExportButton";

export type BreadcrumbItem = {
  title: string;
  link: string;
};
type BreadcrumbProps = {
  breads: BreadcrumbItem[];
  children?: ReactNode;
  withLine?: boolean;
  isNeedImport?: boolean;
  isNeedExport?: boolean;
  currentParams?: Record<string, string>;
  /** Use keys from importSectionNames object */
  importLink?: string;
};

const Breadcrumb: React.FC<BreadcrumbProps> = ({
  breads,
  children,
  withLine,
  currentParams,
  isNeedExport = false,
  isNeedImport = false,
  importLink,
}) => {
  return (
    <div
      className={cn(
        styles.breadcrumb_cont,
        withLine && styles.breadcrumb_cont_withLine
      )}
    >
      <Breadcrumbs separator="&#124;" className={styles.breadcrumb}>
        {breads.map(({ title, link }, i) =>
          i === breads.length - 1 ? (
            <div className={styles.breadcrumb__current_text} key={title}>
              <span className={styles.point}></span>
              <span>{title}</span>
            </div>
          ) : (
            <Link className={styles.breadcrumb__text} key={title} to={link}>
              {title}
            </Link>
          )
        )}
      </Breadcrumbs>
      <div className={styles.breadcrumb__buttons}>
        <ImportExportButton
          isExport={isNeedExport}
          currentParams={currentParams}
          isImport={isNeedImport}
          link={importLink || ""}
        />
        {children}
      </div>
    </div>
  );
};
export default Breadcrumb;
