import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

const ReportsRenewalRemindersList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsRenewalReminders/ReportsRenewalRemindersList/ReportsRenewalRemindersList"
    )
);
export const ReportsRenewalReminders = () => {
  return (
    <Layout>
      <ReportsRenewalRemindersList />
    </Layout>
  );
};
