import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const VendorDetails = lazyLoader(
  () => import("Containers/Vendors/VendorDetails/VendorDetails")
);

export const VendorDetail = () => {
  return (
    <Layout>
      <VendorDetails />
    </Layout>
  );
};
