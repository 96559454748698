import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddFuelType, IFuelTypeSearchList } from "Shared/Types/fuelType";

const getFuelTypeList = createAsyncThunk(
  "fuelType/getFuelTypeList",
  async (params: IFuelTypeSearchList | null) => {
    try {
      const response = await api.fuelType.getFuelTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addFuelType = createAsyncThunk(
  "fuelType/addFuelType",
  async (data: IAddFuelType) => {
    try {
      const response = await api.fuelType.addFuelType(data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addFuelType, getFuelTypeList };
