import { createAsyncThunk } from "@reduxjs/toolkit";
import { aiGeneretedText } from "Shared/Constants/ai";

const generateTextFromImage = createAsyncThunk(
  "ai/generateTextFromImage",
  async () => {
    try {
      const result = await new Promise<string>((resolve) => {
        setTimeout(() => {
          const result = aiGeneretedText;
          resolve(result);
        }, 1000); // Simulates a 1-second delay
      });
      return result;
    } catch (error) {
      console.error("Error generating text from image:", error);
      throw error;
    }
  }
);

export { generateTextFromImage };
