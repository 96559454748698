import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { TGoogleMapType } from "Shared/Types/googleMap";

const getGooglePosition = createAsyncThunk(
  "googleMap/getGooglePosition",
  async (geoParams: TGoogleMapType) => {
    try {
      const response = await api.googleMap.getGooglePosition(geoParams);
      return response.data.display_name;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export { getGooglePosition };
