import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getPurchaseOrder,
  getPurchaseOrderFiles,
  getPurchaseOrdersList,
} from "./reducer";
import {
  IPurchaseOrder,
  IPurchaseOrderDetail,
} from "Shared/Types/purchaseOrder";
import { IFile } from "Shared/Types/file";

interface PurchaseOrdersSliceState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  purchaseOrdersList: IPurchaseOrder[];
  currentPurchaseOrder: IPurchaseOrderDetail | null;
  purchaseOrderFiles: IFile[];
  count: number;
}

const initialState: PurchaseOrdersSliceState = {
  loading: false,
  error: null,
  purchaseOrdersList: [],
  currentPurchaseOrder: null,
  purchaseOrderFiles: [],
  count: 0,
};

const purchaseOrdersSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    clearCurrentPurchaseOrder(state) {
      state.currentPurchaseOrder = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchaseOrdersList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.purchaseOrdersList = action.payload.results;
      state.count = action.payload.count;
    });
    builder.addCase(getPurchaseOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentPurchaseOrder = {
        ...action.payload,
        line_items: action.payload.line_items.map((item) => ({
          ...item,
          amount: item.quantity * item.unit_cost,
        })),
      };
    });
    builder.addCase(getPurchaseOrderFiles.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.purchaseOrderFiles = action.payload;
    });
  },
});

export const { clear, clearCurrentPurchaseOrder } = purchaseOrdersSlice.actions;

export const selectPurchaseOrdersList = (state: RootState) =>
  state.purchaseOrder.purchaseOrdersList;

export const selectCurrentPurchaseOrder = (state: RootState) =>
  state.purchaseOrder.currentPurchaseOrder;
export const selectPurchaseOrderCount = (state: RootState) =>
  state.purchaseOrder.count;
export const selectPurchaseOrderLoading = (state: RootState) =>
  state.purchaseOrder.loading;
export const selectPurchaseOrderFiles = (state: RootState) =>
  state.purchaseOrder.purchaseOrderFiles;
export const selectPurchaseOrderError = (state: RootState) =>
  state.purchaseOrder.error;
export const selectPurchaseOrderSuccess = (state: RootState) =>
  state.purchaseOrder.success;

export default purchaseOrdersSlice;
