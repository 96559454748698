import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const PartsListDashboard = lazyLoader(
  () => import("Containers/Parts/PartsList/Parts/PartsListDashboard")
);

export const PartDashboard = () => {
  return (
    <Layout>
      <PartsListDashboard />
    </Layout>
  );
};
