import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { generateTextFromImage } from "./reducer";

interface AIState {
  loading: boolean;
  imageGenerated?: string | null;
}

const initialState: AIState = {
  loading: false,
};

const aiSlice = createSlice({
  name: "aiSlice",
  initialState,
  reducers: {
    clearAi(state) {
      state.loading = false;
      state.imageGenerated = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateTextFromImage.fulfilled, (state, action) => {
      state.loading = false;
      state.imageGenerated = action.payload;
      console.log("test slice", action.payload);
    });
    builder.addCase(generateTextFromImage.pending, (state) => {
      state.loading = true;
    });
  },
});

export const { clearAi } = aiSlice.actions;

export const selectAILoading = (state: RootState) => state.ai.loading;
export const selectAIGeneratedText = (state: RootState) =>
  state.ai.imageGenerated;

export default aiSlice;
