import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddBrakeType, IBrakeypeSearchList } from "Shared/Types/brakeType";

const getBrakeTypeList = createAsyncThunk(
  "brakeType/getBrakeTypeList",
  async (params: IBrakeypeSearchList | null) => {
    try {
      const response = await api.brakeType.getBrakeTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addBrakeType = createAsyncThunk(
  "brakeType/addBrakeType",
  async (data: IAddBrakeType, { dispatch }) => {
    try {
      const response = await api.brakeType.addBrakeType(data);
      dispatch(getBrakeTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addBrakeType, getBrakeTypeList };
