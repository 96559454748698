import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const SamsaraInfo = lazyLoader(
  () => import("Containers/SamsaraIntegration/SamsaraInfo/SamsaraInfo")
);

export const SamsaraInfoPage = () => {
  return (
    <Layout>
      <SamsaraInfo />
    </Layout>
  );
};
