import { createSlice } from "@reduxjs/toolkit";
import { IBlockType } from "Shared/Types/blockType";
import { RootState } from "../store";
import { IAssignment } from "../../Shared/Types/assignment";
import { getAssignment, getAssignmentsList } from "./reducer";

interface BlockTypeSliceState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  assignmentsList: IAssignment[];
  currentAssignment: IAssignment | null;
}

const initialState: BlockTypeSliceState = {
  loading: false,
  error: null,
  assignmentsList: [],
  currentAssignment: null,
};

const AssignmentSlice = createSlice({
  name: "AssignmentSlice",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    clearCurrentAssignment(state) {
      state.currentAssignment = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssignmentsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.assignmentsList = action.payload.results;
    });
    builder.addCase(getAssignment.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentAssignment = action.payload;
    });
  },
});

export const { clear, clearCurrentAssignment } = AssignmentSlice.actions;

export const selectAssignmentsList = (state: RootState) =>
  state.assignment.assignmentsList;

export const selectCurrentAssignment = (state: RootState) =>
  state.assignment.currentAssignment;
export const selectAssignmentLoading = (state: RootState) =>
  state.assignment.loading;
export const selectAssignmentError = (state: RootState) =>
  state.assignment.error;
export const selectAssignmentSuccess = (state: RootState) =>
  state.assignment.success;

export default AssignmentSlice;
