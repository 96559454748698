import { client } from "Service/axios";
import {
  INSPECTIONS,
  CREATE_INSPECTIONS_FORMS,
  INSPECTIONS_FAILED_ITEMS,
  INSPECTIONS_SUBMIT,
  INSPECTIONS_FORMS,
  INSPECTIONS_ID,
  INSPECTIONS_FORMS_ID,
  DELETE_INSPECTIONS_FORMS_ID,
  UPDATE_INSPECTIONS_FORMS_ID,
  CREATE_INSPECTIONS_FORMS_ID_ITEMS,
  INSPECTIONS_FAILED_ITEMS_ID_ACKNOWLEDGE,
  GET_INSPECTIONS_FORMS_ID_ITEMS,
  INSPECTIONS_FORMS_ID_ARCHIVE,
  INSPECTIONS_FORMS_ID_UNARCHIVE,
  UPDATE_INSPECTIONS_FORMS_ID_ITEMS,
  INSPECTIONS_FORMS_ID_DELETE,
} from "Shared/Constants/apiRequest";
import {
  IInspectionCreate,
  IInspectionFailedItemList,
  IInspectionFormList,
  IInspectionSearchParams,
  IInspectionFormsSearch,
  IInspectionItemsOptionalSearch,
  IInspectionItem,
  IAcknowledgeInspectionPost,
  IInspectionForm,
} from "Shared/Types/inspections";
import {
  InspectionFormDetailsRead,
  InspectionFormWrite,
  InspectionReadItem,
  InspectionSubmissionDetailItems,
} from "Shared/Types/fleetmanagementapi";
import { InspectionFormWriteRequest } from "Shared/Types/fleetmanagementapi";
import { PaginatedResponse } from "Shared/Types/shared";
import { TInspectionCreateItems } from "Shared/Utils/yup/inspection";

class Inspections {
  getInspectionList(params: IInspectionSearchParams | null) {
    return client.get<IInspectionFormList | IInspectionForm>(INSPECTIONS, {
      params,
    });
  }

  getInspectionById(id: string) {
    return client.get<InspectionSubmissionDetailItems>(INSPECTIONS_ID(id));
  }

  getInspectionFailedItems() {
    return client.get<IInspectionFailedItemList>(INSPECTIONS_FAILED_ITEMS);
  }

  // TODO: create type
  submitInspection(data: any) {
    return client.post(INSPECTIONS_SUBMIT, data);
  }

  getInspectionFormsList(params?: IInspectionSearchParams) {
    return client.get<IInspectionFormList>(INSPECTIONS_FORMS, { params });
  }

  getInspectoinFormById(id: string) {
    return client.get<InspectionFormDetailsRead>(INSPECTIONS_FORMS_ID(id));
  }

  createInspectionForm(data: IInspectionCreate) {
    return client.post<InspectionFormWrite>(CREATE_INSPECTIONS_FORMS, data);
  }

  deleteInpectionForm(id: string) {
    return client.delete(DELETE_INSPECTIONS_FORMS_ID(id));
  }
  deleteInspectionForm(id: string) {
    return client.delete(INSPECTIONS_FORMS_ID_DELETE(id));
  }

  updateInspectoinForm(id: string, data: IInspectionCreate) {
    return client.patch(UPDATE_INSPECTIONS_FORMS_ID(id), data);
  }

  getInspectionFormItems(id: string) {
    return client.get<PaginatedResponse<InspectionReadItem[]>>(
      GET_INSPECTIONS_FORMS_ID_ITEMS(id),
    );
  }

  createInspectionFormItems(id: string, data: TInspectionCreateItems) {
    return client.post(CREATE_INSPECTIONS_FORMS_ID_ITEMS(id), data);
  }

  updateInspectionFormItems(id: string, data: TInspectionCreateItems) {
    return client.patch(UPDATE_INSPECTIONS_FORMS_ID_ITEMS(id), data);
  }

  getInspectionForms(params: IInspectionFormsSearch | null) {
    return client.get<PaginatedResponse<InspectionFormWriteRequest[]>>(
      INSPECTIONS_FORMS,
      {
        params,
      },
    );
  }

  getInspectionItems(params?: IInspectionItemsOptionalSearch) {
    return client.get<PaginatedResponse<IInspectionItem[]>>(
      INSPECTIONS_FAILED_ITEMS,
      {
        params: params,
      },
    );
  }

  setAcknowledgeStageToInspection({
    id,
    acknowledged_comment,
  }: IAcknowledgeInspectionPost) {
    return client.post<any>(INSPECTIONS_FAILED_ITEMS_ID_ACKNOWLEDGE(id), {
      acknowledged_comment,
    });
  }

  archiveInspectionForm(id: string) {
    return client.post(INSPECTIONS_FORMS_ID_ARCHIVE(id));
  }
  unarchiveInspectionForm(id: string) {
    return client.post(INSPECTIONS_FORMS_ID_UNARCHIVE(id));
  }
}

export default Inspections;
