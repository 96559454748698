import { client } from "Service/axios";
import {
  VEHICLES_WHEELS_TIRES_DRIVE_TYPES,
  VEHICLES_WHEELS_TIRES_DRIVE_TYPES_CREATE,
} from "Shared/Constants/apiRequest";
import { IDriveTypeListSearch } from "Shared/Types/drive";
import { IAddFuelType } from "Shared/Types/fuelType";

class DriveType {
  getDriveTypeList(params: IDriveTypeListSearch | null) {
    return client.get<any>(VEHICLES_WHEELS_TIRES_DRIVE_TYPES, { params });
  }
  addDriveType(data: IAddFuelType) {
    return client.post<any>(VEHICLES_WHEELS_TIRES_DRIVE_TYPES_CREATE, data);
  }
}

export default DriveType;
