import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const VendorsDashboard = lazyLoader(
  () => import("Containers/Vendors/VendorsDashboard/VendorsDashboard")
);

export const Vendor = () => {
  return (
    <Layout>
      <VendorsDashboard />
    </Layout>
  );
};
