import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const StartInspectionForm = lazyLoader(
  () => import("Containers/Inspections/StartInspectionForm")
);

export const StartInspection = () => {
  return (
    <Layout>
      <StartInspectionForm />
    </Layout>
  );
};
