import { createSlice } from "@reduxjs/toolkit";
import { IBlockType } from "Shared/Types/blockType";
import { RootState } from "../store";
import { addBlockType, getBlockTypeList } from "./BlcokTypeReducer";

interface BlockTypeSliceState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  blockTypeSliceList: IBlockType[];
}

const initialState: BlockTypeSliceState = {
  loading: false,
  error: null,
  blockTypeSliceList: [],
};

const BlockTypeSlice = createSlice({
  name: "BlockTypeSlice",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBlockTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.blockTypeSliceList = action.payload.results;
    });
    builder.addCase(addBlockType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addBlockType.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addBlockType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = BlockTypeSlice.actions;

export const selectBlockTypeList = (state: RootState) =>
  state.blockType.blockTypeSliceList;
export const selectBlockAuthLoading = (state: RootState) =>
  state.blockType.loading;
export const selectBlockAuthError = (state: RootState) => state.blockType.error;
export const selectBlockAuthSuccess = (state: RootState) =>
  state.blockType.success;

export default BlockTypeSlice;
