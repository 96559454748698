import { client } from "Service/axios";
import {
  REPORTS_COST_COMPARISON_BY_YEAR_IN_SERVICE,
  REPORTS_INSPECTIONS_SUBMISSION_LIST,
  REPORTS_OPERATING_COST_SUMMARY,
  REPORTS_PARTS_BY_VEHICLE,
  REPORTS_PURCHASE_ORDERS_LIST,
  REPORTS_SERVICE_ENTRIES_SUMMARY,
  REPORTS_SERVICE_REMINDER_COMPLIANCE,
  REPORTS_VEHICLE_ASSIGNMENT_SUMMARY,
  REPORTS_VEHICLE_LIST_OF_UNITS,
  REPORTS_VEHICLE_STATUS_CHANGES,
  REPORTS_VEHICLE_STATUS_SUMMARY,
} from "Shared/Constants/apiRequest";

import {
  REPORTS_CATALOGUES,
  REPORTS_CATALOGUES_LIST,
  REPORTS_INSPECTIONS_FAILED_INSPECTIONS,
  REPORTS_INSPECTIONS_SUBMISSION_SUMMARY,
  REPORTS_ISSUES_FAULTS_SUMMARY,
  REPORTS_ISSUES_LIST,
  REPORTS_PARTS_BY_LOCATION,
  REPORTS_SERVICES_REMINDERS,
  REPORTS_SERVICES_WITHOUT_SERVICE,
  REPORTS_USERS_LIST,
  REPORTS_USERS_RENEWAL_REMINDERS,
  REPORTS_VEHICLE_ASSIGNMENTS_LOG,
  REPORTS_VEHICLE_DETAILS,
  REPORTS_VEHICLES_RENEWAL_REMINDERS,
  REPORTS_WORK_ORDERS_BY_VEHICLE,
  REPORTS_WORK_ORDERS_LABOR_LIST,
  REPORTS_WORK_ORDERS_LIST,
  REPORTS_VEHICLE_DETAILS_ID,
  REPORTS_PARTS_ACTIVITY,
  REPORTS_SERVICE_HISTORY_BY_VEHICLE,
  REPORTS_FUEL_ENTRIES_BY_VEHICLE,
  REPORTS_FUEL_SUMMARY,
  REPORTS_FUEL_SUMMARY_BY_LOCATION,
} from "Shared/Constants/routes";

import {
  IReportLimit,
  IReportList,
  IReportCataloguesList,
  IReportParams,
  IReportListOfUnitsParams,
  IReportListOfUnits,
  IReportStatusChangeParams,
  IReportStatusChanges,
  IReportsStatusSummary,
  IReportStatusSummaryParams,
  IReportRenewalRemindersParams,
  IReportsRenewalReminders,
  ICostComparisonInServiceParams,
  TReportCostComparisonInService,
  IReportsAssignmentsLogParams,
  IReportsAssignmentsLog,
  IReportsAssignmentsSummaryParams,
  TReportsAssignmentsSummary,
  IReportInspectionsSubmissionSummaryParams,
  IReportInspectionsSubmissionSummary,
  IReportsInspectionSubmissionListParams,
  IReportInspectionsSubmissionList,
  IReportsInspectionsFailedParams,
  IReportsInspectionsFailed,
  IReportsVehicleDetailParams,
  IReportsVehiclesDetail,
  IReportsOperatingCostSummaryParams,
  IReportsOperatingCostSummary,
  IReportsIssueListParams,
  IReportsIssuesList,
  IReportsServiceRemindersParams,
  IReportsServiceReminders,
  IReportsWorkOrdersParams,
  IReportWorkOrdersList,
  IReportServiceReminderComplianceParams,
  IReportsWorkOrdersByVehicle,
  IReportsIssueFaultsSummary,
  IReportsReminderServiceComplianceList,
  IReportUsersRenewalReminders,
  IReportWorkOrderLaborList,
  IReportPartsByLocation,
  IReportsUserList,
  IReportPartsByVehicleParams,
  IReportServicesWithoutService,
  IReportVehiclesDetailId,
  IReportPartVehiclePaginated,
  IReportPartsActivity,
  IReportServiceHistorySummary,
  IReportPurchaseOrdersList,
  IReportPurchaseOrdersListParams,
  IReportsServiceHistoryByVehicle,
  IReportFuelEntriesByVehicle,
  IReportFuelSummary,
  IReportFuelSummaryByLocation,
} from "Shared/Types/reports";

class Reports {
  getCatalogues(params: IReportLimit | null) {
    return client.get<IReportCataloguesList>(REPORTS_CATALOGUES, { params });
  }

  getReportsListCatalogues(params: IReportParams | null) {
    return client.get<IReportList>(REPORTS_CATALOGUES_LIST, { params });
  }

  getReportsListOfUnits(params: IReportListOfUnitsParams | null) {
    return client.get<IReportListOfUnits>(REPORTS_VEHICLE_LIST_OF_UNITS, {
      params,
    });
  }

  getReportsServiceHistorySummary(params: any) {
    return client.get<IReportServiceHistorySummary>(
      REPORTS_SERVICE_ENTRIES_SUMMARY,
      {
        params,
      },
    );
  }

  getReportsServicesWithoutService(params: any) {
    return client.get<IReportServicesWithoutService>(
      REPORTS_SERVICES_WITHOUT_SERVICE,
      { params },
    );
  }

  getReportsStatusChanges(params: IReportStatusChangeParams | null) {
    return client.get<IReportStatusChanges>(REPORTS_VEHICLE_STATUS_CHANGES, {
      params,
    });
  }

  getReportsStatusSummary(params: IReportStatusSummaryParams | null) {
    return client.get<IReportsStatusSummary>(REPORTS_VEHICLE_STATUS_SUMMARY, {
      params,
    });
  }

  getReportsRenewalReminders(params: IReportRenewalRemindersParams | null) {
    return client.get<IReportsRenewalReminders>(
      REPORTS_VEHICLES_RENEWAL_REMINDERS,
      { params },
    );
  }

  getReportsUserList(params: any) {
    return client.get<IReportsUserList>(REPORTS_USERS_LIST, { params });
  }

  getReportsPartsByLocation(params: any) {
    return client.get<IReportPartsByLocation>(REPORTS_PARTS_BY_LOCATION, {
      params,
    });
  }
  getReportsPartsActivity(params: any) {
    return client.get<IReportPartsActivity>(REPORTS_PARTS_ACTIVITY, {
      params,
    });
  }
  getCostComparisonInService(params: ICostComparisonInServiceParams | null) {
    return client.get<TReportCostComparisonInService>(
      REPORTS_COST_COMPARISON_BY_YEAR_IN_SERVICE,
      { params },
    );
  }

  getReportsInspectionsSubmissionSummary(
    params: IReportInspectionsSubmissionSummaryParams | null,
  ) {
    return client.get<IReportInspectionsSubmissionSummary>(
      REPORTS_INSPECTIONS_SUBMISSION_SUMMARY,
      { params },
    );
  }

  getReportsAssignmentsLog(params: IReportsAssignmentsLogParams | null) {
    return client.get<IReportsAssignmentsLog>(REPORTS_VEHICLE_ASSIGNMENTS_LOG, {
      params,
    });
  }

  getReportsUsersRenewalReminders(params: any) {
    return client.get<IReportUsersRenewalReminders>(
      REPORTS_USERS_RENEWAL_REMINDERS,
      { params },
    );
  }

  getReportsAssignmentsSummary(
    params: IReportsAssignmentsSummaryParams | null,
  ) {
    return client.get<TReportsAssignmentsSummary>(
      REPORTS_VEHICLE_ASSIGNMENT_SUMMARY,
      { params },
    );
  }

  getReportsInspectionSubmissionList(
    params: IReportsInspectionSubmissionListParams | null,
  ) {
    return client.get<IReportInspectionsSubmissionList>(
      REPORTS_INSPECTIONS_SUBMISSION_LIST,
      { params },
    );
  }

  getReportsInspectionsFailed(params: IReportsInspectionsFailedParams | null) {
    return client.get<IReportsInspectionsFailed>(
      REPORTS_INSPECTIONS_FAILED_INSPECTIONS,
      { params },
    );
  }

  getReportsVehiclesDetail(params: IReportsVehicleDetailParams | null) {
    return client.get<IReportsVehiclesDetail>(REPORTS_VEHICLE_DETAILS, {
      params,
    });
  }

  getReportOperatingCostSummary(
    params: IReportsOperatingCostSummaryParams | null,
  ) {
    return client.get<IReportsOperatingCostSummary>(
      REPORTS_OPERATING_COST_SUMMARY,
      { params },
    );
  }

  getReportIssueList(params: IReportsIssueListParams | null) {
    return client.get<IReportsIssuesList>(REPORTS_ISSUES_LIST, { params });
  }

  getReportServiceReminders(params: IReportsServiceRemindersParams | null) {
    return client.get<IReportsServiceReminders>(REPORTS_SERVICES_REMINDERS, {
      params,
    });
  }

  getReportIssueFaultsSummary(params: any) {
    return client.get<IReportsIssueFaultsSummary>(
      REPORTS_ISSUES_FAULTS_SUMMARY,
      { params },
    );
  }

  getReportWorkOrderByVehicle(params: any) {
    return client.get<IReportsWorkOrdersByVehicle>(
      REPORTS_WORK_ORDERS_BY_VEHICLE,
      { params },
    );
  }
  getReportWorkOrdersList(params: IReportsWorkOrdersParams | null) {
    return client.get<IReportWorkOrdersList>(REPORTS_WORK_ORDERS_LIST, {
      params,
    });
  }

  getReportWorkOrdersLaborList(params: any) {
    return client.get<IReportWorkOrderLaborList>(
      REPORTS_WORK_ORDERS_LABOR_LIST,
      { params },
    );
  }

  getReportServiceHistoryByVehicle(params: any) {
    return client.get<IReportsServiceHistoryByVehicle>(
      REPORTS_SERVICE_HISTORY_BY_VEHICLE,
      { params },
    );
  }
  getReportServiceReminderCompliance(
    params: IReportServiceReminderComplianceParams | null,
  ) {
    return client.get<IReportsReminderServiceComplianceList>(
      REPORTS_SERVICE_REMINDER_COMPLIANCE,
      { params },
    );
  }

  getReportPartsByVehicle(params: IReportPartsByVehicleParams | null) {
    return client.get<IReportPartVehiclePaginated>(REPORTS_PARTS_BY_VEHICLE, {
      params,
    });
  }

  getReportVehicleDetailId(id: string, params: any) {
    return client.get<IReportVehiclesDetailId>(REPORTS_VEHICLE_DETAILS_ID(id), {
      params,
    });
  }

  getReportPurchaseOrdersList(params: IReportPurchaseOrdersListParams | null) {
    return client.get<IReportPurchaseOrdersList>(REPORTS_PURCHASE_ORDERS_LIST, {
      params,
    });
  }
  getReportFuelEntriesByVehicle(params: any) {
    return client.get<IReportFuelEntriesByVehicle>(
      REPORTS_FUEL_ENTRIES_BY_VEHICLE,
      {
        params,
      },
    );
  }
  getReportFuelSummary(params: any) {
    return client.get<IReportFuelSummary>(REPORTS_FUEL_SUMMARY, {
      params,
    });
  }

  getReportFuelSummaryByLocation(params: any) {
    return client.get<IReportFuelSummaryByLocation>(
      REPORTS_FUEL_SUMMARY_BY_LOCATION,
      {
        params,
      },
    );
  }
}

export default Reports;
