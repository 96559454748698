import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  getArchivedParts,
  getCurrentPart,
  getMeasurements,
  getPartManufactures,
  getParts,
  getPartsAdjustmentReasons,
  getPartsByLocation,
  getPartsCategories,
  getPartsLocations,
  getPartsMetaData,
  getPartsMetaDataByLocation,
  getPurchaseHistory,
  getWorkOrderActivity,
} from "./reducers";
import { PaginatedResponse } from "Shared/Types/shared";
import {
  ILocation,
  IMeasurement,
  IPart,
  IPartCategory,
  IPartManufacture,
  IPartMetaData,
  IReason,
} from "Shared/Types/parts";
import {
  PaginatedPartPurchaseHistoryList,
  PaginatedWorkOrderActivityByPartList,
} from "Shared/Types/fleetmanagementapi";

interface InitialState {
  location: {
    error?: string | null;
    loading: boolean;
    success?: boolean | null;
    locations: PaginatedResponse<ILocation[]>;
  };
  measurements: {
    error?: string | null;
    loading: boolean;
    success?: boolean | null;
    measurements: IMeasurement[];
  };
  part: {
    error?: string | null;
    loading: boolean;
    success?: boolean | null;
    purchaseHistoryCount: number;
    workOrderCount: number;
    parts: PaginatedResponse<IPart[]>;
    workOrderDetails: PaginatedWorkOrderActivityByPartList;
    purchaseHistoryDetails: PaginatedPartPurchaseHistoryList;
    currentPart: IPart | null;
  };
  category: {
    error?: string | null;
    loading: boolean;
    success?: boolean | null;
    categories: PaginatedResponse<IPartCategory[]>;
  };
  manufacture: {
    error?: string | null;
    loading: boolean;
    success?: boolean | null;
    manufactures: PaginatedResponse<IPartManufacture[]>;
  };
  meta_data: IPartMetaData;
  reasons: {
    error?: string | null;
    loading: boolean;
    success?: boolean | null;
    reasons: PaginatedResponse<IReason[]>;
  };
}

const initialState: InitialState = {
  location: {
    loading: false,
    error: null,
    success: null,
    locations: {
      count: 0,
      previous: "",
      next: "",
      results: [],
    },
  },
  part: {
    loading: false,
    error: null,
    purchaseHistoryCount: 0,
    success: null,
    workOrderCount: 0,
    parts: {
      count: 0,
      previous: "",
      next: "",
      results: [],
    },
    purchaseHistoryDetails: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    workOrderDetails: {
      count: 0,
      next: null,
      previous: null,
      results: [],
    },
    currentPart: null,
  },
  category: {
    loading: false,
    error: null,
    success: null,
    categories: {
      count: 0,
      previous: "",
      next: "",
      results: [],
    },
  },
  measurements: {
    loading: false,
    error: null,
    success: null,
    measurements: [],
  },
  manufacture: {
    loading: false,
    error: null,
    success: null,
    manufactures: {
      count: 0,
      previous: "",
      next: "",
      results: [],
    },
  },
  meta_data: {
    total_quantity: 0,
    out_of_stock: 0,
    low_stock: 0,
    total_inventory_value: 0,
  },
  reasons: {
    loading: false,
    error: null,
    success: null,
    reasons: {
      count: 0,
      previous: "",
      next: "",
      results: [],
    },
  },
};

const partSlice = createSlice({
  name: "part",
  initialState,
  reducers: {
    clear(state) {
      state.location = initialState.location;
    },
    clearCurrentPart(state) {
      state.part.currentPart = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPartsLocations.fulfilled, (state, action) => {
      state.location.loading = false;
      state.location.error = null;
      state.location.locations = action.payload;
      state.location.success = true;
    });
    builder.addCase(getPartsLocations.pending, (state) => {
      state.location.loading = true;
      state.location.error = null;
      state.location.success = false;
    });
    builder.addCase(getPartsLocations.rejected, (state, action) => {
      state.location.loading = false;
      state.location.error = action.error.message;
    });

    builder.addCase(getParts.fulfilled, (state, action) => {
      state.part.loading = false;
      state.part.error = null;
      state.part.parts = action.payload;
      state.part.success = true;
    });
    builder.addCase(getParts.pending, (state) => {
      state.part.loading = true;
      state.part.error = null;
      state.part.success = false;
    });
    builder.addCase(getParts.rejected, (state, action) => {
      state.part.loading = false;
      state.part.error = action.error.message;
    });

    builder.addCase(getPartsCategories.fulfilled, (state, action) => {
      state.category.loading = false;
      state.category.error = null;
      state.category.categories = action.payload;
      state.category.success = true;
    });
    builder.addCase(getPartsCategories.pending, (state) => {
      state.category.loading = true;
      state.category.error = null;
      state.category.success = false;
    });
    builder.addCase(getPartsCategories.rejected, (state, action) => {
      state.category.loading = false;
      state.category.error = action.error.message;
    });

    builder.addCase(getPartManufactures.fulfilled, (state, action) => {
      state.manufacture.loading = false;
      state.manufacture.error = null;
      state.manufacture.manufactures = action.payload;
      state.manufacture.success = true;
    });
    builder.addCase(getPartManufactures.pending, (state) => {
      state.manufacture.loading = true;
      state.manufacture.error = null;
      state.manufacture.success = false;
    });
    builder.addCase(getPartManufactures.rejected, (state, action) => {
      state.manufacture.loading = false;
      state.manufacture.error = action.error.message;
    });

    builder.addCase(getPartsByLocation.fulfilled, (state, action) => {
      state.part.loading = false;
      state.part.error = null;
      state.part.parts = action.payload;
      state.part.success = true;
    });
    builder.addCase(getPartsByLocation.pending, (state) => {
      state.part.loading = true;
      state.part.error = null;
      state.part.success = false;
    });
    builder.addCase(getPartsByLocation.rejected, (state, action) => {
      state.part.loading = false;
      state.part.error = action.error.message;
    });

    builder.addCase(getArchivedParts.fulfilled, (state, action) => {
      state.part.loading = false;
      state.part.error = null;
      state.part.parts = action.payload;
      state.part.success = true;
    });
    builder.addCase(getArchivedParts.pending, (state) => {
      state.part.loading = true;
      state.part.error = null;
      state.part.success = false;
    });
    builder.addCase(getArchivedParts.rejected, (state, action) => {
      state.part.loading = false;
      state.part.error = action.error.message;
    });

    builder.addCase(getPartsMetaData.fulfilled, (state, action) => {
      state.meta_data = action.payload;
    });
    builder.addCase(getPartsMetaDataByLocation.fulfilled, (state, action) => {
      state.meta_data = action.payload;
    });
    builder.addCase(getPartsAdjustmentReasons.fulfilled, (state, action) => {
      state.reasons.reasons = action.payload;
    });
    builder.addCase(getMeasurements.fulfilled, (state, action) => {
      state.measurements.loading = false;
      state.measurements.error = null;
      state.measurements.measurements = action.payload.results;
      state.measurements.success = true;
    });
    builder.addCase(getCurrentPart.fulfilled, (state, action) => {
      state.part.loading = false;
      state.part.error = null;
      state.part.currentPart = action.payload;
      state.part.success = true;
    });
    builder.addCase(getWorkOrderActivity.fulfilled, (state, action) => {
      state.part.loading = false;
      state.part.error = null;
      state.part.workOrderDetails = action.payload;
      state.part.workOrderCount = action.payload.count || 0;
      state.part.success = true;
    });
    builder.addCase(getWorkOrderActivity.pending, (state, action) => {
      state.part.loading = true;
      state.part.error = null;
      state.part.success = false;
    });
    builder.addCase(getWorkOrderActivity.rejected, (state, action) => {
      state.part.loading = false;
      state.part.error = action.error.message;
      state.part.success = false;
    });
    builder.addCase(getPurchaseHistory.fulfilled, (state, action) => {
      state.part.loading = false;
      state.part.error = null;
      state.part.purchaseHistoryCount = action.payload.count || 0;
      state.part.purchaseHistoryDetails = action.payload;
      state.part.success = true;
    });
    builder.addCase(getPurchaseHistory.pending, (state, action) => {
      state.part.loading = true;
      state.part.error = null;
      state.part.success = false;
    });
    builder.addCase(getPurchaseHistory.rejected, (state, action) => {
      state.part.loading = false;
      state.part.error = action.error.message;
      state.part.success = false;
    });
  },
});

export const { clear, clearCurrentPart } = partSlice.actions;

export const selectPartsLocations = (state: RootState) => state.part.location;
export const selectPartsAdjustmentReasons = (state: RootState) =>
  state.part.reasons;
export const selectPartsMeasurements = (state: RootState) =>
  state.part.measurements;
export const selectPartsList = (state: RootState) => state.part.part;
export const selectCurrentPart = (state: RootState) =>
  state.part.part.currentPart;
export const selectPartCategories = (state: RootState) => state.part.category;
export const selectPartManufacture = (state: RootState) =>
  state.part.manufacture;
export const selectPartsMetaData = (state: RootState) => state.part.meta_data;
export const selectPartsWorkOrderDetails = (state: RootState) =>
  state.part.part.workOrderDetails;
export const selectPartsWorkOrderCount = (state: RootState) =>
  state.part.part.workOrderCount;
export const selectPartLoading = (state: RootState) => state.part.part.loading;
export const selectPartPurchaseHistoryDetails = (state: RootState) =>
  state.part.part.purchaseHistoryDetails;
export default partSlice;
export const selectPartPurchaseHistoryCount = (state: RootState) =>
  state.part.part.purchaseHistoryCount;
