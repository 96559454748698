import { createSlice } from "@reduxjs/toolkit";
import { IAspiration } from "Shared/Types/aspiration";
import { RootState } from "../store";
import {
  addAspirationType,
  getAspirationTypeList,
} from "./AspirationTypeReducer";

interface IGroupTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  aspirationTypeList: IAspiration[];
}

const initialState: IGroupTypeState = {
  loading: false,
  error: null,
  aspirationTypeList: [],
};

const aspirationSlice = createSlice({
  name: "groupType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAspirationTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.aspirationTypeList = action.payload.results;
    });
    builder.addCase(addAspirationType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addAspirationType.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addAspirationType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = aspirationSlice.actions;

export const selectAspirationTypeList = (state: RootState) =>
  state.aspiration.aspirationTypeList;
export const selectAspirationAuthLoading = (state: RootState) =>
  state.aspiration.loading;
export const selectAspirationAuthError = (state: RootState) =>
  state.aspiration.error;
export const selectAspirationAuthSuccess = (state: RootState) =>
  state.aspiration.success;

export default aspirationSlice;
