import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IAcknowledgeInspectionPost,
  IInspectionFormsSearch,
  IInspectionItemsOptionalSearch,
} from "Shared/Types/inspections";
import { createMemoizedThunk } from "Shared/Utils/shared";
import {
  IInspectionCreate,
  IInspectionSearchParams,
} from "Shared/Types/inspections";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";
import { TInspectionCreateItems } from "Shared/Utils/yup/inspection";

const getInspections = createAsyncThunk(
  "inspection/getInspections",
  async (params: IInspectionSearchParams) => {
    try {
      const response = await api.inspections.getInspectionList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const getInspection = createAsyncThunk(
  "inspection/getInspection",
  async (id: string) => {
    try {
      const response = await api.inspections.getInspectionById(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const submitInspection = createAsyncThunk(
  "inspection/submitInspection",
  async (data: any) => {
    try {
      const response = await api.inspections.submitInspection(data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const getInspectionFormList = createAsyncThunk(
  "inspections/getInspectionFormList",
  async (params?: IInspectionSearchParams) => {
    try {
      const response = await api.inspections.getInspectionFormsList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const getInspectionFormListInfinite = createAsyncThunk(
  "inspections/getInspectionFormListInfinite",
  async (params?: IInspectionSearchParams) => {
    try {
      const response = await api.inspections.getInspectionFormsList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const getCurrentInspectionForm = createAsyncThunk(
  "inspections/getCurrentInspectionForm",
  async (id: string) => {
    try {
      const response = await api.inspections.getInspectoinFormById(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const createInspectionForm = createAsyncThunk(
  "inspection/createInspectionForm",
  async ({
    data,
    onSuccess,
    onError,
  }: IReducerWithCallback<IInspectionCreate>) => {
    try {
      const response = await api.inspections.createInspectionForm(data);
      onSuccess?.(response.data.id);
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

const updateInspectionForm = createAsyncThunk(
  "inspection/updateInspectionForm",
  async ({
    id,
    data,
    onSuccess,
    onError,
  }: IUpdateReducerWithCallback<IInspectionCreate>) => {
    try {
      const response = await api.inspections.updateInspectoinForm(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

const createInspectionFromItems = createAsyncThunk(
  "inspection/createInspectionFromItems",
  async ({
    data,
    onSuccess,
    id,
    onError,
  }: IUpdateReducerWithCallback<TInspectionCreateItems>) => {
    try {
      const response = await api.inspections.createInspectionFormItems(
        id,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

const updateInspectionFromItems = createAsyncThunk(
  "inspection/updateInspectionFromItems",
  async ({
    data,
    onSuccess,
    id,
    onError,
  }: IUpdateReducerWithCallback<TInspectionCreateItems>) => {
    try {
      const response = await api.inspections.updateInspectionFormItems(
        id,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

const getInspectionForms = createMemoizedThunk(
  "inspections/getInspectionForms",
  async (params: IInspectionFormsSearch | null) => {
    const response = await api.inspections.getInspectionForms(params);
    return response.data.results;
  }
);

const getInspectionFormItems = createAsyncThunk(
  "inspections/getInspectionFormItems",
  async (id: string) => {
    try {
      const response = await api.inspections.getInspectionFormItems(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

const getInspectionItems = createMemoizedThunk(
  "inspections/getInspectionItems",
  async (params: {
    type?: string;
    params?: IInspectionItemsOptionalSearch;
  }) => {
    const response = await api.inspections.getInspectionItems(params.params);
    return { type: params.type, data: response.data };
  }
);

const setAcknowledgeStageToInspection = createAsyncThunk(
  "inspections/setAcknowledgeStageToInspection",
  async ({
    id,
    acknowledged_comment,
    onSuccess,
    onError,
  }: IAcknowledgeInspectionPost) => {
    try {
      await api.inspections.setAcknowledgeStageToInspection({
        id,
        acknowledged_comment,
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      console.error(error);

      if (onError) {
        onError();
      }

      throw error;
    }
  }
);

const archieveInspectionForm = createAsyncThunk(
  "inspections/archieveInspectionForm",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.inspections.archiveInspectionForm(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      console.error(error);
      onError?.();
      throw error;
    }
  }
);

const unarchiveInspectionForm = createAsyncThunk(
  "inspections/unarchiveInspectionForm",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.inspections.unarchiveInspectionForm(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

const deleteInspectionForm = createAsyncThunk(
  "inspection/deleteInspectionForm",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.inspections.deleteInspectionForm(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export {
  deleteInspectionForm,
  getInspectionFormList,
  getInspectionFormListInfinite,
  createInspectionForm,
  getInspectionForms,
  getInspectionItems,
  setAcknowledgeStageToInspection,
  createInspectionFromItems,
  getCurrentInspectionForm,
  updateInspectionFromItems,
  updateInspectionForm,
  getInspectionFormItems,
  archieveInspectionForm,
  unarchiveInspectionForm,
  getInspection,
  getInspections,
  submitInspection,
};
