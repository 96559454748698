import { client } from "Service/axios";
import {
  FUEL_HISTORY,
  FUEL_HISTORY_CREATE,
  FUEL_HISTORY_CREATE_COMMENT,
  FUEL_HISTORY_DELETE,
  FUEL_HISTORY_DELETE_COMMENT,
  FUEL_HISTORY_DELETE_FILES,
  FUEL_HISTORY_ID,
  FUEL_HISTORY_MULTIPLE_DELETE,
  FUEL_HISTORY_STATISTICS,
  FUEL_HISTORY_UPDATE,
} from "Shared/Constants/apiRequest";
import {
  BulkFuelEntryRequest,
  FuelEntryCreate,
  FuelEntryListMod,
} from "Shared/Types/fleetmanagementapi";
import {
  IFuelHistorySearchList,
  TFuelHistory,
  TFuelHistoryStatistics,
} from "Shared/Types/fuelHistory";
import { PaginatedResponse } from "Shared/Types/shared";
import { IAddFiles } from "Shared/Types/file";
import { TFaultHistory } from "Shared/Utils/yup/fuelHistory";
import { IComment, IFuelHistoryCommentAdd } from "Shared/Types/comment";
import { FUEL_HISTORY_ADD_FILES } from "../../Shared/Constants/FUEL_HISTORY_ADD_FILES";

class FuelHistory {
  getFuelHistoryList(params: IFuelHistorySearchList | null) {
    return client.get<PaginatedResponse<FuelEntryListMod[]>>(FUEL_HISTORY, {
      params,
    });
  }
  getCurrentFuelHistory(id: string) {
    return client.get<TFuelHistory>(FUEL_HISTORY_ID(id));
  }
  getFuelHistoryStatistics() {
    return client.get<TFuelHistoryStatistics>(FUEL_HISTORY_STATISTICS);
  }
  deleteFuelHistory(id: string) {
    return client.delete<any>(FUEL_HISTORY_DELETE(id));
  }
  deleteMultipleFuelHistory(ids: { ids: string[] }) {
    return client.post<BulkFuelEntryRequest>(FUEL_HISTORY_MULTIPLE_DELETE, ids);
  }

  addDocumentsToFuelHistory({ id, files }: IAddFiles) {
    return client.post(FUEL_HISTORY_ADD_FILES(id), {
      files: files,
    });
  }
  removeDocumentsFromFuelHistory({ id, files }: IAddFiles) {
    return client.post(FUEL_HISTORY_DELETE_FILES(id), {
      files: files,
    });
  }
  createFuelHistory(data: TFaultHistory) {
    return client.post<FuelEntryCreate>(FUEL_HISTORY_CREATE, data);
  }

  editFuelHistory(id: string, data: TFaultHistory) {
    return client.put<FuelEntryCreate>(FUEL_HISTORY_UPDATE(id), data);
  }

  saveComment(data: IFuelHistoryCommentAdd) {
    return client.post<IComment>(FUEL_HISTORY_CREATE_COMMENT, data);
  }
  deleteComment(id: string) {
    return client.delete(FUEL_HISTORY_DELETE_COMMENT(id));
  }
}

export default FuelHistory;
