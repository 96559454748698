import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IRole } from "../../Shared/Types/role";
import { getRolesList } from "./reducer";

interface InitialState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  roles: IRole[];
  totalCount: number;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  roles: [],
  totalCount: 0,
};

const RoleSlice = createSlice({
  name: "Role",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRolesList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.roles = action.payload.results;
      state.totalCount = action.payload.count;
    });
    builder.addCase(getRolesList.pending, (state, action) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getRolesList.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload as string;
    });
  },
});

export const { clear } = RoleSlice.actions;

export const selectRoleList = (state: RootState) => state.role.roles;
export const selectRoleCount = (state: RootState) => state.role.totalCount;
export const selectRoleLoading = (state: RootState) => state.vehicle.loading;
export const selectRoleError = (state: RootState) => state.vehicle.error;
export const selectRoleSuccess = (state: RootState) => state.vehicle.success;

export default RoleSlice;
