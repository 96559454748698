import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddAspiration, IAspirationSearchList } from "Shared/Types/aspiration";

const getAspirationTypeList = createAsyncThunk(
  "aspirationType/getAspirationTypeList",
  async (params: IAspirationSearchList | null) => {
    try {
      const response = await api.aspiration.getAspirationTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addAspirationType = createAsyncThunk(
  "aspirationType/addAspirationType",
  async (data: IAddAspiration, { dispatch }) => {
    try {
      const response = await api.aspiration.addGroupType(data);
      dispatch(getAspirationTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addAspirationType, getAspirationTypeList };
