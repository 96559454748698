import { client } from "Service/axios";
import {
  EQUIPMENTS,
  EQUIPMENTS_ASSIGNED_VEHICLES,
  EQUIPMENTS_CREATE,
  EQUIPMENTS_ID,
  EQUIPMENTS_ID_ASSIGN,
  EQUIPMENTS_ID_ASSIGNMENT_DELETE,
  EQUIPMENTS_ID_ASSIGNMENTS,
  EQUIPMENTS_ID_BULK_ADD_FILES,
  EQUIPMENTS_ID_BULK_REMOVE_FILES,
  EQUIPMENTS_ID_DELETE,
  EQUIPMENTS_ID_LINKED_VEHICLES,
  EQUIPMENTS_ID_RENT_HISTORY,
  EQUIPMENTS_ID_UNASSIGN,
  EQUIPMENTS_RENT_HISTORIES_CREATE,
  EQUIPMENTS_RENT_HISTORIES_ID_DELETE,
  EQUIPMENTS_RENT_HISTORIES_ID_UPDATE,
  EQUIPMENTS_RENT_HISTORIES_PAID,
  EQUIPMENTS_RENT_HISTORIES_UNPAID,
  EQUIPMENTS_TYPES,
  EQUIPMENTS_TYPES_CREATE,
  EQUIPMENTS_TYPES_DELETE,
  EQUIPMENTS_UPDATE,
  EQUIPMENTS_USERS,
  EQUIPMENTS_VEHICLES,
} from "Shared/Constants/apiRequest";
import {
  IAssignedVehiclesSearchParams,
  IAssignEquipPost,
  IAssignmentHistory,
  IEquipDetails,
  IEquipHistorySearchParams,
  IEquipList,
  IEquipSearchParams,
  IEquipTypes,
  IEquipTypesSearchParams,
  ILinkedVehiclesHistory,
  IRentHistory,
  IRentHistoryPatch,
  IRentHistoryPost,
  IRentHistoryStatusPost,
  IUnassignEquipPost,
  IUsers,
  IUsersSearchParams,
  IVehicles,
  IVehiclesSearchParams,
} from "Shared/Types/equipment";
import { IAddFiles } from "Shared/Types/file";
import { EquipmentTypeCreateRequest } from "Shared/Types/fleetmanagementapi";
import { TEquipment } from "Shared/Utils/yup/equipmentShema";

class Equipment {
  getEquipmentTypes(params?: IEquipTypesSearchParams | null) {
    return client.get<IEquipTypes>(EQUIPMENTS_TYPES, { params });
  }
  deleteEquipmentType(id: string) {
    return client.delete(EQUIPMENTS_TYPES_DELETE(id));
  }
  equipmentTypesCreate(data: EquipmentTypeCreateRequest | null) {
    return client.post<EquipmentTypeCreateRequest>(
      EQUIPMENTS_TYPES_CREATE,
      data
    );
  }

  getAssignedVehicles(params?: IAssignedVehiclesSearchParams) {
    return client.get<IVehicles>(EQUIPMENTS_ASSIGNED_VEHICLES, { params });
  }

  getVehicles(params?: IVehiclesSearchParams) {
    return client.get<IVehicles>(EQUIPMENTS_VEHICLES, { params });
  }

  getUsers(params?: IUsersSearchParams) {
    return client.get<IUsers>(EQUIPMENTS_USERS, { params });
  }

  getEquipmentList(params?: IEquipSearchParams) {
    return client.get<IEquipList>(EQUIPMENTS, { params });
  }

  getEquipmentDetails(id: string) {
    return client.get<IEquipDetails>(EQUIPMENTS_ID(id));
  }

  unassignEquipment(id: string, data: IUnassignEquipPost) {
    return client.post(EQUIPMENTS_ID_UNASSIGN(id), data);
  }

  assignEquipment(id: string, data: IAssignEquipPost) {
    return client.post(EQUIPMENTS_ID_ASSIGN(id), data);
  }

  deleteEquipment(id: string) {
    return client.delete(EQUIPMENTS_ID_DELETE(id));
  }

  deleteUnassignedEquipment(id: string) {
    return client.post(EQUIPMENTS_ID_ASSIGNMENT_DELETE(id));
  }

  getLinkedVehiclesHistory(id: string, params?: IEquipHistorySearchParams) {
    return client.get<ILinkedVehiclesHistory>(
      EQUIPMENTS_ID_LINKED_VEHICLES(id),
      { params }
    );
  }

  getAssignmentsHistory(id: string, params?: IEquipHistorySearchParams) {
    return client.get<IAssignmentHistory>(EQUIPMENTS_ID_ASSIGNMENTS(id), {
      params,
    });
  }

  getRentHistory(id: string, params?: IEquipHistorySearchParams) {
    return client.get<IRentHistory>(EQUIPMENTS_ID_RENT_HISTORY(id), { params });
  }

  addRent(data: IRentHistoryPost) {
    return client.post(EQUIPMENTS_RENT_HISTORIES_CREATE, data);
  }

  deleteRent(id: string) {
    return client.delete(EQUIPMENTS_RENT_HISTORIES_ID_DELETE(id));
  }

  updateRent(id: string, data: IRentHistoryPatch) {
    return client.patch(EQUIPMENTS_RENT_HISTORIES_ID_UPDATE(id), data);
  }

  updateRentToPaid(data: IRentHistoryStatusPost) {
    return client.post(EQUIPMENTS_RENT_HISTORIES_PAID, data);
  }

  updateRentToUnpaid(data: IRentHistoryStatusPost) {
    return client.post(EQUIPMENTS_RENT_HISTORIES_UNPAID, data);
  }

  addEquipment(data: TEquipment) {
    return client.post(EQUIPMENTS_CREATE, data);
  }

  updateEquipment(data: any, id: string) {
    return client.patch(EQUIPMENTS_UPDATE(id), data);
  }

  addFilesToEquipment({ id, files }: IAddFiles) {
    return client.post(EQUIPMENTS_ID_BULK_ADD_FILES(id), { files });
  }

  removeFileFromEquipment({ id, files }: IAddFiles) {
    return client.post(EQUIPMENTS_ID_BULK_REMOVE_FILES(id), { files });
  }
}

export default Equipment;
