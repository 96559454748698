import * as Yup from "yup";
import { z } from "zod";
import dayjs, { Dayjs } from "dayjs";
import { ICurrentVehicle } from "Shared/Types/vehicle";

export const getDayjsSchema = (message = "Field is required!") => {
  return z.instanceof(dayjs as unknown as typeof Dayjs, { message });
};

const passwordRules = /^(?=.*\d)(?=.*[a-z]).{8,}$/;

export const PasswordSchema = Yup.string()
  .matches(passwordRules, {
    message: "Please create a stronger password, must be at least 8 characters",
  })
  .min(8, "Must be at least 8 characters")
  .notOneOf(
    ["password", "123456", "qwerty", "123456789"],
    "Password is too common"
  )
  .required("Password is required");

export const changeVehiclesName = (vehicles: ICurrentVehicle[]) => {
  return vehicles.map((vehicle) => ({
    ...vehicle,
    name: `${vehicle.name} - ${vehicle.vin_sn || ""}`,
  }));
};
