import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const EditServiceReminder = lazyLoader(
  () =>
    import("Containers/ReminderService/AddServiceReminder/EditServiceReminder")
);

export const EditServiceReminderPage = () => {
  return (
    <Layout>
      <EditServiceReminder />
    </Layout>
  );
};
