import api from "Service/api/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  ISamsaraDispatch,
  ISamsaraDrivers,
  ISamsaraIntegrationSearchParams,
  ISamsaraSaveDrivers,
  ISamsaraSaveVehicles,
  ISamsaraTokenRegistration,
  ISamsaraTokenRemoval,
  ISamsaraVehicles,
  ISamsaraVehiclesDiagnostics,
} from "Shared/Types/samsara";
import { notifySuccess } from "../../Components/Notify/notify";
import { getFaultsList } from "../Faults/reducer";
import { clear, write } from "Service/storage";
import { INTEGRATION_ID } from "Shared/Constants/samsara";
import { IUpdateReducerWithoutId } from "Shared/Types/shared";

const getSamsaraIntegrations = createAsyncThunk(
  "samsara/getSamsaraIntegrations",
  async (params: ISamsaraIntegrationSearchParams | null) => {
    try {
      const response = await api.samsara.samsaraIntegrations(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getSamsaraDrivers = createAsyncThunk(
  "samsara/getSamsaraDrivers",
  async (data: ISamsaraDrivers) => {
    try {
      const response = await api.samsara.samsaraGetDrivers(data);
      return { results: response.data.results, count: response.data.count };
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getSamsaraVehicleGeoPosition = createAsyncThunk(
  "samsara/getSamsaraVehicleGeoPosition",
  async (id: string) => {
    try {
      const response = await api.samsara.samsaraVehiclesGeoPosition(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getSamsaraVehicles = createAsyncThunk(
  "samsara/getSamsaraVehicles",
  async (data: ISamsaraVehicles) => {
    try {
      const response = await api.samsara.samsaraGetVehicles(data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getSamsaraVehiclesWithoutLimit = createAsyncThunk(
  "samsara/getSamsaraVehiclesWithoutLimit",
  async (data: ISamsaraVehicles) => {
    try {
      const response = await api.samsara.samsaraGetVehicles(data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const samsaraSaveDrivers = createAsyncThunk(
  "samsara/samsaraSaveDrivers",
  async ({
    data,
    onError,
    onSuccess,
  }: ISamsaraDispatch<ISamsaraSaveDrivers>) => {
    try {
      const response = await api.samsara.samsaraSaveDrivers(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const samsaraSaveVehicles = createAsyncThunk(
  "samsara/samsaraSaveVehicles",
  async ({
    data,
    onError,
    onSuccess,
  }: ISamsaraDispatch<ISamsaraSaveVehicles>) => {
    try {
      const response = await api.samsara.samsaraSaveVehicles(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const samsaraVehiclesDiagnostics = createAsyncThunk(
  "samsara/samsaraVehiclesDiagnostics",
  async (data: ISamsaraVehiclesDiagnostics) => {
    try {
      const response = await api.samsara.samsaraVehiclesDiagnostics(data);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const samsaraTokenRegistration = createAsyncThunk(
  "samsara/samsaraTokenRegistration",
  async ({
    data,
    onError,
    onSuccess,
  }: ISamsaraDispatch<ISamsaraTokenRegistration>) => {
    try {
      const response = await api.samsara.samsaraTokenRegistration(data);
      clear(INTEGRATION_ID);
      write(INTEGRATION_ID, `${response.data.data?.integration_id}`);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const samsaraTokenRemoval = createAsyncThunk(
  "samsara/samsaraTokenRemoval",
  async ({
    data,
    onSuccess,
    onError,
  }: IUpdateReducerWithoutId<ISamsaraTokenRemoval>) => {
    try {
      const response = await api.samsara.samsaraTokenRemoval(data);

      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();

      console.error(error);
      throw error;
    }
  },
);

const samsaraUpdateFaults = createAsyncThunk(
  "samsara/samsaraUpdateFaults",
  async (_, { dispatch }) => {
    try {
      const response = await api.samsara.samsaraUpdateFaults();
      notifySuccess("Faults successfully refreshed!");
      dispatch(getFaultsList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export {
  getSamsaraIntegrations,
  getSamsaraDrivers,
  getSamsaraVehicles,
  samsaraSaveDrivers,
  samsaraSaveVehicles,
  samsaraTokenRegistration,
  samsaraTokenRemoval,
  samsaraVehiclesDiagnostics,
  getSamsaraVehicleGeoPosition,
  samsaraUpdateFaults,
  getSamsaraVehiclesWithoutLimit,
};
