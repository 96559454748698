import { client } from "Service/axios";
import {
  VEHICLE_TYPES,
  VEHICLE_TYPES_CREATE,
} from "Shared/Constants/apiRequest";
import { IAddType, ITypeListSearch } from "Shared/Types/type";

class Type {
  getTypeList(params: ITypeListSearch | null) {
    return client.get<any>(VEHICLE_TYPES, { params });
  }
  addType(data: IAddType) {
    return client.post<any>(VEHICLE_TYPES_CREATE, data);
  }
}

export default Type;
