import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  ICurrentVehicle,
  ILinkedVehicleType,
  VehicleAnalyticsComplete,
} from "Shared/Types/vehicle";
import {
  addVehicle,
  editVehicle,
  getLinkedVehicleList,
  getVehicle,
  getVehicleAnalyticsComplete,
  getVehicleAssignedEquipments,
  getVehicleDocumentsList,
  getVehicleEntries,
  getVehicleFaults,
  getVehicleIssues,
  getVehicleLatestOdometer,
  getVehicleList,
  getVehicleServices,
} from "./reducer";
import { IFile } from "Shared/Types/file";
import { IIssue } from "Shared/Types/issue";
import { IFault } from "Shared/Types/faults";
import {
  MeterEntry,
  PaginatedVehicleIssueList,
  PaginatedVehicleServiceList,
  VehicleMeterEntry,
} from "Shared/Types/fleetmanagementapi";
import { IEquipItem } from "Shared/Types/equipment";

interface ICompanyRequestsState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  vehicleList: ICurrentVehicle[];
  loadingVehicleList: boolean;
  loadingVehicleAnalyticsComplete: boolean;
  currentVehicle: ICurrentVehicle | null;
  linkedVehicles: ILinkedVehicleType[];
  vehicleIssuesListFilter: PaginatedVehicleIssueList[];
  vehicleMeterList: VehicleMeterEntry[];
  vehicleMeterCount: number;
  vehicleServicesList: PaginatedVehicleServiceList[];
  vehicleDocuments: IFile[];
  vehicleIssues: IIssue[];
  vehicleFaults: IFault[];
  vehicleAssignedList: IEquipItem[];
  vehicleAnalyticsComplete: VehicleAnalyticsComplete | undefined;
  numPage: number;
  count: number;
  vehicleAssignedListCount: number;
  vehicleLatestOdometer: MeterEntry | null;
}

const initialState: ICompanyRequestsState = {
  loading: false,
  loadingVehicleList: false,
  loadingVehicleAnalyticsComplete: false,
  error: null,
  vehicleList: [],
  currentVehicle: null,
  linkedVehicles: [],
  vehicleDocuments: [],
  vehicleIssues: [],
  vehicleAnalyticsComplete: {
    status_distribution: {
      points: [{ count: 0, label: "", percentage: 0, status: "" }],
      total_vehicles: 0,
      last_updated: "",
    },

    new_vehicles: {
      count: 0.0,
      change_percentage: 0.0,
      period_days: "1 week",
    },
    downtime_count: 0,
  },
  vehicleMeterList: [],
  vehicleServicesList: [],
  vehicleFaults: [],
  vehicleIssuesListFilter: [],
  vehicleAssignedList: [],
  numPage: 0,
  count: 0,
  vehicleAssignedListCount: 0,
  vehicleMeterCount: 0,
  vehicleLatestOdometer: null,
};

const vehiclesSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    clearCurrentVehicle(state) {
      state.currentVehicle = null;
    },
    clearCurrentLastOdometer(state) {
      state.vehicleLatestOdometer = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVehicleList.fulfilled, (state, action) => {
      state.loadingVehicleList = false;
      state.loading = false;
      state.error = null;
      state.vehicleList = action.payload?.results;
      state.numPage = action.payload.count;
      state.count = action.payload.count;
    });
    builder.addCase(getVehicleList.pending, (state) => {
      state.loading = true;
      state.loadingVehicleList = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getVehicleList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.loadingVehicleList = false;
    });
    builder.addCase(getVehicle.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentVehicle = action.payload;
    });
    builder.addCase(getVehicle.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getVehicle.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addVehicle.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addVehicle.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addVehicle.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(editVehicle.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(editVehicle.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(editVehicle.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getLinkedVehicleList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.linkedVehicles = action.payload.linked_vehicles;
    });
    builder.addCase(getLinkedVehicleList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getLinkedVehicleList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getVehicleDocumentsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.vehicleDocuments = action.payload.results;
    });
    builder.addCase(getVehicleDocumentsList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getVehicleDocumentsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getVehicleFaults.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.vehicleFaults = action.payload;
    });

    builder.addCase(getVehicleIssues.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.vehicleIssues = action.payload;
    });
    builder.addCase(getVehicleIssues.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getVehicleIssues.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getVehicleServices.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.vehicleServicesList = action.payload;
    });
    builder.addCase(getVehicleServices.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getVehicleServices.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getVehicleEntries.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.vehicleMeterList = action.payload?.results || [];
      state.vehicleMeterCount = action.payload?.count || 0;
    });
    builder.addCase(getVehicleEntries.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getVehicleEntries.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getVehicleAssignedEquipments.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.vehicleAssignedList = action.payload.results || [];
      state.vehicleAssignedListCount = action.payload.count || 0;
    });
    builder.addCase(getVehicleAssignedEquipments.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.vehicleAssignedList = [];
      state.success = false;
    });
    builder.addCase(getVehicleAssignedEquipments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getVehicleAnalyticsComplete.fulfilled, (state, action) => {
      state.loadingVehicleAnalyticsComplete = false;
      state.error = null;
      state.vehicleAnalyticsComplete = action?.payload;
    });
    builder.addCase(getVehicleAnalyticsComplete.pending, (state) => {
      state.loadingVehicleAnalyticsComplete = true;
      state.error = null;
    });
    builder.addCase(getVehicleAnalyticsComplete.rejected, (state, action) => {
      state.loadingVehicleAnalyticsComplete = false;
      state.error = action.error.message;
    });
    builder.addCase(getVehicleLatestOdometer.fulfilled, (state, action) => {
      state.error = null;
      state.loading = false;
      state.vehicleLatestOdometer = action?.payload;
    });
    builder.addCase(getVehicleLatestOdometer.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(getVehicleLatestOdometer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.vehicleLatestOdometer = null;
    });
  },
});

export const { clear, clearCurrentVehicle, clearCurrentLastOdometer } =
  vehiclesSlice.actions;

export const selectVehicleList = (state: RootState) =>
  state.vehicle.vehicleList;
export const selectVehicleListLoading = (state: RootState) =>
  state.vehicle.loadingVehicleList;
export const selectCurrentVehicle = (state: RootState) =>
  state.vehicle.currentVehicle;
export const selectVehicleDocuments = (state: RootState) =>
  state.vehicle.vehicleDocuments;
export const selectVehicleLoading = (state: RootState) => state.vehicle.loading;
export const selectVehicleError = (state: RootState) => state.vehicle.error;
export const selectVehicleSuccess = (state: RootState) => state.vehicle.success;
export const selectVehicleCount = (state: RootState) => state.vehicle.count;
export const selectVehicleAnalyticsComplete = (state: RootState) =>
  state.vehicle.vehicleAnalyticsComplete;
export const selectVehicleAnalyticsCompleteLoading = (state: RootState) =>
  state.vehicle.loadingVehicleAnalyticsComplete;
export const selectAssignedEquipmentsCount = (state: RootState) =>
  state.vehicle.vehicleAssignedListCount;
export const selectNumPage = (state: RootState): number =>
  state.vehicle.numPage;
export const selectVehicleIssueList = (state: RootState) =>
  state.vehicle.vehicleIssuesListFilter;
export const selectVehicleServiceList = (state: RootState) =>
  state.vehicle.vehicleServicesList;
export const selectVehicleEntries = (state: RootState) =>
  state.vehicle.vehicleMeterList;
export const selectVehicleEntriesCount = (state: RootState) =>
  state.vehicle.vehicleMeterCount;
export const selectLinkedVehicle = (state: RootState) =>
  state.vehicle.linkedVehicles;
export const selectVehicleIssues = (state: RootState) =>
  state.vehicle.vehicleIssues;
export const selectVehicleFaults = (state: RootState) =>
  state.vehicle.vehicleFaults;
export const selectAssignedEquipments = (state: RootState) =>
  state.vehicle.vehicleAssignedList;
export const selectVehicleLatestOdometer = (state: RootState) =>
  state.vehicle.vehicleLatestOdometer;

export default vehiclesSlice;
