import { client } from "Service/axios";
import {
  SERVICES_PAYMENT_TYPE,
  SERVICES_PAYMENT_TYPE_CREATE,
} from "Shared/Constants/apiRequest";
import {
  PaymentType,
  PaymentTypeRequest,
} from "Shared/Types/fleetmanagementapi";

class Payment {
  getPaymentType(params: PaymentType | null) {
    return client.get<any>(SERVICES_PAYMENT_TYPE, { params });
  }

  addPaymentType(data: PaymentTypeRequest) {
    return client.post<any>(SERVICES_PAYMENT_TYPE_CREATE, data);
  }
}

export default Payment;
