import { createSlice } from "@reduxjs/toolkit";
import { IMakeType } from "Shared/Types/make";
import { RootState } from "../store";
import { addMakeType, getMakeTypeList } from "./MakeTypeReducer";

interface makeTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  makeTypeList: IMakeType[];
}

const initialState: makeTypeState = {
  loading: false,
  error: null,
  makeTypeList: [],
};

const makeTypeSlice = createSlice({
  name: "makeType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMakeTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.makeTypeList = action.payload.results;
    });
    builder.addCase(addMakeType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addMakeType.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.makeTypeList = [...state.makeTypeList, action.payload];
    });
    builder.addCase(addMakeType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = makeTypeSlice.actions;

export const selectMakeTypeList = (state: RootState) =>
  state.makeType.makeTypeList;
export const selectMakeAuthLoading = (state: RootState) =>
  state.makeType.loading;
export const selectMakeAuthError = (state: RootState) => state.makeType.error;
export const selectMakeAuthSuccess = (state: RootState) =>
  state.makeType.success;

export default makeTypeSlice;
