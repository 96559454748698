import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getTypeList, addType } from "./typeReducer";
import { IType } from "Shared/Types/type";

interface ICompanyRequestsState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  typeList: IType[];
}

const initialState: ICompanyRequestsState = {
  loading: false,
  error: null,
  typeList: [],
};

const typeSlice = createSlice({
  name: "companyRequest",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.typeList = action.payload.results;
    });
    builder.addCase(addType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addType.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.typeList = [...state.typeList, action.payload];
    });
    builder.addCase(addType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = typeSlice.actions;

export const selectTypeList = (state: RootState) => state.type.typeList;
export const selectAuthLoading = (state: RootState) => state.type.loading;
export const selectAuthError = (state: RootState) => state.type.error;
export const selectAuthSuccess = (state: RootState) => state.type.success;

export default typeSlice;
