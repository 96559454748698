import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddFilesToEntityTypes } from "Shared/Types/file";
import {
  IPurchaseOrderReceiveItems,
  IPurchaseOrderSearchParams,
} from "Shared/Types/purchaseOrder";
import {
  IDeleteReducerWithCallback,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";

export const getPurchaseOrdersList = createAsyncThunk(
  "purchaseOrder/getPurchaseOrdersList",
  async (params: IPurchaseOrderSearchParams | null) => {
    try {
      const response = await api.purchaseOrder.getPurchaseOrdersList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getPurchaseOrder = createAsyncThunk(
  "purchaseOrder/getPurchaseOrder",
  async (id: string) => {
    try {
      const response = await api.purchaseOrder.getPurchaseOrder(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const deletePurchaseOrder = createAsyncThunk(
  "purchaseOrder/deletePurchaseOrder",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.purchaseOrder.deletePurchaseOrder(id);
      onSuccess && onSuccess();
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

export const getPurchaseOrderFiles = createAsyncThunk(
  "purchaseOrder/getPurchaseOrderFiles",
  async (id: string) => {
    try {
      const response = await api.purchaseOrder.getPurchaseOrderFiles(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const addFilesToPurchaseOrder = createAsyncThunk(
  "purchaseOrder/addFilesToPurchaseOrder",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.purchaseOrder.addFilesToPurchaseOrder({ id, files });
      dispatch(getPurchaseOrderFiles(id));
      onSuccessUploadFiles?.();
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

export const removeFilesFromPurchaseOrder = createAsyncThunk(
  "purchaseOrder/removeFilesFromPurchaseOrder",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.purchaseOrder.removeFilesFromPurchaseOrder({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getPurchaseOrderFiles(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

export const sendReceivedLineItemsQuantity = createAsyncThunk(
  "purchaseOrder/sendReceivedLineItemsQuantity",
  async (
    {
      id,
      data,
      onError,
      onSuccess,
    }: IUpdateReducerWithCallback<IPurchaseOrderReceiveItems>,
    { dispatch },
  ) => {
    try {
      const response = await api.purchaseOrder.sendReceivedLineItems({
        id,
        data,
      });
      onSuccess && onSuccess();
      dispatch(getPurchaseOrder(id));
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

export const switchPurchaseOrderStatus = createAsyncThunk(
  "purchaseOrder/switchPurchaseOrderStatus",
  async (
    {
      id,
      status,
      onError,
      onSuccess,
    }: IDeleteReducerWithCallback & { status: string },
    { dispatch },
  ) => {
    try {
      const response = await api.purchaseOrder.switchPurchaseOrderStatus({
        id,
        status,
      });
      dispatch(getPurchaseOrder(id));
      onSuccess && onSuccess();
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);
