import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddLabelsType, ILabelsTypeSearchList } from "Shared/Types/labels";

const getLabelsTypeList = createAsyncThunk(
  "labelsType/getLabelsTypeList",
  async (params: ILabelsTypeSearchList | null) => {
    try {
      const response = await api.labelsType.getLabelsTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addLabelsType = createAsyncThunk(
  "labelsType/addLabelsType",
  async (data: IAddLabelsType, { dispatch }) => {
    try {
      const response = await api.labelsType.addLabelsType(data);
      dispatch(getLabelsTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addLabelsType, getLabelsTypeList };
