import { client } from "Service/axios";
import {
  ADD_INVENTORY_PARTS_CATEGORIES,
  ADD_INVENTORY_PARTS_MANUFACTURES,
  ADD_INVENTORY_PARTS_MEASUREMENT_UNITS,
  INVENTORY_LOCATION,
  INVENTORY_LOCATION_CREATE,
  INVENTORY_LOCATION_ID_DELETE,
  INVENTORY_LOCATION_ID_UPDATE,
  INVENTORY_PARTS,
  INVENTORY_PARTS_ARCHIVED,
  INVENTORY_PARTS_CATEGORIES,
  INVENTORY_PARTS_COMMENTS_CREATE,
  INVENTORY_PARTS_CREATE,
  INVENTORY_PARTS_DOCUMENTS,
  INVENTORY_PARTS_ID,
  INVENTORY_PARTS_ID_ARCHIVE,
  INVENTORY_PARTS_ID_BULK_ADD_DOCUMENTS,
  INVENTORY_PARTS_ID_BULK_REMOVE_DOCUMENTS,
  INVENTORY_PARTS_ID_COMMENTS_DELETE,
  INVENTORY_PARTS_ID_DELETE,
  INVENTORY_PARTS_ID_METADATA,
  INVENTORY_PARTS_ID_PARTS_LOCATION,
  INVENTORY_PARTS_ID_PURCHASE_HISTORY,
  INVENTORY_PARTS_ID_UNARCHIVE,
  INVENTORY_PARTS_ID_UPDATE,
  INVENTORY_PARTS_ID_WORD_ORDER,
  INVENTORY_PARTS_MANUFACTURES,
  INVENTORY_PARTS_MEASUREMENT_UNITS,
  INVENTORY_PARTS_METADATA,
} from "Shared/Constants/apiRequest";
import { IComment, IPartCommentAdd } from "Shared/Types/comment";
import { IAddFiles } from "Shared/Types/file";
import {
  PaginatedPartPurchaseHistoryList,
  PaginatedWorkOrderActivityByPartList,
} from "Shared/Types/fleetmanagementapi";
import {
  FetchWorkOrdersPayload,
  IAddFilesToPart,
  ILocation,
  IPart,
  IPartCategory,
  IPartManufacture,
  IPartMetaData,
  IReason,
  LocationsParams,
  PartCategoriesParams,
  PartManufacturesParams,
  PartsListByLocationParams,
  PartsListParams,
} from "Shared/Types/parts";
import {
  FetchPurchaseHistoryPayload,
  PurchaseHistoryParams,
} from "Shared/Types/purchaseOrder";
import { PaginatedResponse, ReducerTypeForUpdate } from "Shared/Types/shared";
import {
  IAdjustPartLocation,
  IEditPartLocation,
  IPartLocation,
  ITransferPartLocation,
  PartFormData,
} from "Shared/Utils/yup/partSchema";

const URLS = {
  parts: "inventory/parts/",
  reason: "inventory/parts/adjustment_reasons/",
};

class Part {
  getPartLocations(params?: LocationsParams) {
    return client.get<PaginatedResponse<ILocation[]>>(INVENTORY_LOCATION, {
      params,
    });
  }
  createPartLocation(data: IPartLocation) {
    return client.post<PaginatedResponse<IPartLocation[]>>(
      INVENTORY_LOCATION_CREATE,
      data,
    );
  }
  updatePartLocation({ id, data }: ReducerTypeForUpdate<IPartLocation>) {
    return client.patch<PaginatedResponse<IPartLocation[]>>(
      INVENTORY_LOCATION_ID_UPDATE(id),
      data,
    );
  }
  getPartAdjustmentReasons() {
    return client.get<PaginatedResponse<IReason[]>>(`${URLS.reason}`);
  }
  getParts(params: PartsListParams | null) {
    return client.get<PaginatedResponse<IPart[]>>(INVENTORY_PARTS, {
      params,
    });
  }
  deletePart(id: string) {
    return client.delete<PaginatedResponse<IPart[]>>(
      INVENTORY_PARTS_ID_DELETE(id),
    );
  }
  deleteLocationPart(id: string) {
    return client.delete(INVENTORY_LOCATION_ID_DELETE(id));
  }
  archivePart(id: string) {
    return client.post<PaginatedResponse<IPart[]>>(
      INVENTORY_PARTS_ID_ARCHIVE(id),
    );
  }
  unarchivePart(id: string) {
    return client.post<PaginatedResponse<IPart[]>>(
      INVENTORY_PARTS_ID_UNARCHIVE(id),
    );
  }
  getCurrentPart(id: string) {
    return client.get<IPart>(INVENTORY_PARTS_ID(id));
  }
  getWorkOrderActivity({ id, params }: FetchWorkOrdersPayload) {
    return client.get<PaginatedWorkOrderActivityByPartList>(
      INVENTORY_PARTS_ID_WORD_ORDER(id),
      { params },
    );
  }
  getPurchaseHistory = ({ id, params }: FetchPurchaseHistoryPayload) => {
    return client.get<PaginatedPartPurchaseHistoryList>(
      INVENTORY_PARTS_ID_PURCHASE_HISTORY(id),
      {
        params,
      },
    );
  };

  getPartsByLocation(params: PartsListByLocationParams) {
    return client.get<PaginatedResponse<IPart[]>>(
      INVENTORY_PARTS_ID_PARTS_LOCATION(params.location_id),
      {
        params,
      },
    );
  }
  getArchivedParts(params: PartsListParams) {
    return client.get<PaginatedResponse<IPart[]>>(INVENTORY_PARTS_ARCHIVED, {
      params,
    });
  }
  getPartsCategories(params: PartCategoriesParams) {
    return client.get<PaginatedResponse<IPartCategory[]>>(
      INVENTORY_PARTS_CATEGORIES,
      {
        params,
      },
    );
  }
  addPartsCategories(data: { name: string }) {
    return client.post<IPartCategory>(ADD_INVENTORY_PARTS_CATEGORIES, data);
  }

  getPartManufacturers(params: PartManufacturesParams) {
    return client.get<PaginatedResponse<IPartManufacture[]>>(
      INVENTORY_PARTS_MANUFACTURES,
      {
        params,
      },
    );
  }

  addPartManufacturer(data: { name: string }) {
    return client.post<IPartManufacture>(
      ADD_INVENTORY_PARTS_MANUFACTURES,
      data,
    );
  }
  getPartsMetaData() {
    return client.get<IPartMetaData>(INVENTORY_PARTS_METADATA);
  }
  getPartsMetaDataByLocation(locationId: string) {
    return client.get<IPartMetaData>(INVENTORY_PARTS_ID_METADATA(locationId));
  }

  // TODO: replace any
  createPart(data: any) {
    return client.post<any>(INVENTORY_PARTS_CREATE, data);
  }

  updatePart({ id, data }: ReducerTypeForUpdate<PartFormData>) {
    return client.patch<any>(INVENTORY_PARTS_ID_UPDATE(id), data);
  }

  addFilesToPart(data: IAddFilesToPart) {
    return client.post<any>(INVENTORY_PARTS_DOCUMENTS, data);
  }

  getMeasurements() {
    return client.get<PaginatedResponse<IPartManufacture[]>>(
      INVENTORY_PARTS_MEASUREMENT_UNITS,
    );
  }

  addMeasurement(data: { name: string }) {
    return client.post<IPartManufacture>(
      ADD_INVENTORY_PARTS_MEASUREMENT_UNITS,
      data,
    );
  }

  adjustPartLocation({
    id,
    data,
    part_id,
  }: ReducerTypeForUpdate<IAdjustPartLocation> & { part_id: string }) {
    return client.post<any>(
      `${URLS.parts}${part_id}/adjust_part_location/${id}/`,
      data,
    );
  }

  transferPartLocation({
    id,
    data,
    part_id,
  }: ReducerTypeForUpdate<ITransferPartLocation> & { part_id: string }) {
    return client.post<any>(`${URLS.parts}${part_id}/transfer/${id}/`, data);
  }

  editPartLocation({
    id,
    data,
    part_id,
  }: ReducerTypeForUpdate<IEditPartLocation> & { part_id: string }) {
    return client.patch<any>(
      `${URLS.parts}${part_id}/edit_part_location/${id}/`,
      data,
    );
  }
  // Comments
  saveComment(data: IPartCommentAdd) {
    return client.post<IComment>(INVENTORY_PARTS_COMMENTS_CREATE, data);
  }
  deleteComment(id: string) {
    return client.delete(INVENTORY_PARTS_ID_COMMENTS_DELETE(id));
  }
  addDocumentsToParts({ id, files }: IAddFiles) {
    return client.post(INVENTORY_PARTS_ID_BULK_ADD_DOCUMENTS(id), {
      documents: files,
    });
  }
  removeDocumentsFromParts({ id, files }: IAddFiles) {
    return client.post(INVENTORY_PARTS_ID_BULK_REMOVE_DOCUMENTS(id), {
      documents: files,
    });
  }
}

export default Part;
