import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  ITrainingTypeSearchList,
  ITrainingVideosCategoryParams,
  ITrainingVideosParams,
} from "Shared/Types/trainint";

const getTrainingVideoList = createAsyncThunk(
  "training/getTrainingVideoList",
  async (params: ITrainingVideosParams | null) => {
    try {
      const response = await api.traningVideo.getVideoList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getVideoListSections = createAsyncThunk(
  "training/getVideoListSections",
  async (params: ITrainingTypeSearchList | null) => {
    try {
      const response = await api.traningVideo.getVideoListSections(params);
      return response.data?.results;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getVideoListSectionsCategories = createAsyncThunk(
  "training/getVideoListSectionsCategories",
  async (params: ITrainingVideosCategoryParams | null) => {
    try {
      const response =
        await api.traningVideo.getVideoListSectionsCategories(params);
      return response.data?.results;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export {
  getTrainingVideoList,
  getVideoListSections,
  getVideoListSectionsCategories,
};
