import { createSlice } from "@reduxjs/toolkit";
import { IBodyType } from "Shared/Types/bodyType";
import { RootState } from "../store";
import { addBodyType, getBodyTypeList } from "./BodyTypeReducer";

interface bodyTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  bodyTypeList: IBodyType[];
}

const initialState: bodyTypeState = {
  loading: false,
  error: null,
  bodyTypeList: [],
};

const bodyTypeSlice = createSlice({
  name: "bodyType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBodyTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.bodyTypeList = action.payload.results;
    });
    builder.addCase(addBodyType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addBodyType.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addBodyType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = bodyTypeSlice.actions;

export const selectBodyTypeList = (state: RootState) =>
  state.bodyType.bodyTypeList;
export const selectBodyTypeAuthLoading = (state: RootState) =>
  state.bodyType.loading;
export const selectBodyTypeAuthError = (state: RootState) =>
  state.bodyType.error;
export const selectBodyTypeAuthSuccess = (state: RootState) =>
  state.bodyType.success;

export default bodyTypeSlice;
