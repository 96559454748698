import React from "react";
import Suspence from "Components/Layout/Suspence";
import { lazyLoader } from "Service/loader";
const ForgotPasswordStep1 = lazyLoader(
  () => import("Containers/ForgotPassword/ForgotPasswordStep1")
);

export const ForgotPassword1 = () => {
  return (
    <Suspence>
      <ForgotPasswordStep1 />
    </Suspence>
  );
};
