import React, { ReactNode, Suspense } from "react";
import styles from "./Layout.module.scss";
import Header from "Components/Header/Header";
import cn from "classnames";
import SidebarNew from "../SideBarNew/Sidebar";
import { Loader } from "Components/UI/Loader";

type LayoutProps = {
  children?: ReactNode;
  hasSidebar?: boolean;
};

const LayoutM: React.FC<LayoutProps> = ({ children, hasSidebar = true }) => {
  return (
    <div className={styles.layout}>
      <div className={styles.layout__sidebar}>
        {hasSidebar && <SidebarNew />}
      </div>
      <div className={styles.layout__background}></div>
      <div
        className={cn(styles.layout__content, hasSidebar && styles.fullWidth)}
      >
        <Header />
        <Suspense
          fallback={
            <div className={styles.loader}>
              <Loader />
            </div>
          }
        >
          <div>{children}</div>
        </Suspense>
      </div>
    </div>
  );
};

export const Layout = React.memo(LayoutM);
