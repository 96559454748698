import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "Redux/store";
import {
  IAssignmentHistoryItem,
  IEquipDetails,
  IEquipItem,
  IEquipType,
  ILinkedVehicleItem,
  IRentHistoryItem,
  IUser,
  IVehicle,
} from "Shared/Types/equipment";
import { format } from "Shared/Utils/shared";
import {
  addRent,
  assignEquipment,
  deleteEquipment,
  deleteRent,
  getAssignedVehicles,
  getAssignmentsHistory,
  getEquipmentDetails,
  getEquipmentList,
  getEquipmentTypes,
  getLinkedVehiclesHistory,
  getRentHistory,
  getUsers,
  getVehicles,
  unassignEquipment,
  updateRent,
  updateRentToPaid,
  updateRentToUnpaid,
} from "./reducer";

interface IEquipmentState {
  equipmentTypes: {
    loading: boolean;
    items: IEquipType[];
    numPage: number;
  };
  assignedVehicles: {
    items: IVehicle[];
  };
  vehicles: {
    items: IVehicle[];
  };
  users: {
    items: IUser[];
  };
  equipmentList: {
    loading: boolean;
    items: IEquipItem[];
    count: number;
  };
  equipmentHistory: {
    loading: boolean;
    linkedVehiclesItems: ILinkedVehicleItem[];
    assignmentsItems: IAssignmentHistoryItem[];
    rentItems: IRentHistoryItem[];
    count: number;
  };
  equipmentDetails: Partial<IEquipDetails>;
  loading: boolean;
}

const initialState: IEquipmentState = {
  equipmentTypes: {
    loading: false,
    items: [],
    numPage: 0,
  },
  assignedVehicles: {
    items: [],
  },
  vehicles: {
    items: [],
  },
  users: {
    items: [],
  },
  equipmentList: {
    loading: false,
    items: [],
    count: 0,
  },
  equipmentHistory: {
    loading: false,
    linkedVehiclesItems: [],
    assignmentsItems: [],
    rentItems: [],
    count: 0,
  },
  equipmentDetails: {},
  loading: false,
};

const equipmentSlice = createSlice({
  name: "equipment",
  initialState,
  reducers: {
    clearCurrentEquipment(state) {
      state.equipmentDetails = {};
    },
    toggleEquipItemAssignStatus(
      state,
      action: PayloadAction<{
        equipmentID: string;
        vehicleID?: string;
        userID?: string;
      }>,
    ) {
      const equipmentID = action.payload.equipmentID;
      const itemToToggle = state.equipmentList.items.find(
        (item) => item.id === equipmentID,
      );

      if (itemToToggle) {
        if (itemToToggle.is_assigned === true) {
          itemToToggle.is_assigned = false;
          itemToToggle.linked_vehicle = null;
          itemToToggle.assigned_user = null;
          itemToToggle.start_date = null;
        } else {
          const vehicleID = action.payload.vehicleID;
          const linkedVehicle = state.vehicles.items.find(
            (item) => item.id === vehicleID,
          );
          const userID = action.payload.userID;
          const assignedUser = state.users.items.find(
            (item) => item.id === userID,
          );

          itemToToggle.is_assigned = true;
          itemToToggle.linked_vehicle =
            itemToToggle.linked_vehicle && linkedVehicle
              ? { ...itemToToggle.linked_vehicle, ...linkedVehicle }
              : null;
          itemToToggle.assigned_user = assignedUser || null;
          itemToToggle.start_date = format(new Date(), "YYYY-MM-DD");
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEquipmentTypes.pending, (state) => {
      state.equipmentTypes.loading = true;
      state.equipmentTypes.items = [];
      state.equipmentTypes.numPage = 0;
    });
    builder.addCase(getEquipmentTypes.fulfilled, (state, action) => {
      state.equipmentTypes.loading = false;
      state.equipmentTypes.items = action.payload.results || [];
      state.equipmentTypes.numPage = action.payload.count || 0;
    });
    builder.addCase(getEquipmentTypes.rejected, (state) => {
      state.equipmentTypes.loading = false;
      state.equipmentTypes.numPage = 0;
    });

    builder.addCase(getAssignedVehicles.pending, (state) => {
      state.loading = true;
      state.assignedVehicles.items = [];
    });
    builder.addCase(getAssignedVehicles.fulfilled, (state, action) => {
      state.loading = false;
      state.assignedVehicles.items = action.payload.results || [];
    });
    builder.addCase(getAssignedVehicles.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getVehicles.pending, (state) => {
      state.loading = true;
      state.vehicles.items = [];
    });
    builder.addCase(getVehicles.fulfilled, (state, action) => {
      state.loading = false;
      state.vehicles.items = action.payload.results || [];
    });
    builder.addCase(getVehicles.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getUsers.pending, (state) => {
      state.loading = true;
      state.users.items = [];
    });
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users.items = action.payload.results || [];
    });
    builder.addCase(getUsers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(getEquipmentList.pending, (state) => {
      state.equipmentList.loading = true;
      state.equipmentList.items = [];
      state.equipmentList.count = 0;
    });
    builder.addCase(getEquipmentList.fulfilled, (state, action) => {
      state.equipmentList.loading = false;
      state.equipmentList.items = action.payload.results || [];
      state.equipmentList.count = action.payload.count || 0;
    });
    builder.addCase(getEquipmentList.rejected, (state) => {
      state.equipmentList.loading = false;
    });

    builder.addCase(getLinkedVehiclesHistory.pending, (state) => {
      state.equipmentHistory.loading = true;
      state.equipmentHistory.linkedVehiclesItems = [];
      state.equipmentHistory.count = 0;
    });
    builder.addCase(getLinkedVehiclesHistory.fulfilled, (state, action) => {
      state.equipmentHistory.loading = false;
      state.equipmentHistory.linkedVehiclesItems = action.payload.results || [];
      state.equipmentHistory.count = action.payload.count || 0;
    });
    builder.addCase(getLinkedVehiclesHistory.rejected, (state) => {
      state.equipmentHistory.loading = false;
    });

    builder.addCase(getAssignmentsHistory.pending, (state) => {
      state.equipmentHistory.loading = true;
      state.equipmentHistory.assignmentsItems = [];
      state.equipmentHistory.count = 0;
    });
    builder.addCase(getAssignmentsHistory.fulfilled, (state, action) => {
      state.equipmentHistory.loading = false;
      state.equipmentHistory.assignmentsItems = action.payload.results || [];
      state.equipmentHistory.count = action.payload.count || 0;
    });
    builder.addCase(getAssignmentsHistory.rejected, (state) => {
      state.equipmentHistory.loading = false;
    });

    builder.addCase(getRentHistory.pending, (state) => {
      state.equipmentHistory.loading = true;
      state.equipmentHistory.rentItems = [];
      state.equipmentHistory.count = 0;
    });
    builder.addCase(getRentHistory.fulfilled, (state, action) => {
      state.equipmentHistory.loading = false;
      state.equipmentHistory.rentItems = action.payload.results || [];
      state.equipmentHistory.count = action.payload.count || 0;
    });
    builder.addCase(getRentHistory.rejected, (state) => {
      state.equipmentHistory.loading = false;
    });

    builder.addCase(getEquipmentDetails.pending, (state) => {
      state.loading = true;
      state.equipmentDetails = {};
    });
    builder.addCase(getEquipmentDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.equipmentDetails = action.payload;
    });
    builder.addCase(getEquipmentDetails.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(unassignEquipment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(unassignEquipment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(unassignEquipment.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(assignEquipment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(assignEquipment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(assignEquipment.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteEquipment.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteEquipment.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteEquipment.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(addRent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addRent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(addRent.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(deleteRent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteRent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteRent.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateRent.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRent.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateRent.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateRentToPaid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRentToPaid.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateRentToPaid.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(updateRentToUnpaid.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateRentToUnpaid.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateRentToUnpaid.fulfilled, (state) => {
      state.loading = false;
    });
  },
});

export const { toggleEquipItemAssignStatus, clearCurrentEquipment } =
  equipmentSlice.actions;

export const selectEquipmentTypesLoading = (state: RootState) =>
  state.equipment.equipmentTypes.loading;
export const selectEquipmentTypes = (state: RootState) =>
  state.equipment.equipmentTypes.items;
export const selectEquipmentTypesNumPage = (state: RootState) =>
  state.equipment.equipmentTypes.numPage;
export const selectAssignedVehicles = (state: RootState) =>
  state.equipment.assignedVehicles.items;
export const selectVehicles = (state: RootState) =>
  state.equipment.vehicles.items;
export const selectUsers = (state: RootState) => state.equipment.users.items;
export const selectEquipmentListLoading = (state: RootState) =>
  state.equipment.equipmentList.loading;
export const selectEquipmentList = (state: RootState) =>
  state.equipment.equipmentList.items;
export const selectEquipmentListCount = (state: RootState) =>
  state.equipment.equipmentList.count;
export const selectEquipmentHistoryLoading = (state: RootState) =>
  state.equipment.equipmentHistory.loading;
export const selectLinkedVehiclesHistory = (state: RootState) =>
  state.equipment.equipmentHistory.linkedVehiclesItems;
export const selectAssignmentsHistory = (state: RootState) =>
  state.equipment.equipmentHistory.assignmentsItems;
export const selectRentHistory = (state: RootState) =>
  state.equipment.equipmentHistory.rentItems;
export const selectEquipmentHistoryCount = (state: RootState) =>
  state.equipment.equipmentHistory.count;
export const selectEquipmentDetails = (state: RootState) =>
  state.equipment.equipmentDetails;
export const selectEquipmentLoading = (state: RootState) =>
  state.equipment.loading;

export default equipmentSlice;
