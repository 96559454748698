export const welcomeList = [
  "Log in to your Samsara account.",
  "Select the Settings gear in the lower left corner of your Samsara dashboard, then select API Tokens from the Developer section.",
  "Select + Add an API Token.",
  "Enter a Name, such as Easy Fleet Integration.",
  "Ensure the Tag Accessoption is set to Entire Organization.",
  "Set the Global dropdown under Scopes as Global Read and Global Write.",
  "Select Save.",
];

export const INTEGRATION_ID = "integration_id";
