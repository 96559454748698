import React from "react";
import { lazyLoader } from "Service/loader";
const VehicleDashboard = lazyLoader(
  () => import("Containers/Vehicle/VehicleDashboard/VehicleDashboard")
);
import { Layout } from "Components/Layout/Layout";

export const VehicleList = () => {
  return (
    <Layout>
      <VehicleDashboard />
    </Layout>
  );
};
