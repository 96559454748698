import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

const TrainingList = lazyLoader(
  () => import("Containers/TrainingVideo/TrainingList")
);
export const TrainingVideoDashboard = () => {
  return (
    <Layout>
      <TrainingList />
    </Layout>
  );
};
