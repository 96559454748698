import { TStatisticsServiceReminder } from "Shared/Types/reminder";
import { createSlice } from "@reduxjs/toolkit";
import {
  ServiceReminderBulkCreateRequest,
  ServiceReminderDetail,
  ServiceReminderRead,
} from "Shared/Types/fleetmanagementapi";
import { RootState } from "../store";
import {
  createReminderService,
  createReminderServiceWithBulkVehicles,
  getReminderServiceDetail,
  getReminderServicesList,
} from "./reducer";

interface InitialState {
  loading: boolean;
  count: number;
  reminderServiceList: ServiceReminderRead[];
  statistics: TStatisticsServiceReminder | null;
  currentReminderService: ServiceReminderDetail | null;
  currentReminderServiceWithBulkVehicles: ServiceReminderBulkCreateRequest | null;
  currentReminderServiceLoading: boolean;
}

const initialState: InitialState = {
  loading: false,
  count: 0,
  reminderServiceList: [],
  currentReminderService: null,
  statistics: null,
  currentReminderServiceLoading: false,
  currentReminderServiceWithBulkVehicles: null,
};

const reminderServiceSlice = createSlice({
  name: "reminderService",
  initialState,
  reducers: {
    clearCurrentServiceReminder(state) {
      state.currentReminderService = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReminderServicesList.fulfilled, (state, action) => {
      state.loading = false;
      state.reminderServiceList = action.payload.results || [];
      state.count = action.payload.count || 0;
      state.statistics = action.payload || null;
    });
    builder.addCase(getReminderServicesList.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getReminderServicesList.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getReminderServiceDetail.fulfilled, (state, action) => {
      state.loading = false;
      state.currentReminderService = action.payload;
      state.currentReminderServiceLoading = false;
    });
    builder.addCase(getReminderServiceDetail.pending, (state) => {
      state.loading = true;
      state.currentReminderServiceLoading = true;
    });
    builder.addCase(getReminderServiceDetail.rejected, (state) => {
      state.loading = false;
      state.currentReminderServiceLoading = false;
    });
    builder.addCase(createReminderService.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createReminderService.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(createReminderService.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(createReminderServiceWithBulkVehicles.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      createReminderServiceWithBulkVehicles.fulfilled,
      (state) => {
        state.loading = false;
      }
    );
    builder.addCase(createReminderServiceWithBulkVehicles.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { clearCurrentServiceReminder } = reminderServiceSlice.actions;

export const selectReminderServiceList = (state: RootState) =>
  state.reminderService.reminderServiceList;
export const selectReminderServiceListCount = (state: RootState) =>
  state.reminderService.count;
export const selectCurrentReminderServiceDetail = (state: RootState) =>
  state.reminderService.currentReminderService;
export const selectCurrentReminderServiceWithBulkVehicles = (
  state: RootState
) => state.reminderService.currentReminderServiceWithBulkVehicles;
export const selectCurrentReminderServiceDetailLoading = (state: RootState) =>
  state.reminderService.currentReminderServiceLoading;
export const selectReminderServiceLoading = (state: RootState) =>
  state.reminderService.loading;
export const selectReminderServiceStatistics = (state: RootState) =>
  state.reminderService.statistics;
export const selectCount = (state: RootState) => state.reminderService.count;

export default reminderServiceSlice;
