import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "Service/api/api";
import { IBillingInvoiceSearchParams } from "Shared/Types/invoice";

export const getInvoicesList = createAsyncThunk(
  "invoice/getInvoicesList",
  async (params: IBillingInvoiceSearchParams | null) => {
    try {
      const response = await api.invoice.getInvoicesList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getInvoiceInfo = createAsyncThunk(
  "invoice/getInvoiceInfo",
  async () => {
    try {
      const response = await api.invoice.getInvoiceInfo();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getCurrentInvoice = createAsyncThunk(
  "invoice/getCurrentInvoice",
  async () => {
    try {
      const response = await api.invoice.getCurrentInvoice();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
