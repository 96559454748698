import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
import React from "react";

const ReportsUsersRenewalRemindersList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsUsersRenewalReminders/ReportsUsersRenewalRemindersList/ReportsUsersRenewalRemindersList"
    )
);
export const ReportsUsersRenewalReminders = () => {
  return (
    <Layout>
      <ReportsUsersRenewalRemindersList />
    </Layout>
  );
};
