import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { getMe, login, restoreSession } from "./reducers";
import { IAccount, IAccountMe } from "Shared/Types/auth";
import { clearAll, write } from "Service/storage";

interface ICompanyRequestsState {
  account?: IAccount | null;
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  userProfile: IAccountMe | null;
}

const initialState: ICompanyRequestsState = {
  loading: false,
  account: null,
  error: null,
  userProfile: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout(state) {
      state.account = null;
      clearAll();
    },
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    setToken: (state, action) => {
      if (state.account) {
        state.account = { ...state.account, ...action.payload };
        write("access", action.payload.access);
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(restoreSession.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.account = action.payload.profile;
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.account = null;
      state.success = false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.account = action.payload;
      write("account", action.payload);
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.account = null;
    });

    builder.addCase(getMe.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = true;
      state.userProfile = action.payload;
    });
    builder.addCase(getMe.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getMe.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { logout, clear, setToken } = authSlice.actions;

export const selectAccount = (state: RootState) => state.auth.account;
export const selectAuthLoading = (state: RootState) => state.auth.loading;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectAuthSuccess = (state: RootState) => state.auth.success;
export const selectUserProfile = (state: RootState) => state.auth.userProfile;

export default authSlice;
