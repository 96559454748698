import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { dataMigrationExportData, getImportData } from "./reducer";
import { ImportSectionWithCount } from "Shared/Types/fleetmanagementapi";

interface IGlobalSearchTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  result: ImportSectionWithCount[] | null;
  loadingExport: boolean;
}

const initialState: IGlobalSearchTypeState = {
  loading: false,
  loadingExport: false,
  error: null,
  result: null,
};

const importDataSlice = createSlice({
  name: "importDataSlice",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getImportData.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.result = action.payload.data;
    });
    builder.addCase(getImportData.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(dataMigrationExportData.rejected, (state) => {
      state.loadingExport = false;
    });
    builder.addCase(dataMigrationExportData.pending, (state) => {
      state.loadingExport = true;
    });
    builder.addCase(dataMigrationExportData.fulfilled, (state) => {
      state.loadingExport = false;
    });
  },
});

export const { clear } = importDataSlice.actions;

export const selectImportDataResult = (state: RootState) =>
  state.importData.result;
export const selectExportLoading = (state: RootState) =>
  state.importData.loadingExport;
export default importDataSlice;
