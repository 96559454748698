import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
import React from "react";

const ReportsInspectionsSubmissionSummaryList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsInspectionsSubmissionList/ReportsInspectionsSubmissionListDashboard/ReportsInspectionsSubmissionListDashboard"
    )
);
export const ReportsInspectionsSubmissionList = () => {
  return (
    <Layout>
      <ReportsInspectionsSubmissionSummaryList />
    </Layout>
  );
};
