import { createSlice } from "@reduxjs/toolkit";

import { FuelEntryListMod } from "Shared/Types/fleetmanagementapi";
import { TFuelHistory, TFuelHistoryStatistics } from "Shared/Types/fuelHistory";

import { RootState } from "../store";
import {
  getCurrentFuelHistory,
  getFuelHistoryList,
  getFuelHistoryStatistics,
} from "./reducer";

interface fuelHistoryState {
  fuelHistoryList: {
    loading: boolean;
    fuelHistoryList: FuelEntryListMod[];
    numPage: number;
  };
  fuelHistoryStatistics: {
    loading: boolean;
    statistics: TFuelHistoryStatistics | null;
  };
  currentFuelHistory: {
    loading: boolean;
    currentFuelHistory: TFuelHistory | null;
  };
}

const initialState: fuelHistoryState = {
  fuelHistoryList: {
    loading: false,
    fuelHistoryList: [],
    numPage: 0,
  },
  fuelHistoryStatistics: {
    loading: false,
    statistics: null,
  },
  currentFuelHistory: {
    loading: false,
    currentFuelHistory: null,
  },
};

const fuelHistorySlice = createSlice({
  name: "fuelHistory",
  initialState,
  reducers: {
    clearCurrentFuelHistory(state) {
      state.currentFuelHistory.currentFuelHistory = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFuelHistoryList.pending, (state) => {
      state.fuelHistoryList.loading = true;
    });
    builder.addCase(getFuelHistoryList.fulfilled, (state, action) => {
      state.fuelHistoryList.loading = false;
      state.fuelHistoryList.fuelHistoryList = action.payload.results;
      state.fuelHistoryList.numPage = action.payload.count;
    });
    builder.addCase(getFuelHistoryList.rejected, (state) => {
      state.fuelHistoryList.loading = false;
    });
    builder.addCase(getFuelHistoryStatistics.pending, (state) => {
      state.fuelHistoryStatistics.loading = true;
    });
    builder.addCase(getFuelHistoryStatistics.fulfilled, (state, action) => {
      state.fuelHistoryStatistics.loading = false;
      state.fuelHistoryStatistics.statistics = action.payload;
    });
    builder.addCase(getFuelHistoryStatistics.rejected, (state) => {
      state.fuelHistoryStatistics.loading = false;
    });
    builder.addCase(getCurrentFuelHistory.pending, (state) => {
      state.currentFuelHistory.loading = true;
    });
    builder.addCase(getCurrentFuelHistory.fulfilled, (state, action) => {
      state.currentFuelHistory.loading = false;
      state.currentFuelHistory.currentFuelHistory = action.payload;
    });
    builder.addCase(getCurrentFuelHistory.rejected, (state) => {
      state.currentFuelHistory.loading = false;
    });
  },
});

export const { clearCurrentFuelHistory } = fuelHistorySlice.actions;

export const selectFuelHistoryList = (state: RootState) =>
  state.fuelHistory.fuelHistoryList.fuelHistoryList;
export const selectFuelHistoryNumPage = (state: RootState) =>
  state.fuelHistory.fuelHistoryList.numPage;
export const selectFuelHistoryLoading = (state: RootState) =>
  state.fuelHistory.fuelHistoryList.loading;

export const selectFuelHistoryStatistics = (state: RootState) =>
  state.fuelHistory.fuelHistoryStatistics.statistics;
export const selectFuelHistoryStatisticsLoading = (state: RootState) =>
  state.fuelHistory.fuelHistoryStatistics.loading;

export const selectCurrentFuelHistory = (state: RootState) =>
  state.fuelHistory.currentFuelHistory.currentFuelHistory;
export const selectCurrentFuelHistoryLoading = (state: RootState) =>
  state.fuelHistory.currentFuelHistory.loading;

export default fuelHistorySlice;
