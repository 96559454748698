import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IMeterHistorySearchParams,
  IMeterHistoryVoid,
} from "Shared/Types/meter-history";
import {
  IReducerWithCallback,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";
import {
  IMeterHistoryAddForm,
  IMeterHistoryUpdateForm,
} from "Shared/Utils/meter-history";

export const addMeterHistory = createAsyncThunk(
  "meterHisotry/addMeterHistory",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<IMeterHistoryAddForm>) => {
    try {
      const response = await api.meterHistory.addMeterHistory(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const getMeterHistorysList = createAsyncThunk(
  "meterHisotry/getMeterHistoryList",
  async (params: IMeterHistorySearchParams | null) => {
    try {
      const response = await api.meterHistory.getMeterHistoryList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getMeterHistory = createAsyncThunk(
  "meterHisotry/getMeterHistory",
  async (id: string) => {
    try {
      const response = await api.meterHistory.getMeterHistory(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const deleteMeterHistory = createAsyncThunk(
  "meterHisotry/deleteMeterHistory",
  async ({
    data: { id },
    onError,
    onSuccess,
  }: IReducerWithCallback<{ id: string }>) => {
    try {
      const response = await api.meterHistory.deleteMeterHistory(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const updateMeterHistory = createAsyncThunk(
  "meterHisotry/updateMeterHistory",
  async ({
    data,
    id,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<IMeterHistoryUpdateForm>) => {
    try {
      const response = await api.meterHistory.updateMeterHistory(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const voidMeterHistory = createAsyncThunk(
  "meterHisotry/updateMeterHistory",
  async ({
    data,
    id,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<IMeterHistoryVoid>) => {
    try {
      const response = await api.meterHistory.doVoidMeterHistory(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);
