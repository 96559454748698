import { client } from "Service/axios";
import {
  DATA_MIGRATION_EXPORT_DATA,
  DATA_MIGRATION_IMPORT_BULK,
  DATA_MIGRATION_IMPORT_DATA,
  DATA_MIGRATION_SECTIONS,
} from "Shared/Constants/apiRequest";
import { PaginatedResponse } from "Shared/Types/shared";
import {
  TExportSentData,
  TImportData,
  TImportSentData,
  TParamsImportBulk,
} from "Shared/Utils/yup/vehicleImport";

class ImportData {
  getImportType(params: any | null) {
    return client.get<PaginatedResponse<TImportData>>(DATA_MIGRATION_SECTIONS, {
      params,
    });
  }
  dataMigrationImportBulk({ section, csv_file }: TParamsImportBulk) {
    return client.post(DATA_MIGRATION_IMPORT_BULK, {
      csv_file: csv_file,
      section: section,
    });
  }

  dataMigrationImportData({ template_id, file }: TImportSentData) {
    return client.post(
      DATA_MIGRATION_IMPORT_DATA,
      {
        file,
        template_id,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
  }
  dataMigrationExportData(
    { template_id, export_scope, file_type }: TExportSentData,
    params?: any,
  ) {
    return client.post(
      DATA_MIGRATION_EXPORT_DATA,
      {
        export_scope,
        file_type,
        template_id,
      },
      { params },
    );
  }
}

export default ImportData;
