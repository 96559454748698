import { client } from "Service/axios";
import {
  VEHICLE_METER_ENTITY,
  VEHICLE_METER_ENTITY_CREATE,
  VEHICLE_METER_ENTITY_DELETE,
  VEHICLE_METER_ENTITY_ID,
  VEHICLE_METER_ENTITY_ID_UPDATE,
} from "Shared/Constants/apiRequest";
import {
  IMeterHistory,
  IMeterHistorySearchParams,
  IMeterHistoryVoid,
} from "Shared/Types/meter-history";
import { PaginatedResponse } from "Shared/Types/shared";
import {
  IMeterHistoryAddForm,
  IMeterHistoryUpdateForm,
} from "Shared/Utils/meter-history";

class MeterHistory {
  getMeterHistoryList(params?: IMeterHistorySearchParams | null) {
    return client.get<PaginatedResponse<IMeterHistory[]>>(
      VEHICLE_METER_ENTITY,
      {
        params,
      }
    );
  }
  getMeterHistory(id: string) {
    return client.get<IMeterHistory>(VEHICLE_METER_ENTITY_ID(id));
  }
  deleteMeterHistory(id: string) {
    return client.delete(VEHICLE_METER_ENTITY_DELETE(id));
  }
  addMeterHistory(data: IMeterHistoryAddForm) {
    return client.post<IMeterHistory>(VEHICLE_METER_ENTITY_CREATE, data);
  }
  updateMeterHistory(id: string, data: IMeterHistoryUpdateForm) {
    return client.patch<IMeterHistory>(
      VEHICLE_METER_ENTITY_ID_UPDATE(id),
      data
    );
  }
  doVoidMeterHistory(id: string, data: IMeterHistoryVoid) {
    return client.patch<IMeterHistory>(
      VEHICLE_METER_ENTITY_ID_UPDATE(id),
      data
    );
  }
}

export default MeterHistory;
