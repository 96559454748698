import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { INearVendor, IVendor, IVendorDetail } from "Shared/Types/vendor";
import {
  getNearestVendors,
  getNearestVendorsMore,
  getVehicleVendorsList,
  getVendor,
  getVendorsList,
  updateVendorDocuments,
} from "./reducer";

interface InitialState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  vendorsList: IVendor[];
  vehicleVendorsList: INearVendor[];
  nearestVendors: {
    list: INearVendor[];
    next: boolean | null;
    loading: boolean;
    error?: string | null;
  };
  vendor: IVendorDetail | null;
  numPage: number;
}

const initialState: InitialState = {
  loading: false,
  error: null,
  vendorsList: [],
  vehicleVendorsList: [],
  nearestVendors: {
    list: [],
    next: null,
    loading: false,
  },
  vendor: null,
  numPage: 0,
};

const vendorSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    clearCurrentVendor(state) {
      state.vendor = null;
    },
    clearNearestVendors(state) {
      state.nearestVendors = initialState.nearestVendors;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVendorsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.vendorsList = action.payload.results;
      state.numPage = action.payload.count;
    });
    builder.addCase(getVendorsList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getVendorsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getNearestVendors.fulfilled, (state, action) => {
      state.nearestVendors.loading = false;
      state.nearestVendors.error = null;
      state.nearestVendors.list = action.payload.results;
      state.nearestVendors.next = !!action.payload.next;
    });
    builder.addCase(getNearestVendors.pending, (state) => {
      state.nearestVendors.loading = true;
      state.nearestVendors.error = null;
    });
    builder.addCase(getNearestVendors.rejected, (state, action) => {
      state.nearestVendors.loading = false;
      state.nearestVendors.error = action.error.message;
    });
    builder.addCase(getNearestVendorsMore.fulfilled, (state, action) => {
      state.nearestVendors.loading = false;
      state.nearestVendors.error = null;
      state.nearestVendors.list = [
        ...state.nearestVendors.list,
        ...action.payload.results,
      ];
      state.nearestVendors.next = !!action.payload.next;
    });
    builder.addCase(getNearestVendorsMore.pending, (state) => {
      state.nearestVendors.loading = true;
      state.nearestVendors.error = null;
    });
    builder.addCase(getNearestVendorsMore.rejected, (state, action) => {
      state.nearestVendors.loading = false;
      state.nearestVendors.error = action.error.message;
    });
    builder.addCase(getVehicleVendorsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.vehicleVendorsList = action.payload.results;
    });
    builder.addCase(getVehicleVendorsList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getVehicleVendorsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getVendor.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.vendor = action.payload;
    });
    builder.addCase(getVendor.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getVendor.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateVendorDocuments.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
    });
    builder.addCase(updateVendorDocuments.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(updateVendorDocuments.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear, clearCurrentVendor, clearNearestVendors } =
  vendorSlice.actions;

export const selectVendorList = (state: RootState) => state.vendors.vendorsList;
export const selectVehicleVendorList = (state: RootState) =>
  state.vendors.vehicleVendorsList;
export const selectVendor = (state: RootState) => state.vendors.vendor;
export const selectVendorLoading = (state: RootState) => state.vendors.loading;
export const selectVendorError = (state: RootState) => state.vendors.error;
export const selectVendorSuccess = (state: RootState) => state.vendors.success;
export const selectNearestVendors = (state: RootState) =>
  state.vendors.nearestVendors;
export default vendorSlice;
