import { useMemo } from "react";
import type { Location } from "history";

import { useSelector } from "react-redux";
import { selectAccount } from "Redux/Auth/slice";
import { readObj } from "Service/storage";

import { IAccount } from "../Types/auth";

export const usePermissions = () => {
  const profile = useSelector(selectAccount);

  return useMemo<Set<string>>(
    () => new Set<string>(profile?.user_permissions || []),
    [profile]
  );
};

export const useAuthRedirect = ({ pathname }: Location) => {
  const profile =
    useSelector(selectAccount) || (readObj("account") as IAccount);

  const pagesWithoutAuth = [
    "/login",
    "/reset-password-email",
    "/register",
    "/healthcheck",
  ];

  if (profile == null || !profile.is_accepted_from_admin) {
    if (
      pagesWithoutAuth.includes(pathname) ||
      pathname.startsWith("/verification") ||
      pathname.startsWith("/reset-password") ||
      pathname.startsWith("/contact/setup-password")
    )
      return;

    return "/login";
  } else if (pathname === "/login") {
    if (profile.has_completed_onboarding || !profile.is_owner) {
      if (profile?.domain?.domain_status === "registered") {
        if (profile.domain.domain) {
          window.location.replace(
            `https://${profile.domain?.domain}.easyfleet.ai/units`
          );
        }
        return;
      } else if (!profile.domain?.domain) {
        window.location.replace(`https://app.easyfleet.ai/units`);
      } else {
        return "/units";
      }
    }
    return "/onboarding-form";
  }
};
