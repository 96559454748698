import { createSlice } from "@reduxjs/toolkit";
import {
  VehicleRenewalList,
  VehicleRenewalRetrieve,
  VehicleRenewalTypeList,
} from "Shared/Types/fleetmanagementapi";
import {
  getReminderVehicleById,
  getReminderVehicleList,
  getReminderVehicleTypeList,
} from "./reducer";
import { RootState } from "Redux/store";

type State = {
  reminderVehicleList: {
    loading: boolean;
    reminderVehicleList: VehicleRenewalList[];
    count: number;
  };
  currentVehicleReminder: {
    loading: boolean;
    currentVehicleReminder: VehicleRenewalRetrieve | null;
  };
  reminderVehicleType: VehicleRenewalTypeList[];
};

const initialState: State = {
  reminderVehicleList: {
    loading: false,
    reminderVehicleList: [],
    count: 0,
  },
  currentVehicleReminder: {
    loading: false,
    currentVehicleReminder: null,
  },
  reminderVehicleType: [],
};

const reminderVehicleSlice = createSlice({
  name: "reminder",
  initialState,
  reducers: {
    clearCurrentReminderVehicle(state) {
      state.currentVehicleReminder.currentVehicleReminder = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getReminderVehicleList.pending, (state) => {
      state.reminderVehicleList.loading = true;
    });
    builder.addCase(getReminderVehicleList.fulfilled, (state, action) => {
      state.reminderVehicleList.loading = false;
      state.reminderVehicleList.reminderVehicleList = action.payload.results;
      state.reminderVehicleList.count = action.payload.count;
    });
    builder.addCase(getReminderVehicleById.pending, (state) => {
      state.currentVehicleReminder.loading = true;
    });
    builder.addCase(getReminderVehicleById.fulfilled, (state, action) => {
      state.currentVehicleReminder.loading = false;
      state.currentVehicleReminder.currentVehicleReminder = action.payload;
    });
    builder.addCase(getReminderVehicleTypeList.fulfilled, (state, action) => {
      state.reminderVehicleType = action.payload;
    });
  },
});

export const { clearCurrentReminderVehicle } = reminderVehicleSlice.actions;

export const selectCurrentReminderVehicleLoading = (state: RootState) =>
  state.reminderVehilce.currentVehicleReminder.loading;
export const selectCurrentReminderVehicle = (state: RootState) =>
  state.reminderVehilce.currentVehicleReminder.currentVehicleReminder;
export const selectReminderVehicleList = (state: RootState) =>
  state.reminderVehilce.reminderVehicleList.reminderVehicleList;
export const selectReminderVehicleListCount = (state: RootState) =>
  state.reminderVehilce.reminderVehicleList.count;
export const selectReminderVehicleListLoading = (state: RootState) =>
  state.reminderVehilce.reminderVehicleList.loading;
export const selectReminderVehicleTypeList = (state: RootState) =>
  state.reminderVehilce.reminderVehicleType;

export default reminderVehicleSlice;
