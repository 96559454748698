import React, { useEffect, useMemo, useState } from "react";
import { create, antdModalV5, useModal } from "@ebay/nice-modal-react";
import { DialogProps as MUIDialogProps } from "@mui/material";
import Button from "Components/UI/Button/Button";
import cn from "classnames";
import styles from "./ExportFilesModal.module.scss";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  selectExportLoading,
  selectImportDataResult,
} from "Redux/ImportData/slice";
import { Loader } from "Components/UI/Loader";

import Checkbox from "Components/UI/Checkbox";
import Modal from "Components/UI/Modal/Modal";
import { useAppDispatch } from "Redux/store";
import {
  dataMigrationExportData,
  getImportData,
} from "Redux/ImportData/reducer";
import { getOffset } from "Shared/Utils/shared";
import { useSearchParams } from "react-router-dom";
import { LIMIT_PAGE, TEMPLATE_LIMIT } from "Shared/Constants/app";
import { importSectionNames } from "Shared/Utils/yup/vehicleImport";
import { notifyError } from "Components/Notify/notify";
import { ModalSuccess } from "Components/UI/ ModalSuccess/ ModalSuccess";

type Props = Omit<MUIDialogProps, "onClick"> & {
  template: string;
  currentParams: any;
};

export const ExportFilesModal = create<Props>(
  ({ template, currentParams, ...props }) => {
    const modal = useModal();
    const onSuccessModal = useModal(ModalSuccess);
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const [currentTemplate, setCurrentTemplate] = useState<any>();
    const sectionsExport = useSelector(selectImportDataResult);
    const loading = useSelector(selectExportLoading);
    const { handleSubmit, control, setValue } = useForm({
      defaultValues: {
        visible: false,
        all: true,
      },
    });
    const templates = useMemo(() => {
      return (
        sectionsExport?.flatMap((section) =>
          section.templates.map((template) => ({
            name: importSectionNames[template.code_name] || template.name,
            id: template.id,
            empty_file: template.empty_file,
            code_name: template.code_name,
          })),
        ) || []
      );
    }, [sectionsExport]);

    const onCancel = () => modal.hide();
    const isOpen = modal.visible;
    const onSuccess = (data: any) => {
      const blob = new Blob([data], { type: "text/csv" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${currentTemplate?.code_name || "export"}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      modal.hide();
      onSuccessModal.show({ dynamicText: currentTemplate?.name || "Page" });
    };
    const onError = () => {
      notifyError("Failed to export!");
    };
    const handleSubmitLogic = async (data: any) => {
      dispatch(
        dataMigrationExportData({
          data: {
            template_id: currentTemplate?.id,
            file_type: "csv",
            export_scope: data.visible ? "basic" : "detailed",
          },
          onSuccess,
          onError,
          params: currentParams,
        }),
      );
    };

    useEffect(() => {
      const limitValue = +(searchParams.get("limit") || TEMPLATE_LIMIT);
      const params = {
        offset: getOffset(+(searchParams.get("page") || 1), limitValue),
        limit: limitValue,
      };
      if (isOpen) {
        dispatch(getImportData(params));
      }
    }, [isOpen, dispatch]);
    useEffect(() => {
      if (templates.length > 0 && template) {
        setCurrentTemplate(
          templates.find((item) => template === item.code_name),
        );
      }
    }, [template, templates]);
    return (
      <Modal
        {...antdModalV5(modal)}
        {...props}
        classes={{
          paper: cn(styles.container),
          scrollPaper: styles.scroll,
        }}
        onClose={onCancel}
        title={currentTemplate && `Export ${currentTemplate?.name}`}
        isClosed={true}
      >
        {!currentTemplate && (
          <div className={styles.loader}>
            <Loader size={100} />{" "}
          </div>
        )}
        {currentTemplate && (
          <form onSubmit={handleSubmit(handleSubmitLogic)}>
            <div className={styles.wrapper}>
              <div className={styles.wrapperCheckbox}>
                <Controller
                  name="visible"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      size="small"
                      labelClassName={styles.label}
                      labelPosition="right"
                      label="Visible Columns"
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        if (e.target.checked) {
                          setValue("all", false);
                        }
                      }}
                    />
                  )}
                />
                <p className={styles.description}>
                  Export only the columns that are visible on the page.
                </p>
              </div>
              <div className={styles.wrapperCheckbox}>
                <Controller
                  name="all"
                  control={control}
                  defaultValue={true}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      size="small"
                      labelPosition="right"
                      label="All Columns"
                      labelClassName={styles.label}
                      checked={field.value}
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        if (e.target.checked) {
                          setValue("visible", false);
                        }
                      }}
                    />
                  )}
                />
                <p className={styles.description}>
                  Export all available columns.
                </p>
              </div>
            </div>
            <div className={styles.btns}>
              <Button
                color="secondary"
                type="button"
                onClick={onCancel}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" disabled={loading}>
                {loading ? <Loader /> : "Export"}
              </Button>
            </div>
          </form>
        )}
      </Modal>
    );
  },
);
