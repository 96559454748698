import { client } from "Service/axios";
import {
  ISSUES_FAULTS,
  ISSUES_FAULTS_CODES,
  ISSUES_FAULTS_ID_IGNORE,
  ISSUES_FAULTS_RULES,
  ISSUES_FAULTS_RULES_CREATE,
  ISSUES_FAULTS_RULES_ID,
  ISSUES_FAULTS_RULES_ID_DELETE,
  ISSUES_FAULTS_RULES_ID_UPDATE,
  ISSUES_ID_FAULTS,
} from "Shared/Constants/apiRequest";
import {
  IAddFaultsRule,
  IFaultsListSearch,
  IFaultsRule,
  IFaultsRulesSearch,
} from "Shared/Types/faults";

class FaultsType {
  getFaultsList(params: IFaultsListSearch | null) {
    return client.get<any>(ISSUES_FAULTS, { params });
  }
  getFault(id: string) {
    return client.get<any>(ISSUES_ID_FAULTS(id));
  }
  getFaultsCodesList() {
    return client.get<any>(ISSUES_FAULTS_CODES);
  }
  getFaultsRulesList(params: IFaultsRulesSearch | null) {
    return client.get<any>(ISSUES_FAULTS_RULES, { params });
  }
  getFaultsRule(id: string) {
    return client.get<any>(ISSUES_FAULTS_RULES_ID(id));
  }
  createFaultsRule(data: IAddFaultsRule) {
    return client.post<any>(ISSUES_FAULTS_RULES_CREATE, data);
  }
  updateFaultsRule(data: IFaultsRule) {
    return client.patch<any>(ISSUES_FAULTS_RULES_ID_UPDATE(data.id), data);
  }
  ignoreFault(id: string) {
    return client.patch<any>(ISSUES_FAULTS_ID_IGNORE(id), {
      status: "ignored",
    });
  }
  deleteFaultsRule(id: string) {
    return client.delete<any>(ISSUES_FAULTS_RULES_ID_DELETE(id));
  }
}

export default FaultsType;
