import { createSlice } from "@reduxjs/toolkit";
import { IDriveType } from "Shared/Types/drive";
import { RootState } from "../store";
import { addDriveType, getDriveTypeList } from "./DriveTypeReducer";

interface bodyTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  driveTypeList: IDriveType[];
}

const initialState: bodyTypeState = {
  loading: false,
  error: null,
  driveTypeList: [],
};

const DriveTypeSlice = createSlice({
  name: "driveType",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDriveTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.driveTypeList = action.payload.results;
    });
    builder.addCase(addDriveType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addDriveType.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addDriveType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = DriveTypeSlice.actions;

export const selectDriveTypeList = (state: RootState) =>
  state.driveType.driveTypeList;
export const selectDriveTypeAuthLoading = (state: RootState) =>
  state.driveType.loading;
export const selectDriveTypeAuthError = (state: RootState) =>
  state.driveType.error;
export const selectDriveTypeAuthSuccess = (state: RootState) =>
  state.driveType.success;

export default DriveTypeSlice;
