import { client } from "Service/axios";
import {
  IIssue,
  IIssueCreate,
  IIssueList,
  IIssueNote,
  IIssueSearchParams,
  IIssueTimeLine,
} from "Shared/Types/issue";

import {
  ISSUES,
  ISSUES_CHART_ANALYTICS,
  ISSUES_CREATE,
  ISSUES_ID,
  ISSUES_ID_BULK_ADD_FILES,
  ISSUES_ID_BULK_REMOVE_FILES,
  ISSUES_ID_COMMENTS_CREATE,
  ISSUES_ID_COMMENTS_DELETE,
  ISSUES_ID_DELETE,
  ISSUES_ID_FILES,
  ISSUES_ID_ISSUES_TIMELINE,
  ISSUES_ID_REOPEN,
  ISSUES_ID_RESOLVE_WITH_NOTES,
  ISSUES_ID_UPDATE,
} from "Shared/Constants/apiRequest";
import { IComment, IIssueCommentAdd } from "Shared/Types/comment";
import { IAddFiles } from "Shared/Types/file";
import { IssueDashboardResponse } from "Shared/Types/fleetmanagementapi";

class Issue {
  getIssueList(params: IIssueSearchParams | null) {
    return client.get<IIssueList>(ISSUES, { params });
  }
  getIssue(id: string) {
    return client.get<IIssue>(ISSUES_ID(id));
  }
  addIssue(data: IIssueCreate) {
    return client.post<IIssue>(ISSUES_CREATE, data);
  }
  updateIssue(id: string, data: IIssueCreate) {
    return client.patch<IIssue>(ISSUES_ID_UPDATE(id), data);
  }
  deleteIssue(id: string) {
    return client.delete(ISSUES_ID_DELETE(id));
  }
  getIssueTimeLine(id: string) {
    return client.get<IIssueTimeLine>(ISSUES_ID_ISSUES_TIMELINE(id));
  }
  reopenIssue(id: string) {
    return client.post(ISSUES_ID_REOPEN(id));
  }
  resoliveWithNotes(id: string, data: IIssueNote) {
    return client.post(ISSUES_ID_RESOLVE_WITH_NOTES(id), data);
  }

  getIssueFilesList(id: string) {
    return client.get(ISSUES_ID_FILES(id));
  }
  addFilesToIssue({ id, files }: IAddFiles) {
    return client.post(ISSUES_ID_BULK_ADD_FILES(id), { files });
  }
  removeFileFromIssue({ id, files }: IAddFiles) {
    return client.post(ISSUES_ID_BULK_REMOVE_FILES(id), { files });
  }

  // Comments
  saveComment(data: IIssueCommentAdd) {
    return client.post<IComment>(ISSUES_ID_COMMENTS_CREATE, data);
  }
  deleteComment(id: string) {
    return client.delete(ISSUES_ID_COMMENTS_DELETE(id));
  }

  //   Analytics
  getIssueChartAnalytics() {
    return client.get<IssueDashboardResponse>(ISSUES_CHART_ANALYTICS);
  }
}

export default Issue;
