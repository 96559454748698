import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const FormAddEquipment = lazyLoader(
  () => import("Containers/Equipment/FormEquipment/FormAddEquipment")
);

export const CreateEquipment = () => {
  return (
    <Layout>
      <FormAddEquipment />
    </Layout>
  );
};
