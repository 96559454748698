import { ImportSectionWithCount } from "Shared/Types/fleetmanagementapi";

export type TImportData = {
  data: ImportSectionWithCount[];
  overall_templates_count: number;
};

export type TParamsImportBulk = {
  section: string;
  csv_file: string;
};
export type TImportSentData = {
  file: File | string;
  template_id: string;
};
export type TExportSentData = Pick<TImportSentData, "template_id"> & {
  file_type: string;
  export_scope: "basic" | "detailed";
};

export const importSectionNames: { [key: string]: string } = {
  contacts: "Contacts",
  // users: "Users",
  // contact_renewals: "Contact Renewal",
  // fuel_history: "Fuel History",
  issues: "Issue",
  // service_reminders: "Service Reminders",
  // service_task: "Service Tasks",
  vehicle_list: "Units",
  vendors: "Vendors",
  // work_order: "Work Orders",
  // purchase_orders: "Purchase Orders",
  // inspection_history: "Inspection History",
  // meter_history: "Meter History",
  // faults: "Faults",
  // parts: "Parts",
  // vehicle_renewals: "Vehicle Renewals",
  // service_history: "Service History",
};
