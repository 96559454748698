import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IAddBodyType, IBodyTypeSearchList } from "Shared/Types/bodyType";

const getBodyTypeList = createAsyncThunk(
  "bodyType/getBodyTypeList",
  async (params: IBodyTypeSearchList | null) => {
    try {
      const response = await api.bodyType.getBodyTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addBodyType = createAsyncThunk(
  "bodyType/addBodyType",
  async (data: IAddBodyType, { dispatch }) => {
    try {
      const response = await api.bodyType.addBodyType(data);
      dispatch(getBodyTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addBodyType, getBodyTypeList };
