import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";

interface IBreadcrumbs {
  links: BreadcrumbItem[];
}

const initialState: IBreadcrumbs = {
  links: [],
};

const breadcrumbSlice = createSlice({
  name: "companyRequest",
  initialState,
  reducers: {
    pushBreadcrumb(state, action) {
      state.links = action.payload;
    },
    clearBreadcrumb(state) {
      state.links = [];
    },
  },
});

export const { pushBreadcrumb, clearBreadcrumb } = breadcrumbSlice.actions;

export const selectBreadcrumb = (state: RootState) => state.breadcrumb.links;

export default breadcrumbSlice;
