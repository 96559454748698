import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

const ReportsServiceHistoryByVehicleList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsServiceHistoryByVehicle/ReportsServiceHistoryByVehicleList/ReportsServiceHistoryByVehicleList"
    )
);
export const ReportsServiceHistoryByVehicle = () => {
  return (
    <Layout>
      <ReportsServiceHistoryByVehicleList />
    </Layout>
  );
};
