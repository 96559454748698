import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../Service/api/api";
import { IAddAssignment } from "../../Shared/Types/assignment";
import { clearCurrentAssignment } from "./slice";

export const addAssignment = createAsyncThunk(
  "assignment/addAssignment",
  async (data: IAddAssignment, { dispatch }) => {
    try {
      const response = await api.assignment.addAssignment(data);
      dispatch(getAssignmentsList());
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getAssignmentsList = createAsyncThunk(
  "assignment/getAssignmentsList",
  async () => {
    try {
      const response = await api.assignment.getAssignmentsList();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getAssignment = createAsyncThunk(
  "assignment/getAssignment",
  async (id: string) => {
    try {
      const response = await api.assignment.getAssignment(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const deleteAssignment = createAsyncThunk(
  "assignment/deleteAssignment",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.assignment.deleteAssignment(id);
      clearCurrentAssignment();
      dispatch(getAssignmentsList());
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const updateAssignment = createAsyncThunk(
  "assignment/updateAssignment",
  async (data: { id: string; values: IAddAssignment }, { dispatch }) => {
    try {
      const response = await api.assignment.updateAssignment(data);
      dispatch(getAssignmentsList());
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
