import { createSlice } from "@reduxjs/toolkit";

import {
  AssignmentDetail,
  AssignmentList,
  RecentCommentDashboard,
  VehicleCalendarIssues,
} from "Shared/Types/fleetmanagementapi";

import { RootState } from "../store";
import {
  getDashboardReminders,
  getDashboardVehicleAssignment,
  getDashboardVehicleAssignmentDetail,
  getDashboardIssuesCalendar,
  getDashboardWorkOrder,
  getVehicleStatus,
  getDashboardRecentComments,
  getDashboardInspectionSubmission,
  getDashboardInspectionSubmissionOutcomes,
  getDashboardLocation,
  getDashboardIssuesStatus,
} from "./reducer";
import {
  DashboardInspectionSubmission,
  DashboardInspectionSubmissionOutcomes,
  DashboardIssuesStatus,
  DashboardLocation,
  DashboardReminder,
  DashboardVehicleStatus,
  DashboardWorkOrder,
} from "Shared/Types/dashboard";

interface operatorState {
  vehicleStatusLoading: boolean;
  vehicleStatus: DashboardVehicleStatus;
  dashboardIssuesStatusLoading: boolean;
  dashboardInspectionSubmissionLoading: boolean;
  dashboardInspectionSubmissionOutcomesLoading: boolean;
  vehicleStatusError: string | undefined | null;
  dashboardReminders: DashboardReminder;
  dashboardRemindersLoading: boolean;
  dashboardRemindersCount: number;
  dashboardWorkOrder: DashboardWorkOrder;
  dashboardWorkOrderLoading: boolean;
  dashboardLocation: DashboardLocation;
  dashboardIssuesStatus: DashboardIssuesStatus;
  dashboardInspectionSubmission: DashboardInspectionSubmission;
  dashboardInspectionSubmissionOutcomes: DashboardInspectionSubmissionOutcomes;
}

interface InitialState {
  loading: boolean;
  count?: number;
  loadingLocation: boolean;
  error: string | null | undefined;
  vehicleAssignment: {
    vehicleAssignmentList: AssignmentList[];
    loading: boolean;
  };
  vehicleAssignmentDetail: {
    vehicleAssignmentDetail: AssignmentDetail | null;
    loading: boolean;
  };
  issuesCalendarList: {
    issuesCalendarList: VehicleCalendarIssues[];
    loading: boolean;
  };
  dashboardRecentComment: RecentCommentDashboard[];
}

const initialState: InitialState & operatorState = {
  loading: false,
  count: 0,
  error: null,
  dashboardIssuesStatusLoading: false,
  dashboardInspectionSubmissionLoading: false,
  dashboardInspectionSubmissionOutcomesLoading: false,
  loadingLocation: false,
  dashboardLocation: {
    states: [],
  },
  dashboardInspectionSubmission: {
    current_week: [],
    previous_week: [],
  },
  dashboardInspectionSubmissionOutcomes: {
    data: [],
  },
  dashboardIssuesStatus: {
    bar_data: [],
    counts: { all: 0, overdue: 0, open: 0, resolved: 0 },
  },
  vehicleAssignment: {
    vehicleAssignmentList: [],
    loading: false,
  },
  vehicleAssignmentDetail: {
    vehicleAssignmentDetail: null,
    loading: false,
  },

  dashboardRemindersLoading: false,
  dashboardRemindersCount: 0,
  dashboardWorkOrder: {
    results: [],
  },
  dashboardWorkOrderLoading: false,
  dashboardReminders: {
    count: 0,
    reminders_count: [
      {
        count: 0,
        type: "service_reminder",
      },
    ],
    results: [
      {
        associated_item: "",
        id: "",
        type: "contact_renewal",
        status: "",
        date: "",
        service_count: 0,
        vehicle_count: 0,
        contact_first_name: "",
        contact_last_name: "",
        contact_count: 0,
      },
    ],
  },

  vehicleStatusLoading: false,
  vehicleStatus: {
    active: {
      color: "",
      count: 0,
    },
    in_shop: {
      color: "",
      count: 0,
    },
    inactive: {
      color: "",
      count: 0,
    },
    out_of_service: {
      color: "",
      count: 0,
    },
    downtime: {
      color: "",
      count: 0,
    },
    sold: {
      color: "",
      count: 0,
    },
  },
  vehicleStatusError: null,
  issuesCalendarList: {
    issuesCalendarList: [],
    loading: false,
  },
  dashboardRecentComment: [],
};

const dashboardSlice = createSlice({
  name: "dashboardSlice",
  initialState,
  reducers: {
    clearCurrentVehicleAssignmentDetail(state) {
      state.vehicleAssignmentDetail.vehicleAssignmentDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getDashboardVehicleAssignment.fulfilled,
      (state, action) => {
        state.vehicleAssignment.loading = false;
        state.vehicleAssignment.vehicleAssignmentList =
          action.payload.assignments || [];
      }
    );
    builder.addCase(getDashboardVehicleAssignment.pending, (state) => {
      state.vehicleAssignment.loading = true;
    });
    builder.addCase(getDashboardVehicleAssignment.rejected, (state) => {
      state.vehicleAssignment.loading = false;
    });
    builder.addCase(
      getDashboardVehicleAssignmentDetail.fulfilled,
      (state, action) => {
        state.vehicleAssignmentDetail.loading = false;
        state.vehicleAssignmentDetail.vehicleAssignmentDetail = action.payload;
      }
    );
    builder.addCase(getDashboardVehicleAssignmentDetail.pending, (state) => {
      state.vehicleAssignmentDetail.loading = true;
    });
    builder
      .addCase(getVehicleStatus.pending, (state) => {
        state.vehicleStatusLoading = true;
      })
      .addCase(getVehicleStatus.fulfilled, (state, action) => {
        state.vehicleStatusLoading = false;
        state.vehicleStatusError = null;
        state.vehicleStatus = action.payload;
      })
      .addCase(getVehicleStatus.rejected, (state, action) => {
        state.vehicleStatusLoading = false;
        state.vehicleStatusError = action.error.message;
      })
      .addCase(getDashboardReminders.pending, (state) => {
        state.dashboardRemindersLoading = true;
      })
      .addCase(getDashboardReminders.fulfilled, (state, action) => {
        state.dashboardRemindersLoading = false;
        state.dashboardReminders = action.payload;
        state.dashboardRemindersCount = action.payload.count;
        state.error = null;
      })
      .addCase(getDashboardReminders.rejected, (state, action) => {
        state.dashboardRemindersLoading = false;
        state.error = action.error.message;
      })
      .addCase(getDashboardWorkOrder.pending, (state) => {
        state.dashboardWorkOrderLoading = true;
      })
      .addCase(getDashboardWorkOrder.fulfilled, (state, action) => {
        state.dashboardWorkOrderLoading = false;
        state.error = null;
        state.dashboardWorkOrder = action.payload;
      })
      .addCase(getDashboardWorkOrder.rejected, (state, action) => {
        state.dashboardWorkOrderLoading = false;
        state.error = action.error.message;
      })
      .addCase(getDashboardIssuesCalendar.pending, (state) => {
        state.issuesCalendarList.loading = true;
      })
      .addCase(getDashboardIssuesCalendar.fulfilled, (state, action) => {
        state.issuesCalendarList.loading = false;
        state.issuesCalendarList.issuesCalendarList = action.payload.vehicles;
      })
      .addCase(getDashboardIssuesCalendar.rejected, (state, action) => {
        state.issuesCalendarList.loading = false;
      })
      .addCase(getDashboardRecentComments.fulfilled, (state, action) => {
        state.dashboardRecentComment = action.payload.results || [];
      })
      .addCase(getDashboardRecentComments.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(getDashboardInspectionSubmission.fulfilled, (state, action) => {
        state.dashboardInspectionSubmission = action.payload || [];
        state.dashboardInspectionSubmissionLoading = false;
      })
      .addCase(getDashboardInspectionSubmission.rejected, (state, action) => {
        state.error = action.error.message;
        state.dashboardInspectionSubmissionLoading = false;
      })
      .addCase(getDashboardInspectionSubmission.pending, (state) => {
        state.dashboardInspectionSubmissionLoading = true;
      })
      .addCase(
        getDashboardInspectionSubmissionOutcomes.fulfilled,
        (state, action) => {
          state.dashboardInspectionSubmissionOutcomes = action.payload || [];
          state.dashboardInspectionSubmissionOutcomesLoading = false;
        }
      )
      .addCase(
        getDashboardInspectionSubmissionOutcomes.rejected,
        (state, action) => {
          state.error = action.error.message;
          state.dashboardInspectionSubmissionOutcomesLoading = false;
        }
      )
      .addCase(getDashboardInspectionSubmissionOutcomes.pending, (state) => {
        state.dashboardInspectionSubmissionOutcomesLoading = true;
      })
      .addCase(getDashboardLocation.fulfilled, (state, action) => {
        state.loadingLocation = false;
        state.dashboardLocation = action.payload || [];
      })
      .addCase(getDashboardLocation.rejected, (state, action) => {
        state.error = action.error.message;
        state.loadingLocation = false;
      })
      .addCase(getDashboardLocation.pending, (state) => {
        state.loadingLocation = true;
      })
      .addCase(getDashboardIssuesStatus.fulfilled, (state, action) => {
        state.dashboardIssuesStatusLoading = false;
        state.dashboardIssuesStatus = action.payload || [];
      })
      .addCase(getDashboardIssuesStatus.rejected, (state, action) => {
        state.error = action.error.message;
        state.dashboardIssuesStatusLoading = false;
      })
      .addCase(getDashboardIssuesStatus.pending, (state) => {
        state.dashboardIssuesStatusLoading = true;
      });
  },
});

export const { clearCurrentVehicleAssignmentDetail } = dashboardSlice.actions;

export const selectDashboardVehicleAssignmentList = (state: RootState) =>
  state.dashboard.vehicleAssignment.vehicleAssignmentList;
export const selectDashboardInspectionSubmissionWeekly = (state: RootState) =>
  state.dashboard.dashboardInspectionSubmission;
export const selectDashboardInspectionSubmissionWeeklyLoading = (
  state: RootState
) => state.dashboard.dashboardInspectionSubmissionLoading;
export const selectDashboardInspectionSubmissionWeeklyOutcomes = (
  state: RootState
) => state.dashboard.dashboardInspectionSubmissionOutcomes;

export const selectDashboardInspectionSubmissionWeeklyOutcomesLoading = (
  state: RootState
) => state.dashboard.dashboardInspectionSubmissionOutcomesLoading;

export const selectDashboardVehicleAssignmentLoading = (state: RootState) =>
  state.dashboard.vehicleAssignment.loading;
export const selectDashboardVehicleAssignmentDetail = (state: RootState) =>
  state.dashboard.vehicleAssignmentDetail.vehicleAssignmentDetail;
export const selectDashboardVehicleAssignmentDetailLoading = (
  state: RootState
) => state.dashboard.vehicleAssignmentDetail.loading;
export const selectDashboardVehicleStatus = (state: RootState) =>
  state.dashboard.vehicleStatus as DashboardVehicleStatus;
export const selectDashboardVehicleStatusLoading = (state: RootState) =>
  state.dashboard.vehicleStatusLoading;
export const selectDashboardVehicleStatusError = (state: RootState) =>
  state.dashboard.vehicleStatusError;
export const selectDashboardIssuesCalendarLoading = (state: RootState) =>
  state.dashboard.issuesCalendarList.loading;
export const selectDashboardIssuesCalendarList = (state: RootState) =>
  state.dashboard.issuesCalendarList.issuesCalendarList;
export const selectDashboardRemindersLoading = (state: RootState) =>
  state.dashboard.dashboardRemindersLoading;
export const selectDashboardReminders = (state: RootState) =>
  state.dashboard.dashboardReminders;
export const selectDashboardRemindersCount = (state: RootState) =>
  state.dashboard.dashboardRemindersCount;
export const selectDashboardLocation = (state: RootState) =>
  state.dashboard.dashboardLocation;
export const selectDashboardLocationLoading = (state: RootState) =>
  state.dashboard.loadingLocation;
export const selectDashboardWorkOrderLoading = (state: RootState) =>
  state.dashboard.dashboardWorkOrderLoading;
export const selectDashboardWorkOrder = (state: RootState) =>
  state.dashboard.dashboardWorkOrder;
export const selectDashboardRecentComments = (state: RootState) =>
  state.dashboard.dashboardRecentComment;
export const selectDashboardIssuesStatusLoading = (state: RootState) =>
  state.dashboard.dashboardIssuesStatusLoading;
export const selectDashboardIssuesStatus = (state: RootState) =>
  state.dashboard.dashboardIssuesStatus;
export default dashboardSlice;
