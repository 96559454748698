import { client } from "Service/axios";
import {
  SERVICES,
  SERVICES_COMMENTS_CREATE,
  SERVICES_COMMENTS_DELETE,
  SERVICES_CREATE,
  SERVICES_ID,
  SERVICES_ID_BULK_ADD_DOCUMENTS,
  SERVICES_ID_BULK_REMOVE_DOCUMENTS,
  SERVICES_ID_DELETE,
  SERVICES_ID_FILES,
  SERVICES_ID_UPDATE,
} from "Shared/Constants/apiRequest";
import { IComment, IServicesCommentAdd } from "Shared/Types/comment";
import { IAddDocuments, IAddFiles } from "Shared/Types/file";
import { IDetailService, IServicesSearch } from "Shared/Types/services-history";
import { IAddServices } from "Shared/Utils/yup/service-history";

class ServicesHistory {
  getServicesList(params: IServicesSearch | null) {
    return client.get<any>(SERVICES, { params });
  }
  getServicesId(id: string) {
    return client.get<IDetailService>(SERVICES_ID(id));
  }

  deleteServiceHistory(id: string) {
    return client.delete<any>(SERVICES_ID_DELETE(id));
  }
  updateServiceHistory(id: string) {
    return client.put<any>(SERVICES_ID_UPDATE(id));
  }
  updateServiceHistoryPatch(id: string, data: IAddServices) {
    return client.patch(SERVICES_ID_UPDATE(id), data);
  }
  addServiceHistory(data: IAddServices) {
    return client.post<IAddServices>(SERVICES_CREATE, data);
  }
  getServicesFiles(id: string) {
    return client.get<any>(SERVICES_ID_FILES(id));
  }

  addFilesToServices({ id, files }: IAddFiles) {
    return client.post<any>(SERVICES_ID_BULK_ADD_DOCUMENTS(id), {
      photos: files,
      documents: files,
    });
  }
  removeFileFromServices({ id, documents }: IAddDocuments) {
    return client.post(SERVICES_ID_BULK_REMOVE_DOCUMENTS(id), {
      documents,
    });
  }

  saveComment(data: IServicesCommentAdd) {
    return client.post<IComment>(SERVICES_COMMENTS_CREATE, data);
  }
  deleteServicesComments(id: string) {
    return client.delete<any>(SERVICES_COMMENTS_DELETE(id));
  }
}

export default ServicesHistory;
