import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const ReportFuelEntriesByVehiclesList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportFuelEntriesByVehicle/ReportFuelEntriesByVehicleList/ReportFuelEntriesByVehicleList"
    )
);

export const ReportFuelEntriesByVehicles = () => {
  return (
    <Layout>
      <ReportFuelEntriesByVehiclesList />
    </Layout>
  );
};
