import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { IMeterHistory } from "Shared/Types/meter-history";
import { getMeterHistory, getMeterHistorysList } from "./reducer";

interface BlockTypeSliceState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  meterHistoryList: IMeterHistory[];
  currentMeterHistory: IMeterHistory | null;
  numPage: number;
}

const initialState: BlockTypeSliceState = {
  loading: false,
  error: null,
  meterHistoryList: [],
  currentMeterHistory: null,
  numPage: 0,
};

const meterHistorySlice = createSlice({
  name: "meterHistorySlice",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
    clearCurrentMeterHistory(state) {
      state.currentMeterHistory = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMeterHistorysList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.meterHistoryList = action.payload.results;
      state.numPage = action.payload.count;
    });
    builder.addCase(getMeterHistory.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentMeterHistory = action.payload;
    });
  },
});

export const { clear, clearCurrentMeterHistory } = meterHistorySlice.actions;

export const selectMeterHistorysList = (state: RootState) =>
  state.meterHistory.meterHistoryList;
export const selectMeterHistorysNumPage = (state: RootState): number =>
  state.meterHistory.numPage;
export const selectCurrentMeterHistory = (state: RootState) =>
  state.meterHistory.currentMeterHistory;
export const selectMeterHistoryLoading = (state: RootState) =>
  state.meterHistory.loading;
export const selectMeterHistoryError = (state: RootState) =>
  state.meterHistory.error;
export const selectMeterHistorySuccess = (state: RootState) =>
  state.meterHistory.success;

export default meterHistorySlice;
