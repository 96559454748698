import { createSlice } from "@reduxjs/toolkit";
import { IVendorsType } from "Shared/Types/purchaseVendor";
import { RootState } from "../store";
import { getPurchaseVendorList } from "./PurchaseVendorReducer";

interface venderState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  venderTypeList: IVendorsType[];
}

const initialState: venderState = {
  loading: false,
  error: null,
  venderTypeList: [],
};

const VenderSlice = createSlice({
  name: "vender",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPurchaseVendorList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.venderTypeList = action.payload.results;
    });
  },
});

export const { clear } = VenderSlice.actions;

export const selectVendersTypeList = (state: RootState) =>
  state.venders.venderTypeList;
export const selectVendersAuthLoading = (state: RootState) =>
  state.venders.loading;
export const selectVendersAuthError = (state: RootState) => state.venders.error;
export const selectVendersAuthSuccess = (state: RootState) =>
  state.venders.success;

export default VenderSlice;
