import { createSlice } from "@reduxjs/toolkit";

import { ExpenseList } from "Shared/Types/fleetmanagementapi";

import { RootState } from "../store";
import { getExpenseList } from "./reducer";

interface expenseState {
  loading: boolean;
  expenseList: {
    data: ExpenseList[];
    loading: boolean;
    error?: string | null;
    count: number;
  };
}

const initialState: expenseState = {
  loading: false,
  expenseList: {
    data: [],
    loading: false,
    error: null,
    count: 0,
  },
};

const Expense = createSlice({
  name: "expense",
  initialState,
  reducers: {
    clear(state) {
      state.loading = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getExpenseList.pending, (state, action) => {
      state.expenseList.loading = true;
      state.expenseList.error = null;
    });
    builder.addCase(getExpenseList.fulfilled, (state, action) => {
      state.expenseList.loading = false;
      state.expenseList.error = null;
      state.expenseList.data = action.payload.results;
      state.expenseList.count = action.payload.count;
    });
    builder.addCase(getExpenseList.rejected, (state, action) => {
      state.expenseList.loading = false;
      state.expenseList.error = action.error.message;
    });
  },
});

export const { clear } = Expense.actions;

export const selectExpenseListLoading = (state: RootState) =>
  state.expense.expenseList.loading;
export const selectExpenseList = (state: RootState) =>
  state.expense.expenseList.data;
export const selectExpenseCount = (state: RootState) =>
  state.expense.expenseList.count;

export default Expense;
