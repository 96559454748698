import React, { memo, useState } from "react";

import { ReactComponent as User } from "Assets/user-removebg-preview.svg";
import styles from "./Header.module.scss";
import ModalUserProfile from "Components/ModalUserProfile/ModalUserProfile";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUserProfile } from "Redux/Auth/slice";
import { selectPersonalGeneral } from "Redux/CompanyProps/slice";
import GlobalSearch from "Components/UI/Inputs/InputSearch/GlobalSearch";

const Header = () => {
  const [open, setOpen] = useState(false);

  const personalInfo = useSelector(selectUserProfile);
  const companyInfo = useSelector(selectPersonalGeneral);

  return (
    <div className={styles.content}>
      <div className={styles.contant__display}>
        <h3 className={styles.logo}>
          <Link to="/">Easy Fleet - {companyInfo?.account_name}</Link>
        </h3>
        <div>
          <GlobalSearch />
        </div>

        <div className={styles.contant__display_blockIcon}>
          {personalInfo && personalInfo.profile_picture ? (
            <img
              src={personalInfo.profile_picture.file}
              alt="User Profile"
              className={styles.contant__display_blockIcon_icon}
              onClick={() => setOpen(true)}
            />
          ) : (
            <User
              className={styles.contant__display_blockIcon_icon}
              onClick={() => setOpen(true)}
            />
          )}

          {open && <ModalUserProfile setOpen={setOpen} />}
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
