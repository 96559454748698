import { client } from "Service/axios";
import {
  VEHICLES_ENGINE_FUEL_INDUCTIONS,
  VEHICLES_ENGINE_FUEL_INDUCTIONS_CREATE,
} from "Shared/Constants/apiRequest";
import {
  IAddFuelInductionType,
  IFuelInductionTypeListSearch,
} from "Shared/Types/fuelInduction";

class FuelInductionType {
  getFuelInductionTypeList(params: IFuelInductionTypeListSearch | null) {
    return client.get<any>(VEHICLES_ENGINE_FUEL_INDUCTIONS, { params });
  }
  addFuelInductionType(data: IAddFuelInductionType) {
    return client.post<any>(VEHICLES_ENGINE_FUEL_INDUCTIONS_CREATE, data);
  }
}

export default FuelInductionType;
