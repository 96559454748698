import { client } from "Service/axios";
import {
  VEHICLES_ASSIGNMENTS,
  VEHICLES_ASSIGNMENTS_CREATE,
  VEHICLES_ASSIGNMENTS_ID,
  VEHICLES_ASSIGNMENTS_ID_DELETE,
  VEHICLES_ASSIGNMENTS_ID_UPDATE,
} from "Shared/Constants/apiRequest";
import { IAddAssignment } from "Shared/Types/assignment";

class Assignments {
  getAssignmentsList() {
    return client.get<any>(VEHICLES_ASSIGNMENTS);
  }

  getAssignment(id: string) {
    return client.get<any>(VEHICLES_ASSIGNMENTS_ID(id));
  }

  deleteAssignment(id: string) {
    return client.delete<any>(VEHICLES_ASSIGNMENTS_ID_DELETE(id));
  }
  addAssignment(data: IAddAssignment) {
    return client.post<any>(VEHICLES_ASSIGNMENTS_CREATE, data);
  }

  updateAssignment(data: { id: string; values: IAddAssignment }) {
    return client.patch<any>(
      VEHICLES_ASSIGNMENTS_ID_UPDATE(data.id),
      data.values
    );
  }
}

export default Assignments;
