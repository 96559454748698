import { client } from "Service/axios";
import { GLOBAL_SEARCH } from "Shared/Constants/apiRequest";

class InputGlobal {
  getInputGlobal(params: any | null) {
    return client.get<any>(GLOBAL_SEARCH, {
      params: params || {},
    });
  }
}

export default InputGlobal;
