import React, { FC, lazy } from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const UpdateUserForm = lazyLoader(
  () => import("Containers/User/EditUserForm/EditUserForm")
);

export const EditUser: FC = () => {
  return (
    <Layout>
      <UpdateUserForm />
    </Layout>
  );
};
