import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "Service/api/api";
import {
  IDeleteReducerWithCallback,
  IDeleteReducerWithCallbackWithTwoId,
  IReducerWithCallback,
  IUpdateReducerWithCallback,
  IUpdateReducerWithCallbackWithTwoId,
} from "Shared/Types/shared";
import { TLaborDetailFields } from "Shared/Utils/yup/workOrders";
import { IWorkOrderCommentAdd } from "Shared/Types/comment";
import { IAddFilesToEntityTypes } from "Shared/Types/file";
import { IWorkOrderListSearch } from "Shared/Types/workOrder";
import { WorkOrderCreateRequest } from "Shared/Types/fleetmanagementapi";
import { DashboardWorkOrderData } from "Shared/Types/dashboard";

export const getWorkOrder = createAsyncThunk(
  "workOrder/getWorkOrder",
  async (id: string) => {
    try {
      const response = await api.workOrder.getWorkOrderById(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const getWorkOrderList = createAsyncThunk(
  "workOrder/getWorkOrderList",
  async (params: IWorkOrderListSearch | null) => {
    try {
      const response = await api.workOrder.getWorkOrderList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const switchWorkOrderStatusToOpen = createAsyncThunk(
  "workOrder/switchWorkOrderStatusToOpen",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.workOrder.switchWorkOrderStatusToOpen(id);
      dispatch(getWorkOrder(id));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const switchWorkOrderStatusToPending = createAsyncThunk(
  "workOrder/switchWorkOrderStatusToPending",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.workOrder.switchWorkOrderStatusToPending(id);
      dispatch(getWorkOrder(id));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
export const switchWorkOrderStatusToComplete = createAsyncThunk(
  "workOrder/switchWorkOrderStatusToComplete",
  async (id: string, { dispatch }) => {
    try {
      const response = await api.workOrder.switchWorkOrderStatusToComplete(id);
      dispatch(getWorkOrder(id));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const deleteWorkOrder = createAsyncThunk(
  "workOrder/deleteWorkOrder",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.workOrder.deleteWorkOrder(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const startWorkOrderLabor = createAsyncThunk(
  "workOrder/startWorkOrderLabor",
  async (
    {
      id,
      data,
      onError,
      onSuccess,
    }: IUpdateReducerWithCallback<{ service_task: string }>,
    { dispatch },
  ) => {
    try {
      const response = await api.workOrder.startWorkOrderLabor(id, data);
      dispatch(getWorkOrder(id));
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const stopWorkOrderLabor = createAsyncThunk(
  "workOrder/stopWorkOrderLabor",
  async (
    {
      id,
      id_second,
      data,
      onError,
      onSuccess,
    }: IUpdateReducerWithCallbackWithTwoId<TLaborDetailFields>,
    { dispatch },
  ) => {
    try {
      const response = await api.workOrder.stopWorkOrderLabor(
        id,
        id_second,
        data,
      );
      onSuccess?.();
      dispatch(getWorkOrder(id));
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const deleteWorkOrderServiceTaskLabor = createAsyncThunk(
  "workOrder/deleteWorkOrderServiceTaskLabor",
  async (
    { id, id_second, onError, onSuccess }: IDeleteReducerWithCallbackWithTwoId,
    { dispatch },
  ) => {
    try {
      const response = await api.workOrder.deleteWorkOrderServiceTaskLabor(
        id,
        id_second,
      );
      onSuccess?.();
      dispatch(getWorkOrder(id));
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const getWorkOrderRelatedServiceTasks = createAsyncThunk(
  "workOrder/getWorkOrderRelatedServiceTasks",
  async (id: string) => {
    try {
      const response = await api.workOrder.getWorkOrderRelatedServiceTasks(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export const addWorkOrderComment = createAsyncThunk(
  "workOrder/addWorkOrderComment",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<IWorkOrderCommentAdd>) => {
    try {
      const response = await api.workOrder.addWorkOrderComment(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const deleteWorkOrderComment = createAsyncThunk(
  "workOrder/deleteWorkOrderComment",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.workOrder.deleteWorkOrderComment(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const addWorkOrderFiles = createAsyncThunk(
  "workOrder/addWorkOrderFiles",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.workOrder.addWorkOrderFiles(id, files);
      onSuccessUploadFiles?.();
      dispatch(getWorkOrder(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

export const removeWorkOrderFiles = createAsyncThunk(
  "workOrder/removeWorkOrderFiles",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.workOrder.removeWorkOrderFile(id, files);
      onSuccessUploadFiles?.();
      dispatch(getWorkOrder(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

export const addWorkOrder = createAsyncThunk(
  "workOrder/addWorkOrder",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<WorkOrderCreateRequest>) => {
    try {
      const response = await api.workOrder.addWorkOrder(data);
      onSuccess?.(response.data.id);
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

export const editWorkOrder = createAsyncThunk(
  "workOrder/editWorkOrder",
  async ({
    id,
    data,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<
    WorkOrderCreateRequest | DashboardWorkOrderData
  >) => {
    try {
      const response = await api.workOrder.editWorkOrder({ id, data });
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);
