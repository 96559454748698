import React, { Suspense } from "react";
import SetupUserPassword from "Containers/User/SetupUserPassword/SetupUserPassword";
import { Loader } from "Components/UI/Loader";

export const SetupUserPasswordPage = () => {
  return (
    <Suspense fallback={<Loader />}>
      <SetupUserPassword />
    </Suspense>
  );
};
