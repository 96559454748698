import { client } from "Service/axios";
import {
  VEHICLES_ENGINE_ASPIRATIONS,
  VEHICLES_ENGINE_ASPIRATIONS_CREATE,
} from "Shared/Constants/apiRequest";

import { IAddAspiration, IAspirationSearchList } from "Shared/Types/aspiration";

class AspirationType {
  getAspirationTypeList(params: IAspirationSearchList | null) {
    return client.get<any>(VEHICLES_ENGINE_ASPIRATIONS, { params });
  }
  addGroupType(data: IAddAspiration) {
    return client.post<any>(VEHICLES_ENGINE_ASPIRATIONS_CREATE, data);
  }
}

export default AspirationType;
