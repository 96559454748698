import { createSlice } from "@reduxjs/toolkit";
import {
  ICompany,
  ICurrency,
  IFleetSize,
  IIndustry,
} from "../../Shared/Types/companyProps";
import { RootState } from "../store";
import {
  companyDomainCheck,
  companyDomainSuggest,
  getCurrencyList,
  getFleetSizeList,
  getIndustryList,
  getPersonalGeneral,
} from "./reducer";

interface operatorState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  currencyList: ICurrency[];
  industryList: IIndustry[];
  fleetSizeList: IFleetSize[];
  personalGeneral: ICompany | null;
  isDomainValid: boolean;
  suggestions: string[];
}

const initialState: operatorState = {
  loading: false,
  error: null,
  success: null,
  currencyList: [],
  industryList: [],
  fleetSizeList: [],
  personalGeneral: null,
  isDomainValid: false,
  suggestions: [],
};

const CompanyPropsSlice = createSlice({
  name: "companyProps",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
      state.isDomainValid = false;
      state.suggestions = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(companyDomainCheck.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(companyDomainCheck.fulfilled, (state, action) => {
      state.loading = false;
      state.isDomainValid = action.payload.exists;
    });
    builder.addCase(companyDomainCheck.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(companyDomainSuggest.fulfilled, (state, action) => {
      state.suggestions = action.payload;
    });
    builder.addCase(getCurrencyList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currencyList = action.payload.results;
    });
    builder.addCase(getIndustryList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.industryList = action.payload.results;
    });
    builder.addCase(getFleetSizeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.fleetSizeList = action.payload.results;
    });
    builder.addCase(getPersonalGeneral.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.personalGeneral = action.payload;
    });
  },
});

export const { clear } = CompanyPropsSlice.actions;

export const selectCurrencyList = (state: RootState) =>
  state.companyProps.currencyList;
export const selectIndustryList = (state: RootState) =>
  state.companyProps.industryList;
export const selectFleetSizeList = (state: RootState) =>
  state.companyProps.fleetSizeList;
export const selectContactAuthLoading = (state: RootState) =>
  state.companyProps.loading;
export const selectContactAuthError = (state: RootState) =>
  state.companyProps.error;
export const selectContactAuthSuccess = (state: RootState) =>
  state.companyProps.success;
export const selectPersonalGeneral = (state: RootState) =>
  state.companyProps.personalGeneral;
export const selectIsDomainValid = (state: RootState) =>
  state.companyProps.isDomainValid;
export const selectSuggestions = (state: RootState) =>
  state.companyProps.suggestions;

export default CompanyPropsSlice;
