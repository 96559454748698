import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const AddServiceHistory = lazyLoader(
  () =>
    import("Containers/ServiceHistory/AddEditServiceHistory/AddServiceHistory")
);

export const AddServiceHistorys = () => {
  return (
    <Layout>
      <AddServiceHistory />
    </Layout>
  );
};
