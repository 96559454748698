import React, { FC } from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const IssueDetail = lazyLoader(
  () => import("Containers/Issue/IssueDetail/IssueDetail")
);

export const IssueDetailPage: FC = () => {
  return (
    <Layout>
      <IssueDetail />
    </Layout>
  );
};
