import { ClipLoader } from "react-spinners";
import React from "react";

interface Props {
  size?: number;
}

export const Loader = ({ size = 20 }: Props) => {
  return (
    <ClipLoader
      color="white"
      cssOverride={{ margin: "auto" }}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};
