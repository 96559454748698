import React, { FC } from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
const IssueDashboard = lazyLoader(
  () => import("Containers/Issue/IssueDashboard/IssueDashboard")
);

export const IssueDashboardPage: FC = () => {
  return (
    <Layout>
      <IssueDashboard />
    </Layout>
  );
};
