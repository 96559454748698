import { client } from "Service/axios";
import {
  DOCUMENTS,
  DOCUMENTS_CREATE,
  DOCUMENTS_ID,
  DOCUMENTS_ID_DELETE,
  DOCUMENTS_ID_PATCH,
} from "Shared/Constants/apiRequest";
import { IPapersSearch } from "Shared/Types/paper";
import { IPaperData } from "Shared/Utils/yup/document";

class Document {
  getDocumentList(params: IPapersSearch | null) {
    return client.get<any>(DOCUMENTS, { params });
  }

  getDocument(id: string) {
    return client.get<any>(DOCUMENTS_ID(id));
  }

  deleteDocument(id: string) {
    return client.delete(DOCUMENTS_ID_DELETE(id));
  }

  updateDocument({ data, id }: { data: IPaperData; id: string }) {
    return client.patch<any>(DOCUMENTS_ID_PATCH(id), data);
  }
  document(id: string) {
    return client.get<any>(DOCUMENTS_ID(id));
  }

  createDocument(data: FormData) {
    return client.post<any>(DOCUMENTS_CREATE, data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  }
}
export default Document;
