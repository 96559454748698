import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import { IIssueCreate, IIssueSearchParams } from "Shared/Types/issue";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IUpdateReducerWithCallback,
} from "Shared/Types/shared";
import { IAddFilesToEntityTypes } from "../../Shared/Types/file";
import { IIssueCommentAdd } from "Shared/Types/comment";

const getIssueList = createAsyncThunk(
  "issue/getIssueList",
  async (params: IIssueSearchParams | null) => {
    try {
      const response = await api.issue.getIssueList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getIssue = createAsyncThunk("issue/getIssue", async (id: string) => {
  try {
    const response = await api.issue.getIssue(id);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const deleteIssue = createAsyncThunk(
  "issue/deleteIssue",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<{ id: string }>) => {
    try {
      const response = await api.issue.deleteIssue(data.id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const updateIssue = createAsyncThunk(
  "issue/addIssue",
  async ({
    id,
    data,
    onError,
    onSuccess,
  }: IUpdateReducerWithCallback<IIssueCreate>) => {
    try {
      const response = await api.issue.updateIssue(id, data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const addIssue = createAsyncThunk(
  "issue/addIssue",
  async ({ data, onError, onSuccess }: IReducerWithCallback<IIssueCreate>) => {
    try {
      const response = await api.issue.addIssue(data);
      onSuccess?.(response.data.id);
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const openIssue = createAsyncThunk(
  "issue/openIssue",
  async ({
    data: { id },
    onError,
    onSuccess,
  }: IReducerWithCallback<{ id: string }>) => {
    try {
      const response = await api.issue.reopenIssue(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const resolveIssue = createAsyncThunk(
  "issue/resolveIssue",
  async ({
    data: { note, id },
    onError,
    onSuccess,
  }: IReducerWithCallback<{ id: string; note: string }>) => {
    try {
      const response = await api.issue.resoliveWithNotes(id, { note });
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const getIssueFilesList = createAsyncThunk(
  "issue/getIssueFilesList",
  async (id: string) => {
    try {
      const response = await api.issue.getIssueFilesList(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addFilesToIssue = createAsyncThunk(
  "issue/addFilesToIssue",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.issue.addFilesToIssue({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getIssueFilesList(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

const removeFileFromIssue = createAsyncThunk(
  "issue/removeFilesFromIssue",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.issue.removeFileFromIssue({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getIssueFilesList(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

const createComment = createAsyncThunk(
  "user/createComment",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<IIssueCommentAdd>) => {
    try {
      const response = await api.issue.saveComment(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const deleteComment = createAsyncThunk(
  "user/createComment",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.issue.deleteComment(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const getIssuesChartAnalytics = createAsyncThunk(
  "issue/getIssuesChartAnalytics",
  async () => {
    try {
      const response = await api.issue.getIssueChartAnalytics();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export {
  addIssue,
  getIssueList,
  getIssue,
  deleteIssue,
  updateIssue,
  openIssue,
  resolveIssue,
  addFilesToIssue,
  removeFileFromIssue,
  getIssueFilesList,
  createComment,
  deleteComment,
  getIssuesChartAnalytics,
};
