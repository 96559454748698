import { createSlice } from "@reduxjs/toolkit";
import { IPapers, IPapersDetail } from "Shared/Types/paper";
import { RootState } from "../store";
import { createDocument, document, getDocumentsList } from "./reducer";

interface documentState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  documentsList: IPapers[];
  document: IPapersDetail | null;
  numPage: number;
}

const initialState: documentState = {
  loading: false,
  error: null,
  documentsList: [],
  document: null,
  numPage: 0,
};

const DocumentSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDocumentsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.documentsList = action.payload.results;
      state.numPage = action.payload.count;
    });
    builder.addCase(getDocumentsList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getDocumentsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
    });
    builder.addCase(createDocument.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(createDocument.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(document.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.document = action.payload.results;
    });
    builder.addCase(document.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(document.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      state.success = false;
    });
  },
});

export const { clear } = DocumentSlice.actions;

export const selectDocumentsList = (state: RootState) =>
  state.documents.documentsList;
export const selectDocuments = (state: RootState) => state.documents.document;
export const selectDocumentsLoading = (state: RootState) =>
  state.documents.loading;
export const selectDocumentsError = (state: RootState) => state.documents.error;
export const selectDocumentsSuccess = (state: RootState) =>
  state.documents.success;
export const selectDocumentNumPage = (state: RootState) =>
  state.documents.numPage;

export default DocumentSlice;
