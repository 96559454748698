import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  IAddFuelInductionType,
  IFuelInductionTypeListSearch,
} from "Shared/Types/fuelInduction";

const getFuelInductionTypeList = createAsyncThunk(
  "fuelInductionType/getFuelInductionTypeList",
  async (params: IFuelInductionTypeListSearch | null) => {
    try {
      const response = await api.fuelInduction.getFuelInductionTypeList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addFuelInductionType = createAsyncThunk(
  "fuelInductionType/addFuelInductionTypeList",
  async (data: IAddFuelInductionType, { dispatch }) => {
    try {
      const response = await api.fuelInduction.addFuelInductionType(data);
      dispatch(getFuelInductionTypeList(null));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

export { addFuelInductionType, getFuelInductionTypeList };
