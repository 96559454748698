import { client } from "Service/axios";
import {
  VEHICLE_BODY_SUBTYPES,
  VEHICLE_BODY_SUBTYPES_CREATE,
} from "Shared/Constants/apiRequest";
import {
  IAddBodySubType,
  IBodySubTypeListSearch,
} from "Shared/Types/bodySubType";

class BodySubType {
  getBodySubTypeList(params: IBodySubTypeListSearch | null) {
    return client.get<any>(VEHICLE_BODY_SUBTYPES, { params });
  }
  addBodySubType(data: IAddBodySubType) {
    return client.post<any>(VEHICLE_BODY_SUBTYPES_CREATE, data);
  }
}

export default BodySubType;
