import React from "react";
import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

const ReportsServiceHistorySummaryList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsServiceHistorySummary/ReportsServiceHistorySummaryList/ReportsServiceHistorySummaryList"
    )
);
export const ReportsServiceHistorySummary = () => {
  return (
    <Layout>
      <ReportsServiceHistorySummaryList />
    </Layout>
  );
};
