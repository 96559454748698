import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "Service/api/api";
import {
  PaymentType,
  PaymentTypeRequest,
} from "Shared/Types/fleetmanagementapi";
import { IReducerWithCallback } from "Shared/Types/shared";

export const getPaymentType = createAsyncThunk(
  "payment/getPaymentType",
  async (params: PaymentType | null) => {
    try {
      const response = await api.payment.getPaymentType(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const addPaymentType = createAsyncThunk(
  "payment/addPaymentType",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<PaymentTypeRequest>) => {
    try {
      const response = await api.payment.addPaymentType(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
