import { createAsyncThunk } from "@reduxjs/toolkit";

import api from "Service/api/api";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IUpdateReducerWithCallback,
  MultiplyActionDispatchParams,
} from "Shared/Types/shared";
import { TReminderVehicleSchema } from "Shared/Utils/yup/reminderVehicles";
import {
  IReminderVehiclesSearch,
  IRenewalTypesListSearch,
} from "Shared/Types/reminder";
import { VehicleRenewalTypeCreate } from "Shared/Types/fleetmanagementapi";

export const getReminderVehicleList = createAsyncThunk(
  "reminder/getReminderVehicleList",
  async (params: IReminderVehiclesSearch | null) => {
    try {
      const response = await api.reminderVehicle.getReminderVehicleList(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const createReminderTypeVehicle = createAsyncThunk(
  "reminder/createReminderVehicleType",
  async ({
    onError,
    onSuccess,
    data,
  }: IReducerWithCallback<VehicleRenewalTypeCreate>) => {
    try {
      const response =
        await api.reminderVehicle.createReminderVehicleType(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
export const getReminderVehicleTypeList = createAsyncThunk(
  "reminder/getReminderVehicleTypeList",
  async (params: IRenewalTypesListSearch | null) => {
    try {
      const response =
        await api.reminderVehicle.getReminderVehicleTypeList(params);
      return response.data.results;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getReminderVehicleById = createAsyncThunk(
  "reminder/getReminderVehicleById",
  async (id: string) => {
    try {
      const response = await api.reminderVehicle.getReminderVehicleById(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const createReminderVehicle = createAsyncThunk(
  "reminder/createReminderVehicle",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<TReminderVehicleSchema>) => {
    try {
      const response = await api.reminderVehicle.createReminderVehicle(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const updateReminderVehicle = createAsyncThunk(
  "reminder/updateReminderVehicle",
  async ({ id, data, onError, onSuccess }: IUpdateReducerWithCallback<any>) => {
    try {
      const response = await api.reminderVehicle.updateReminderVehicle(
        id,
        data
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const deleteReminderVehicle = createAsyncThunk(
  "reminder/deleteReminderVehicle",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.reminderVehicle.deleteReminderVehicle(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);

export const deleteMultipleReminderVehicle = createAsyncThunk(
  "reminder/deleteMultipleReminderVehicle",
  async ({ ids, onError, onSuccess }: MultiplyActionDispatchParams) => {
    try {
      const response = await api.reminderVehicle.deleteMultipleReminderVehicles(
        { ids }
      );
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
