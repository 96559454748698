import React from "react";
import { Layout } from "Components/Layout/Layout";
import Breadcrumb, { BreadcrumbItem } from "Components/Breadcrumb/Breadcrumb";
import { PURCHASE_ORDERS } from "Shared/Constants/routes";
import { lazyLoader } from "Service/loader";
const PurchaseOrderForm = lazyLoader(
  () =>
    import(
      "Containers/PurchaseOrder/AddPurchaseOrder/PurchaseOrderForm/PurchaseOrderForm"
    )
);

export const EditPurchaseOrder = () => {
  const breads: BreadcrumbItem[] = [
    { title: "Parts", link: PURCHASE_ORDERS },
    { title: "Edit Purchase Order", link: "" },
  ];

  return (
    <Layout hasSidebar={false}>
      <Breadcrumb breads={breads} />
      <PurchaseOrderForm type="EDIT" />
    </Layout>
  );
};
