import * as React from "react";
import styles from "./Modal.module.scss";
import { Dialog, DialogTitle, DialogContent, DialogProps } from "@mui/material";
import Icon from "../Icon/Icon";
import { CloseIcon } from "Assets/index";
import IconButton from "Components/UI/IconButton/IconButton";
import classNames from "classnames";

type ModalProps = DialogProps & {
  isClosed?: boolean;
  icon?: React.ElementType;
};
const Modal: React.FC<ModalProps> = ({
  open,
  onClose,
  title,
  children,
  fullScreen,
  isClosed,
  icon,
  ...props
}) => {
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      fullWidth
      {...props}
      className={classNames(styles.modal, !!props.className && props.className)}
    >
      {(isClosed || title) && (
        <DialogTitle display="flex" justifyContent="space-between">
          <div className={styles.headerTitleCont}>
            {icon && <IconButton variant="primary" icon={icon} />}
            <span className={styles.header_title_cont__title}>{title}</span>
          </div>
          {isClosed && (
            <Icon
              component={CloseIcon}
              onClick={(event) => onClose?.(event, "backdropClick")}
            />
          )}
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default Modal;
