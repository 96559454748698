import { createAsyncThunk } from "@reduxjs/toolkit";
import { IReducerWithCallback } from "Shared/Types/shared";
import { IPersonalCompanyData } from "Shared/Utils/yup/personalCompany";
import api from "Service/api/api";
import { ISubdomain } from "Shared/Utils/yup/companyShema";

export const getIndustryList = createAsyncThunk(
  "companyProps/getIndustryList",
  async () => {
    try {
      const response = await api.companyProps.getIndustryList();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const getCurrencyList = createAsyncThunk(
  "companyProps/getCurrencyList",
  async () => {
    try {
      const response = await api.companyProps.getCurrencyList();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const getPersonalGeneral = createAsyncThunk(
  "companyProps/getPersonalGeneral",
  async () => {
    try {
      const response = await api.companyProps.getPersonalGeneral();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const companyDomainCheck = createAsyncThunk(
  "companyProps/companyDomainCheck",
  async ({ data, onError, onSuccess }: IReducerWithCallback<ISubdomain>) => {
    try {
      const response = await api.companyProps.checkDomain(data);
      onSuccess?.(response.data.exists);
      return response.data.exists;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  }
);
export const createCompanyDomain = createAsyncThunk(
  "companyProps/createCompanyDomain",
  async ({ data, onError, onSuccess }: IReducerWithCallback<ISubdomain>) => {
    try {
      const response = await api.companyProps.createNewDomain(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
    }
  }
);

export const companyDomainSuggest = createAsyncThunk(
  "companyProps/companyDomainSuggest",
  async (base: any) => {
    try {
      const response = await api.companyProps.suggestDomain(base);
      return response.data.suggestions;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
export const updatePersonalGeneral = createAsyncThunk(
  "companyProps/updatePersonalGeneral",
  async (
    { data, onError, onSuccess }: IReducerWithCallback<IPersonalCompanyData>,
    { dispatch }
  ) => {
    try {
      const response = await api.companyProps.updatePersonalGeneral(data);
      onSuccess && onSuccess();
      dispatch(getPersonalGeneral());
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  }
);

export const getFleetSizeList = createAsyncThunk(
  "companyProps/getFleetSizeList",
  async () => {
    try {
      const response = await api.companyProps.getFleetSizeList();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);
