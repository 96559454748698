import { createSlice } from "@reduxjs/toolkit";
import { ICamType } from "Shared/Types/camType";
import { RootState } from "../store";
import { addCamType, getCamTypeList } from "./CamTypeReducer";

interface CamTypeSliceState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  camTypeList: ICamType[];
}

const initialState: CamTypeSliceState = {
  loading: false,
  error: null,
  camTypeList: [],
};

const CamTypeSlice = createSlice({
  name: "CamTypeSlice",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCamTypeList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.camTypeList = action.payload.results;
    });
    builder.addCase(addCamType.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(addCamType.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.success = true;
    });
    builder.addCase(addCamType.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = CamTypeSlice.actions;

export const selectCamTypeList = (state: RootState) =>
  state.camType.camTypeList;
export const selectCamTypeAuthLoading = (state: RootState) =>
  state.camType.loading;
export const selectCamTypeAuthError = (state: RootState) => state.camType.error;
export const selectCamTypeAuthSuccess = (state: RootState) =>
  state.camType.success;

export default CamTypeSlice;
