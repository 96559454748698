import { createAsyncThunk } from "@reduxjs/toolkit";
import { notifyError, notifySuccess } from "Components/Notify/notify";
import api from "Service/api/api";
import { IPartCommentAdd } from "Shared/Types/comment";
import { IAddFilesToEntityTypes } from "Shared/Types/file";
import {
  FetchWorkOrdersPayload,
  IAddFilesToPart,
  LocationsParams,
  PartCategoriesParams,
  PartManufacturesParams,
  PartsListByLocationParams,
  PartsListParams,
} from "Shared/Types/parts";
import { FetchPurchaseHistoryPayload } from "Shared/Types/purchaseOrder";
import {
  IDeleteReducerWithCallback,
  IReducerWithCallback,
  IUpdateReducerWithCallback,
  ReducerTypeForUpdate,
} from "Shared/Types/shared";
import {
  IAdjustPartLocation,
  IEditPartLocation,
  IPartLocation,
  ITransferPartLocation,
  PartFormData,
} from "Shared/Utils/yup/partSchema";

const getPartsLocations = createAsyncThunk(
  "parts/getPartsLocations",
  async (params: LocationsParams) => {
    try {
      const response = await api.part.getPartLocations(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addPartsLocation = createAsyncThunk(
  "parts/addPartsLocation",
  async (
    { data, onError, onSuccess }: IReducerWithCallback<IPartLocation>,
    { dispatch },
  ) => {
    try {
      const response = await api.part.createPartLocation(data);
      onSuccess && onSuccess();
      dispatch(getPartsLocations());
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const updatePartsLocation = createAsyncThunk(
  "parts/updatePartsLocation",
  async (
    { id, data, onError, onSuccess }: IUpdateReducerWithCallback<IPartLocation>,
    { dispatch },
  ) => {
    try {
      const response = await api.part.updatePartLocation({ id, data });
      onSuccess && onSuccess();
      dispatch(getPartsLocations());
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const getPartsAdjustmentReasons = createAsyncThunk(
  "parts/getPartsAdjustmentReasons",
  async () => {
    try {
      const response = await api.part.getPartAdjustmentReasons();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getPartsCategories = createAsyncThunk(
  "parts/getPartsCategories",
  async (params: PartCategoriesParams) => {
    try {
      const response = await api.part.getPartsCategories(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addPartsCategories = createAsyncThunk(
  "parts/addPartsCategories",
  async (
    { data, onError, onSuccess }: IReducerWithCallback<{ name: string }>,
    { dispatch },
  ) => {
    try {
      const response = await api.part.addPartsCategories(data);
      onSuccess?.();
      dispatch(getPartsCategories());
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const getPartManufactures = createAsyncThunk(
  "parts/getPartManufactures",
  async (params: PartManufacturesParams) => {
    try {
      const response = await api.part.getPartManufacturers(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addPartManufacture = createAsyncThunk(
  "parts/addPartManufacture",
  async (
    { data, onError, onSuccess }: IReducerWithCallback<{ name: string }>,
    { dispatch },
  ) => {
    try {
      const response = await api.part.addPartManufacturer(data);
      onSuccess?.();
      dispatch(getPartManufactures());
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const getParts = createAsyncThunk(
  "parts/getParts",
  async (params: PartsListParams | null) => {
    try {
      const response = await api.part.getParts(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const deletePart = createAsyncThunk(
  "parts/deletePart",
  async (
    { id, onError, onSuccess }: IDeleteReducerWithCallback,
    { dispatch },
  ) => {
    try {
      const response = await api.part.deletePart(id);
      onSuccess && onSuccess();
      dispatch(getParts());
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const deleteLocationPart = createAsyncThunk(
  "parts/deleteLocationPart",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.part.deleteLocationPart(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const archivePart = createAsyncThunk(
  "parts/archivePart",
  async (
    { id, onError, onSuccess }: IDeleteReducerWithCallback,
    { dispatch },
  ) => {
    try {
      const response = await api.part.archivePart(id);
      onSuccess && onSuccess();
      dispatch(getParts());
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const unarchivePart = createAsyncThunk(
  "parts/unarchivePart",
  async (
    { id, onError, onSuccess }: IDeleteReducerWithCallback,
    { dispatch },
  ) => {
    try {
      const response = await api.part.unarchivePart(id);
      onSuccess && onSuccess();
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const getCurrentPart = createAsyncThunk(
  "parts/getCurrentPart",
  async (id: string) => {
    try {
      const response = await api.part.getCurrentPart(id);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getWorkOrderActivity = createAsyncThunk(
  "parts/getWorkOrderActivity",
  async ({ id, params }: FetchWorkOrdersPayload) => {
    try {
      const response = await api.part.getWorkOrderActivity({ id, params });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getPurchaseHistory = createAsyncThunk(
  "parts/getPurschaseHistory",
  async ({ id, params }: FetchPurchaseHistoryPayload) => {
    try {
      const response = await api.part.getPurchaseHistory({ id, params });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);
const getPartsByLocation = createAsyncThunk(
  "parts/getPartsByLocation",
  async (params: PartsListByLocationParams) => {
    try {
      const response = await api.part.getPartsByLocation(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getArchivedParts = createAsyncThunk(
  "parts/getArchivedParts",
  async (params: PartsListParams) => {
    try {
      const response = await api.part.getArchivedParts(params);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getMeasurements = createAsyncThunk("parts/getMeasurements", async () => {
  try {
    const response = await api.part.getMeasurements();
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
});

const addMeasurement = createAsyncThunk(
  "parts/addMeasurement",
  async (
    { data, onError, onSuccess }: IReducerWithCallback<{ name: string }>,
    { dispatch },
  ) => {
    try {
      const response = await api.part.addMeasurement(data);
      onSuccess?.();
      dispatch(getMeasurements());
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const getPartsMetaData = createAsyncThunk(
  "parts/getPartsMetaData",
  async () => {
    try {
      const response = await api.part.getPartsMetaData();
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getPartsMetaDataByLocation = createAsyncThunk(
  "parts/getPartsMetaDataByLocation",
  async (locationId: string) => {
    try {
      const response = await api.part.getPartsMetaDataByLocation(locationId);
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const createPart = createAsyncThunk(
  "parts/createPart",
  async ({ data, onError, onSuccess }: IReducerWithCallback<any>) => {
    try {
      const response = await api.part.createPart(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const updatePart = createAsyncThunk(
  "parts/updatePart",
  async (data: ReducerTypeForUpdate<PartFormData>, { dispatch }) => {
    try {
      const response = await api.part.updatePart(data);
      dispatch(getCurrentPart(data.id));
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const addFilesToPart = createAsyncThunk(
  "parts/addFilesToPart",
  async (data: IAddFilesToPart) => {
    try {
      const response = await api.part.addFilesToPart(data);
      notifySuccess("Files successfully uploaded to part!");
      return response.data;
    } catch (error) {
      notifyError("Couldn't upload files to part!");
      console.error(error);
      throw error;
    }
  },
);

const adjustPartLocation = createAsyncThunk(
  "parts/adjustPartLocation",
  async (
    {
      id,
      part_id,
      data,
      onError,
      onSuccess,
    }: IUpdateReducerWithCallback<IAdjustPartLocation> & { part_id: string },
    { dispatch },
  ) => {
    try {
      const response = await api.part.adjustPartLocation({ id, data, part_id });
      onSuccess && onSuccess();
      dispatch(getCurrentPart(part_id));
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const transferPartLocation = createAsyncThunk(
  "parts/transferPartLocation",
  async (
    {
      id,
      part_id,
      data,
      onError,
      onSuccess,
    }: IUpdateReducerWithCallback<ITransferPartLocation> & {
      part_id: string;
    },
    { dispatch },
  ) => {
    try {
      const response = await api.part.transferPartLocation({
        id,
        data,
        part_id,
      });
      onSuccess && onSuccess();
      dispatch(getCurrentPart(part_id));
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const editPartLocation = createAsyncThunk(
  "parts/editPartLocation",
  async (
    {
      id,
      part_id,
      data,
      onError,
      onSuccess,
    }: IUpdateReducerWithCallback<IEditPartLocation> & {
      part_id: string;
    },
    { dispatch },
  ) => {
    try {
      const response = await api.part.editPartLocation({
        id,
        data,
        part_id,
      });
      onSuccess && onSuccess();
      dispatch(getCurrentPart(part_id));
      return response.data;
    } catch (error) {
      onError && onError();
      console.error(error);
      throw error;
    }
  },
);

const createComment = createAsyncThunk(
  "part/createComment",
  async ({
    data,
    onError,
    onSuccess,
  }: IReducerWithCallback<IPartCommentAdd>) => {
    try {
      const response = await api.part.saveComment(data);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);

const deleteComment = createAsyncThunk(
  "part/createComment",
  async ({ id, onError, onSuccess }: IDeleteReducerWithCallback) => {
    try {
      const response = await api.part.deleteComment(id);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.();
      console.error(error);
      throw error;
    }
  },
);
export const addDocumentsToParts = createAsyncThunk(
  "parts/addDocumentsToParts",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.part.addDocumentsToParts({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getCurrentPart(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

export const removeDocumentsFromParts = createAsyncThunk(
  "parts/removeDocumentsFromParts",
  async (
    {
      id,
      files,
      onErrorUploadFiles,
      onSuccessUploadFiles,
    }: IAddFilesToEntityTypes,
    { dispatch },
  ) => {
    try {
      await api.part.removeDocumentsFromParts({ id, files });
      onSuccessUploadFiles?.();
      dispatch(getCurrentPart(id));
    } catch (error) {
      onErrorUploadFiles?.();
      console.error(error);
      throw error;
    }
  },
);

export {
  addFilesToPart,
  addMeasurement,
  addPartManufacture,
  addPartsCategories,
  addPartsLocation,
  adjustPartLocation,
  archivePart,
  createComment,
  createPart,
  deleteComment,
  deleteLocationPart,
  deletePart,
  editPartLocation,
  getArchivedParts,
  getCurrentPart,
  getMeasurements,
  getPartManufactures,
  getParts,
  getPartsAdjustmentReasons,
  getPartsByLocation,
  getPartsCategories,
  getPartsLocations,
  getPartsMetaData,
  getPartsMetaDataByLocation,
  transferPartLocation,
  unarchivePart,
  updatePart,
  updatePartsLocation,
  getPurchaseHistory,
  getWorkOrderActivity,
};
