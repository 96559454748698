import React from "react";
import Suspence from "Components/Layout/Suspence";
import { lazyLoader } from "Service/loader";
const ForgotPasswordStep2 = lazyLoader(
  () => import("Containers/ForgotPassword/ForgotPasswordStep2")
);

export const ForgotPassword2 = () => {
  return (
    <Suspence>
      <ForgotPasswordStep2 />
    </Suspence>
  );
};
