import React from "react";

import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";

const ReportsServiceRemindersList = lazyLoader(
  () =>
    import(
      "Containers/Reports/ReportsInnerPages/ReportsServiceReminders/ReportsServiceRemindersList/ReportsServiceRemindersList"
    )
);
2;

export const ReportsServiceReminders = () => {
  return (
    <Layout>
      <ReportsServiceRemindersList />
    </Layout>
  );
};
