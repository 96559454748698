import {
  IServiceTask,
  TAssemblyCode,
  TCodesSearchParams,
  TMaintenanceCategory,
  TReasonForRepairCode,
  TServiceTasksParams,
  TSystemCode,
} from "Shared/Types/serviceTask";
import {
  ARCHIVE_SERVICE_TASK_ID,
  SERVICE_TASK,
  SERVICE_TASK_ASSEMBLY_CODES,
  SERVICE_TASK_ASSEMBLY_CODES_CREATE,
  SERVICE_TASK_ID,
  SERVICE_TASK_ID_CREATE,
  SERVICE_TASK_ID_DELETE,
  SERVICE_TASK_ID_UPDATE,
  SERVICE_TASK_MAINTENANCE_CATEGORIES,
  SERVICE_TASK_MAINTENANCE_CATEGORIES_CREATE,
  SERVICE_TASK_REASON_FOR_REPAIR_CODES,
  SERVICE_TASK_REASON_FOR_REPAIR_CODES_CREATE,
  SERVICE_TASK_SYSTEM_CODES,
  SERVICE_TASK_SYSTEM_CODES_CREATE,
  UNARCHIVE_SERVICE_TASK_ID,
} from "Shared/Constants/apiRequest";
import { PaginatedResponse, ReducerTypeForUpdate } from "Shared/Types/shared";
import { TServiceTask } from "Shared/Utils/yup/serviceTask";

import { client } from "../axios";

class ServiceTask {
  getServiceTasks(params: TServiceTasksParams | null) {
    return client.get<PaginatedResponse<IServiceTask[]>>(SERVICE_TASK, {
      params,
    });
  }
  getServiceTaskById(id: string) {
    return client.get<IServiceTask>(SERVICE_TASK_ID(id));
  }

  archiveServiceTask(id: string) {
    return client.post<IServiceTask>(ARCHIVE_SERVICE_TASK_ID(id));
  }
  unarchiveServiceTask(id: string) {
    return client.post<IServiceTask>(UNARCHIVE_SERVICE_TASK_ID(id));
  }
  deleteServiceTask(id: string) {
    return client.delete<any>(SERVICE_TASK_ID_DELETE(id));
  }
  addServiceTask(data: TServiceTask) {
    return client.post<TServiceTask>(SERVICE_TASK_ID_CREATE, data);
  }
  editServiceTask({ id, data }: ReducerTypeForUpdate<TServiceTask>) {
    return client.patch<TServiceTask>(SERVICE_TASK_ID_UPDATE(id), data);
  }
  getSystemCodes(params: TCodesSearchParams | null) {
    return client.get<PaginatedResponse<TSystemCode[]>>(
      SERVICE_TASK_SYSTEM_CODES,
      { params },
    );
  }

  getMaintenanceCategories(params: TCodesSearchParams | null) {
    return client.get<PaginatedResponse<TMaintenanceCategory[]>>(
      SERVICE_TASK_MAINTENANCE_CATEGORIES,
      { params },
    );
  }
  getAssemblyCodes(params: TCodesSearchParams | null) {
    return client.get<PaginatedResponse<TAssemblyCode[]>>(
      SERVICE_TASK_ASSEMBLY_CODES,
      { params },
    );
  }
  getReasonForRepairCodes(params: TCodesSearchParams | null) {
    return client.get<PaginatedResponse<TReasonForRepairCode[]>>(
      SERVICE_TASK_REASON_FOR_REPAIR_CODES,
      { params },
    );
  }
  createSystemCodes({ data }: any) {
    return client.post(SERVICE_TASK_SYSTEM_CODES_CREATE, data);
  }

  createMaintenanceCategories({ data }: any) {
    return client.post(SERVICE_TASK_MAINTENANCE_CATEGORIES_CREATE, data);
  }
  createAssemblyCodes({ data }: any) {
    return client.post(SERVICE_TASK_ASSEMBLY_CODES_CREATE, data);
  }
  createReasonForRepairCodes({ data }: any) {
    return client.post(SERVICE_TASK_REASON_FOR_REPAIR_CODES_CREATE, data);
  }
}

export default ServiceTask;
