import { Layout } from "Components/Layout/Layout";
import { lazyLoader } from "Service/loader";
import React from "react";

const EquipmentTypeList = lazyLoader(
  () => import("Containers/Settings/EquipmentTypeList/EquipmentTypeList")
);
export const EquipmentType = () => {
  return (
    <Layout>
      <EquipmentTypeList />
    </Layout>
  );
};
