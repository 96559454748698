import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  addFaultRule,
  getCurrentFault,
  getCurrentFaultRule,
  getFaultCodesList,
  getFaultRulesList,
  getFaultsList,
} from "./reducer";
import { IFault, IFaultsCode, IFaultsRule } from "Shared/Types/faults";

interface bodyTypeState {
  error?: string | null;
  loading: boolean;
  success?: boolean | null;
  faultsList: IFault[];
  currentFault: IFault | null;
  faultsCodesList: IFaultsCode[];
  faultsRulesList: IFaultsRule[];
  currentFaultsRule: IFaultsRule | null;
  rulesCount: number;
  faultsNumPage: number;
}

const initialState: bodyTypeState = {
  loading: false,
  error: null,
  faultsList: [],
  currentFault: null,
  faultsCodesList: [],
  faultsRulesList: [],
  currentFaultsRule: null,
  rulesCount: 0,
  faultsNumPage: 0,
};

const FaultsSlice = createSlice({
  name: "faults",
  initialState,
  reducers: {
    clear(state) {
      state.error = null;
      state.loading = false;
      state.success = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFaultsList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getFaultsList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.faultsList = action.payload.results;
      state.faultsNumPage = action.payload.count;
    });
    builder.addCase(getFaultsList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getCurrentFault.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getCurrentFault.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentFault = action.payload;
    });
    builder.addCase(getCurrentFault.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getFaultCodesList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getFaultCodesList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.faultsCodesList = action.payload.results;
    });
    builder.addCase(getFaultCodesList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getFaultRulesList.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getFaultRulesList.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.faultsRulesList = action.payload.results;
      state.rulesCount = action.payload.count;
    });
    builder.addCase(getFaultRulesList.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getCurrentFaultRule.pending, (state) => {
      state.loading = true;
      state.error = null;
      state.success = false;
    });
    builder.addCase(getCurrentFaultRule.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.currentFaultsRule = action.payload.results;
    });
    builder.addCase(getCurrentFaultRule.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(addFaultRule.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { clear } = FaultsSlice.actions;

export const selectFaultsList = (state: RootState) => state.faults.faultsList;
export const selectFaultsNumPage = (state: RootState) =>
  state.faults.faultsNumPage;
export const selectCurrentFault = (state: RootState) =>
  state.faults.currentFault;
export const selectFaultsRulesList = (state: RootState) =>
  state.faults.faultsRulesList;
export const selectRulesCount = (state: RootState) => state.faults.rulesCount;
export const selectCurrentFaultsRule = (state: RootState) =>
  state.faults.currentFaultsRule;
export const selectFaultsCodesList = (state: RootState) =>
  state.faults.faultsCodesList;
export const selectFaultsAuthLoading = (state: RootState) =>
  state.faults.loading;
export const selectFaultsAuthError = (state: RootState) => state.faults.error;
export const selectFaultsAuthSuccess = (state: RootState) =>
  state.faults.success;

export default FaultsSlice;
