import { client } from "Service/axios";
import {
  REMINDERS_SERVICES,
  REMINDERS_SERVICES_COMMENTS_CREATE,
  REMINDERS_SERVICES_COMMENTS_DELETE,
  REMINDERS_SERVICES_DELETE,
  REMINDERS_SERVICES_ID,
  REMINDERS_SERVICES_CREATE,
  REMINDERS_SERVICES_UPDATE,
  REMINDERS_SERVICES_RESOLVE_BY_SERVICE_TASK,
  REMINDERS_SERVICES_SNOOZE,
  REMINDERS_SERVICES_DELETE_MULTIPLE,
  REMINDERS_SERVICES_RESUME,
  REMINDERS_SERVICES_DELETE_DOCUMENT,
  REMINDERS_SERVICES_ADD_DOCUMENT,
  REMINDERS_SERVICES_RESTART,
  REMINDERS_SERVICES_CREATE_BULK_VEHICLE,
} from "Shared/Constants/apiRequest";
import {
  ServiceReminder,
  ServiceReminderRequest,
  ServiceReminderComment,
  ResolveServiceReminderRequest,
  ServiceReminderRead,
  PatchedUpdateServiceReminderRequest,
  ServiceReminderSnoozeRequest,
  ServiceReminderSnooze,
  ServiceReminderBulkCreateRequest,
} from "Shared/Types/fleetmanagementapi";
import { IComment } from "Shared/Types/comment";
import {
  IReminderServiceSearch,
  TStatisticsServiceReminder,
} from "Shared/Types/reminder";
import { IAddFilesToEntityTypes } from "Shared/Types/file";

class ReminderServices {
  getReminderServicesLIst(params: IReminderServiceSearch | null) {
    return client.get<TStatisticsServiceReminder>(REMINDERS_SERVICES, {
      params,
    });
  }
  getReminderServiceDetail(id: string) {
    return client.get(REMINDERS_SERVICES_ID(id));
  }
  deleteReminderServices(id: string) {
    return client.delete(REMINDERS_SERVICES_DELETE(id));
  }
  multiplyDeletedReminderService({ ids }: { ids: string[] }) {
    return client.post<any>(REMINDERS_SERVICES_DELETE_MULTIPLE, {
      ids: ids,
    });
  }

  createReminderService(data: ServiceReminderRequest) {
    return client.post<ServiceReminder>(REMINDERS_SERVICES_CREATE, data);
  }
  createReminderServiceWithBulkVehicles(
    data: ServiceReminderBulkCreateRequest,
  ) {
    return client.post<ServiceReminderBulkCreateRequest>(
      REMINDERS_SERVICES_CREATE_BULK_VEHICLE,
      data,
    );
  }
  editReminderService(id: string, data: ServiceReminderBulkCreateRequest) {
    return client.put<ServiceReminder>(REMINDERS_SERVICES_UPDATE(id), data);
  }
  saveComment(data: ServiceReminderComment) {
    return client.post<IComment>(REMINDERS_SERVICES_COMMENTS_CREATE, data);
  }
  deleteComment(id: string) {
    return client.delete(REMINDERS_SERVICES_COMMENTS_DELETE(id));
  }
  resolveServiceReminderByServiceTask(
    id: string,
    data: ResolveServiceReminderRequest,
  ) {
    return client.post<ServiceReminderRead>(
      REMINDERS_SERVICES_RESOLVE_BY_SERVICE_TASK(id),
      data,
    );
  }
  addWorkOrderToServiceReminder(
    id: string,
    data: PatchedUpdateServiceReminderRequest,
  ) {
    return client.patch<ServiceReminderRead>(
      REMINDERS_SERVICES_UPDATE(id),
      data,
    );
  }
  snoozeServiceReminder(id: string, data: ServiceReminderSnoozeRequest) {
    return client.post<ServiceReminderSnooze>(
      REMINDERS_SERVICES_SNOOZE(id),
      data,
    );
  }
  resumeServiceReminder(id: string) {
    return client.post<any>(REMINDERS_SERVICES_RESUME(id));
  }
  restartServiceReminder(id: string) {
    return client.patch<any>(REMINDERS_SERVICES_RESTART(id));
  }

  addDocumentsToServiceReminder({ id, files }: IAddFilesToEntityTypes) {
    return client.post<any>(REMINDERS_SERVICES_ADD_DOCUMENT(id), {
      documents: files,
    });
  }
  removeDocumentsFromServiceReminder({ id, files }: IAddFilesToEntityTypes) {
    return client.post<any>(REMINDERS_SERVICES_DELETE_DOCUMENT(id), {
      documents: files,
    });
  }
}

export default ReminderServices;
