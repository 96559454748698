import React, { FC } from "react";
import MUILink, { LinkProps as MUILinkProps } from "@mui/material/Link";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

type Props = MUILinkProps & RouterLinkProps;

const Link: FC<Props> = ({ ...props }) => {
  return (
    <MUILink
      onClick={(e) => e.stopPropagation()}
      {...props}
      component={RouterLink}
    />
  );
};

export default Link;
