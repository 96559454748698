import React from "react";
import { useNavigate } from "react-router";
import { useModal } from "@ebay/nice-modal-react";

import IconCircleFrame from "Components/UI/IconCircleFrame/IconCircleFrame";
import {
  ExportDocumentIcon,
  ImportIcon,
  ThreeDotsHorizontalIcon,
} from "Assets";
import { IMPORT_FILES } from "Shared/Constants/routes";
import { ExportFilesModal } from "Containers/ExportFiles/ExportFilesModal/ExportFilesModal";

import Meatballs, { MeatballsCallback } from "../../UI/Meatballs/Meatballs";

type ImportExportButtonProps = {
  /** Use keys from importSectionNames object */
  link: string;
  isExport?: boolean;
  isImport?: boolean;
  currentParams?: Record<string, string>;
};

const ImportExportButton: React.FC<ImportExportButtonProps> = ({
  link,
  isImport = true,
  isExport = true,
  currentParams,
}) => {
  const navigate = useNavigate();
  const exportModal = useModal(ExportFilesModal);
  if (!isImport && !isExport) return null;
  const goToImportPage = () => {
    navigate(`${IMPORT_FILES}/?link=${link}`);
  };

  const onExportClick = () => {
    exportModal.show({ template: link, currentParams });
  };

  const tableMeatballsOptions = () => {
    const options = [];

    if (isExport) {
      options.push({
        label: "Export",
        key: "export",
        icon: <ExportDocumentIcon />,
      });
    }
    if (isImport) {
      options.push({ label: "Import", key: "import", icon: <ImportIcon /> });
    }

    return options;
  };

  const onMeatballsChange: MeatballsCallback<any> = (value, optionKey) => {
    if (optionKey === "import") goToImportPage();
    if (optionKey === "export") onExportClick();
  };

  return (
    <Meatballs<any>
      value={{}}
      onChange={onMeatballsChange}
      options={tableMeatballsOptions()}
    >
      <IconCircleFrame bgColor="transparent" icon={ThreeDotsHorizontalIcon} />
    </Meatballs>
  );
};

export default ImportExportButton;
